
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";

const PropertyStore = namespace("PropertyStore");
@Component({ components: {} })
class RemoveCartItemModal extends Vue {
  @PropertyStore.State
  public property!: Property;
}
export default RemoveCartItemModal;
