import { render, staticRenderFns } from "./MpFooter.vue?vue&type=template&id=30cc6b83&scoped=true&"
import script from "./MpFooter.vue?vue&type=script&lang=ts&"
export * from "./MpFooter.vue?vue&type=script&lang=ts&"
import style1 from "./MpFooter.vue?vue&type=style&index=1&id=30cc6b83&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30cc6b83",
  null
  
)

export default component.exports