
import { fetchSimple } from "@/utils/fetchUtil";
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class HealthChecks extends Vue {
  private isTest = location.href.includes("localhost");
  private loading = true;
  private checks = Array<string>();

  private API_URI =
    "https://api.lodgevault.com/api/book/units/1C77120C-F4EB-4BD2-88EB-171112E7D5FD";

  private LOCAL_URI =
    "http://localhost:49154/api/book/units/1C77120C-F4EB-4BD2-88EB-171112E7D5FD";

  async created() {
    this.loading = true;
    await this.getHealthChecks();
  }

  async getHealthChecks() {
    this.API_URI = this.isTest ? this.LOCAL_URI : this.API_URI;
    this.checks.push("calling endpoint " + this.API_URI);
    fetchSimple(this.API_URI, null, "GET")
      .then((p) => {
        this.checks.push("success: call was successful");
      })
      .catch((ex) => {
        this.checks.push("unhealthy : " + ex.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  checkIsUnhealthy(check: string) {
    return check.toLowerCase().startsWith("unhealthy");
  }
  checkIsHealthy(check: string) {
    return check.toLowerCase().startsWith("success");
  }
}
