import { axiosRetry } from "@/utils/fetchUtil";

export class AddOnInUnit {
    constructor() {
    }
    public chargeItemGuid!: string;
    public unitGuid!: string;
    public rateGuid!: string;
}

export async function getAddOnsInUnit(unitGuid: string): Promise<Array<AddOnInUnit>> {
    const response = await axiosRetry(
        `/api/chargeitem/getaddonsinunit/${unitGuid}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<AddOnInUnit>>;
    } else {
        const err =
            "An error occurred getting the customQuestion from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function saveAddOnInUnit(unitGuid: string, chargeItemGuid: string): Promise<boolean> {
    const response = await axiosRetry(
        `/api/chargeitem/saveaddoninunit/${unitGuid}/${chargeItemGuid}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the customQuestion from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function deleteAddOnInUnit(unitGuid: string, chargeItemGuid: string): Promise<boolean> {
    const response = await axiosRetry(
        `/api/chargeitem/deleteaddoninunit/${unitGuid}/${chargeItemGuid}`,
        null,
        "DELETE"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the customQuestion from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
