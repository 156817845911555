
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { UnitGroup } from "@/models/UnitGroup";
import { getUnitGroups } from "../Cloud/Units/UnitGroupService";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { },
})
export default class UnitGroups extends Vue {
  private gridMode = false;
  private typeMode = true;
  private busy = false;
  private unitGroups = new Array<UnitGroup>();

  async mounted() {
    this.busy = true;
    if (this.property) {
      this.busy = true;
      this.unitGroups = await getUnitGroups(this.property.propertyGuid);
      if (this.unitGroups.length == 0) {
        this.$router.push({
          name: "Units",
        });
      }
    }
    this.busy = false;
  }

  navigateToUnits(param: string) {
   // this.setUnitGroup(param);
    this.$router.push({
      name: "Units",
      query: { group: param },
    });
  }

  @PropertyStore.State
  public property!: Property;

 // @UnitGroupStore.Action
 // public setUnitGroup!: (unitGroupGuid: string) => void;
}
