
import Property from "@/models/Property";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "../Auth/AuthService";
import { LoginCredentials } from "../Auth/LoginCredentials";
import TeamMember from "./TeamMember.vue";
import { User } from "../Auth/User";
import { Field } from "@/models/interfaces/Field";
import ConfirmModal from "@/components/Controls/ConfirmModal.vue";

const Auth = namespace("AuthStore");
const PropertyStore = namespace("PropertyStore");
@Component({
  components: { TeamMember, ConfirmModal },
})
class Team extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Auth.State("user")
  private currentUser!: User;

  private loading = true;
  private showTeamMemberModal = false;
  private members = new Array<User>();
  private name = "";
  private nameState: boolean | null = null;
  private showProgressOverlay = false;
  private teamFields: Array<Field> = [];
  private showConfirmModal = false;
  private clickedMemberEmail!: string;

  formatDate(dateFor: Date) {
    if (new Date(dateFor).isMinDate()) {
      return "never";
    }
    return new Date(dateFor).formatDayOfWeekMMDDYYYY();
  }

  closeConfirmModal() {
    this.showConfirmModal = false;
  }

  confirmDelete(teamMemberEmail: string) {
    this.clickedMemberEmail = teamMemberEmail;
    this.showConfirmModal = true;
  }

  async deleteTeamMember() {
    this.showConfirmModal = false;
    this.showProgressOverlay = true;
    this.loading = true;
    await AuthService.deleteTeamMember(this.clickedMemberEmail);
    await this.loadTeam();
    this.showProgressOverlay = false;
    this.loading = false;
    this.showConfirmModal = false;
  }

  private getTeamFields() {
    let field = new Field();
    field.key = "fullName";
    field.label = "Full Name";
    this.teamFields.push(field);

    field = new Field();
    field.key = "email";
    field.label = "Email";
    this.teamFields.push(field);

    field = new Field();
    field.key = "emailConfirmed";
    field.label = "Confirmed";
    this.teamFields.push(field);

    field = new Field();
    field.key = "role";
    field.label = "Role";
    this.teamFields.push(field);

    field = new Field();
    field.key = "lastLogin";
    field.label = "Last Login";
    this.teamFields.push(field);

    field = new Field();
    field.key = "delete";
    field.label = "Delete";
    this.teamFields.push(field);
  }

  async addNewTeamMember() {
    this.showTeamMemberModal = true;
  }

  closeModal() {
    this.showTeamMemberModal = false;
    this.loadTeam();
  }

  async created() {
    this.loading = true;
    await this.loadTeam();
    this.loading = false;
  }

  mounted() {
    this.getTeamFields();
  }

  async loadTeam() {
    this.members = await AuthService.getUsersForProperty(
      this.property.propertyGuid
    );
  }
}
export default Team;
