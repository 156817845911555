
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Invoice, InvoiceItemKind } from "@/components/Cloud/Invoices/Invoice";
import {
  getInvoice,
  saveInvoice,
} from "@/components/Cloud/Invoices/InvoiceService";
import { Guid } from "@/utils/Guid";
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import { getReservation } from "@/components/Cloud/Reservations/ReservationService";
import { getPrimaryInvoice } from "@/components/Cloud/Invoices/InvoiceService";
import { IInvoiceItem } from "@/components/Cloud/Invoices/IInvoiceItem";
import { Field } from "@/models/interfaces/Field";
import { Person } from "../People/Person";
import { getPerson } from "../People/PersonService";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import AddCharge from "./AddCharge.vue";
import AddPayment from "./AddPayment.vue";
import { deleteCharge, saveCharge, saveCharges } from "./ChargeService";
import { Payment } from "./Payment";
import { Charge } from "./Charge";
import { deletePayment, savePayment } from "./PaymentService";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { export_table_to_csv } from "@/utils/exportToCsv";
import TemplateEdit from "../Templates/TemplateEdit.vue";
import {
  getSendInvoiceTemplate,
  getTemplates,
  Template,
} from "@/models/Template";
import { ResStatus } from "../Reservations/ResStatus";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { AddPayment, AddCharge, ConfirmDeleteModal, TemplateEdit },
})
export default class InvoiceEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public invoiceGuid!: string;

  private locked = false;
  private res!: Reservation;
  private person = new Person();
  private invoice = new Invoice();
  private showEditModal = false;
  private loading = false;
  private InvoiceIndex = 0;
  private invoiceItems: Array<IInvoiceItem> = [];
  private fields: Array<Field> = [];
  private selectedCell = "";
  private showAddChargeModal = false;
  private showAddPaymentModal = false;
  private chargesAndPaymentsTable: any;
  private selected = new Array<any>();
  private showConfirmModal = false;
  public templateSelected!: Template | null;
  private showTemplateSendModal = false;
  private templates: Template[] = [];
  private isLocal = window.location.href.indexOf("localhost") > -1;
  private baseUrl = this.isLocal
    ? "http://localhost:8080"
    : "https://book.lodgevault.com";
  private templateMode = "print";

  getLocked(): boolean {
    let locked = false; // for when it's paid in full and complete

    // if (
    //   this.res &&
    //   this.res.status == ResStatus.CheckedOut &&
    //   this.invoice.balance == 0 &&
    //   this.invoice.totalCharges !== 0
    // ) {
    //   locked = true;
    // }
    // if (
    //   !this.res &&
    //   this.invoice.balance == 0 &&
    //   this.invoice.totalCharges !== 0
    // ) {
    //   locked = true;
    // }
    return locked;
  }

  async send() {
    this.invoice = await saveInvoice(this.invoice);
    // merge and show the standard invoice template
    // once sent, then mark the date and save the invoice
    this.templateMode = "send";
    this.templateSelected = await getSendInvoiceTemplate(
      this.invoice.invoiceGuid
    );
    this.showTemplateSendModal = true;
  }

  getTemplateMode() {
    return this.templateMode;
  }

  async customEmail() {
    this.templateMode = "send";
    this.templateSelected = null;
    this.showTemplateSendModal = true;
  }

  getInvoicePayUrl() {
    return this.baseUrl + "/invoicepay/" + this.invoice.invoiceGuid;
  }

  navigateToInvoicePay() {
    window.open(this.getInvoicePayUrl(), "_blank");
  }

  async mergeAndDisplayTemplate(template: Template) {
    this.templateMode = "send";
    await (this.templateSelected = template);
    await (this.showTemplateSendModal = true);
  }
  async printTemplate(template: Template) {
    this.templateMode = "print";
    await (this.templateSelected = template);
    await (this.showTemplateSendModal = true);
  }

  async templatesent() {
    if (
      this.invoice.sentDate == null &&
      this.templateSelected?.name.startsWith("Collect")
    ) {
      this.invoice.sentDate = new Date();
      this.invoice = await saveInvoice(this.invoice);
    }
    this.showTemplateSendModal = false;
  }
  getSelectedTemplateGuid() {
    if (this.templateSelected) {
      return this.templateSelected.templateGuid;
    } else {
      return null;
    }
  }
  getReservationGuid() {
    return this.res?.reservationGuid;
  }
  getRes() {
    if (this.res) {
      return this.res;
    }
  }
  getInvoiceGuid() {
    return this.invoice.invoiceGuid;
  }
  getPersonGuid() {
    return this.invoice.personGuid;
  }
  getSendToEmailAddress() {
    return this.person?.email;
  }

  getAssignsOnRes() {
    if (this.res && this.res.assigns) {
      return this.res.assigns;
    }
  }

  async exportTableToExcel() {
    this.loading = true;
    var csvTable = this.$el.querySelector("#chargesAndPaymentsTableRef"); // this is ID
    if (csvTable) {
      await export_table_to_csv(
        csvTable,
        "Invoice-" + this.invoice.invoiceGuid + ".csv"
      );
    }
    this.loading = false;
  }

  formatDate(dateTo: Date) {
    if (new Date(dateTo).isMinDate()) {
      return "";
    }
    return new Date(dateTo).readableDate(false);
  }

  readableDate(dateObj: Date) {
    return new Date(dateObj).formatDayOfWeekMMDDYY();
  }

  sumTax(taxIndex: number) {
    // sum all invoice items with this tax amount
    let sum: number = 0;
    if (this.invoiceItems) {
      for (const item of this.invoiceItems) {
        if (this.isCharge(item.kind)) {
          let charge: Charge = <Charge>item;
          sum += charge["tax" + taxIndex + "Total"];
        }
      }
    }
    return sum;
  }

  getClassFromKind(kind: InvoiceItemKind) {
    return InvoiceItemKind[kind].toLowerCase();
  }

  isPayment(kind: InvoiceItemKind) {
    return (
      kind == InvoiceItemKind.Payment ||
      kind == InvoiceItemKind.Deposit ||
      kind == InvoiceItemKind.Certificate ||
      kind == InvoiceItemKind.Refund
    );
  }

  isCharge(kind: InvoiceItemKind) {
    return (
      kind == InvoiceItemKind.Charge ||
      kind == InvoiceItemKind.UnitRate ||
      kind == InvoiceItemKind.Component ||
      kind == InvoiceItemKind.Service
    );
  }

  getInvoiceItemKindReadableString(kind: InvoiceItemKind): string {
    return InvoiceItemKind[kind];
  }
  getInvoiceItemKindVariant(kind: InvoiceItemKind): string {
    return new String(InvoiceItemKind[kind]).toLowerCase();
  }

  getTotalDiscounts() {
    // all charges where the discount is not zero
    let sum: number = 0;
    if (this.invoiceItems) {
      this.invoiceItems.forEach((i) => {
        if (this.isCharge(i.kind)) {
          let charge: Charge = <Charge>i;
          sum += charge.discountAmount;
        }
      });
    }
    return sum;
  }
  getTotalTax() {
    // all charges where the discount is not zero
    let sum: number = 0;
    if (this.invoiceItems) {
      this.invoiceItems.forEach((i) => {
        if (this.isCharge(i.kind)) {
          let charge: Charge = <Charge>i;
          sum +=
            charge.tax1Total +
            charge.tax2Total +
            charge.tax3Total +
            charge.tax4Total +
            charge.tax5Total +
            charge.tax6Total +
            charge.tax7Total +
            charge.tax8Total +
            charge.tax9Total +
            charge.tax10Total;
        }
      });
    }
    return sum;
  }

  getTotalWithTax() {
    // all charges where the discount is not zero
    let sum: number = 0;
    if (this.invoiceItems) {
      this.invoiceItems.forEach((i) => {
        if (this.isCharge(i.kind)) {
          let charge: Charge = <Charge>i;
          sum += charge.totalPrice;
        }
      });
    }
    return sum;
  }
  getTotalPaid() {
    // all charges where the discount is not zero
    let sum: number = 0;
    if (this.invoiceItems) {
      this.invoiceItems.forEach((i) => {
        if (this.isPayment(i.kind)) {
          let payment: Payment = <Payment>i;
          sum += payment.totalPrice;
        }
      });
    }
    return sum;
  }

  getBalance() {
    // all charges where the discount is not zero
    let sum: number = 0;
    if (this.invoiceItems) {
      this.invoiceItems.forEach((i) => {
        sum += i.totalPrice;
      });
    }
    return sum;
  }

  chargesPreTax() {
    let sum: number = 0;
    if (this.invoiceItems) {
      this.invoiceItems.forEach((i) => {
        if (
          i.kind == InvoiceItemKind.Charge ||
          i.kind == InvoiceItemKind.UnitRate
        ) {
          sum += i.preTaxSubTotal;
        }
      });
    }
    return sum;
  }

  newPaymentsAdded(arg: any) {
    this.loading = true;
    let addedPayments: Array<Payment> = arg;
    addedPayments.forEach((c) => this.invoiceItems.push(c));
    this.loading = false;
  }
  async newChargesAdded(arg: any) {
    this.loading = true;
    let addedCharges: Array<Charge> = arg;
    addedCharges = await saveCharges(addedCharges);
    for (let c of addedCharges) {
      await this.invoiceItems.push(c);
    }
    this.loading = false;
  }
  closeAddCharge() {
    this.showAddChargeModal = false;
  }

  cancelDeletion() {
    this.showConfirmModal = false;
  }

  formatCurrency(num: number) {
    if (!this.invoice) {
      return "usd";
    }

    let isNum = typeof num === "number" && num === num;
    if (!isNum) {
      return "";
    }

    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  getPaymentDiscount(num: number) {
    num = num * 100;
    return this.getPercentage(num);
  }

  getChargeDiscount(num: number) {
    num = num * 100;
    return this.getPercentage(num);
  }

  getPercentage(num: number) {
    let isNum = typeof num === "number" && num === num;
    if (!isNum) {
      return "";
    }

    var s = Number(num / 100).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return s;
  }

  confirmDelete() {
    this.showConfirmModal = true;
  }

  getReadableDateTime(dateToFormat: Date) {
    if (new Date(dateToFormat).isDate() == false) {
      return "";
    }
    return new Date(dateToFormat).readableDateLocalized();
  }

  addCharge() {
    this.showAddChargeModal = true;
  }

  addPayment() {
    this.showAddPaymentModal = true;
  }
  getActivePaymentGuid() {}
  getActiveChargeGuid() {}
  closeAddPaymentModal() {
    this.showAddPaymentModal = false;
  }
  afterCloseNewPaymentModal() {}
  afterAddCharge() {}
  afterAddPayment() {}
  closeAddChargeModal() {}

  async deleteInvoiceItems() {
    if (this.selected && this.selected.length > 0) {
      let result = false;

      const arrayOfGuids = new Array<Guid>();
      const invoiceItems = new Array<IInvoiceItem>();
      this.selected.forEach((i) => {
        arrayOfGuids.push(i.itemGuid);
        invoiceItems.push(i);
      });

      invoiceItems.forEach((item) => {
        if (item.hasOwnProperty("paymentGuid")) {
          //delete the payment
          let paymentGuid = item["paymentGuid"];
          deletePayment(paymentGuid.toString());
          result = true;
        } else if (item.hasOwnProperty("chargeGuid")) {
          // delete the charge
          let chargeGuid = item["chargeGuid"];
          deleteCharge(chargeGuid.toString());
          result = true;
        }

        if (result == true) {
          if (this.invoiceItems) {
            let index = this.invoiceItems.findIndex(
              (a) => a.itemGuid === item.itemGuid
            );
            this.invoiceItems.splice(index, 1);
          }
        }
      });
      this.showConfirmModal = false;
    }
    await this.chargesAndPaymentsTable.refresh();
  }

  onRowSelected(items: any) {
    this.invoiceItems.forEach((item) => (item.isEdit = false));
    this.selected = items;
    this.$forceUpdate();
  }

  expand() {
    this.$router.push({
      name: "InvoiceEdit",
      params: {
        id: this.invoiceGuid.toString(),
      },
    });
  }

  navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }

  private getFields() {
    let field = {
      key: "chargeDate",
      label: "Date",
      sortable: true,
      tdClass: "",
    };
    this.fields.push(field);

    //  field = new Field();
    field = {
      key: "itemName",
      label: "Item",
      sortable: true,
      tdClass: "",
    };
    this.fields.push(field);
    // field = new Field();
    field = {
      key: "preTaxPrice",
      label: "Pre Tax",
      sortable: true,
      tdClass: "",
    };
    this.fields.push(field);

    // field = new Field();
    field = {
      key: "quantity",
      label: "Qty",
      sortable: true,
      tdClass: "",
    };
    this.fields.push(field);
    // field = new Field();
    field = {
      key: "discount",
      label: "Discount",
      sortable: true,
      tdClass: "",
    };
    this.fields.push(field);

    // field = new Field();
    field = {
      key: "preTaxSubTotal",
      label: "Sub Total",
      sortable: true,
      tdClass: "",
    };

    this.fields.push(field);

    for (let i = 0; i <= 10; i++) {
      if (
        this.property.preference &&
        this.property.preference["tax" + i.toString() + "Label"] &&
        this.property.preference["tax" + i.toString() + "Label"] != ""
      ) {
        field = {
          key: "tax" + i.toString() + "Total",
          label: this.property.preference[
            "tax" + i.toString() + "Label"
          ] as string,
          sortable: true,
          tdClass: "",
        };
        this.fields.push(field);
      }
    }

    field = {
      key: "totalPrice",
      label: "Total",
      sortable: true,
      tdClass: "",
    };
    this.fields.push(field);
    field = {
      key: "unitName",
      label: "Unit",
      sortable: true,
      tdClass: "",
    };
    this.fields.push(field);
    field = {
      key: "kind",
      label: "Kind",
      sortable: true,
      tdClass: "",
    };
    this.fields.push(field);
    field = {
      key: "number",
      label: "Number",
      sortable: true,
      tdClass: "",
    };

    //   field = { key: "totalPrice", label: "Total", sortable: true, tdClass: "" };
    this.fields.push(field);
  }

  editCellHandler(data: any, name: string) {
    this.invoiceItems.forEach((item) => (item.isEdit = false));
    this.invoiceItems[data.index].isEdit = true;
    this.selectedCell = name;
  }

  async editCellCompleteHandler(data: any) {
    let chargeOrPayment = data.item;
    let result: IInvoiceItem;

    if (chargeOrPayment.hasOwnProperty("paymentGuid")) {
      chargeOrPayment.discount = chargeOrPayment.discount * 0.01;
      result = await savePayment(chargeOrPayment);
      this.replaceInvoiceItem(result);
    } else if (chargeOrPayment.hasOwnProperty("chargeGuid")) {
      chargeOrPayment.discount = chargeOrPayment.discount * 0.01;
      result = await saveCharge(chargeOrPayment);
      this.replaceInvoiceItem(result);
    }
    this.invoiceItems.forEach((item) => (item.isEdit = false));
  }

  async replaceInvoiceItem(replacement: IInvoiceItem) {
    this.loading = true;
    let found = this.invoiceItems.find(
      (a) => a.itemGuid === replacement.itemGuid
    );
    if (!found) {
      await this.invoiceItems.unshift(replacement);
    }
    let index = await this.invoiceItems.findIndex(
      (a) => a.itemGuid === replacement.itemGuid
    );
    this.invoiceItems[index] = replacement;
    await this.chargesAndPaymentsTable.refresh();
    this.chargesAndPaymentsTable.selectRow(index);
    this.loading = false;
  }

  public cancel() {
    this.loading = true;
    this.$emit("cancel");
    if (this.isExpandedMode()) {
      this.navigate("Invoices", "");
    }
    this.loading = false;
  }

  async save() {
    this.loading = true;
    this.invoice = await saveInvoice(this.invoice);
    if (this.isExpandedMode()) {
      this.navigate("Invoices", "");
    }

    this.$emit("success", this.invoice);
    this.loading = false;
  }

  async mounted() {
    this.loading = true;
    this.chargesAndPaymentsTable = this.$refs.chargesAndPaymentsTableRef;
    let invGuid!: string;
    this.getFields();
    if (this.invoiceGuid) {
      invGuid = this.invoiceGuid.toString();
    } else if (this.$route.params.id) {
      invGuid = this.$route.params.id.toString();
    } else if (this.$route.query.invoiceGuid) {
      invGuid = this.$route.query.invoiceGuid.toString();
    }
    if (!invGuid) {
      this.invoice = new Invoice();
      this.invoice.invoiceGuid = Guid.newGuid();
      this.invoice.propertyGuid = this.property.propertyGuid;
      this.invoice.createdDate = new Date();
    } else {
      this.invoice = await getInvoice(invGuid);
    }

    if (
      this.invoice &&
      this.invoice.reservationGuid &&
      this.invoice.reservationGuid !== Guid.emptyGuid()
    ) {
      this.res = await getReservation(this.invoice.reservationGuid.toString());
    }
    if (
      this.invoice &&
      this.invoice.personGuid &&
      Guid.isEmptyGuid(this.invoice.invoiceGuid) == false
    ) {
      try {
        this.person = await getPerson(this.invoice.personGuid);
      } catch {}
    }

    this.loadInvoiceItems();

    this.templates = await getTemplates(this.property.propertyGuid);
    this.locked = this.getLocked();
    this.loading = false;
  }

  async loadInvoiceItems() {
    this.invoiceItems = [];
    if (this.invoice && this.invoice.charges) {
      this.invoice.charges.forEach((c) => {
        this.invoiceItems.push(c);
      });
    }

    if (this.invoice && this.invoice.payments) {
      this.invoice.payments.forEach((p) => {
        this.invoiceItems.push(p);
      });
    }

    this.invoiceItems = this.invoiceItems.map((item) => ({
      ...item,
      isEdit: true,
    }));
  }

  isExpandedMode() {
    return this.$route.name?.toLowerCase() == "invoiceedit";
  }
}
