import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { axiosRetry } from '@/utils/fetchUtil';
import { ChargeItem } from '@/components/Cloud/ChargeItems/ChargeItem';

@Module({ namespaced: true })
class ChargeItemStore extends VuexModule {
    public chargeItems!: Array<ChargeItem>;
    public incidentals: Array<ChargeItem> = [];
    public haveFetched = false;
    public haveFetchedIncidentals = false;

    @Action
    public fetchIncidentals(propertyGuid: string): Promise<boolean> {
        if (this.haveFetchedIncidentals) {
            return Promise.resolve(true)
        } else {
            return axiosRetry(`/api/cart/incidentals/${propertyGuid}`, null, "GET")
                .then(response => {
                    this.context.commit('saveIncidentals', response.data)
                    return true
                })
                .catch(() => {
                    console.log({
                        title: 'Error',
                        type: 'error',
                        message: 'Could not fetch the list'
                    })
                    return false
                })
        }
    }

    @Action
    public fetchAllChargeItems(propertyGuid: string): Promise<boolean> {
        if (this.haveFetched) {
            return Promise.resolve(true)
        } else {
            return axiosRetry(`/api/cart/chargeitems/${propertyGuid}`, null, "GET")
                .then(response => {
                    this.context.commit('saveChargeItems', response.data)
                    return true
                })
                .catch(() => {
                    console.log({
                        title: 'Error',
                        type: 'error',
                        message: 'Could not fetch the list'
                    })
                    return false
                })
        }
    }

    @Mutation
    public saveChargeItems(data: Array<ChargeItem>): void {
        this.haveFetched = true;
        this.chargeItems = data;
    }
    @Mutation
    public saveIncidentals(data: Array<ChargeItem>): void {
        this.haveFetchedIncidentals = true;
        this.incidentals = data;
    }
}
export default ChargeItemStore
