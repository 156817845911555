
import AppHeader from "@/components/Cloud/partials/AppHeader.vue";
import AppNav from "@/components/Cloud/partials/AppNav.vue";
import Property from "@/models/Property";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { AppHeader, AppNav },
})
class LayoutCloud extends Vue {
  private loading = true;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;

  onWindowResized() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  async created() {
    this.loading = true;
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    let tempPropGuid = "";
    if (localStorage.getItem("propertyGuid")) {
      tempPropGuid = localStorage.getItem("propertyGuid")!.toString();
      await this.getProperty(tempPropGuid);
    }
  }

  async getProperty(propGuid: string) {
    await this.getPropertyFromServer(propGuid)
      .then((p) => {
        this.setProperty(p);
        localStorage.setItem("propertyGuid", p.propertyGuid);

        if (p.name) {
          document.title = p.name + " - Lodge Vault";
        }
      })
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => (this.loading = false));
  }

  scrollToTop() {
    const myDiv = document.getElementById("book-wrap");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  @PropertyStore.State
  public property!: Property;

  @PropertyStore.Action
  public setProperty!: (prop: Property) => void;

  @PropertyStore.Action
  public getPropertyFromServer!: (propGuid: string) => Promise<Property>;
}
export default LayoutCloud;
