
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Reservation } from "../Reservations/Reservation";
import { getReservation } from "../Reservations/ReservationService";
import { export_table_to_csv } from "@/utils/exportToCsv";
import { Payment } from "../Invoices/Payment";
import InvoiceEdit from "../Invoices/InvoiceEdit.vue";
import { getPayments } from "../Invoices/PaymentService";
import { Invoice, InvoiceItemKind } from "../Invoices/Invoice";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { InvoiceEdit } })
export default class PaymentTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  @Prop({ default: "daterange" }) public mode!: string; // 'property', 'daterange' ??
  private loading = true;
  private showInvoiceModal = false;
  private payments: Array<Payment> = [];
  private fields: Array<Field> = [];
  private activePayment = new Payment();
  private paymentTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;
  private reservation!: Reservation;
  private startDate = new Date().addDays(-7);
  private endDate = new Date();

  getPaymentDiscount(num: number) {
    num = num * 100;
    return this.getPercentage(num);
  }

  getPercentage(num: number) {
    let isNum = typeof num === "number" && num === num;
    if (!isNum) {
      return "";
    }

    var s = Number(num / 100).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return s;
  }

  getInvoiceItemKindReadableString(kind: InvoiceItemKind): string {
    return InvoiceItemKind[kind];
  }
  getInvoiceItemKindVariant(kind: InvoiceItemKind): string {
    return new String(InvoiceItemKind[kind]).toLowerCase();
  }

  getClassFromKind(kind: InvoiceItemKind) {
    return InvoiceItemKind[kind].toLowerCase();
  }

  async saved(invoice: Invoice) {
    this.loading = true;
    let index = 0;
    for (const payment of invoice.payments) {
      let found = this.payments.find(
        (a) => a.paymentGuid === payment.paymentGuid
      );
      if (!found) {
        await this.payments.push(payment);
      }
      index = await this.payments.findIndex(
        (a) => a.paymentGuid === payment.paymentGuid
      );
      this.payments[index] = payment;
    }

    await this.paymentTable.refresh();
    this.paymentTable.selectRow(index);
    this.$emit("invoices-changed");
    this.showInvoiceModal = false;
    this.loading = false;
  }

  formatCurrency(num: number) {
    let isNum = typeof num === "number" && num === num;
    if (!isNum) {
      return "";
    }

    // to do: do we need the payment currency code here?
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  sum(fieldName: string) {
    let sum = 0;
    for (const payment of this.payments) {
      sum = sum + payment[fieldName];
    }
    return sum;
  }
  getTotalPayments() {
    let sum = 0;
    for (const payment of this.payments) {
      sum = sum + payment.totalPrice;
    }
    return sum;
  }

  async exportTableToExcel() {
    this.loading = true;
    var csvTable = this.$el.querySelector("#paymentTableRef"); // this is the ID of the table to export
    if (csvTable) {
      await export_table_to_csv(csvTable, "Payments.csv");
    }
    this.loading = false;
  }

  async startDateChanged(arg: Date) {
    this.startDate = new Date(arg.toString() + " 00:00:00");
    await this.loadPayments();
  }
  async endDateChanged(arg: Date) {
    this.endDate = new Date(arg.toString() + " 00:00:00");
    await this.loadPayments();
  }

  async loadPayments() {
    this.loading = true;
    this.payments = await getPayments(
      "",
      "",
      this.property.propertyGuid,
      this.startDate,
      this.endDate
    );
    this.loading = false;
  }
  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

 getCurrency(num: number) {
    let isNum = typeof num === "number" && num === num;
    if (!isNum) {
      return "";
    }

    let currencyCode = this.property.currencyCode;
    // do we need to get this from the invoice?

    let result = formatter(num, currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  getReadableDate(dateToFormat: Date) {
    if (new Date(dateToFormat).isDate() == false) {
      return "";
    }
    return new Date(dateToFormat).readableDateLocalized();
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.paymentTable.selectAllRows();
  }
  clearSelected() {
    this.paymentTable.clearSelected();
  }

  private getFields() {
    let field = {
      key: "chargeDate",
      label: "Date",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };
    this.fields.push(field);

    field = {
      key: "itemName",
      label: "Item",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };
    this.fields.push(field);
    field = {
      key: "preTaxPrice",
      label: "Pre Tax",
      sortable: true,
      tdClass: "",
      summable: true,
      isCurrency: true,
    };
    this.fields.push(field);

    field = {
      key: "discount",
      label: "Discount",
      sortable: true,
      summable: false,
      tdClass: "",
      isCurrency: false,
    };
    this.fields.push(field);


    field = {
      key: "totalPrice",
      label: "Total",
      sortable: true,
      tdClass: "",
      summable: true,
      isCurrency: true,
    };
    this.fields.push(field);
    field = {
      key: "kind",
      label: "Kind",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };
    this.fields.push(field);
    field = {
      key: "number",
      label: "Number",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };

    this.fields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.paymentTable = this.$refs.paymentTableRef;
    this.getFields();
    if (this.personGuid) {
      this.payments = await getPayments(this.personGuid);
    } else if (this.reservationGuid) {
      this.reservation = await getReservation(this.reservationGuid);
      this.payments = await getPayments(undefined, this.reservationGuid);
    } else if (this.mode == "property") {
      await this.loadPayments();
    } else {
      this.payments = new Array<Payment>();
    }

    this.loading = false;
  }

  getActivePayment() {
    if (this.activePayment) {
      return this.activePayment;
    } else {
      return "";
    }
  }

  edit(payment: any) {
    if (payment) {
      this.activePayment = payment;
    } else if (this.selected && this.selected.length > 0) {
      this.activePayment = this.selected[0];
    }
    this.showInvoiceModal = true;
  }

  async closeInvoiceModal() {
    await this.paymentTable.refresh();
    this.showInvoiceModal = false;
    this.$emit("payments-changed");
  }
}
