
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";
import CustomQuestionTable from "./CustomQuestionTable.vue";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { CustomQuestionTable, ConfigurationNavMenu } })
export default class CustomQuestions extends Vue {
  @PropertyStore.State
  public property!: Property;
}
