
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class CompanyDetails extends Vue {
  @Prop({ default: () => null }) public property!: Property;
  async mounted() {}

  formatDate(dateFor: Date) {
    return new Date(dateFor).formatDayOfWeekMMDDYYYY();
  }

  goToMap() {
    // go to google maps
  }
}
