
import { formatter } from "@/utils/currencyUtils";
import { StringUtil } from "@/utils/StringUtil";
import { fetchRetry, fetchSimple } from "@/utils/fetchUtil";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CartItem, { recalcCartItem } from "./CartItem";
import Billing from "../../models/Billing";
import { ELEMENT_CONFIG, StripeElement } from "@/models/StripeElement";
import { COMPONENT_OPTIONS } from "@/models/StripeStyle";
import { CardTransactionResult } from "@/models/CardTransactionResult";
import Property from "@/models/Property";
import { ChargeItem } from "../Cloud/ChargeItems/ChargeItem";
import RemoveCartItemModal from "@/components/SiteBook/RemoveCartItemModal.vue";
import CartSummary from "./CartSummary.vue";
import CartItemDetail from "./CartItemDetail.vue";
import { getProperty } from "../Cloud/Settings/PropertyService";
import { Guid } from "@/utils/Guid";
import {
  getTotalAddOns,
  getTotalDelivery,
  getTotalDeposit,
  getTotalDue,
  getTotalAppFee,
  getTotalPrice,
  getTotalTaxAmount,
} from "./Cart";

const cartStore = namespace("CartStore");
const ChargeItemStore = namespace("ChargeItemStore");
@Component({
  components: { RemoveCartItemModal, CartSummary, CartItemDetail },
})
class Cart extends Vue {
  private cartItemGuid = "";
  private showCartItemRemoveModal = false;
  private showProgressOverlay = false;
  private stripeInstance;
  private cardNumberEl;
  private cardExpiryEl;
  private cardCvcEl;
  private billingModel = new Billing();
  private errors = new Array<string>();
  private submitClicked = false;
  private currentIndex = 0;
  private collapseExpand = "collapsed";
  private readonly testStripePublishableKey =
    "pk_test_TiLjR6XavA0qw6492vNcxYzr";
  public property = new Property();
  private showTermsModal = false;

  showTerms() {
    this.showTermsModal = true;
  }

  keepShoppingLink() {
    this.$router.push({
      name: "Company",
      query: {
        prop: this.property.propertyGuid,
      },
      params: {
        propertyGuid: this.property.propertyGuid,
      },
    });
  }

  collapse(collapseExpand: string) {
    this.collapseExpand =
      collapseExpand === "collapsed" ? "expanded" : "collapsed";

    const termsElement = document.getElementById(
      "terms-panel"
    ) as HTMLTextAreaElement;
    if (termsElement) {
      if (
        termsElement.style.maxHeight !== "0px" &&
        termsElement.style.maxHeight !== ""
      ) {
        termsElement.style.maxHeight = "0px";
      } else {
        termsElement.style.maxHeight = termsElement.scrollHeight + "px";
      }
    }
  }

  validateBillingAddress() {
    if (!this.billingModel) {
      return null;
    }
    if (this.submitClicked) {
      if (
        this.billingModel.billingAddress &&
        this.billingModel.billingAddress.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateBillingCity() {
    if (this.submitClicked) {
      if (
        this.billingModel.billingCity &&
        this.billingModel.billingCity.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateBillingState() {
    if (this.submitClicked) {
      if (
        this.billingModel.billingState &&
        this.billingModel.billingState.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateZip() {
    if (this.submitClicked) {
      if (
        this.billingModel.billingPostalCode &&
        this.billingModel.billingPostalCode.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateFirstName() {
    if (this.submitClicked) {
      if (
        this.billingModel.firstName &&
        this.billingModel.firstName.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateLastName() {
    if (this.submitClicked) {
      if (this.billingModel.lastName && this.billingModel.lastName.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validatePhone() {
    if (this.submitClicked) {
      if (this.billingModel.phone && this.billingModel.phone.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateNameOnCard() {
    if (this.submitClicked) {
      if (
        this.billingModel.nameOnCard &&
        this.billingModel.nameOnCard.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateEmail() {
    if (this.submitClicked) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.billingModel.email);
    }
    return null;
  }

  validate() {
    this.errors = [];
    if (!this.validateBillingAddress()) {
      this.errors.push("Billing address is required.");
    }
    if (!this.validateBillingCity()) {
      this.errors.push("Billing city is required.");
    }
    if (!this.validateFirstName()) {
      this.errors.push("First name is required.");
    }
    if (!this.validateLastName()) {
      this.errors.push("Last name is required.");
    }
    if (!this.validatePhone()) {
      this.errors.push("Phone number is required.");
    }
    if (!this.validateEmail()) {
      this.errors.push("Email address is required.");
    }
    if (!this.validateNameOnCard()) {
      this.errors.push("Name on card is required.");
    }
    if (!this.validateBillingState()) {
      this.errors.push("Billing state/province/region is required.");
    }
    if (!this.validateZip()) {
      this.errors.push("Billing zip/postal code is required.");
    }
    if (getTotalDue(this.cartItems) === 0) {
      this.errors.push(
        "The total for this booking is zero dollars. Please click Keep Shopping and choose another item."
      );
    }
    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }

  async addIncidental(item: ChargeItem) {
    let newCartItem = new CartItem();
    newCartItem.cartItemGuid = Guid.newGuid();
    newCartItem.propertyGuid = this.property.propertyGuid;
    newCartItem.quantity = 1;
    newCartItem.kind = "ChargeItem";
    newCartItem.unitName = item.name;
    newCartItem.description = item.name;
    newCartItem.modifiedDate = new Date();
    newCartItem.createdDate = new Date();
    newCartItem.taxAmount = item.totalTax;
    newCartItem.price = item.preTaxPrice;

    newCartItem = await recalcCartItem(newCartItem);
    newCartItem.recordGuid = item.chargeItemGuid;
    newCartItem.rartInUnitGuid = item.chargeItemGuid;
    this.addCartItem(newCartItem);
  }

  fullNameState() {
    return this.billingModel.fullName.trim().length > 0 ? true : false;
  }

  nameOnCardState() {
    return this.billingModel.nameOnCard.trim().length > 0 ? true : false;
  }

  formatCurrency(num: number) {
    const result = formatter(Math.abs(num), this.property.currencyCode);
    return result;
  }

  showRemoveItemModal(id: string) {
    this.cartItemGuid = id;
    this.showCartItemRemoveModal = true;
  }
  deleteItem(cartItem: CartItem) {
    if (cartItem && cartItem.cartItemGuid) {
      let id = cartItem.cartItemGuid?.toString();
      this.showRemoveItemModal(id);
    }
  }
  modifyItem(cartItem: CartItem) {
    this.$router.push({
      name: "Book",
      params: { unit: cartItem.unitGuid },
      query: { cartItemGuid: cartItem.cartItemGuid },
    });
  }
  remove(id: string) {
    this.showCartItemRemoveModal = false;
    this.removeCartItem(id);
  }

  async mounted() {
    if (this.cartItems && this.cartItems.length > 0) {
      this.property = await getProperty(this.cartItems[0].propertyGuid);
      this.billingModel.propertyGuid = this.property.propertyGuid;
      await this.initCardComponents();

     // this.fetchIncidentals(this.property.propertyGuid);

      if (
        this.property.checkoutSettings &&
        this.property.checkoutSettings.isTestMode
      ) {
        //debug values
        this.billingModel.billingAddress = "test";
        this.billingModel.billingCity = "test";
        this.billingModel.billingCountry = "USA";
        this.billingModel.billingPostalCode = "55555";
        this.billingModel.billingState = "MN";
        this.billingModel.comments = "comments";
        this.billingModel.email = "support@lodgevault.com";
        this.billingModel.firstName = "Joe  ";
        this.billingModel.lastName = "Shopper";
        this.billingModel.nameOnCard = "Joe Shopper";
        this.billingModel.phone = "9424910586";
        this.billingModel.isTestMode = true;
      }
      if (this.billingModel.cartItems) {
        this.billingModel.cartItems.forEach((ci) => {
          this.billingModel.depositAmount += ci.deposit;
        });
        this.billingModel.total = getTotalDue(this.cartItems);
      }
    } else {
      let prop = localStorage.getItem("propertyGuid");
      if (prop) {
        this.property = await getProperty(prop.toString());
      }
    }
    const inputElement = document.getElementById("phone-input");
    if (inputElement) {
      inputElement.addEventListener("keydown", StringUtil.enforceFormat);
      inputElement.addEventListener("keyup", StringUtil.formatToPhone);
    }
  }

  scrollToTop() {
    const myDiv = document.getElementById("book-wrap");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  totalDueToday() {
    return getTotalDue(this.cartItems);
  }

  async complete() {
    this.showProgressOverlay = true;
    this.submitClicked = true;
    this.billingModel.depositAmount = getTotalDeposit(this.cartItems);
    this.billingModel.fee = getTotalAppFee(this.cartItems);
    this.billingModel.total = getTotalDue(this.cartItems);
    console.log("total = " + this.billingModel.total);
    if (this.validate()) {
      /* eslint-disable @typescript-eslint/camelcase */
      const cardData = {
        name: this.billingModel.fullName,
        address_line1: this.billingModel.billingAddress,
        address_line2: this.billingModel.billingAddressStreetLine2,
        address_city: this.billingModel.billingCity,
        address_state: this.billingModel.billingState,
        address_zip: this.billingModel.billingPostalCode,
        address_country: this.postalCodeIsCanadian(
          this.billingModel.billingPostalCode
        )
          ? "CA"
          : "US", // create a drop down list with country codes. For now just use US for testing
        // this.billingModel.billingCountry, //A two character country code (for example, US).
        //currency: this.billingModel.billingCurrency,
        //Required in order to add the card to a Connect account (in all other cases, this parameter is not used).
      };

      this.stripeInstance
        .createToken(this.cardNumberEl, cardData)
        .then((response: any) => {
          if (response.error) {
            this.errors.push(response.error.message);
            this.showProgressOverlay = false;
          } else {
            const token = response.token.id;
            this.billingModel.token = token;
            this.submitOrder()
              .then((response: any) => {
                if (response.message) {
                  this.errors.push(response.message);
                  this.showProgressOverlay = false;
                } else {
                  this.clearCart();
                  this.$router.push({
                    name: "Confirmation",
                    query: {
                      req: response,
                      new: "true",
                    },
                  });
                }
              })
              .catch((err) => {
                this.showProgressOverlay = false;
                this.errors.push(err);
              });
          }
        });
    } else {
      //invalid - stop
      this.showProgressOverlay = false;
    }
  }

  private async submitOrder(): Promise<CardTransactionResult> {
    this.billingModel.cartItems = this.cartItems;
    const response = await fetchSimple(
      "/api/cart/submitorder",
      this.billingModel,
      "POST"
    );
    if (response.ok) {
      return response.json() as Promise<CardTransactionResult>;
    } else {
      const err =
        "An error occurred submitting the order to the server." +
        `The error code was ${response.status} (${response.statusText}).`;
      throw Error(err);
    }
  }

  hasCartItem() {
    return this.cartItems.length > 0;
  }

  hasIncidental() {
    if (
      this.property &&
      this.property.checkoutSettings &&
      this.property.checkoutSettings.marketplaceEnabled == true
    ) {
      return false;
    }
    return this.incidentals.length > 0;
  }

  notifyError(error: string) {
    console.log("error:" + error);
  }

  async initCardComponents(): Promise<void> {
    this.stripeInstance = (window as any).Stripe(
      this.billingModel.isTestMode
        ? this.testStripePublishableKey
        : this.property.checkoutSettings.stripePublishableKey
    );
    const elements = this.stripeInstance.elements();
    this.cardNumberEl = elements.create(StripeElement.CARD_NUMBER, {
      ...COMPONENT_OPTIONS,
      ...ELEMENT_CONFIG[StripeElement.CARD_NUMBER],
    });
    this.cardExpiryEl = elements.create(StripeElement.CARD_EXPIRY, {
      ...COMPONENT_OPTIONS,
      ...ELEMENT_CONFIG[StripeElement.CARD_EXPIRY],
    });
    this.cardCvcEl = elements.create(StripeElement.CARD_CVC, {
      ...COMPONENT_OPTIONS,
      ...ELEMENT_CONFIG[StripeElement.CARD_CVC],
    });

    this.cardNumberEl.mount("#stripe-card-number-element");
    this.cardExpiryEl.mount("#stripe-card-expiry-element");
    this.cardCvcEl.mount("#stripe-card-cvc-element");
  }

  postalCodeIsCanadian(postalCode): boolean {
    if (!postalCode) {
      return false;
    }
    postalCode = postalCode.toString().trim();
    const us = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
    const ca = new RegExp(
      /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
    );
    const uk =
      /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
    const other = new RegExp("^\\d+$");
    return ca.test(postalCode.toString());
  }

  @cartStore.Action
  public removeCartItem!: (id: string) => void;

  @cartStore.Action
  public clearCart!: () => void;

  @cartStore.State
  public cartItems!: Array<CartItem>;

  @ChargeItemStore.State
  public incidentals!: Array<ChargeItem>;

  @ChargeItemStore.Action
  public fetchIncidentals!: (propertyGuid: string) => Promise<boolean>;

  @cartStore.Action
  public addCartItem!: (cartItem: CartItem) => void;
}
export default Cart;
