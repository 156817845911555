
import { Vue, Component } from "vue-property-decorator";
import Tree from "../Controls/Tree.vue";

@Component({ components: { Tree } })
export default class Help extends Vue {
  
  private async handleMouseDown(arg: any) {
    let url = arg.target.attributes["title"].nodeValue;
    let el: HTMLElement = arg.target;
    el.style.fontWeight = "bold";
    el.style.fontStyle = "italic";
    this.load(url);
  }

  async load(url: string) {
    var xhr = new XMLHttpRequest();
    xhr.open("get", url, true);
    xhr.send();
    xhr.onreadystatechange = await function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        let frame = document.getElementById("frame_content");
        if (frame) {
          frame.innerHTML = xhr.responseText;
        }
      }
    };
    this.scollToTop();
    await this.wireUpHyperlinks();

    //this should just zoom on small screens
    let meta = document.getElementsByName("meta")[0];
    if (meta) {
      document.getElementsByTagName("head")[0].removeChild(meta);
    }
  }

  async wireUpHyperlinks() {
    window.onclick = function (event: any) {
      if (event.target.href) {
        event.preventDefault();
        let url = event.target.href;

        var xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.send();
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4 && xhr.status == 200) {
            let frame = document.getElementById("frame_content");
            if (frame) {
              frame.innerHTML = xhr.responseText;

              if ("scrollRestoration" in history) {
                history.scrollRestoration = "manual";
              }
              // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
              window.scrollTo(0, 0);
            }
          }
        };
      }
    };
  }

  async mounted() {
    let files = document.getElementsByClassName("file");
    if (files) {
      for (const file of files) {
        file.addEventListener("mousedown", this.handleMouseDown);
      }
    }
    let folders = document.getElementsByClassName("folder");
    if (folders) {
      for (const folder of folders) {
        folder.addEventListener("mousedown", this.handleMouseDown);
      }
    }
    if (this.$route.query.page) {
      let strroute = this.$route.query.page.toString();
      await this.load(strroute);
    } else {
      await this.load("overview.htm");
    }
    await this.wireUpHyperlinks();
  }
  async created() {}

  async scollToTop() {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
    // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
    window.scrollTo(0, 0);
  }

  private tree = {
    label: "A cool folder",
    children: [
      {
        label: "A cool sub-folder 1",
        children: [
          { label: "A cool sub-sub-folder 1" },
          { label: "A cool sub-sub-folder 2" },
        ],
      },
      { label: "This one is not that cool" },
    ],
  };
}
