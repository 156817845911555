
import { Activity } from '@/components/Cloud/Activity/Activity';
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';


export async function deleteActivities(activitysGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/activity/delete`,
    activitysGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the activity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteActivity(activityGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/activity/` + activityGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the activity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveActivity(activity: Activity): Promise<boolean> {
  const response = await axiosRetry(
    `/api/activity`,
    activity,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the activity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}


export async function getActivities(personGuid?: string, reservationGuid?: string, propertyGuid?: string): Promise<Array<Activity>> {
  let url = '';
  if (personGuid) {
    url = `/api/activity/getbyperson/${personGuid}`;
  } else if (reservationGuid) {

    url = `/api/activity/getbyreservation/${reservationGuid}`;
  } else {

    url = `/api/activity/getbyperson/${personGuid}`;
  }

  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Activity>>;
  } else {
    const err =
      "An error occurred getting the activity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getActivitysByPerson(personGuid: string): Promise<Array<Activity>> {
  const response = await axiosRetry(
    `/api/activity/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Activity>>;
  } else {
    const err =
      "An error occurred getting the activity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getActivitiesByDate(start: Date, end: Date, propertyGuid: string): Promise<Array<Activity>> {

  let url = '';
  if (start && end) {
    let strStart = new Date(start).toDateString();
    let strEnd = new Date(end).toDateString();
    url = `/api/activity/getbydate/${propertyGuid}/${strStart}/${strEnd}`;
  }
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Activity>>;
  } else {
    const err =
      "An error occurred getting the activity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getActivity(activityGuid: string): Promise<Activity> {
  const response = await axiosRetry(
    `/api/activity/getactivity/${activityGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Activity>;
  } else {
    const err =
      "An error occurred getting the activity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

