import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../components/Marketplace/Home.vue';
import Company from '../components/Marketplace/Company.vue';
import Search from '../components/Marketplace/Search.vue';
import Book from '../components/Marketplace/Book.vue';
import UnitListings from '../components/Marketplace/UnitListings.vue';
import HostSignUp from '../components/Marketplace/HostSignUp.vue';
import GuestProfile from '../components/Marketplace/GuestProfile.vue';
import GuestBookings from '../components/Marketplace/GuestBookings.vue';
import LayoutMp from '../layouts/LayoutMp.vue';
import AddressSuggest from '../components/testers/AddressSuggest.vue';
import MultiSelectTester from '../components/testers/MultiSelectTester.vue';
import Confirmation from '../components/SiteBook/Confirmation.vue';
import ForgotPassword from '../components/Cloud/Auth/ForgotPassword.vue';
import LoginPage from '../components/Cloud/Auth/LoginPage.vue';
import Cart from '../components/Cart/Cart.vue';
import { loadStripeScript } from '@/stripe-import';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/healthz', // Ignore or pass on to server 
    meta: { layout: null }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: LayoutMp }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    beforeEnter: (to, from, next) => {
      loadStripeScript().then(() => next());
    },
    meta: { layout: LayoutMp, hideCart: true }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: { layout: LayoutMp }
  },
  {
    path: '/search:/propertyGuid/:',
    name: 'Search',
    component: Search,
    meta: { layout: LayoutMp }
  },
  {
    path: '/unitlistings',
    name: 'UnitListings',
    component: UnitListings,
    meta: { layout: LayoutMp }
  },
  {
    path: '/company/:propertyGuid',
    name: 'Company',
    component: Company,
    meta: { layout: LayoutMp }
  },
  {
    path: '/book/:unit',
    name: 'Book',
    props: true,
    component: Book,
    meta: { layout: LayoutMp }
  },
  {
    path: '/book',
    name: 'Book',
    props: true,
    component: Book,
    meta: { layout: LayoutMp }
  },
  {
    path: '/addresssuggest',
    name: 'AddressSuggest',
    component: AddressSuggest,
    meta: { layout: LayoutMp }
  },
  {
    path: '/multiselecttester',
    name: 'MultiSelectTester',
    component: MultiSelectTester,
    meta: { layout: LayoutMp }
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation,
    meta: { layout: LayoutMp }
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPassword,
    meta: { layout: LayoutMp }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { layout: LayoutMp }
  },
  {
    path: '/host',
    name: 'HostSignUp',
    component: HostSignUp,
    meta: { layout: LayoutMp }
  },
  {
    path: '/bookings',
    name: 'GuestBookings',
    component: GuestBookings,
    meta: { layout: LayoutMp }
  },
  {
    path: '/profile',
    name: 'GuestProfile',
    component: GuestProfile,
    meta: { layout: LayoutMp }
  },
]

const marketplaceRouter = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

marketplaceRouter.onReady(() => {
  let meta = document.createElement('meta');
  meta.httpEquiv = "X-UA-Compatible";
  meta.content = "width=device-width,initial-scale=1.0";
  meta.name = "viewport";
  document.getElementsByTagName('head')[0].appendChild(meta);
});

export default marketplaceRouter;