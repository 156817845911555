export class RateInUnit {
    constructor() {
    }
    public rateInUnitGuid!: string;
    public unitGuid!: string;
    public propertyGuid!: string;
    public rateGuid!: string;

    // calculated fields
    public unitName!: string;
    public rateName!: string;
}
