
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import ReservationEdit from "./ReservationEdit.vue";

@Component({ components: { ReservationEdit } })
class ReservationModal extends Vue {
  @Prop({ default: null }) public reservationGuid!: Guid;
  private loading = false;

  async close(res: Reservation) {
    this.$emit("success", res);
  }
  async xClicked() {
    this.$emit("cancel", this.reservationGuid);
  }
}
export default ReservationModal;
