var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-container',{attrs:{"fluid":"xl"}},[_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"p-3",class:{
          active: _vm.isActiveTab('Dashboard'),
        },attrs:{"pill":"","variant":"primary"},on:{"click":function($event){return _vm.navigate('Dashboard')}}},[_vm._v("Home "),_c('b-icon-house')],1),_c('b-button',{staticClass:"p-3",class:{
          active: _vm.isActiveTab('Grid'),
        },attrs:{"pill":"","variant":"primary","size":"md"},on:{"click":function($event){return _vm.navigate('Grid')}}},[_vm._v("Grid "),_c('b-icon-grid')],1),_c('b-button',{staticClass:"p-3",class:{
          active:
            _vm.isActiveTab('Reservations') || _vm.isActiveTab('ReservationEdit'),
        },attrs:{"pill":"","variant":"primary","size":"md"},on:{"click":function($event){return _vm.navigate('Reservations')}}},[_vm._v("Reservations "),_c('b-icon-calendar2-check')],1),_c('b-button',{staticClass:"p-3",class:{
          active: _vm.isActiveTab('People') || _vm.isActiveTab('PersonEdit'),
        },attrs:{"pill":"","variant":"primary","size":"md"},on:{"click":function($event){return _vm.navigate('People')}}},[_vm._v("People"),_c('b-icon-person')],1),(_vm.isAdmin() == true)?_c('b-button',{staticClass:"p-3",class:{
          active:
            _vm.isActiveTab('Invoices') ||
            _vm.isActiveTab('Payments') ||
            _vm.isActiveTab('Charges') ||
            _vm.isActiveTab('InvoiceEdit'),
        },attrs:{"pill":"","variant":"primary","size":"md"},on:{"click":function($event){return _vm.navigate('Finance')}}},[_vm._v("Finance "),_c('b-icon-currency-dollar')],1):_vm._e(),(_vm.isAdmin() == true)?_c('b-button',{staticClass:"p-3",class:{
          active:
            _vm.isActiveTab('Configuration') ||
            _vm.isActiveTab('Units') ||
            _vm.isActiveTab('UnitEdit') ||
            _vm.isActiveTab('UnitGroupEdit') ||
            _vm.isActiveTab('Rates') ||
            _vm.isActiveTab('Accounts') ||
            _vm.isActiveTab('Amenities') ||
            _vm.isActiveTab('ChargeItems') ||
            _vm.isActiveTab('TimeSlotCollections') ||
            _vm.isActiveTab('Activities'),
        },attrs:{"pill":"","variant":"primary","size":"md"},on:{"click":function($event){return _vm.navigate('Configuration')}}},[_vm._v("Configuration "),_c('b-icon-gear')],1):_vm._e(),(_vm.isAdmin() == true)?_c('b-button',{staticClass:"p-3",class:{
          active: _vm.isActiveTab('Templates'),
        },attrs:{"pill":"","variant":"primary","size":"md"},on:{"click":function($event){return _vm.navigate('Templates')}}},[_vm._v("Templates "),_c('b-icon-printer')],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }