
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "../Auth/User";

const Auth = namespace("AuthStore");
@Component({
  components: {},
})
class AppNav extends Vue {
  private loading = true;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;

  @Auth.State("user")
  private currentUser!: User;

  isActiveTab(section: string) {
    return section.toLowerCase() == this.$route.name?.toLowerCase();
  }

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  onWindowResized() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  created() {
    this.loading = true;

    this.loading = false;
  }

  scrollToTop() {
    const myDiv = document.getElementById("book-wrap");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  isAdmin() {
    if (
      this.currentUser.roles.includes("ADMINISTRATOR") ||
      this.currentUser.roles.includes("SITEADMINISTRATOR")
    ) {
      return true;
    }
    return false;
  }
}
export default AppNav;
