import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../components/GWS/Home.vue';
import Products from '../components/GWS/Products.vue';
import Company from '../components/GWS/Company.vue';
import Desktop from '../components/GWS/Desktop.vue';
import BookingEngine from '../components/GWS/BookingEngine.vue';
import BoisBrule from '../components/GWS/BoisBrule.vue';
import Faqs from '../components/GWS/Faqs.vue';
import Purchase from '../components/GWS/Purchase.vue';
import PurchaseDesktop from '../components/GWS/PurchaseDesktop.vue';
import Demo from '../components/GWS/Demo.vue';
import Download from '../components/GWS/Download.vue';
import Clients from '../components/GWS/Clients.vue';
import Support from '../components/GWS/Support.vue';
import Help from '../components/GWS/Help.vue';
import LayoutGws from '../layouts/LayoutGws.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/healthz', // Ignore or pass on to server 
    meta: { layout: null }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { layout: LayoutGws }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: { layout: LayoutGws }
  },
  {
    path: '/purchase',
    name: 'Purchase',
    component: Purchase,
    meta: { layout: LayoutGws }
  },
  {
    path: '/purchasedesktop',
    name: 'PurchaseDesktop',
    component: PurchaseDesktop,
    meta: { layout: LayoutGws }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    meta: { layout: LayoutGws }
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: { layout: LayoutGws }
  },
  {
    path: '/company',
    name: 'Company',
    component: Company,
    meta: { layout: LayoutGws }
  },
  {
    path: '/desktop',
    name: 'Desktop',
    component: Desktop,
    meta: { layout: LayoutGws }
  },
  {
    path: '/bookingengine',
    name: 'BookingEngine',
    component: BookingEngine,
    meta: { layout: LayoutGws }
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: Faqs,
    meta: { layout: LayoutGws }
  },
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
    meta: { layout: LayoutGws }
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: { layout: LayoutGws }
  },
  {
    path: '/bois-brule',
    name: 'BoisBrule',
    component: BoisBrule
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
]

const gwsRouter = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

gwsRouter.onReady(() => {
  let meta = document.createElement('meta');
  //meta.httpEquiv = "X-UA-Compatible";
  meta.content = "width=device-width,initial-scale=1.0";
  meta.name = "viewport";
  document.getElementsByTagName('head')[0].appendChild(meta);
  var x = document.getElementById("navDemo");
  if (x) {
    x.className = x.className.replace(" lv-show", "");
  }

});

export default gwsRouter;