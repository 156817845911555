
import { Field } from "@/models/interfaces/Field";
import { PersonInReservation } from "@/components/Cloud/Reservations/PersonInReservation";
import {
  deleteGroupMember,
  getAssignsByReservation,
  getGroup,
  saveAssign,
  saveGroupMember,
} from "@/components/Cloud/Reservations/ReservationService";
import { Vue, Component, Prop } from "vue-property-decorator";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import GroupContextMenu from "../../ContextMenu/GroupContextMenu.vue";
import SearchBox from "../../Controls/SearchBox.vue";
import { Guid } from "@/utils/Guid";
import Property from "@/models/Property";
import { namespace } from "vuex-class";
import { Invoice } from "../Invoices/Invoice";
import { saveInvoice } from "../Invoices/InvoiceService";
import { GroupMemberStatus, ResStatus } from "../Reservations/ResStatus";
import { Activity, ActivityKind } from "../Activity/Activity";
import { saveActivity } from "../Activity/ActivityService";
import { User } from "../Auth/User";
import { Assign } from "../Assign/Assign";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({
  components: { ConfirmDeleteModal, GroupContextMenu, SearchBox },
})
class GroupTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Auth.State("user")
  private currentUser!: User;
  @Prop({ default: null }) public reservationGuid!: string;
  private assigns = Array<Assign>();
  private loading = true;
  private group: Array<PersonInReservation> = [];
  private fields: Array<Field> = [];
  private personGuid = "";
  private showEditModal = false;
  private groupTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;
  private isContextMenuVisible = false;
  private popoverOptions = {
    popoverReference: null as any,
    placement: "bottom",
    offset: "0,0",
  };
  private showSearch = false;

  async changeStatus(pir: PersonInReservation, status: GroupMemberStatus) {
    this.loading = true;
    pir.status = status;
    await saveGroupMember(pir);
    this.group = await getGroup(this.reservationGuid);
    this.$forceUpdate();
    await this.groupTable.refresh();
    this.loading = false;
  }

  async addExisting(arg: any) {
    this.$emit("add-group-member", arg);
    this.showSearch = false;
  }

  async editCellCompleteHandler(pir: PersonInReservation, assign: Assign) {
    this.loading = true;
    if (!assign) {
      pir.unitGuid = null;
    } else {
      pir.unitGuid = assign.unitGuid;
    }
    await saveGroupMember(pir);
    this.group = await getGroup(this.reservationGuid);
    this.$forceUpdate();
    await this.groupTable.refresh();
    this.loading = false;
  }

  checkIn() {
    if (this.selected && this.selected.length > 0) {
      let personToCheckin = this.selected[0];
      this.$emit("checkin", personToCheckin.personGuid);
    }
  }

  async makePrimary() {
    if (this.selected && this.selected.length > 0) {
      let personToMakePrimary = this.selected[0];
      for (let groupMember of this.group) {
        if (groupMember.personGuid == personToMakePrimary.personGuid) {
          groupMember.isPrimaryContact = true;
        } else {
          groupMember.isPrimaryContact = false;
        }

        await saveGroupMember(groupMember);
      }
      this.$emit("make-primary", personToMakePrimary.personGuid);
    }
  }
  getResStatusVariant(pir: PersonInReservation): string {
    return new String(GroupMemberStatus[pir.status]).toLowerCase();
  }

  getReadableResStatus(pir: PersonInReservation) {
    if (pir.status && pir.status > 0) {
      let resStatusVariant = GroupMemberStatus[pir.status];
      if (resStatusVariant == "CheckedIn") {
        resStatusVariant = "Checked In";
      }
      if (resStatusVariant == "CheckedOut") {
        resStatusVariant = "Checked Out";
      }
      return resStatusVariant;
    }
    return "None";
  }

  confirmDeleteGroupMember() {
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async showContextMenu(
    groupMember: PersonInReservation,
    index: any,
    event: any
  ) {
    await this.closeContextMenu;
    await this.openPopover(event);
    this.isContextMenuVisible = true;
  }

  async openPopover(event: any) {
    const element = event.srcElement
      ? event.srcElement
      : this.referenceObject(event); //event.srcElement
    if (element) {
      this.popoverOptions.popoverReference = element;
      //  this.popoverOptions.offset = event.srcElement.offset;
    }
  }

  async closeContextMenu() {
    this.isContextMenuVisible = false;
    this.popoverOptions.popoverReference = null;
  }

  referenceObject(evt) {
    const left = evt.clientX;
    const top = evt.clientY;
    const right = left + 1;
    const bottom = top + 1;
    const clientWidth = 1;
    const clientHeight = 1;
    console.log(
      " left = " +
        left +
        "  top =" +
        top +
        "  right= " +
        right +
        " bottom= " +
        bottom
    );
    function getBoundingClientRect() {
      return {
        left,
        top,
        right,
        bottom,
      };
    }

    const obj = {
      getBoundingClientRect,
      clientWidth,
      clientHeight,
    };
    return obj;
  }

  async deleteGroupMembers() {
    if (this.selected && this.selected.length > 0) {
      for (const d of this.selected) {
        let deleted = d;
        let result = await deleteGroupMember(deleted);
        if (result) {
          if (this.group) {
            let index = await this.group.findIndex(
              (a) => a.personGuid === deleted.personGuid
            );
            this.group.splice(index, 1);
          }
        }
      }
      this.showConfirmModal = false;
    }

    await this.groupTable.refresh();
  }

  private getFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    this.fields.push(field);

    field = new Field();
    field.key = "status";
    field.label = "Status";
    this.fields.push(field);

    field = new Field();
    field.key = "unit";
    field.label = "Unit";
    this.fields.push(field);
  }

  async mounted() {
    this.groupTable = this.$refs.groupTableRef;
    this.getFields();
    if (this.reservationGuid) {
      this.group = await getGroup(this.reservationGuid);
      this.assigns = await getAssignsByReservation(this.reservationGuid);
    } else {
      this.group = new Array<PersonInReservation>();
    }
    this.loading = false;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  saved() {
    this.showEditModal = false;
  }
  cancel() {
    this.showEditModal = false;
  }

  addNewGroupMember() {
    this.$emit("edit-group-member");
  }

  openGroupMember(arg: any) {
    if (arg) {
      let groupMember = arg;
      this.$emit("edit-group-member", groupMember.personGuid);
    }
  }
}
export default GroupTable;
