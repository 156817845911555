<template>
  <b-container align="center">
    <h2>You have been unsubscribed.</h2>
  </b-container>
</template>

<script>
export default {};
</script>

<style>
</style>