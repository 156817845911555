
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { UnitGroup } from "@/models/UnitGroup";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import UnitGroupEdit from "./UnitGroupEdit.vue";
import UnitGroupModal from "./UnitGroupModal.vue";
import {
  deleteUnitGroup,
  getAllUnitGroups,
  saveUnitGroup,
} from "./UnitGroupService";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { UnitGroupEdit, ConfirmDeleteModal } })
export default class UnitGroupTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  private mode = "reservation";
  private loading = true;
  private showUnitGroupModal = false;
  private unitGroups: Array<UnitGroup> = [];
  private unitGroupFields: Array<Field> = [];
  private clickedUnitGroup!: UnitGroup | null;
  private unitGroupTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;

  async cancelDeletion() {
    this.showConfirmModal = false;
  }

  async confirmDelete() {
    this.showConfirmModal = true;
  }

  async add() {
    this.clickedUnitGroup = null;
    this.showUnitGroupModal = true;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.unitGroupTable.selectAllRows();
  }
  clearSelected() {
    this.unitGroupTable.clearSelected();
  }

  async saved(unitGroup: UnitGroup) {
    this.loading = true;
    // if the list doesn't contain the unitGroup, add it
    let found = this.unitGroups.find(
      (a) => a.unitGroupGuid === unitGroup.unitGroupGuid
    );
    if (!found) {
      await this.unitGroups.unshift(unitGroup);
    }
    let index = await this.unitGroups.findIndex(
      (a) => a.unitGroupGuid === unitGroup.unitGroupGuid
    );
    this.unitGroups[index] = unitGroup;
    await this.unitGroupTable.refresh();
    this.unitGroupTable.selectRow(index);
    this.loading = false;
  }

  private getUnitGroupFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    this.unitGroupFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.unitGroupTable = this.$refs.unitGroupTableRef;
    this.getUnitGroupFields();
    if (this.property && this.property.propertyGuid) {
      this.unitGroups = await getAllUnitGroups(this.property.propertyGuid);
    } else {
      this.unitGroups = new Array<UnitGroup>();
    }
    this.loading = false;
  }

  getActiveUnitGroup() {
    if (this.clickedUnitGroup) {
      return this.clickedUnitGroup.unitGroupGuid;
    } else {
      return "";
    }
  }

  async deleteUnitGroups() {
    // put up warning confirmation first?
    if (this.selected && this.selected.length > 0) {
      this.selected.forEach(async (s) => {
        let deleted = s;
        let result = await deleteUnitGroup(deleted.unitGroupGuid.toString());
        if (result) {
          if (this.unitGroups) {
            let index = this.unitGroups.findIndex(
              (a) => a.unitGroupGuid === deleted.unitGroupGuid
            );
            this.unitGroups.splice(index, 1);
          }
        }
      });
    }
    await this.unitGroupTable.refresh();
    this.showConfirmModal = false;
  }

  edit(unitGroup: any, index: any, event: any) {
    if (unitGroup) {
      this.clickedUnitGroup = unitGroup;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedUnitGroup = this.selected[0];
    }
    this.showUnitGroupModal = true;
  }

  closeUnitGroupModal() {
    this.showUnitGroupModal = false;
  }
}
