import { Account } from "../Accounts/Account";
import { IInvoiceItem } from "./IInvoiceItem";
import { InvoiceItemKind } from "./Invoice";

export class Charge implements IInvoiceItem {
    constructor() {
    }
    public itemGuid!: string;
    public reservationGuid!: string;
    public personGuid!: string;
    public isEdit!: boolean;
    public invoiceGuid!: string;
    public chargeGuid!: string;
    public unitGuid!: string;
    public rateGuid!: string;
    public accountGuid!: string;
    public kind!: InvoiceItemKind;
    public itemName!: string;
    public chargeDate!: Date;
    public postingDate!: Date;
    public exported: number = 0;
    public takenBy!: string;
    public discount: number = 0;
    public discountAmount: number = 0;
    public quantity: number = 1;
    public preTaxPrice: number = 0;
    public tax1Rate: number = 0;
    public tax2Rate: number = 0;
    public tax3Rate: number = 0;
    public tax4Rate: number = 0;
    public tax5Rate: number = 0;
    public tax6Rate: number = 0;
    public tax7Rate: number = 0;
    public tax8Rate: number = 0;
    public tax9Rate: number = 0;
    public tax10Rate: number = 0;
    public tax1Total: number = 0;
    public tax2Total: number = 0;
    public tax3Total: number = 0;
    public tax4Total: number = 0;
    public tax5Total: number = 0;
    public tax6Total: number = 0;
    public tax7Total: number = 0;
    public tax8Total: number = 0;
    public tax9Total: number = 0;
    public tax10Total: number = 0;
    public preTaxSubTotal: number = 0;
    public totalTax: number = 0;
    public totalTaxAmount: number = 0;
    public createdDate!: Date;
    public modifiedDate!: Date;
    public stripeId!: string;
    // not persisted
    public unitName!: string;
    public personName!: string;
    public reservationName!: string;
    public collapsed!: boolean;
    public totalPrice: number = 0;

}

export function recalculate(charge: Charge): Charge {
    charge.preTaxSubTotal = charge.preTaxPrice * charge.quantity * (1 - charge.discount);
    charge.tax1Total = charge.preTaxSubTotal * charge.tax1Rate * 0.01;
    charge.tax2Total = charge.preTaxSubTotal * charge.tax2Rate * 0.01;
    charge.tax3Total = charge.preTaxSubTotal * charge.tax3Rate * 0.01;
    charge.tax4Total = charge.preTaxSubTotal * charge.tax4Rate * 0.01;
    charge.tax5Total = charge.preTaxSubTotal * charge.tax5Rate * 0.01;
    charge.tax6Total = charge.preTaxSubTotal * charge.tax6Rate * 0.01;
    charge.tax7Total = charge.preTaxSubTotal * charge.tax7Rate * 0.01;
    charge.tax8Total = charge.preTaxSubTotal * charge.tax8Rate * 0.01;
    charge.tax9Total = charge.preTaxSubTotal * charge.tax9Rate * 0.01;
    charge.tax10Total = charge.preTaxSubTotal * charge.tax10Rate * 0.01;
    charge.totalTax = charge.tax1Total + charge.tax2Total + charge.tax3Total + charge.tax4Total + charge.tax5Total + charge.tax6Total + charge.tax7Total + charge.tax8Total + charge.tax9Total + charge.tax10Total;
    charge.totalPrice = charge.preTaxSubTotal + charge.totalTax;
    return charge;
}


export function setTaxRates(charge: Charge, account: Account): Charge {
    charge.tax1Rate = account.tax1Rate;
    charge.tax2Rate = account.tax2Rate;
    charge.tax3Rate = account.tax3Rate;
    charge.tax4Rate = account.tax4Rate;
    charge.tax5Rate = account.tax5Rate;
    charge.tax6Rate = account.tax6Rate;
    charge.tax7Rate = account.tax7Rate;
    charge.tax8Rate = account.tax8Rate;
    charge.tax9Rate = account.tax9Rate;
    charge.tax10Rate = account.tax10Rate;
    return charge;
}
