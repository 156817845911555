
import PriceQuote from "@/models/PriceQuote";
import CartItem from "@/components/Cart/CartItem";
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { Rate } from "@/components/Cloud/Rates/Rate";
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import { getReservationStatusString } from "@/components/Cloud/Reservations/ResStatus";
import {
  getAllRatesForUnit,
  getChargesForPriceQuote,
} from "@/components/Cloud/Units/UnitService";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { formatter } from "@/utils/currencyUtils";
import ComboBox from "@/components/Controls/ComboBox.vue";
import { Invoice } from "@/components/Cloud/Invoices/Invoice";
import { Guid } from "@/utils/Guid";
import { IInvoiceItem } from "@/components/Cloud/Invoices/IInvoiceItem";
import { Charge } from "@/components/Cloud/Invoices/Charge";
import { Assign } from "../Assign/Assign";

const AddReservationStore = namespace("AddReservationStore");
const PropertyStore = namespace("PropertyStore");
@Component({ components: { ComboBox } })
class AvailableRatesModal extends Vue {
  @Prop({ default: 0 }) public title!: string;

  @AddReservationStore.State
  public reservation!: Reservation;
  @AddReservationStore.Action
  public saveNewReservationToStore!: (res: Reservation) => void;
  @PropertyStore.State
  public property!: Property;

  private fields: Array<Field> = [];
  private cartItems!: Array<CartItem>;
  private showOverlay = false;
  private selectedAssign!: Assign;
  private priceQuotesTable: any;
  private priceQuotes: Array<PriceQuote> = new Array<PriceQuote>();
  private rates: Array<Rate> = new Array<Rate>();
  private selectedPriceQuote = new PriceQuote();
  private selectedRate = new Rate();
  private showModal = true;
  private numPeople = 2;
  private discount = 0;

  async discountChanged(arg: any) {
    if (!arg || arg == "") {
      this.discount = 0;
    }

    await this.recalculate();
  }

  numPeopleChanged(arg: any) {
    this.recalculate();
  }

  getSeasonName(pq: PriceQuote) {
    if (pq && pq.seasonSelected) {
      return pq.seasonSelected.name;
    } else {
      return "Base Rate";
    }
  }

  async rateSelected(selected: Rate) {
    if (selected) {
      this.selectedPriceQuote.rateSelected = selected;
      this.recalculate();
    }
  }

  async recalculate() {
    this.selectedPriceQuote.discount = this.discount * 0.01;
    this.selectedPriceQuote.numberOfPeople = this.numPeople;
    this.selectedPriceQuote.charges = await getChargesForPriceQuote(
      this.selectedPriceQuote
    );
  }

  formatCurrency(num: number) {
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  private getPriceQuoteTotal(pq: PriceQuote) {
    let result = 0;
    if (pq && pq.charges && pq.charges.length > 0) {
      result = pq.charges.reduce((total, charge) => {
        return total + charge.totalPrice;
      }, 0);
    } else {
      result = 0;
    }
    return result;
  }

  private getTotal() {
    let result = 0;
    this.priceQuotes.forEach((pq) => {
      if (pq.charges && pq.charges.length > 0) {
        result += pq.charges.reduce((total, charge) => {
          return total + charge.totalPrice;
        }, 0);
      }
    });
    return result;
  }

  private getFields() {
    let field = new Field();
    field.tdClass = "assign-table-td";
    field.key = "unitName";
    field.label = "Unit";
    this.fields.push(field);

    field = new Field();
    field.key = "nights";
    field.label = "Nights";
    this.fields.push(field);

    field = new Field();
    field.key = "rate";
    field.label = "Rate";
    this.fields.push(field);

    field = new Field();
    field.key = "season";
    field.label = "Season";
    this.fields.push(field);

    field = new Field();
    field.key = "total";
    field.label = "Total";
    this.fields.push(field);
  }

  async mounted() {
    this.showOverlay = true;
    await this.populatePriceQuotes();
    this.priceQuotesTable = this.$refs.priceQuotesTableRef;
    this.getFields();
    this.selectFirstRow();
    this.showOverlay = false;
  }

  private async populatePriceQuotes() {
    for (const a of this.reservation.assigns) {
      let priceQuote = new PriceQuote();
      priceQuote.numberOfPeople = this.property.defaultPeopleCount;
      priceQuote.assign = a;
      priceQuote.availableRates = await getAllRatesForUnit(priceQuote);
      if (priceQuote.availableRates) {
        priceQuote.rateSelected = priceQuote.availableRates[0];
      }
      priceQuote.charges = await getChargesForPriceQuote(priceQuote);
      this.priceQuotes.push(priceQuote);
    }
  }

  async onRowSelected(arg: any) {
    if (arg) {
      const priceQuote = arg[0];
      this.numPeople = priceQuote.numberOfPeople;
      this.discount = priceQuote.discount * 100;
      this.selectedPriceQuote = priceQuote;
      this.populateRatesDropDown();
    }
    this.$forceUpdate();
  }

  private populateRatesDropDown() {
    if (this.selectedPriceQuote && this.selectedPriceQuote.availableRates) {
      this.rates = [];
      this.selectedPriceQuote.availableRates.forEach((r) => {
        this.rates.push(r);
      });

      if (this.selectedPriceQuote.rateSelected) {
        const found = this.rates.find(
          (r) => r.rateGuid === this.selectedPriceQuote.rateSelected.rateGuid
        );
        if (found) {
          this.selectedRate = found;
        } else {
          this.selectedRate = new Rate();
        }
      } else if (this.selectedPriceQuote.availableRates) {
        this.selectedPriceQuote.rateSelected =
          this.selectedPriceQuote.availableRates[0];
        this.selectedRate = this.selectedPriceQuote.rateSelected;
      } else {
        this.selectedRate = new Rate();
      }
    }
  }
  async selectFirstRow() {
    this.selectedPriceQuote = this.priceQuotes[0];
    this.priceQuotesTable.selectRow(0);
  }

  cancel() {
    this.$emit("close");
  }

  async saveAndClose() {
    this.showOverlay = true;
    if (!this.reservation.invoices || this.reservation.invoices.length == 0) {
      let inv = new Invoice();
      inv.invoiceGuid = Guid.newGuid();
      inv.propertyGuid = this.property.propertyGuid;
      inv.reservationGuid = this.reservation.reservationGuid;
      inv.charges = new Array<Charge>();
      for (const pq of this.priceQuotes) {
        if (
          pq.numberOfPeople &&
          this.reservation.numberOfPeople1 != pq.numberOfPeople
        ) {
          this.reservation.numberOfPeople1 = pq.numberOfPeople;
        }
        if (pq.charges && pq.charges.length > 0) {
          for (const charge of pq.charges) {
            charge.invoiceGuid = inv.invoiceGuid;
            inv.charges.push(charge);
          }
        }
      }
      this.reservation.invoices = new Array<Invoice>();
      this.reservation.invoices.push(inv);
    }
    for (const assign of this.reservation.assigns) {
      assign.assignGuid = Guid.newGuid();
      assign.propertyGuid = this.reservation.propertyGuid;
      assign.reservationGuid = this.reservation.reservationGuid;
    }
    this.save();
  }

  async save() {
    this.showOverlay = true;
    await this.saveNewReservationToStore(this.reservation);
    this.$emit("save", this.priceQuotes);
    this.showOverlay = false;
  }
}
export default AvailableRatesModal;
