
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import InvoiceModal from "../Invoices/InvoiceModal.vue";
import FinanceNavMenu from "../partials/FinanceNavMenu.vue";
import PaymentTable from "./PaymentTable.vue";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { InvoiceModal, FinanceNavMenu, PaymentTable } })
export default class Revenue extends Vue {
  @PropertyStore.State
  public property!: Property;
  
}
