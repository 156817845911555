
import { Unit } from "@/components/Cloud/Units/Unit";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

@Component({ components: {} })
class GridDatePopup extends Vue {
  @Prop({ default: Date.now() }) public arrivalDate!: Date;
  @Prop() public unit!: Unit;
  @Prop() public topPos!: number;
  @Prop() public leftPos!: number;
}
export default GridDatePopup;
