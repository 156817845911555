import { Assign } from "@/components/Cloud/Assign/Assign";
import { OutOfService } from "@/components/Cloud/OutOfService/OutOfService";
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import { getReservationStatusColorVariant, getReservationStatusString, ResStatus, ResStatusBackgroundColor, ResStatusFontColor } from "@/components/Cloud/Reservations/ResStatus";
import GridSettings from "@/components/Cloud/Settings/GridSettings";
import { formatter } from "@/utils/currencyUtils";
import { DrawableSelection } from "./DrawableSelection";
import { IDrawable } from "./IDrawable";
import { Point } from "./Point";
import { GridAssign } from "../GridAssign";
import { Unit } from "@/components/Cloud/Units/Unit";


const svgns = 'http://www.w3.org/2000/svg';

export class DrawableRectangle implements IDrawable {

    public constructor() {
        this.id = "";
        this.board = document.getElementById("board");
    }
    public board: HTMLElement | null;
    public reservation!: Reservation;
    public assign!: Assign;
    public unit!: Unit;
    public outOfService!: OutOfService;
    public gridSettings!: GridSettings;
    public gridAssign!: GridAssign;
    public id = "0";
    public isDirty = false;
    public startPoint = new Point(0, 0);
    public fillColor = "";
    public fill = "";
    public fillOpacity = 100;
    public height = 0;
    public width = 0;
    public isDragging = false;
    public strokeColor = "";
    public strokeOpacity = 100;
    public strokeWidth = 2;
    public cssClass = "";
    public cssId = "1234";
    public roundedCornerAmount = 12;
    public selector!: DrawableSelection;
    public rectElement!: SVGRectElement;
    public svgWrapper!: SVGElement;
    public textSvgWrapper!: SVGElement;
    public textEl!: SVGTextElement;
    public zIndex = "2";
    public text = "";
    public isSelected = false;

    updateUpperLeftCorner(x: number, y: number) {
        this.upperLeftCorner.x = x;
        this.upperLeftCorner.y = y;
    }
    updateFillOpacity(opacity) {
        this.fillOpacity = opacity;
        this.rectElement.setAttribute("fill-opacity", opacity);
    }
    updateOutlineColor(rgbColor) {
        this.strokeColor = rgbColor;
        this.rectElement.setAttribute("stroke", rgbColor);
    }
    updateThickness(thickness) {
        this.strokeWidth = thickness;
        this.rectElement.setAttribute("stroke-width", thickness);
    }
    public updateWidth(newWidth: number) {
        this.width = newWidth;
        this.rectElement.setAttribute("width", newWidth.toString());
    }
    public updatePos(newUpperLeftCornerX: number, newUpperLeftCornerY: number) {
        this.startPoint.x = newUpperLeftCornerX;
        this.startPoint.y = newUpperLeftCornerY;
        this.updateUpperLeftCorner(newUpperLeftCornerX, newUpperLeftCornerY);

        this.rectElement.setAttribute("x", newUpperLeftCornerX.toString());
        this.rectElement.setAttribute("y", newUpperLeftCornerY.toString());
    }

    public updateText(text: string) {
        this.text = text;
        const textXAdjustment = (this.startPoint.x < 0) ? (-this.startPoint.x) : 0;
        this.textEl.setAttribute("x", (10 + textXAdjustment).toString());
        this.textEl.setAttribute("y", "20");
        this.textSvgWrapper.setAttribute("width", this.width.toString());
        if (this.startPoint.x < 0) {
            this.textEl.textContent = "..." + this.text;
        } else {
            this.textEl.textContent = this.text;
        }
    }

    public update() {
        if (this.width <= 0) {
            return;
        }
        this.rectElement.setAttribute("width", this.width.toString());
        this.rectElement.setAttribute("height", this.height.toString());

        this.svgWrapper.setAttribute("width", this.width.toString());
        this.svgWrapper.setAttribute("height", this.height.toString());
        this.svgWrapper.setAttribute("x", this.startPoint.x.toString());
        this.svgWrapper.setAttribute("y", this.startPoint.y.toString());

        // if we need to put an ellipsis beause it's off the view
        const textXAdjustment = (this.startPoint.x < 0) ? (-this.startPoint.x) : 0;
        const textFudgeRight = 25;

        this.textSvgWrapper.setAttribute("width", this.width.toString());
        this.textEl.setAttribute("x", (textFudgeRight + textXAdjustment).toString());
        this.textEl.setAttribute("y", "20");

        if (this.startPoint.x < 0) {
            this.textEl.textContent = "..." + this.text;
        } else {
            this.textEl.textContent = this.text;
        }
    }

    updateHeight(newHeight: number) {
        this.height = newHeight;
        this.rectElement.setAttribute("height", newHeight.toString());
    }

    updateRoundValue(roundValue) {
        this.roundedCornerAmount = roundValue;
        this.rectElement.setAttribute("rx", roundValue);
        this.rectElement.setAttribute("ry", roundValue);
    }

    public get right(): Point {
        const rightPoint: Point = new Point(this.startPoint.x + this.width, this.startPoint.y + this.height);
        return rightPoint;
    }
    public get upperLeftCorner(): Point {
        const upperLeftPoint: Point = new Point(this.startPoint.x, this.startPoint.y);
        return upperLeftPoint;
    }

    public draw() {

        this.svgWrapper = document.createElementNS(
            svgns,
            "svg"
        );
        const defsEl = document.createElementNS(
            svgns,
            "defs"
        );
        const filterEl = document.createElementNS(
            svgns,
            "filter"
        );
        this.textEl = document.createElementNS(
            svgns,
            "text"
        );

        this.textSvgWrapper = document.createElementNS(
            svgns,
            "svg"
        );

        this.svgWrapper.setAttribute("class", "shadow");
        this.svgWrapper.setAttribute("ref", this.id.toString());
        this.svgWrapper.setAttribute("id", "svg-" + this.id.toString());

        const defs = document.createElementNS(svgns, 'defs');
        const gradient = document.createElementNS(svgns, 'linearGradient');


        // const crossHatchPattern = document.createElementNS(svgns, 'pattern');
        // crossHatchPattern.id = "diagonalHatch";
        // const path = document.createElementNS(svgns, 'path');
        // path.setAttribute('d', "M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2");
        // path.setAttribute('style', "stroke:black; stroke-width:1");
        // crossHatchPattern.appendChild(path);
        // defs.appendChild(crossHatchPattern);

        // crossHatchPattern.patternUnits = "userSpaceOnUse";

        // use this for out of service
        //https://stackoverflow.com/questions/13069446/simple-fill-pattern-in-svg-diagonal-hatching

        //         <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="4">
        //   <path d="M-1,1 l2,-2
        //            M0,4 l4,-4
        //            M3,5 l2,-2" 
        //         style="stroke:black; stroke-width:1" />
        // </pattern>
        if (this.reservation && this.reservation.status && this.gridSettings.showStatus) {
            this.fillColor =
                ResStatusBackgroundColor[
                ResStatus[this.reservation.status].toString()
                ];
        }


        const stops = [
            {
                color: "#fff", // white
                offset: "0%"
            }, {
                color: this.fillColor,
                offset: "100%"
            }
        ];

        /**                                         
         * Parse the array of stop information and append a `<stop>` element for each
         * stop to `<linearGradient>`.
         */
        stops.forEach(stop => {
            const el = document.createElementNS(svgns, 'stop');
            el.setAttribute('offset', stop.offset);
            el.setAttribute('stop-color', stop.color);

            // Add the `<stop>` element to `<linearGradient>`.
            gradient.appendChild(el);
        });

        gradient.id = 'Gradient-' + this.id.toString();
        gradient.setAttribute('x1', '0');
        gradient.setAttribute('x2', '1');
        gradient.setAttribute('y1', '0');
        gradient.setAttribute('y2', '1');
        defs.appendChild(gradient);

        const rect = document.createElementNS(svgns, "rect");

        rect.setAttribute("fill-opacity", this.fillOpacity.toString());
        rect.setAttribute("stroke", this.strokeColor.toString());
        rect.setAttribute(
            "stroke-opacity",
            this.strokeOpacity.toString()
        );
        rect.setAttribute("stroke-width", this.strokeWidth.toString());
        rect.setAttribute("class", this.cssClass);
        rect.setAttribute("id", this.id.toString());
        rect.setAttribute("ref", this.id.toString());
        rect.setAttribute("rx", this.roundedCornerAmount.toString());
        rect.setAttribute("ry", this.roundedCornerAmount.toString());


        if (this.reservation && this.reservation.status) {
            if (this.gridSettings.showResName && this.reservation.name) {
                this.text = this.reservation.name + ' - ' + getReservationStatusString(this.reservation);
            }
            if (this.gridSettings.showArrivalTime) {
                this.text = new Date(this.assign.inDateTime).time(true) + ' - ' + this.text;
            }
            if (this.gridSettings.showBalance) {
                this.text = this.text + " - " + formatter(this.reservation.balance);
            }

            if (this.gridSettings.showNotes) {
                if (this.reservation.notes) {
                    this.text = this.text + " - " + this.reservation.notes;
                }
            }
            if (this.gridSettings.showRequests) {
                if (this.reservation.requests) {
                    this.text = this.text + " - " + this.reservation.requests;
                }
            }
            if (this.gridSettings.showPersonCount) {
                this.text = this.text + " - " + (this.reservation.numberOfPeople1 +
                    this.reservation.numberOfPeople2 +
                    this.reservation.numberOfPeople3 +
                    this.reservation.numberOfPeople4) + " people";
            }

            if (this.gridSettings.showStatus) {
                let variant = getReservationStatusColorVariant(this.reservation);
                this.textEl.setAttribute("font-weight", "bold");
                this.textEl.setAttribute("class", "badge-" + variant);
            }


        } else if (this.outOfService) {
            //out of service
            if (this.gridSettings.showStatus) {
                this.text = "OUT OF SERVICE - " + this.outOfService.notes;
                this.textEl.setAttribute("font-weight", "bold");
                this.fillColor = ResStatusBackgroundColor["OutOfService"];
                this.textEl.setAttribute("class", "badge-outofservice");
            }
        } else {

            this.textEl.setAttribute("class", "badge-outofservice");
        }

        rect.style.zIndex = this.zIndex;
        rect.style.cursor = "pointer";
        this.svgWrapper.style.zIndex = this.zIndex;

        this.svgWrapper.appendChild(defs);
        this.svgWrapper.appendChild(rect);
        this.textEl.textContent = this.text;

        this.textSvgWrapper.appendChild(this.textEl);
        if (this.width < 0) {
            this.width = 0;
        }
        this.textSvgWrapper.setAttribute("width", this.width.toString());
        this.svgWrapper.appendChild(this.textSvgWrapper);

        if (this.reservation && this.reservation.status == ResStatus.CheckedIn && this.gridSettings.showStatus) {
            this.svgWrapper.appendChild(this.checkMarkIcon());
        }
        else if (this.reservation && (this.reservation.takenBy == 'CRS' || this.reservation.takenBy?.startsWith('Online Booking'))) {
            this.svgWrapper.appendChild(this.globeIcon());
        }

        this.rectElement = rect;
        this.fill = 'url(#' + gradient.id + ')';
        if (this.outOfService) {

            this.fill = 'gray';
        }
        this.rectElement.setAttribute("fill", this.fill);

        this.update();
        if (this.board) {
            this.board.appendChild(this.svgWrapper);
        }
    }

    private adjustText(boxElem: SVGElement) {
        const rectElem = boxElem.querySelector("rect");
        const textElem = boxElem.querySelector("text");
        if (rectElem && textElem) {
            const rectBbox = rectElem.getBBox();
            const translateX = rectBbox.x;
            const translateY = rectBbox.y;

            textElem.setAttribute("transform", "translate(" + translateX + "," + translateY + ")");
        }

    }

    globeIcon() {
        let height = 10;
        let width = 10;
        const icon = document.createElementNS(
            svgns,
            "svg"
        );
        icon.setAttribute("class", "svg-icon");
        icon.setAttribute("y", "5");
        icon.setAttribute("x", "5");

        const path = document.createElementNS(
            svgns,
            "path"
        );
        const group = document.createElementNS(
            svgns,
            "g"
        );
        icon.setAttribute("class", "b-icon bi");
        path.setAttribute("fill", "blue");
        path.setAttribute("d", "M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z");
        group.appendChild(path);
        icon.appendChild(group);
        icon.style.zIndex = this.zIndex + 1;
        return icon;
    }

    checkMarkIcon() {
        const icon = document.createElementNS(
            svgns,
            "svg"
        );
        icon.setAttribute("class", "svg-icon");
        icon.setAttribute("viewBox", "0 0 25 25");
        icon.setAttribute("y", "5");
        icon.setAttribute("x", "5");

        const path = document.createElementNS(
            svgns,
            "path"
        );
        const group = document.createElementNS(
            svgns,
            "g"
        );
        icon.setAttribute("class", "bi-currency-dollar mx-auto b-icon bi");
        icon.setAttribute("height", "25");
        icon.setAttribute("width", "25");
        path.setAttribute("fill", "green");
        path.setAttribute("d", "M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z");
        group.appendChild(path);
        icon.appendChild(group);
        icon.style.zIndex = this.zIndex + 1;
        return icon;
    }

    public removeRectangle() {
        if (this.rectElement) { this.rectElement.remove(); }
        if (this.selector) {
            this.selector.removeSelection();
        }
        if (this.svgWrapper) { this.svgWrapper.remove(); }
    }
}