export default class Category {
    constructor(name: string, code: string) {
        this.name = name;
        this.code = code;
    }
    public code: string;
    public name: string;
}


export async function getCategories(): Promise<Array<Category>> {

    let categories = [
        new Category("ATV", "1"),
        new Category("UTV", "2"),
        new Category("Snowmobile", "3"),
        new Category("Boat", "4"),
        new Category("Cabin", "5"),
        new Category("Houseboat", "6"),
        new Category("Bicycle", "7"),
        new Category("Paddleboard", "8"),
        new Category("Fishing Guide", "9"),
        new Category("Event", "10"),
        new Category("Trip", "11"),
        new Category("Fish House", "12"),
        new Category("Canoe", "13"),
        new Category("Kayak", "14"),
        new Category("Room", "15"),
        new Category("Snowshoe", "16"),
        new Category("Ski", "17"),
    ];
    categories.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });
    categories.unshift(
        new Category("Any", "0"));
    return categories;
}