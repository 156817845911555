import { axiosRetry } from "@/utils/fetchUtil";

export class Card {

    public id!: string;
    public object!: string;
    public address_city!: string;
    public address_country!: string;
    public address_line1!: string;
    public address_line1_check!: string;
    public address_line2!: string;
    public address_state!: string;
    public address_zip!: string;
    public address_zip_check!: string;
    public brand!: string;
    public country!: string;
    public customer!: string;
    public cvc_check!: string;
    public dynamic_last4!: string;
    public expMonth!: number;
    public expYear!: number;
    public fingerprint!: string;
    public funding!: string;
    public last4!: string;
    public name!: string;
    public tokenization_method!: string;
    public wallet!: string;
    public readableDescription!: string;
    public isDefault!: boolean;
}


export async function getCardsByPerson(personGuid: string): Promise<Array<Card>> {
    const response = await axiosRetry(
        `/api/invoice/GetCardsByPerson/${personGuid}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<Card>>;
    } else {
        const err =
            "An error occurred getting the cards from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function setDefaultPaymentMethodToNewCard(token: string, customerStripeId: string): Promise<string> {
    const response = await axiosRetry(
        `/api/invoice/setdefaultpaymentmethodtonewcard/${token}/${customerStripeId}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    } else {
        const err =
            "An error occurred getting the cards from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function setDefaultPaymentMethodToExistingCard(cardStripeId: string, customerStripeId: string): Promise<string> {
    const response = await axiosRetry(
        `/api/invoice/setdefaultpaymentmethodtoexistingcard/${cardStripeId}/${customerStripeId}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    } else {
        const err =
            "An error occurred getting the cards from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function createCard(token: string, personGuid: string): Promise<string> {
    const response = await axiosRetry(
        `/api/invoice/createcard/${token}/${personGuid}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    } else {
        const err =
            "An error occurred getting the cards from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function deleteCard(cardStripeId: string, customerStripeId: string): Promise<string> {
    const response = await axiosRetry(
        `/api/invoice/deletecard/${cardStripeId}/${customerStripeId}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    } else {
        const err =
            "An error occurred deleting the card from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function createChargeForDefault(customerStripeId: string, amount: number): Promise<string> {
    amount = Math.trunc(amount);
    const response = await axiosRetry(
        `/api/invoice/createcharge/${customerStripeId}/${amount}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data.result as Promise<string>;
    } else {
        const err =
            "An error occurred getting the cards from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function refund(chargeStripeId: string, amount?: number): Promise<string> {
    var route = `/api/invoice/refund/${chargeStripeId}` + (amount) ? `/${amount}` : ``;
    const response = await axiosRetry(
        route,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    } else {
        const err =
            "An error occurred getting the cards from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
    // {
    //     "id": "card_1NNktTAnwTl9yoxXeqP7ntmP",
    //     "object": "card",
    //     "address_city": null,
    //     "address_country": null,
    //     "address_line1": null,
    //     "address_line1_check": null,
    //     "address_line2": null,
    //     "address_state": null,
    //     "address_zip": null,
    //     "address_zip_check": null,
    //     "brand": "Visa",
    //     "country": "US",
    //     "customer": "cus_LjrUSudGhFm4qp",
    //     "cvc_check": "pass",
    //     "dynamic_last4": null,
    //     "exp_month": 8,
    //     "exp_year": 2024,
    //     "fingerprint": "Fn095AWQQLP5QbxS",
    //     "funding": "credit",
    //     "last4": "4242",
    //     "metadata": {},
    //     "name": null,
    //     "tokenization_method": null,
    //     "wallet": null
    //   },
