
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ELEMENT_CONFIG, StripeElement } from "@/models/StripeElement";
import { COMPONENT_OPTIONS } from "@/models/StripeStyle";
import { namespace } from "vuex-class";
import { getProperty } from "../Settings/PropertyService";
import { createCard } from "./Card";
import { Person } from "../People/Person";
@Component({
  components: {},
})
export default class CreditCardModal extends Vue {
  @Prop({ default: null }) public property!: Property;
  @Prop({ default: null }) public person!: Person;
  private stripeInstance;
  private showCardModal = true;
  private showProgressOverlay = false;
  private cardNumberEl;
  private cardExpiryEl;
  private cardCvcEl;
  private errors = new Array<string>();
  private saveCardResult;

  private readonly testStripePublishableKey =
    "pk_test_TiLjR6XavA0qw6492vNcxYzr";

  async initCardComponents(): Promise<void> {
    if (
      this.property &&
      this.property.checkoutSettings &&
      (this.property.checkoutSettings.isTestMode ||
        (this.property.checkoutSettings.stripePublishableKey &&
          this.property.checkoutSettings.stripeConnectAccountId))
    ) {
      this.stripeInstance = (window as any).Stripe(
        this.property.checkoutSettings &&
          this.property.checkoutSettings.isTestMode
          ? this.testStripePublishableKey
          : this.property.checkoutSettings.stripePublishableKey
      );
      const elements = this.stripeInstance.elements();
      this.cardNumberEl = elements.create(StripeElement.CARD_NUMBER, {
        ...COMPONENT_OPTIONS,
        ...ELEMENT_CONFIG[StripeElement.CARD_NUMBER],
      });
      this.cardExpiryEl = elements.create(StripeElement.CARD_EXPIRY, {
        ...COMPONENT_OPTIONS,
        ...ELEMENT_CONFIG[StripeElement.CARD_EXPIRY],
      });
      this.cardCvcEl = elements.create(StripeElement.CARD_CVC, {
        ...COMPONENT_OPTIONS,
        ...ELEMENT_CONFIG[StripeElement.CARD_CVC],
      });

      this.cardNumberEl.mount("#stripe-card-number-element");
      this.cardExpiryEl.mount("#stripe-card-expiry-element");
      this.cardCvcEl.mount("#stripe-card-cvc-element");
    }
  }

  async mounted() {
    this.showProgressOverlay = true;
    await this.initCardComponents();
    this.showProgressOverlay = false;
  }
  async saveAndClose() {
    // save the card if it validates
    await this.createCard();
  }

  async createCard() {
    this.showProgressOverlay = true;
    this.errors = [];
    let response = await this.stripeInstance.createToken(this.cardNumberEl);

    if (response.error) {
      this.errors.push(response.error.message);
      this.showProgressOverlay = false;
    } else {
      const token = response.token.id;

      try {
        console.log(this.person.personGuid);
        this.saveCardResult = await createCard(token, this.person.personGuid);
        if (this.saveCardResult == "Error") {
          this.errors.push(this.saveCardResult.error);
        } else {
          this.$emit("close", response.token);
        }
      } catch (err) {
        this.errors.push("Error adding card-" + err);
      }
      this.showProgressOverlay = false;
    }
  }
}
