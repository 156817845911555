
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";
import { namespace } from "vuex-class";
import ActivityTable from "./ActivityTable.vue";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    ActivityTable,
    ConfigurationNavMenu,
  },
})
export default class Activities extends Vue {
  @PropertyStore.State
  public property!: Property;
}
