
import { Vue, Component } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";

@Component({ components: {} })
export default class Clients extends Vue {
  // Initialize and add the map

  async mounted() {
    const loader = new Loader({
      apiKey: "AIzaSyDVYoGJ1NaKgqdrfVviGXh7rqVhY1uZe0g",
      version: "weekly",
      libraries: ["places"],
    });

    await loader.load().then((google) => {
      this.initMap();
    });
  }

  initMap() {
    // The location of saint louis park mn
    var slp = { lat: 44.935198, lng: -93.333792 };
    // The map, centered at Uluru
    let el = document.getElementById("map");
    if (el) {
      var map = new google.maps.Map(el, {
        zoom: 4,
        center: slp,
      });

      // The marker, positioned at saint louis park mn
      var marker = new google.maps.Marker({ position: slp, map: map });
    }
  }
}
