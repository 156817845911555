import { axiosRetry } from "@/utils/fetchUtil";

export default class GridSettings {
  constructor() {
  }
  public propertyGuid!: string;
  public showResName!: boolean;
  public showPersonName!: boolean;
  public showBalance!: boolean;
  public showStatus!: boolean;
  public allowResize!: boolean;
  public allowMove!: boolean;
  public showOOS!: boolean;
  public showPersonCount!: boolean;
  public showArrivalTime!: boolean;
  public showNotes!: boolean;
  public showRequests!: boolean;
  public datesHeaderHeight!: number;
  public rowHeight!: number;
  public columnWidth!: number;
  public scrollAmount!: number;
}

export async function saveGridSettings(gridSettings: GridSettings): Promise<boolean> {
  const response = await axiosRetry(
    `/api/property/savegridsettings`,
    gridSettings,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
