
import { Amenity } from '@/components/Cloud/Amenity/Amenity';
import { Unit } from '@/components/Cloud/Units/Unit';
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { AmenityInUnit } from './AmenityInUnit';


export async function deleteAmenities(amenitysGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/amenity/delete`,
    amenitysGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteAmenity(amenityGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/amenity/` + amenityGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveAmenity(amenity: Amenity): Promise<boolean> {
  const response = await axiosRetry(
    `/api/amenity`,
    amenity,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getAmenities(propertyGuid: string): Promise<Array<Amenity>> {
  let url = `/api/amenity/getbyproperty/${propertyGuid}`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Amenity>>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveAmenityInUnits(amenityInUnits: Array<AmenityInUnit>): Promise<boolean> {
  let url = '';
  url = `/api/amenity/saveamenitiesinunit`;
  const response = await axiosRetry(
    url,
    amenityInUnits,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteAmenityInUnitsByUnit(unitGuid: string): Promise<boolean> {
  let url = '';
  url = `/api/amenity/deleteamenityinunitsbyunit/${unitGuid}`;
  const response = await axiosRetry(
    url,
    unitGuid,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteAmenityInUnitsByAmenity(amenityGuid: string): Promise<boolean> {
  let url = '';
  url = `/api/amenity/deleteamenityinunitsbyamenity/${amenityGuid}`;
  const response = await axiosRetry(
    url,
    amenityGuid,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getAmenitiesByUnit(unitGuid: string): Promise<Array<Amenity>> {
  let url = '';
  url = `/api/amenity/getbyunit/${unitGuid}`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Amenity>>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getUnitsByAmenity(amenityGuid: string): Promise<Array<Unit>> {
  let url = '';
  url = `/api/amenity/getbyamenity/${amenityGuid}`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Unit>>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getAmenitysByPerson(personGuid: string): Promise<Array<Amenity>> {
  const response = await axiosRetry(
    `/api/amenity/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Amenity>>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getAmenity(amenityGuid: string): Promise<Amenity> {
  const response = await axiosRetry(
    `/api/amenity/getamenity/${amenityGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Amenity>;
  } else {
    const err =
      "An error occurred getting the amenity from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

