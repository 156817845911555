
import { Invoice } from "@/components/Cloud/Invoices/Invoice";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import InvoiceEdit from "./InvoiceEdit.vue";

@Component({ components: { InvoiceEdit } })
class InvoiceModal extends Vue {
  @Prop({ default: null }) public invoiceGuid!: Guid;
  private loading = false;

  async save(inv: Invoice) {
    this.$emit("save", inv);
  }
}
export default InvoiceModal;
