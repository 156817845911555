
import { AvailabilityParameters } from "@/models/AvailabilityParameters";
import Property from "@/models/Property";
import { Unit } from "@/components/Cloud/Units/Unit";
import { UnitGroup } from "@/models/UnitGroup";
import { formatter, roundToDollar } from "@/utils/currencyUtils";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getProperty } from "../Cloud/Settings/PropertyService";
import { getUnitGroup } from "../Cloud/Units/UnitGroupService";
import { getBookableUnits } from "../Cloud/Units/UnitService";

const PropertyStore = namespace("PropertyStore");
const AvailabilityParametersStore = namespace("AvailabilityParametersStore");

@Component({
  components: {},
})
export default class Units extends Vue {
  @AvailabilityParametersStore.State
  private availParams!: AvailabilityParameters;

  public property!: Property;

  private propertyGuid!: string;

  private loading = false;
  private units: Array<Unit> = [];
  public unitGroup = new UnitGroup();
  private pickedDate: Date = new Date();

  async datePicked(arg: any) {
    // this.pickedDate = new Date(arg);
    this.$router.replace({
      query: {
        ...this.$router.currentRoute.query,
        date: new Date(this.pickedDate).dashedDate(),
      },
    });
  }

  public onClickButton(unitGuid: string): void {
    this.$router.push({
      name: "Book",
      params: {
        unit: unitGuid,
      },
      query: {
        length: this.availParams ? this.availParams.nights.toString() : "2",
        adults: this.availParams ? this.availParams.adults.toString() : "2",
        children: this.availParams ? this.availParams.children.toString() : "0",
      },
    });
  }

  formatCurrency(num: number) {
    return roundToDollar(num, this.property.currencyCode);
  }

  async mounted() {
    this.loading = true;
    if (this.$route.params.propertyGuid) {
      this.propertyGuid = this.$route.params.propertyGuid.toString();
    } else if (this.availParams.propertyGuid) {
      this.propertyGuid = this.availParams.propertyGuid;
    }
    this.property = await getProperty(this.propertyGuid);
    if (this.$route.query.group) {
      this.unitGroup = await getUnitGroup(this.$route.query.group.toString());
      if (
        !this.property ||
        this.unitGroup.propertyGuid !== this.property.propertyGuid
      ) {
        localStorage.setItem("propertyGuid", this.unitGroup.propertyGuid);
        window.location.reload();
      }
      this.propertyGuid = this.unitGroup.propertyGuid;
      this.property = await getProperty(this.propertyGuid);
    }

    this.units = await getBookableUnits(this.property.propertyGuid);

    if (this.unitGroup.unitGroupGuid) {
      this.units = this.units.filter((item) => {
        return item.unitGroupGuid === this.unitGroup.unitGroupGuid;
      });
    }
    this.loading = false;
  }
}
