
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { Document } from "@/components/Cloud/Documents/Document";
import {
  getDocument,
  saveDocument,
} from "@/components/Cloud/Documents/DocumentService";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class DocumentEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public paramDocumentGuid!: string;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  public documentGuid!: string;
  private showEditModal = false;
  private loading = false;
  public document!: Document;
  private busy = false;

  validateName() {
    return true;
  }

  public cancel() {
    this.showEditModal = false;
    this.$emit('cancel');
  }

  getDocument() {
    return this.document;
  }

  async mounted() {
    this.loading = true;
    if (this.paramDocumentGuid) {
      this.documentGuid = this.paramDocumentGuid;
    } else if (this.$route.params.documentGuid) {
      this.documentGuid = this.$route.params.id.toString();
    } else if (this.$route.query.documentGuid) {
      this.documentGuid = this.$route.query.documentGuid.toString();
    }
    if (this.documentGuid) {
      this.document = await getDocument(this.documentGuid.toString());
    }
    if (!this.document) {
      this.document = new Document();
      this.document.documentGuid = Guid.newGuid();
      this.document.propertyGuid = this.property.propertyGuid;
      this.document.createdDate = new Date();
    }
    this.loading = false;
  }

  async saveAndClose() {
    this.busy = true;
    //const nameTextBox = this.$refs["name-textbox"];
    // this.document.description = (nameTextBox as any).localValue;
    await saveDocument(this.document).then(() =>
      this.$emit("success", this.document)
    );
    this.busy = false;
  }
}
