
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "../Cloud/Auth/User";
import {
  SendEmailParams,
  submitDemoRequest,
} from "../Cloud/Templates/EmailService";
const Auth = namespace("AuthStore");
@Component({
  components: {},
})
export default class HostSignUp extends Vue {
  @Auth.State("user")
  private currentUser!: User;
  private showProgressOverlay = false;
  private submitClicked = false;
  private errors = new Array<string>();
  private message = "";
  private model = { name: "", email: "", company: "" };

  async validate() {
    this.errors = [];
    if (this.model.name == "") {
      this.errors.push("Your name is required");
    }
    if (
      this.model.email == "" ||
      !(this.model.email.includes("@") && this.model.email.includes("."))
    ) {
      this.errors.push("A valid email address is required");
    }
    return this.errors.length == 0;
  }

  validateName() {
    if (this.submitClicked) {
      if (this.model.name && this.model.name.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateEmail() {
    if (this.submitClicked) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.model.email);
    }
    return null;
  }

  async sendDemoRequest() {
    this.showProgressOverlay = true;
    this.submitClicked = true;
    if (await this.validate()) {
      // send en email to this person
      let params = new SendEmailParams();
      params.subject = "Demo Request";
      params.recipientEmail = this.model.email;
      params.recipientFirstName = this.model.name + " - " + this.model.company;
      params.fromEmail = "support@lodgevault.com";
      params.fromName = "Lodge Vault";
      let response = await submitDemoRequest(params);

      this.message =
        "Thank you. An email message with further instructions has been sent to the address you specified. " +
        "We'll be in contact with you shortly.";

      this.clearForm();
    }
    this.showProgressOverlay = false;
  }

  clearForm() {
    this.model = { name: "", email: "", company: "" };
    this.submitClicked = false;
  }
}
