import { GridWeek } from "./GridWeek";

export default class MonthInfo {
    constructor() {
        this.days = 0;
    }
    public days!: number;
    public month!: number;
    public year!: number;
    public weeks!: Array<GridWeek>;
    public monthName!: string;

    public static GetPreviousMonthInfo(date: Date): MonthInfo {
        let days = 0;
        let month = 0;
        let year = 0;
        if (date.getMonth() == 0) {
            //it's january so get december from year before           
            month = 11;
            year = date.getFullYear() - 1;
            days = new Date(year, month + 1, 1).daysInMonth();
        }
        else {
            //get previous month in same year
            month = date.getMonth() - 1;
            year = date.getFullYear();
            days = new Date(year, month + 1, 1).daysInMonth();
        }

        const result = new MonthInfo();
        result.days = days;
        result.month = month;
        result.year = year;
        return result;
    }
    public static GetNextMonthInfo(date: Date): MonthInfo {
        let days = 0;
        let month = 0;
        let year = 0;
        if (date.getMonth() == 11) {
            //it's december so get January of next year
            year = date.getFullYear() + 1;
            month = 0;
            days = new Date(year, month + 1, 1).daysInMonth();
        }
        else {
            //get previous month in same year
            month = date.getMonth() + 1;
            year = date.getFullYear();
            days = new Date(year, month + 1, 1).daysInMonth();

        }
        const result = new MonthInfo();
        result.days = days;
        result.month = month;
        result.year = year;
        return result;
    }
}