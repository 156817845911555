import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { Document } from './Document';


export async function deleteDocuments(documentsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/document/delete`,
    documentsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the document from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteDocument(documentGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/document/` + documentGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the document from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function uploadDocument(files: FormData): Promise<boolean> {
  const response = await axiosRetry(
    `/api/document/upload`,
    files,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the document from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function saveDocument(doc: Document): Promise<boolean> {
  const response = await axiosRetry(
    `/api/document`,
    doc,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the document from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getDocuments(propertyGuid: string): Promise<Array<Document>> {
  let url = `/api/document/getbyproperty/${propertyGuid}`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Document>>;
  } else {
    const err =
      "An error occurred getting the document from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getDocumentsByPerson(personGuid: string): Promise<Array<Document>> {
  const response = await axiosRetry(
    `/api/document/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Document>>;
  } else {
    const err =
      "An error occurred getting the document from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getDocumentsByUnit(unitGuid: string): Promise<Array<Document>> {
  const response = await axiosRetry(
    `/api/document/getbyunit/${unitGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Document>>;
  } else {
    const err =
      "An error occurred getting the document from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getDocument(documentGuid: string): Promise<Document> {
  const response = await axiosRetry(
    `/api/document/getdocument/${documentGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Document>;
  } else {
    const err =
      "An error occurred getting the document from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

