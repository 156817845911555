import { AvailabilityParameters } from "@/models/AvailabilityParameters";
import { AvailableUnitsViewModel } from "@/models/AvailableUnitsViewModel";
import { DateSearchParameters } from "@/models/DateSearchParameters";
import { PagingParameters } from "@/models/PagingParameters";
import { fetchRetry } from "@/utils/fetchUtil";


class AvailModelDataService {
  public async getAll(availParams: AvailabilityParameters, pagingParams: PagingParameters): Promise<AvailableUnitsViewModel> {
    const dateSearchParameters = new DateSearchParameters()
    dateSearchParameters.AvailabilityParameters = availParams;
    dateSearchParameters.PagingParameters = pagingParams;
    const response = await fetchRetry("/api/book/availableunits", dateSearchParameters, "POST");
    if (response.ok) {
      return response.json() as Promise<AvailableUnitsViewModel>;
    } else {
      const err =
        "An error occurred retrieving the AvailableUnitsViewModel from the server." +
        `The error code was ${response.status} (${response.statusText}).`;
      throw Error(err);
    }
  }
}

export default new AvailModelDataService();
