
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class ComboBox extends Vue {
  @Prop({ default: null }) public items!: Array<string>;
  @Prop({ default: null }) public value!: string;

  private placeholder = "foo";
  private showAutocompleteDropdown = false;
  private selectedIndex = 0;
  private selectedItem = "";

  mounted() {
    const searchbox = document.getElementById("combobox-input");
    if (searchbox) {
      searchbox.addEventListener("search", this.clearHandler);

      document.body.addEventListener("click", this.hideIfNoFocus);
    }
    this.selectedItem = this.value;
  }

  clearHandler() {
    this.$emit("valuechanged", "");
  }

  hideIfNoFocus() {
    const inputBox = document.getElementById("combobox-input");
    if (inputBox) {
      if (inputBox === document.activeElement) {
        this.showAutocompleteDropdown = true;
      } else {
        //this.showAutocompleteDropdown = false;
      }
    }
  }

  handleBackspace() {
    this.showAutocompleteDropdown = true;
  }

  select(index: number) {
     this.showAutocompleteDropdown = false;
    this.selectedIndex = index;
    this.selectedItem = this.items[index];
    this.$emit("valuechanged", this.selectedItem);
  }

  selectNext() {
    if (this.showAutocompleteDropdown) {
      if (this.selectedIndex < this.items.length - 1) {
        this.selectedIndex++;
      } else {
        this.selectedIndex = 0;
      }
    } else {
      this.showAutocompleteDropdown = true;
    }
    this.select(this.selectedIndex);
  }

  selectPrev() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    } else {
      this.selectedIndex = this.items.length - 1;
    }
    this.select(this.selectedIndex);
  }
}
