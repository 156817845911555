
import { Field } from "@/models/interfaces/Field";
import { PagingParameters } from "@/models/PagingParameters";
import Property from "@/models/Property";
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import { ReservationsPagedModel } from "@/components/Cloud/Reservations/ReservationsPagedModel";
import {
  getReservationStatusColorVariant,
  getReservationStatusString,
} from "@/components/Cloud/Reservations/ResStatus";
import {
  getReservationsByPerson,
  deleteReservation,
} from "@/components/Cloud/Reservations/ReservationService";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import ReservationEdit from "./ReservationEdit.vue";
import { Activity, ActivityKind } from "../Activity/Activity";
import { saveActivity } from "../Activity/ActivityService";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { ReservationEdit, ConfirmDeleteModal } })
export default class ReservationsTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: null }) public personGuid!: string;
  private isNewRes = false;
  private loading = true;
  private resFields: Array<Field> = [];
  private pageSize = 30;
  private page = 1;
  private reservationIndex = 0;
  private searchTitle = "";
  private reservations = new Array<Reservation>();
  private selected!: Array<any>;
  private showResModal = false;
  private selectedRes = new Reservation();
  private clickedIndex = 0;
  private showConfirmModal = false;
  private resTable: any;

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async selectnone() {
    await this.resTable.clearSelected();
  }

  async add() {
    this.selectnone();
    this.isNewRes = true;
    this.showResModal = true;
    // need to add a pir with primary as the person guid to the res first
    // do this prior to opening?
  }

  async deleteReservations() {
    this.loading = true;
    if (this.selected && this.selected.length > 0) {
      await this.selected.forEach(async (d) => {
        let deleted = d;
        let result = await deleteReservation(
          deleted.reservationGuid.toString()
        );
        if (result) {
          if (this.reservations) {
            let index = this.reservations.findIndex(
              (a) => a.reservationGuid === deleted.reservationGuid
            );
            this.reservations.splice(index, 1);
            // add an activity on the person sayign this happened
            try {
              let act = new Activity();
              act.activityGuid = Guid.newGuid();
              act.createdDate = new Date();
              act.propertyGuid = this.property.propertyGuid;
              act.description =
                "Deleted reservation " +
                d.name +
                " arriving on " +
                new Date(d.arrivalDate).formatMMDDYYYY();
              act.kind = ActivityKind.Other;
              act.personGuid = this.personGuid;
              act.notes = act.description;
              await saveActivity(act);
            } catch (err) {
              console.log(err);
            }
          }
        }
      });
      this.showConfirmModal = false;
    }

    await this.resTable.refresh();
    this.loading = false;
  }

  edit(res: any, index: any, event: any) {
    this.isNewRes = false;
    if (res) {
      this.selectedRes = res;
    } else if (this.selected && this.selected.length > 0) {
      this.selectedRes = this.selected[0];
    }
    this.showResModal = true;
  }

  closeResModal() {
    this.showResModal = false;
    this.$emit("modified");
  }

  async afterSaveResModal(arg: any) {
    // refresh the grid?
    this.retrieve();
    this.showResModal = false;
    await this.resTable.refresh();
  }

  async afterCloseResModal(arg: any) {
    this.retrieve();
    // refresh the grid?
    this.showResModal = false;
    await this.resTable.refresh();
  }

  closeReservationModal() {
    this.showResModal = false;
  }

  formatCurrency(num: number) {
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  async saveAndClose(res: Reservation) {
    let found = this.reservations.find(
      (r) => r.reservationGuid === res.reservationGuid
    );
    if (!found) {
      await this.reservations.push(res);
    }
    let index = await this.reservations.findIndex(
      (r) => r.reservationGuid === res.reservationGuid
    );
    this.reservations[index] = res;
    await this.resTable.refresh();
    this.resTable.selectRow(index);
    this.showResModal = false;
  }

  getReadableDateTime(dateToFormat: Date) {
    if (new Date(dateToFormat).isMinDate()) {
      return "";
    }
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  getReadableDate(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateLocalized();
  }

  getTotalPeople(val: any) {
    const res: Reservation = val.item;
    return (
      res.numberOfPeople1 +
      res.numberOfPeople2 +
      res.numberOfPeople3 +
      res.numberOfPeople4
    );
  }

  getReservationStatusColor(val: any) {
    return getReservationStatusColorVariant(val.item);
  }
  getReservationStatusString(val: any) {
    return getReservationStatusString(val.item);
  }

  private getResFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    this.resFields.push(field);

    field = new Field();
    field.key = "balance";
    field.label = "Balance";
    this.resFields.push(field);

    field = new Field();
    field.key = "arrivalDate";
    field.label = "Arrives";
    this.resFields.push(field);

    field = new Field();
    field.key = "departureDate";
    field.label = "Departs";
    this.resFields.push(field);

    field = new Field();
    field.key = "status";
    field.label = "Status";
    this.resFields.push(field);

    field = new Field();
    field.key = "totalPeople";
    field.label = "People";
    this.resFields.push(field);

    field = new Field();
    field.key = "unitAssignments";
    field.label = "Units";
    this.resFields.push(field);

    field = new Field();
    field.key = "requests";
    field.label = "Requests";
    this.resFields.push(field);

    field = new Field();
    field.key = "notes";
    field.label = "Notes";
    this.resFields.push(field);
  }

  async mounted() {
    this.resTable = this.$refs.resTableRef;
    this.getResFields();
    await this.retrieve();
    this.loading = false;
  }

  async retrieve() {
    if (this.personGuid) {
      this.reservations = await getReservationsByPerson(this.personGuid);
    }
  }
}
