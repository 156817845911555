
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ClearBackground from "@/components/SiteBook/ClearBackground.vue";
import { GridDay } from "@/models/GridDay";
import MonthInfo from "@/models/MonthInfo";
import { GridWeek } from "@/models/GridWeek";
import { namespace } from "vuex-class";
import Property from "@/models/Property";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    ClearBackground,
  },
})
class DatePicker extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: () => new Date() }) public startDate!: Date;
  @Prop({ default: () => new Date() }) public endDate!: Date;
  @Prop({ default: "range" }) public mode!: string; // range or single (button) or singleInput, searchbanner
  @Prop({ default: "bottom" }) public opens!: string; // top, bottom, center
  @Prop({ default: true }) public showCheckInOutLabels!: boolean;
  @Prop({ default: false }) public masked!: boolean; // this when true, allows a user to type in a date
  @Prop({ default: false }) public showCalendarButton!: boolean;
  @Prop({ default: true }) public allowEdit!: boolean;

  @Prop({ default: "md" }) public size!: string; // sm, md, lg (sm = sitebook)
  @Prop({ default: "Change Date" }) public buttonText!: string; // display on button
  @Prop({ default: true }) public popup!: boolean; // true for popup or false for inline
  @Prop({ default: () => new Array<GridDay>() })
  public gridDays!: Array<GridDay>;
  @Prop({ default: 2 }) public numberOfMonths!: number;
  @Prop({ default: true }) public disablePastDays!: boolean;
  @Prop({ default: false }) public disabled!: boolean;
  @Prop({ default: null }) public daysEnabledInFuture!: number;

  private firstPickedDate!: Date | null;
  private secondPickedDate!: Date | null;
  private calendarRenderStartDate!: Date;
  private showDatePicker = false;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;
  private months = new Array<MonthInfo>();
  private calendarWidth = 350;
  private calendarHeight = 310;
  private today = new Date().getTime();
  private nightsMessage = "Select dates";
  private dateTextBox!: HTMLInputElement;

  isWeekend(dateObj: GridDay) {
    if (dateObj && dateObj.dateFor) {
      return new Date(dateObj.dateFor).isWeekend(true);
    }
    return false;
  }

  @Watch("startDate", { immediate: true })
  onStartDateChanged(value: Date, oldValue: Date) {
    this.firstPickedDate = new Date(this.startDate);
    this.calendarRenderStartDate = new Date(this.startDate);
    this.populateGrid();
  }
  @Watch("endDate", { immediate: true })
  onEndDateChanged(value: Date, oldValue: Date) {
    this.secondPickedDate = new Date(this.endDate);
    this.calendarRenderStartDate = new Date(this.startDate);
    this.populateGrid();
  }

  showAsUnavailable(gridDay: GridDay) {
    return (
      ((gridDay.booked || this.showPastDayAsDisabled(gridDay)) &&
        !gridDay.arriving) ||
      this.isTooFarInFuture(gridDay)
    );
  }

  isTooFarInFuture(gridDay: GridDay) {
    if (this.daysEnabledInFuture) {
      return (
        new Date(gridDay.dateFor).valueOf() >= new Date().addDays(365).valueOf()
      );
    }
    return false;
  }

  showAsNotInMonth(gridDay: GridDay) {
    return !gridDay.inMonth && !gridDay.selected && !gridDay.inRange;
  }

  showPastDayAsDisabled(gridDay: GridDay) {
    return gridDay.isInPast && this.disablePastDays;
  }

  public toggle(): void {
    this.showDatePicker = !this.showDatePicker;
  }

  firstDate() {
    if (this.firstPickedDate) {
      return new Date(this.firstPickedDate).toLocaleDateString();
    } else {
      return "";
    }
  }
  secondDate(): string {
    if (this.secondPickedDate) {
      return new Date(this.secondPickedDate).toLocaleDateString();
    } else {
      return "";
    }
  }

  getWeeks(dateInMonthFor: Date) {
    this.months = new Array<MonthInfo>();

    for (let w = 0; w <= this.numberOfMonths - 1; w++) {
      let firstDayInMonth = new Date(dateInMonthFor).addMonths(w);
      firstDayInMonth = new Date(
        firstDayInMonth.getFullYear(),
        firstDayInMonth.getMonth(),
        1
      );

      let isNextMonth = false;
      let isInPreviousMonth = true;
      let isThisMonth = false;
      const firstWeekdayInMonth = new Date(
        firstDayInMonth.getFullYear(),
        firstDayInMonth.getMonth(),
        1
      ).getDay();
      const daysInMonth = new Date(
        firstDayInMonth.getFullYear(),
        firstDayInMonth.getMonth() + 1,
        1
      ).daysInMonth();
      const nextMonth = MonthInfo.GetNextMonthInfo(firstDayInMonth);
      const previousMonth = MonthInfo.GetPreviousMonthInfo(firstDayInMonth);

      let day =
        previousMonth.days -
        ((firstWeekdayInMonth === 0 ? 7 : firstWeekdayInMonth) + 1);
      let monthIndex = previousMonth.month;
      let year = previousMonth.year;

      const month = new MonthInfo();
      const weeks = new Array<GridWeek>();
      for (let w = 1; w <= 6; w++) {
        const week = new GridWeek();
        for (let d = 0; d < 7; d++) {
          // We need to know when to start counting actual month days
          if (isInPreviousMonth && d >= firstWeekdayInMonth) {
            // Reset day/month/year counters
            day = 1;
            monthIndex = firstDayInMonth.getMonth();
            year = firstDayInMonth.getFullYear();
            // ...and flag we're tracking actual month days
            isInPreviousMonth = false;
            isThisMonth = true;
          }
          const gridDay = new GridDay();
          gridDay.dateFor = new Date(year, monthIndex, day);
          gridDay.inMonth = isThisMonth;

          gridDay.selected =
            gridDay.dateFor.areSameDay(
              this.firstPickedDate != null ? this.firstPickedDate : new Date()
            ) ||
            gridDay.dateFor.areSameDay(
              this.secondPickedDate != null ? this.secondPickedDate : new Date()
            );

          gridDay.dateFor.setHours(0, 0, 0, 0);
          let isDisabled = 0;
          let isBookedDayBefore = 0;
          if (this.gridDays) {
            isDisabled = this.gridDays.findIndex((g) => {
              let thisDate = new Date(g.dateFor);
              thisDate = thisDate.removeTime();
              return thisDate.areSameDay(gridDay.dateFor) && g.booked;
            });
            isBookedDayBefore = this.gridDays.findIndex((g) => {
              let thisDate = new Date(g.dateFor);
              let dayBefore = new Date(gridDay.dateFor).addDays(-1);
              dayBefore = dayBefore.removeTime();
              thisDate = thisDate.removeTime();
              return thisDate.areSameDay(dayBefore) && g.booked;
            });
          }

          gridDay.booked = isDisabled !== -1;

          gridDay.arriving = isBookedDayBefore === -1 && gridDay.booked;

          gridDay.inRange = this.isInRange(gridDay.dateFor);

          gridDay.isInPast = gridDay.dateFor.valueOf() < this.today.valueOf();

          week.days.push(gridDay);

          // We've hit the last day of the month
          if (isThisMonth && day >= daysInMonth) {
            isThisMonth = false;
            isNextMonth = true;
            day = 1;
            monthIndex = nextMonth.month;
            year = nextMonth.year;
            // Still in the middle of the month (hasn't ended yet)
          } else {
            day++;
          }
        }
        weeks.push(week);
      }
      month.monthName = new Date(firstDayInMonth).getMonthName(true);
      month.year = firstDayInMonth.getFullYear();
      month.weeks = weeks;
      this.months.push(month);
    }
  }

  mainContainerWidth() {
    if (this.size === "sm") {
      this.calendarWidth = 250;
    }
    if (!this.isMobile()) {
      return this.numberOfMonths * this.calendarWidth;
    } else {
      return this.calendarWidth;
    }
  }

  top() {
    if (this.opens === "center") {
      return "-100px";
    }
    if (this.opens === "top") {
      return "-340px";
    }
    return "";
  }

  left() {
    if (this.opens === "center") {
      return "-15px";
    }
    return "";
  }

  positioning() {
    if (this.opens === "center" || this.opens === "top") {
      return "relative";
    }
    if (this.popup) {
      return "absolute";
    } else {
      return "";
    }
  }

  mainContainerHeight() {
    if (this.size === "sm") {
      this.calendarHeight = 300;
    }
    if (!this.isMobile()) {
      return this.calendarHeight;
    } else {
      return this.numberOfMonths * this.calendarHeight;
    }
  }

  toggleDatePicker() {
    if (this.disabled) {
      return;
    }
    this.showDatePicker = !this.showDatePicker;
  }

  onWindowResized() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  mouseoverDay(event: Event, day: GridDay) {
    if (!this.firstPickedDate) {
      return;
    } else if (this.firstPickedDate != null && this.secondPickedDate != null) {
      return;
    }

    let element = event.target as HTMLTextAreaElement;
    if (element?.getAttribute("data-date") === null) {
      element = element.parentElement as HTMLTextAreaElement;
    }
    const hoveredDateString = element?.getAttribute("data-date");
    const hoveredDate = new Date(hoveredDateString ? hoveredDateString : "");
    if (this.mode === "range") {
      this.highlightDateRange(this.firstPickedDate, hoveredDate);
    }
  }

  private highlightDateRange(firstDate: Date, secondDate: Date) {
    if (!this.$el) {
      return;
    }
    const children = this.$el.querySelectorAll(".datepicker-day");
    firstDate = new Date(firstDate).removeTime();
    secondDate = new Date(secondDate).removeTime();

    for (const cell of children) {
      const cellElement = cell as HTMLTextAreaElement;
      const thisCellData = cellElement?.getAttribute("data-date")?.toString();
      if (thisCellData) {
        const thisCellsDate = new Date(thisCellData).removeTime();
        const gridDay = this.getGridDay(thisCellsDate);
        if (!gridDay) {
          //disregard; it's nothing
        } else {
          if (new Date(thisCellsDate).areSameDay(secondDate)) {
            gridDay.selected = true;
          } else if (new Date(thisCellsDate).areSameDay(firstDate)) {
            gridDay.selected = true;
          } else if (
            (thisCellsDate.valueOf() < secondDate.valueOf() &&
              thisCellsDate.valueOf() > firstDate.valueOf()) ||
            (thisCellsDate.valueOf() > secondDate.valueOf() &&
              thisCellsDate.valueOf() < firstDate.valueOf())
          ) {
            gridDay.selected = false;
            gridDay.inRange = true;
          } else {
            gridDay.inRange = false;
            gridDay.selected = false;
          }
        }
      }
    }
    this.setNights(firstDate, secondDate);
  }

  isInRange(dateFor: Date) {
    if (!this.firstPickedDate || !this.secondPickedDate) {
      return false;
    } else {
      return (
        new Date(dateFor).removeTime().valueOf() <
          new Date(this.secondPickedDate).removeTime().valueOf() &&
        new Date(dateFor).removeTime().valueOf() >
          new Date(this.firstPickedDate).removeTime().valueOf()
      );
    }
  }

  dayClicked(gridDay: GridDay) {
    if (gridDay.booked && !gridDay.arriving) {
      return;
    }
    if (this.mode.includes("single")) {
      this.firstPickedDate = gridDay.dateFor;
      this.$emit("date-picked", this.firstPickedDate);
      this.calendarRenderStartDate = this.firstPickedDate;
      this.secondPickedDate = this.firstPickedDate;
      if (this.dateTextBox) {
        this.dateTextBox.value = this.firstDate();
      }
      this.scrollToDate(this.firstPickedDate);
      this.showDatePicker = false;
    } else if (this.mode === "range") {
      //if already range selected
      if (this.firstPickedDate != null && this.secondPickedDate != null) {
        this.clear();
      }

      //if  first date is null then set first date to this date
      if (this.firstPickedDate === null) {
        this.firstPickedDate = gridDay.dateFor;
        this.secondPickedDate = null;
      } else if (this.secondPickedDate === null) {
        this.secondPickedDate = gridDay.dateFor;
        //range of dates selected is complete; do some validation

        if (this.firstPickedDate.valueOf() > this.secondPickedDate.valueOf()) {
          //swap
          const firstDateTemp = this.firstPickedDate;
          this.firstPickedDate = this.secondPickedDate;
          this.secondPickedDate = firstDateTemp;
        }

        if (!this.validDateRange(this.firstPickedDate, this.secondPickedDate)) {
          this.clear();
        } else {
          this.$emit(
            "date-picked",
            this.firstPickedDate,
            this.secondPickedDate
          );
          this.showDatePicker = false;
        }
      }
    }
  }

  validDateRange(first: Date, second: Date) {
    //iterate and check for any gridDays that are not selectable between range
    for (const month of this.months) {
      for (const week of month.weeks) {
        for (const day of week.days) {
          if (this.isInRange(day.dateFor) && day.booked) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private clear() {
    for (const month of this.months) {
      for (const week of month.weeks) {
        for (const day of week.days) {
          day.selected = false;
          day.inRange = false;
        }
      }
    }
    this.firstPickedDate = null;
    this.secondPickedDate = null;
  }

  private getGridDay(forDate: Date): GridDay | undefined {
    for (const month of this.months) {
      for (const week of month.weeks) {
        for (const day of week.days) {
          if (new Date(day.dateFor).areSameDay(forDate) && day.inMonth) {
            return day;
          }
        }
      }
    }
  }

  async scrollDays(days: number) {
    if (this.calendarRenderStartDate) {
      this.calendarRenderStartDate = new Date(
        this.calendarRenderStartDate
      ).addDays(days);
    }
    this.populateGrid();
  }

  async scrollMonths(months: number) {
    if (this.calendarRenderStartDate) {
      this.calendarRenderStartDate = new Date(
        this.calendarRenderStartDate
      ).addMonths(months);
    }
    this.populateGrid();
  }

  public async scrollToDate(goToDate: Date) {
    this.calendarRenderStartDate = goToDate;
    this.populateGrid();
  }

  async populateGrid() {
    try {
      this.getWeeks(this.calendarRenderStartDate);
    } catch (err) {
      throw new Error();
    }
  }

  mounted() {
    if (this.mode == "singleInput") {
      this.dateTextBox = this.$el.getElementsByTagName(
        "input"
      )[0] as HTMLInputElement;

      if (new Date(this.firstDate()).isMinDate()) {
        //it's blank to dont' set the text box and set the start date to today
      } else if (this.dateTextBox) {
        this.dateTextBox.value = this.firstDate();
      }
    }
  }

  created() {
    this.firstPickedDate = new Date(this.startDate);
    this.secondPickedDate = new Date(this.endDate);

    if (this.mode.includes("single")) {
      this.secondPickedDate = new Date(this.startDate);

      if (new Date(this.firstDate()).isMinDate()) {
        const today = new Date();
        this.calendarRenderStartDate = new Date(today);
      }
    } else {
      this.calendarRenderStartDate = new Date(this.startDate);
    }

    this.populateGrid().then(() => {
      if (this.firstPickedDate && this.secondPickedDate) {
        this.highlightDateRange(this.firstPickedDate, this.secondPickedDate);
      }
    });
    window.addEventListener("resize", this.onWindowResized);
  }

  destroyed() {
    window.removeEventListener("resize", this.onWindowResized);
  }

  setNights(firstPickedDate: Date, secondPickedDate: Date) {
    if (
      typeof firstPickedDate === "undefined" ||
      typeof secondPickedDate === "undefined" ||
      !firstPickedDate ||
      !secondPickedDate
    ) {
      return;
    }
    if (!new Date(firstPickedDate).areSameDay(new Date(secondPickedDate))) {
      const nights = new Date(firstPickedDate).daysDiff(secondPickedDate);
      if (nights !== 1) {
        this.nightsMessage = nights + " nights";
      } else {
        this.nightsMessage = nights + " night";
      }
    } else {
      this.nightsMessage = "Select dates";
    }
  }

  dateTextChanged(event) {
    let changedValue = event.target.value;
    if (changedValue.toString().trim() == "today") {
      changedValue = new Date().toLocaleDateString();
      this.dateTextBox.value = changedValue;
    } else if (changedValue.toString().trim() == "tomorrow") {
      changedValue = new Date().addDays(1).toLocaleDateString();
    } else if (changedValue.toString().trim() == "yesterday") {
      this.dateTextBox.value = changedValue;
      changedValue = new Date().addDays(-1).toLocaleDateString();
      this.dateTextBox.value = changedValue;
    }
    if (new Date(changedValue).isDate()) {
      const tempDate = new Date(changedValue);
      this.firstPickedDate = new Date(changedValue);

      this.$emit("date-picked", this.firstPickedDate);
      this.secondPickedDate = new Date(changedValue);

      this.scrollToDate(tempDate);
    } else {
      this.calendarRenderStartDate = new Date();
    }
  }

  changedStartDateInput(arg: any) {
    if (new Date(arg).isDate()) {
      let newStartDate = new Date(arg);
      this.firstPickedDate = new Date(newStartDate);
      this.populateGrid();
      this.$emit("date-picked", this.firstPickedDate, this.secondPickedDate);
    }
  }
  changedEndDateInput(arg: any) {
    if (new Date(arg).isDate()) {
      let newEndDate = new Date(arg);
      this.secondPickedDate = new Date(newEndDate);
      this.populateGrid();
      this.$emit("date-picked", this.firstPickedDate, this.secondPickedDate);
    }
  }
}
export default DatePicker;
