export enum PlanType {
    Hourly,
    Nightly,
    Weekly,
    Monthly,
    PerPerson,
    Special,
    OtherTimePeriod,
    PerAdultPerChild,
    Package, 
    TimeSlot
}