import { render, staticRenderFns } from "./AvailableRatesModal.vue?vue&type=template&id=774c7d94&scoped=true&"
import script from "./AvailableRatesModal.vue?vue&type=script&lang=ts&"
export * from "./AvailableRatesModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "774c7d94",
  null
  
)

export default component.exports