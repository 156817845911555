
import { Field } from "@/models/interfaces/Field";
import { formatter } from "@/utils/currencyUtils";
import { export_table_to_csv } from "@/utils/exportToCsv";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { Activity, ActivityKind } from "./Activity";
import ActivityModal from "./ActivityModal.vue";
import {
  deleteActivity,
  getActivities,
  getActivitiesByDate,
  saveActivity,
} from "./ActivityService";

@Component({ components: { ActivityModal, ConfirmDeleteModal } })
export default class ActivityTable extends Vue {
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public propertyGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  @Prop({ default: "reservation" }) public mode!: string; // 'property', 'daterange'"reservation";
  private startDate = new Date().addDays(-30);
  private endDate = new Date().addDays(7);
  private loading = true;
  private showActivityModal = false;
  private activities: Array<Activity> = [];
  private activityFields: Array<Field> = [];
  private clickedActivity!: Activity | null;
  private activityTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;

  cancelDeletion() {
    this.showConfirmModal = false;
  }
  async confirmDelete() {
    this.showConfirmModal = true;
  }

  async exportTableToExcel() {
    this.loading = true;
    var csvTable = this.$el.querySelector("#activityTableRef"); // this is the ID of the table to export
    if (csvTable) {
      await export_table_to_csv(csvTable, "Activities.csv");
    }
    this.loading = false;
  }

  async startDateChanged(arg: Date) {
    this.startDate = new Date(arg.toString() + " 00:00:00");
    await this.retrieve();
  }
  async endDateChanged(arg: Date) {
    this.endDate = new Date(arg.toString() + " 00:00:00");
    await this.retrieve();
  }

  async add() {
    this.clickedActivity = null;
    this.showActivityModal = true;
  }

  getKindString(kind: ActivityKind) {
    var kindStr: string = ActivityKind[kind];
    return kindStr;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.activityTable.selectAllRows();
  }
  clearSelected() {
    this.activityTable.clearSelected();
  }

  async saved(activity: Activity) {
    this.loading = true;
    // if the list doesn't contain the activity, add it
    let found = this.activities.find(
      (a) => a.activityGuid === activity.activityGuid
    );
    if (!found) {
      await this.activities.push(activity);
    }
    let index = await this.activities.findIndex(
      (a) => a.activityGuid === activity.activityGuid
    );
    this.activities[index] = activity;
    await this.activityTable.refresh();
    this.activityTable.selectRow(index);
    this.showActivityModal = false;
    this.loading = false;
  }

  private getActivityFields() {
    let field = new Field();
    field.key = "createdDate";
    field.label = "Created";
    this.activityFields.push(field);

    field = new Field();
    field.key = "description";
    field.label = "Description";
    this.activityFields.push(field);

    field = new Field();
    field.key = "notes";
    field.label = "Notes";
    this.activityFields.push(field);

    field = new Field();
    field.key = "kind";
    field.label = "Kind";
    this.activityFields.push(field);

    field = new Field();
    field.key = "takenBy";
    field.label = "Taken By";
    this.activityFields.push(field);

    if (this.mode == "property") {
      field = new Field();
      field.key = "personName";
      field.label = "Person";
      this.activityFields.push(field);

      field = new Field();
      field.key = "reservationName";
      field.label = "Reservation";
      this.activityFields.push(field);
    }
  }

  async mounted() {
    this.activityTable = this.$refs.activityTableRef;
    this.getActivityFields();
    await this.retrieve();
  }

  async retrieve() {
    this.loading = true;
    if (this.personGuid) {
      this.activities = await getActivities(this.personGuid);
    } else if (this.reservationGuid) {
      this.activities = await getActivities(undefined, this.reservationGuid);
    } else if (this.propertyGuid) {
      if (this.startDate && this.endDate) {
        this.activities = await getActivitiesByDate(
          this.startDate,
          this.endDate,
          this.propertyGuid
        );
      } else {
        this.activities = await getActivities(
          undefined,
          undefined,
          this.propertyGuid
        );
      }
    } else {
      this.activities = new Array<Activity>();
    }
    this.loading = false;
  }

  getActiveActivity() {
    if (this.clickedActivity) {
      return this.clickedActivity.activityGuid;
    } else {
      return "";
    }
  }

  async deleteActivity() {
    if (this.selected && this.selected.length > 0) {
      for (const deleted of this.selected) {
        let result = await deleteActivity(deleted.activityGuid.toString());
        if (result) {
          if (this.activities) {
            let index = this.activities.findIndex(
              (a) => a.activityGuid === deleted.activityGuid
            );
            this.activities.splice(index, 1);
          }
        }
      }
      this.showConfirmModal = false;
    }

    await this.activityTable.refresh();
  }

  edit(activity: any, index: any, event: any) {
    if (activity) {
      this.clickedActivity = activity;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedActivity = this.selected[0];
    }
    this.showActivityModal = true;
  }

  closeActivityModal() {
    this.showActivityModal = false;
  }
}
