import { DayOfWeek } from "./DayOfWeek";

export class GridDay {
    constructor() {
        this.isFirstDate = false;
        this.isSecondDate = false;
        this.selected = false;
        this.booked = false;
        this.arriving = false;
        this.isInPast = false;
    }
    public dateFor!: Date;
    public nightlyPrice!: number;
    public weeklyPrice!: number;
    public booked!: boolean;
    public arriving!: boolean;
    public day!: number;
    public inMonth!: boolean;
    public isToday!: boolean;
    public active!: boolean;
    public isFirstDate!: boolean;
    public isSecondDate!: boolean;
    public selected!: boolean;
    public inRange!: boolean;
    public isInPast!: boolean;
    public daysRequired!: number
    public daysOfWeekArrivalAllowed!: Array<DayOfWeek>;
}