
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MaskedInput from "@/components/Controls/MaskedInput.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { MaskedInput },
})
export default class EmailDomains extends Vue {
  @PropertyStore.State
  public property!: Property;
  private loading = true;
  private showVerificationModal = false;

  mounted() {
    this.loading = false;
  }

  getHost(email: string) {
    return "host.com";
  }

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  async save() {
    //save the property info
    this.navigate("Settings");
  }
}
