
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ConfirmModal extends Vue {
  @Prop({ default: "Are you sure?" }) public prompt!: string;
  @Prop({ default: "Are you sure?" }) public headerText!: string;
  private showConfirmModal = true;
  yes() {
    this.$emit("yes");
  }
  no() {
    this.$emit("no");
  }
}
