
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";
import DeliveryOptionTable from "./DeliveryOptionTable.vue";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { DeliveryOptionTable, ConfigurationNavMenu } })
export default class DeliveryOptions extends Vue {
  @PropertyStore.State
  public property!: Property;
}
