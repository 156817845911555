import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import Property from '@/models/Property';
import { axiosRetry, fetchRetry } from '@/utils/fetchUtil';

@Module({ namespaced: true })
class PropertyStore extends VuexModule {
  public property!: Property;
  public fetchComplete = false;
  public defaultPropertyGuid = '';//'1C77120C-F4EB-4BD2-88EB-171112E7D5FD';
  static State: any;

  @Action
  public async getPropertyFromServer(propGuid: string): Promise<Property> {
    if (this.property && this.property.propertyGuid.toString().toUpperCase() == propGuid.toString().toUpperCase()) {
      return this.property;

    }
    const response = await axiosRetry(
      `/api/property/propertyasync/` + propGuid,
      null,
      "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
      return response.data as Promise<Property>;
    } else {
      const err =
        "An error occurred getting the property from the server." +
        `The error code was ${response.status} (${response.statusText}).`;
      throw Error(err);
    }
  }

  @Action
  public async getProperty(): Promise<Property> {
    return Promise.resolve(this.property);
  }

  @Action
  public setProperty(propertyObj: Property): void {
    this.context.commit('updateProperty', propertyObj);
  }

  @Mutation
  public updateProperty(propertyObj: Property): void {
    this.property = propertyObj;
  }
}
export default PropertyStore
