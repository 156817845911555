
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IPopoverOptions } from "../Popover/IPopoverOptions";
import Popper, { Placement } from "popper.js";
import { Template } from "@/models/Template";

@Component({
  components: {},
})
export default class GroupContextMenu extends Vue {
  @Prop() public popoverOptions!: IPopoverOptions;
  @Prop() public template!: Template;
  @Prop({ default: "body" }) public boundariesElement!: string;

  private popperInstance: any;

  mounted() {
    this.initPopper();
  }

  initPopper() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }

    const modifiers: IPopoverOptions = {};
    const popoverReference = this.popoverOptions?.popoverReference;
    const offset = this.popoverOptions?.offset;
    const placement = this.popoverOptions?.placement;

    if (offset) {
      modifiers.offset = offset;
    }

    if (placement) {
      modifiers.placement = placement;
    } else {
      modifiers.placement = "right-start";
    }

    if (popoverReference) {
      this.popperInstance = new Popper(
        popoverReference,
        this.$refs.contextMenuContent as HTMLElement,
        {
          placement,
          modifiers: {
            ...modifiers,
            preventOverflow: {
              boundariesElement: document.querySelector(
                this.boundariesElement.toString()
              ) as Element,
            },
          },
        }
      );
    }

    // Recalculate position
    this.$nextTick(() => {
      this.popperInstance.scheduleUpdate();
    });
  }

  destroyPopover() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
      this.$emit("closePopover");
    }
  }
}
