import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Unit } from '@/components/Cloud/Units/Unit'
import { axiosRetry } from '@/utils/fetchUtil';

@Module({ namespaced: true })
class UnitStore extends VuexModule {
    public units: Array<Unit> = [];
    public unitsForGroup: Array<Unit> = [];
    public haveFetchedList = false;
    public unit: Unit | undefined;

    @Mutation
    public saveUnitsByGroupList(data: Array<Unit>): void {
        this.unitsForGroup = data;
    }

    @Mutation
    public saveList(data: Array<Unit>): void {
        this.haveFetchedList = true;
        this.units = data;
    }
    @Action
    public getActiveAndBookableUnits(propertyGuid: string): Promise<boolean> {
        if (this.haveFetchedList) {
            return Promise.resolve(true)
        } else {
            return axiosRetry(`/api/book/units/${propertyGuid}/true`, null, "GET")
                .then(response => {
                    const units: Array<Unit> = response.data;
                    this.context.commit('saveList', units)
                    return true;
                })
                .catch(() => {
                    console.log({
                        title: 'Error',
                        type: 'error',
                        message: 'Could not fetch the list'
                    })
                    return false;
                })
        }
    }
    @Action
    public getAllUnits(propertyGuid: string): Promise<boolean> {
        if (this.haveFetchedList) {
            return Promise.resolve(true)
        } else {
            return axiosRetry(`/api/book/units/${propertyGuid}/false`, null, "GET")
                .then(response => {
                    const units: Array<Unit> = response.data;
                    this.context.commit('saveList', units)
                    return true;
                })
                .catch(() => {
                    console.log({
                        title: 'Error',
                        type: 'error',
                        message: 'Could not fetch the list'
                    })
                    return false;
                })
        }
    }

    @Mutation
    public save(data: Unit): void {
        this.unit = data
    }
    @Action
    public async getUnit(unitGuid: string): Promise<Unit | boolean> {
        if (this.units.length !== 0) {
            const fetched = await this.units.filter(item => {
                return item.unitGuid === unitGuid
            })

            if (fetched.length) {
                return Promise.resolve(fetched[0])
            } else {
                return Promise.reject(false)
            }
        } else {
            return axiosRetry(`/api/book/unit/${unitGuid}`, null, "GET")
                .then(response => {
                    this.context.commit('save', response.data)
                    return response.data
                })
                .catch(() => {
                    console.log({
                        title: 'Error',
                        type: 'error',
                        message: 'Could not fetch Article'
                    })
                    return false
                })
        }
    }

    @Action
    public async getUnitsByGroup(params: any): Promise<boolean> {
        if (!this.haveFetchedList) {
            return axiosRetry(`/api/book/units/${params.propertyGuid}/true`, null, "GET")
                .then(response => {
                    this.context.commit('saveList', response.data);
                    const filtered = this.units.filter(item => {
                        return item.unitGroupGuid === params.unitGroupGuid
                    });
                    this.context.commit('saveUnitsByGroupList', filtered);
                    return true;
                })
                .catch(() => {
                    console.log({
                        title: 'Error',
                        type: 'error',
                        message: 'Could not fetch the list'
                    })
                    return false
                })
        }
        const filtered = this.units.filter(item => {
            return item.unitGroupGuid === params.unitGroupGuid
        })
        this.context.commit('saveUnitsByGroupList', filtered);

        return Promise.resolve(true);
    }
}


export default UnitStore
