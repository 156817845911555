
import { AvailabilityParameters } from "@/models/AvailabilityParameters";
import { getDayOfWeekString } from "@/models/DayOfWeek";
import { GridDay } from "@/models/GridDay";
import Property from "@/models/Property";
import { Unit } from "@/components/Cloud/Units/Unit";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const PropertyStore = namespace("PropertyStore");
const AvailabilityParametersStore = namespace("AvailabilityParametersStore");
@Component({
  components: {},
})
class RatePopup extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public startDate!: Date;
  @Prop({ default: false }) public frozen!: boolean;
  @Prop({ default: false }) public clicked!: boolean;
  @Prop({ default: null }) public endDate!: Date;
  @Prop({ default: "" }) public backgroundImage!: string;
  @Prop({ default: null }) public unit!: Unit;
  @Prop({ default: null }) public gridDay!: GridDay;

  private errors = new Array<string>();

  @Watch("endDate", { immediate: true })
  @Watch("startDate", { immediate: true })
  onDateChanged(value: Date, oldValue: Date) {
    this.validate();
  }

  isValid() {
    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }

  navigateToUnit() {
    if (!this.availParams) {
      this.availParams = new AvailabilityParameters();
    }
    this.availParams.arrivalDate = this.startDate;
    this.availParams.departureDate = this.endDate;
    this.availParams.unitGuid = this.unit.unitGuid;
    this.availParams.defaultMode = false;
    this.set(this.availParams);

    this.$router.push({
      name: "Book",
      params: {
        unit: this.unit.unitGuid,
      },
      query: {
        length: this.availParams.nights.toString(),
        date: new Date(this.availParams.arrivalDate).dashedDate(),
      },
    });
  }

  validate() {
    this.errors = [];
    if (!this.unit || !this.clicked) {
      return false;
    }
    if (this.numNights() < this.unit.minimumNights) {
      this.errors.push(`Requires a ${this.unit.minimumNights} night minimum`);
    }
    if (this.numNights() > this.unit.maximumNights) {
      this.errors.push(
        `Requires a maximum of ${this.unit.maximumNights} nights`
      );
    }

    if (
      this.gridDay.daysRequired > 0 &&
      this.gridDay.daysOfWeekArrivalAllowed &&
      this.gridDay.daysRequired > 0
    ) {
      if (
        this.numNights() !== this.gridDay.daysRequired ||
        new Date(this.startDate).getDayOfWeekName(false) !==
          getDayOfWeekString(this.gridDay.daysOfWeekArrivalAllowed)
      ) {
        this.errors.push(
          `Requires arrival on ${getDayOfWeekString(
            this.gridDay.daysOfWeekArrivalAllowed
          )} for  ${this.gridDay.daysRequired} nights`
        );
      }
    }
    else if (this.gridDay && this.gridDay.daysOfWeekArrivalAllowed) {
        let thisDayOfWeekName = new Date(this.startDate).getDayOfWeekName(false);
        let allowedList = getDayOfWeekString(this.gridDay.daysOfWeekArrivalAllowed);
        if (allowedList.indexOf(thisDayOfWeekName) == -1){
            this.errors.push(
            `Check in on ${thisDayOfWeekName} is not allowed.`
          );
        }
      }

    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }

  numNights() {
    return this.startDate && this.endDate
      ? new Date(this.startDate).daysDiff(this.endDate)
      : 0;
  }
  @AvailabilityParametersStore.Action
  public set!: (params: AvailabilityParameters) => Promise<boolean>;

  @AvailabilityParametersStore.State
  private availParams!: AvailabilityParameters;
}
export default RatePopup;
