
import Property from "@/models/Property";
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "../Auth/AuthService";
import { LoginCredentials } from "../Auth/LoginCredentials";
import { User } from "../Auth/User";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");

@Component({
  components: {},
})
export default class TeamMember extends Vue {
  @Prop() public user!: User;
  @PropertyStore.State
  public property!: Property;
  @Auth.State("user")
  private currentUser!: User;
  private loading = true;
  private role = "";
  private email = "";
  private showProgressOverlay = false;

  public close() {
    this.$emit("close");
  }

  validate() {
    return this.email != "" && this.role != "";
  }

  async sendInvites() {
    this.showProgressOverlay = true;
    if (this.validate()) {
      const login = new LoginCredentials();
      login.password = "L0dgeVault!";
      login.email = this.email;
      await AuthService.inviteTeamMember(
        login,
        this.role,
        this.property.propertyGuid,
        this.currentUser.email,
        this.property.name,
        this.currentUser.userGuid
      );
    } else {
      /// please fill out form
    }

    this.showProgressOverlay = false;
    this.close();
  }
}
