export class SearchParams
{
    public arrivalDate!: Date;
    public departureDate!: Date;
    public peopleCount1!: number;
    public peopleCount2!: number;
    public peopleCount3!: number;
    public peopleCount4!: number;
    public propertyGuid!: string;
    public unitClassGuid!: string;
    public cartGuid!: string;
    public unitGuid!: string;
}