
import Property from "@/models/Property";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "../Auth/User";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({ components: {} })
export default class Settings extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Auth.State("user")
  private currentUser!: User;

  navigate(page: string) {
    this.$router.push({ name: page });
  }
}
