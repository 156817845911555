
import ContextMenu from "@/components/ContextMenu/ContextMenu.vue";
import { IPopoverOptions } from "@/components/Popover/IPopoverOptions";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: { ContextMenu },
})
export default class ReservationContextMenu extends Vue {
  @Prop() public popoverOptions!: IPopoverOptions;
  private assignGuid!: string;

  async mounted() {
    this.assignGuid =
      this.popoverOptions?.popoverReference?.attributes?.ref?.nodeValue;
  }
  checkIn() {
    this.$emit("checkin", this.assignGuid);
  }
  charges() {
    this.$emit("charges", this.assignGuid);
  }
  edit() {
    this.$emit("edit", this.assignGuid);
  }
  checkout() {
    this.$emit("checkout", this.assignGuid);
  }
  deleteItem() {
    this.$emit("delete", this.assignGuid);
  }
}
