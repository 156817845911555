
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';

export class CustomQuestion {
  constructor() {
  }
  public customQuestionGuid!: string;
  public propertyGuid!: string;
  public questionText!: string;
  public type!: string;
  public required!: boolean;
  public checked = false;
  public answer!: string;
  public state!: boolean | null;
}

export class CustomQuestionInUnit {
  constructor() {
  }
  public customQuestionGuid!: string;
  public unitGuid!: string;
}

export enum QuestionType {
  TextBox,
  CheckBox
}


export async function deleteCustomQuestions(customQuestionsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/customQuestion/delete`,
    customQuestionsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the customQuestion from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteCustomQuestion(customQuestionGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/customQuestion/` + customQuestionGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the customQuestion from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveCustomQuestion(customQuestion: CustomQuestion): Promise<boolean> {
  const response = await axiosRetry(
    `/api/customQuestion`,
    customQuestion,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the customQuestion from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getCustomQuestions(unitGuid: string): Promise<Array<CustomQuestion>> {
  const response = await axiosRetry(
    `/api/customQuestion/getbyunit/${unitGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<CustomQuestion>>;
  } else {
    const err =
      "An error occurred getting the customQuestion from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getCustomQuestionsByProperty(propertyGuid: string): Promise<Array<CustomQuestion>> {
  const response = await axiosRetry(
    `/api/customQuestion/getbyproperty/${propertyGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<CustomQuestion>>;
  } else {
    const err =
      "An error occurred getting the customQuestion from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getCustomQuestion(customQuestionGuid: string): Promise<CustomQuestion> {
  const response = await axiosRetry(
    `/api/customQuestion/getcustomQuestion/${customQuestionGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<CustomQuestion>;
  } else {
    const err =
      "An error occurred getting the customQuestion from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}


export async function saveQuestionInUnits(questionInUnits: Array<CustomQuestionInUnit>): Promise<boolean> {
  let url = '';
  url = `/api/customquestion/savequestionsinunit`;
  const response = await axiosRetry(
    url,
    questionInUnits,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the question from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deleteQuestionInUnitsByUnit(unitGuid: string): Promise<boolean> {
  let url = '';
  url = `/api/customquestion/deletequestioninunitsbyunit/${unitGuid}`;
  const response = await axiosRetry(
    url,
    unitGuid,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the question from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}



