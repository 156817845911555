
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "./AuthService";
const AuthStore = namespace("AuthStore");

@Component({
  components: {},
})
export default class ForgotPassword extends Vue {
  private email = "";
  private loading = false;
  private errors!: Array<string>;
  private resetComplete = false;

  @AuthStore.Getter
  private isLoggedIn!: boolean;

  created() {
    this.errors = [];
    if (this.$route.query.email) {
      this.email = this.$route.query.email.toString();
    }
    if (this.isLoggedIn) {
      this.$router.push("/profile");
    }
  }

  validate(): boolean {
    const emailTextBox = document.getElementById(
      "email-textbox"
    ) as HTMLInputElement;
    this.email = emailTextBox.value;
    this.errors = [];
    if (!this.email || !this.email.includes(".") || !this.email.includes("@")) {
      this.errors.push("Please enter a valid email address.");
    }
    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }

  async handleForgotPassword() {
    this.loading = true;
    if (this.validate()) {
      if (this.email) {
        await AuthService.forgotPassword(this.email).then(
          (response) => {
            if (response.data.error) {
              this.loading = false;
              this.errors.push(response.data.error);
            } else {
              this.resetComplete = true;
            }
          },
          (error) => {
            this.loading = false;
            this.errors.push(error.message);
          }
        );
      }
    } else {
      this.loading = false;
    }
  }
}
