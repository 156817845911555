
import { fetchRetry } from "@/utils/fetchUtil";
import { namespace } from "vuex-class";
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";

const PropertyStore = namespace("PropertyStore");

@Component({
  components: {},
})
class StripeComplete extends Vue {
  private loading = true;
  private stripeAccountId = "";
  private successMessage =
    "Congratulations! Lodge Vault and your Stripe account are now connected!";
  private failMessage =
    "There was a problem and the accounts could not be connected. Please email support@lodgevault.com";

  async created() {
    this.loading = true;

    await this.getProperty()
      .then((p) => {
        this.setProperty(p);
      })
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => {
        this.postStripeComplete()
          .then((r) => {
            this.stripeAccountId = r;
          })
          .finally(() => {
            this.loading = false;
          });
      });
  }

  async postStripeComplete() {
    const params = {
      code: this.$route.query.code ? this.$route.query.code : "",
      error: this.$route.query.error ? this.$route.query.error : null,
      scope: this.$route.query.scope ? this.$route.query.scope : "read_write",
      propertyGuid: localStorage.getItem("propertyGuid"),
      isTest: location.href.includes("localhost"),
    };
    const response = await fetchRetry(
      "/api/stripe/stripecomplete",
      params,
      "POST"
    );
    if (response.ok) {
      return response.json() as Promise<string>;
    } else {
      const err =
        "An error occurred retrieving pricing from the server." +
        `The error code was ${response.status} (${response.statusText}).`;
      throw Error(err);
    }
  }
  @PropertyStore.State
  public property!: Property;

  @PropertyStore.Action
  public getProperty!: () => Promise<Property>;

  @PropertyStore.Action
  public setProperty!: (prop: Property) => void;
}
export default StripeComplete;
