
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Popper, { Placement } from "popper.js";
import { getTemplates, Template } from "@/models/Template";
import { IPopoverOptions } from "@/components/Popover/IPopoverOptions";
import { namespace } from "vuex-class";
import Property from "@/models/Property";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class ReservationListContextMenu extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop() public popoverOptions!: IPopoverOptions;
  @Prop() public template!: Template;
  @Prop({ default: "body" }) public boundariesElement!: string;
  private templates: Template[] = [];

  private popperInstance: any;

  async mounted() {
    this.initPopper();
  }
  async created() {
    this.templates = await getTemplates(this.property.propertyGuid);
  }

  mergeAndSend(template: Template) {
    this.$emit("send-emails", template);
  }

  initPopper() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }

    const modifiers: IPopoverOptions = {};
    const popoverReference = this.popoverOptions?.popoverReference;
    const offset = this.popoverOptions?.offset;
    const placement = this.popoverOptions?.placement;

    if (offset) {
      modifiers.offset = offset;
    }

    if (placement) {
      modifiers.placement = placement;
    } else {
      modifiers.placement = "right-start";
    }

    if (popoverReference) {
      this.popperInstance = new Popper(
        popoverReference,
        this.$refs.contextMenuContent as HTMLElement,
        {
          placement,
          modifiers: {
            ...modifiers,
            preventOverflow: {
              boundariesElement: document.querySelector(
                this.boundariesElement.toString()
              ) as Element,
            },
          },
        }
      );
    }

    // Recalculate position
    this.$nextTick(() => {
      this.popperInstance.scheduleUpdate();
    });
  }

  destroyPopover() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
      this.$emit("closePopover");
    }
  }
}
