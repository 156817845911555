
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { calculateStripeFee, Payment, PaymentType } from "./Payment";
import { savePayment } from "./PaymentService";
import {
  Card,
  createChargeForDefault,
  getCardsByPerson,
  refund,
  setDefaultPaymentMethodToExistingCard,
  setDefaultPaymentMethodToNewCard,
} from "@/components/Cloud/Invoices/Card";
import { Invoice, InvoiceItemKind } from "./Invoice";
import { getInvoice } from "./InvoiceService";
import { formatter } from "@/utils/currencyUtils";
import { User } from "../Auth/User";
import { Reservation } from "../Reservations/Reservation";
import { Person } from "../People/Person";
import { getPerson } from "../People/PersonService";
import CreditCardModal from "./CreditCardModal.vue";
import { getTotalStripeFee } from "@/components/Cart/Cart";
import { FeeBillingPlan } from "@/models/CheckoutSettings";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({
  components: { CreditCardModal },
})
export default class AddPayment extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Auth.State("user")
  private currentUser!: User;

  @Prop({ default: null }) public invoiceGuid!: string;
  @Prop({ default: null }) public res!: Reservation;
  private showCardModal = false;
  private showProgressOverlay = false;
  private showCardsOverlay = false;
  private localInvoiceGuid!: string;
  private newPayment = new Payment();
  private kind = InvoiceItemKind.Payment;
  private type = PaymentType.CreditCard;
  private invoice = new Invoice();
  private person = new Person();
  private discount = 1;
  private amount = 0;
  private newPayments: Array<Payment> = [];
  private cards: Array<Card> = [];
  private itemName = "Payment";
  private isLocal = window.location.href.indexOf("localhost") > -1;
  private baseUrl = this.isLocal
    ? "http://localhost:8080"
    : "https://book.lodgevault.com";
  private selectedCard = new Card();
  private token!: string;

  async closeCardModal(arg: any) {
    this.showCardsOverlay = true;
    // refresh teh cards
    if (arg) {
      this.token = arg;
      this.cards = await getCardsByPerson(this.person.personGuid);
      if (this.cards && this.cards.length > 0) {
        this.selectedCard = this.cards[0];
        // first one is always the default
      }
      this.person = await getPerson(this.person.personGuid);
    }
    this.showCardModal = false;
    this.showCardsOverlay = false;
  }

  changeCard(arg: any) {
    if (arg == "") {
      this.showCardModal = true;
    }
    this.selectedCard = arg;
  }
  addCard(arg: any) {
    this.showCardModal = true;
  }

  getInvoicePayUrl() {
    return this.baseUrl + "/invoicepay/" + this.invoice.invoiceGuid;
  }

  navigateToInvoicePay() {
    window.open(this.getInvoicePayUrl(), "_blank");
  }

  async kindChanged(arg: any) {
    this.itemName = InvoiceItemKind[arg];
    this.kind = parseInt(arg, 10);
    await this.recalculate();
  }

  async typeChanged(arg: any) {
    await this.recalculate();
  }

  async discountChanged(arg: any) {
    if (!arg || arg == "") {
      this.discount = 0;
    }

    await this.recalculate();
  }
  async itemNameChanged(arg: any) {
    await this.recalculate();
  }
  async amountChanged(arg: any) {
    if (arg == "") {
      this.amount = 0;
    }

    await this.recalculate();
  }

  formatCurrency(num: number) {
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  getTotalTransaction() {
    // calc the total times the conversion
    return this.newPayment.preTaxPrice * this.discount;
  } 
  
  getFees() {
    // calc the total times the conversion
    return this.newPayment.stripeFee;
  }

  addNewPayment() {
    // open the payment item edit modal in add mode
  }

  validateName() {
    return true;
  }

  async recalculate() {
    this.newPayment.type = this.type;
    this.newPayment.discount = this.discount;
    this.newPayment.kind = this.kind;
    this.newPayment.preTaxPrice =
      this.newPayment.kind == InvoiceItemKind.Refund
        ? this.amount
        : -1 * this.amount;
    this.newPayment.totalPrice = this.newPayment.preTaxPrice * this.discount;
    if (this.newPayment.type == PaymentType.CreditCard 
        && this.property.checkoutSettings.feeBillingPlan == FeeBillingPlan.CustomerAddOn) {
      this.newPayment.stripeFee = -1 * calculateStripeFee(this.newPayment);
    } else {
      this.newPayment.stripeFee = 0;
    }
    this.newPayment.itemName = this.itemName;
  }

  private cancel() {
    this.$emit("cancel");
  }

  async mounted() {
    this.showProgressOverlay = true;
    if (this.invoiceGuid) {
      this.localInvoiceGuid = this.invoiceGuid;
    } else if (this.$route.params.invoiceGuid) {
      this.localInvoiceGuid = this.$route.params.id.toString();
    } else if (this.$route.query.invoiceGuid) {
      this.localInvoiceGuid = this.$route.query.invoiceGuid.toString();
    }
    this.invoice = await getInvoice(this.localInvoiceGuid);
    this.person = await getPerson(this.invoice.personGuid);

    if (this.res && this.res.depositAmount) {
      this.amount = parseFloat(this.res.depositAmount.toFixed(2));
      this.kind = InvoiceItemKind.Deposit;
      this.itemName = "Deposit";
    } else if (this.invoice && this.invoice.depositAmount) {
      this.amount = parseFloat(this.invoice.depositAmount.toFixed(2));
      this.kind = InvoiceItemKind.Deposit;
      this.itemName = "Deposit";
    } else {
      this.amount = parseFloat(this.invoice.balance.toFixed(2));
      this.kind = InvoiceItemKind.Payment;
      this.itemName = "Payment";
    }
    this.newPayment.chargeDate = new Date();
    this.newPayment.invoiceGuid = this.invoiceGuid;
    this.newPayment.createdDate = new Date();
    this.newPayment.paymentGuid = Guid.newGuid();
    this.newPayment.quantity = 1;
    this.newPayment.takenBy = this.currentUser.fullname;

    await this.recalculate();

    this.showProgressOverlay = false;

    this.showCardsOverlay = true;
    if (this.property.checkoutSettings.stripeConnectAccountId) {
      try {
        this.cards = await getCardsByPerson(this.invoice.personGuid);
      } catch (err) {
        console.log("Error processing payment-" + err);
      }
      if (this.cards && this.cards.length > 0) {
        this.selectedCard = this.cards[0];
      }
    } else {
      this.type = PaymentType.Cash;
    }

    this.showCardsOverlay = false;
  }

  async saveAndClose() {
    this.showProgressOverlay = true;
    // if it's a refund, then refund the charge id
    if (this.newPayment.kind == InvoiceItemKind.Refund) {
      // get the other payments on this invoice and if only one, use it
      // otherwise let them choose amount and/or which one to refund
      // use and get the stripe id chargeid on the payment and refund it
      //var charge = await refund(chargeStripeId, amount);
    }
    // if it's a card then set the selected (if any) card to the default
    // then run the charge
    if (this.type == PaymentType.CreditCard) {
      var cardId = await setDefaultPaymentMethodToExistingCard(
        this.selectedCard.id,
        this.person.stripeId
      );
      var chargeId = await createChargeForDefault(
        this.person.stripeId,
        Math.abs(this.newPayment.totalPrice + this.newPayment.stripeFee) * 100
      );
      this.newPayment.cardStripeId = this.selectedCard.id;
      this.newPayment.stripeId = chargeId;
    }
    this.newPayment.personGuid = this.person.personGuid;
    this.newPayment = await savePayment(this.newPayment);
    this.newPayments.push(this.newPayment);
    this.showProgressOverlay = false;
    this.$emit("payments-added", this.newPayments);
    this.$emit("close");
  }
}
