
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatPercentage, formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { Document } from "./Document";
import DocumentEdit from "./DocumentEdit.vue";
import DocumentModal from "./DocumentModal.vue";
import {
  deleteDocument,
  getDocuments,
  getDocumentsByPerson,
  getDocumentsByUnit,
  saveDocument,
  uploadDocument,
} from "./DocumentService";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { DocumentEdit, ConfirmDeleteModal } })
export default class DocumentTable extends Vue {
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public propertyGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  @Prop({ default: null }) public unitGuid!: string;
  @PropertyStore.State
  public property!: Property;
  private mode = "reservation";
  private loading = true;
  private showDocumentModal = false;
  private documents: Array<Document> = [];
  private documentFields: Array<Field> = [];
  private clickedDocument!: Document | null;
  private documentTable: any;
  private selected!: Array<any>;
  private showFileUploadControl = false;
  private s3Path = "https://s3.us-east-2.amazonaws.com/service.lodgevault/";
  private showConfirmModal = false;

  showFileUpload() {
    this.showFileUploadControl = true;
  }

  confirmDeleteDocuments() {
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async deleteDocuments() {
    this.loading = true;
    // put up warning confirmation first?
    if (this.selected && this.selected.length > 0) {
      for (const doc of this.selected) {
        let deleted = doc;
        let result = await deleteDocument(deleted.documentGuid.toString());
        if (result) {
          if (this.documents) {
            let index = this.documents.findIndex(
              (a) => a.documentGuid === deleted.documentGuid
            );
            this.documents.splice(index, 1);
          }
        }
      }
    }
    await this.documentTable.refresh();
    this.showConfirmModal = false;
    this.loading = false;
  }

  async fileUploaded(file: File) {
    this.loading = true;
    var formData = new FormData();
    let doc = new Document();
    doc.documentGuid = Guid.newGuid();
    doc.propertyGuid = this.property.propertyGuid;
    doc.personGuid = this.personGuid;
    doc.createdDate = new Date();
    doc.modifiedDate = new Date();
    doc.path = file.name;
    await saveDocument(doc);
    let extension = file.name.slice(-4);
    formData.append("files", file, doc.documentGuid + extension);
    await uploadDocument(formData);
    let src = this.s3Path + doc.documentGuid + extension;
    await this.saved(doc);
    this.showFileUploadControl = false;
    this.loading = false;
  }

  async add() {
    this.clickedDocument = null;
    this.showDocumentModal = true;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getTaxRateFormat(amount: number) {
    return formatPercentage(amount, false);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.documentTable.selectAllRows();
  }
  clearSelected() {
    this.documentTable.clearSelected();
  }

  async saved(document: Document) {
    this.loading = true;
    // if the list doesn't contain the document, add it
    let found = this.documents.find(
      (a) => a.documentGuid === document.documentGuid
    );
    if (!found) {
      await this.documents.push(document);
    }
    let index = await this.documents.findIndex(
      (a) => a.documentGuid === document.documentGuid
    );
    this.documents[index] = document;
    await this.documentTable.refresh();
    this.documentTable.selectRow(index);
    this.showDocumentModal = false;
    this.loading = false;
  }

  private getDocumentFields() {
    let field = new Field();
    field.key = "path";
    field.label = "Path";
    this.documentFields.push(field);

    field = new Field();
    field.key = "createdDate";
    field.label = "Created";
    this.documentFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.documentTable = this.$refs.documentTableRef;
    this.getDocumentFields();
    if (this.unitGuid) {
      this.documents = await getDocumentsByUnit(
        this.unitGuid,
        this.property.propertyGuid
      );
    } else if (this.personGuid) {
      this.documents = await getDocumentsByPerson(this.personGuid);
    } else {
      this.documents = new Array<Document>();
    }
    this.loading = false;
  }

  getActiveDocument() {
    if (this.clickedDocument) {
      return this.clickedDocument.documentGuid;
    } else {
      return "";
    }
  }

  edit(arg: any) {
    this.clickedDocument = arg;
    if (this.clickedDocument) {
      let extension = this.clickedDocument?.path.slice(-4);
      window.open(
        this.s3Path + this.clickedDocument.documentGuid + extension,
        "_blank"
      );
    }
    this.showDocumentModal = false;
  }

  closeDocumentModal() {
    this.showDocumentModal = false;
  }
}
