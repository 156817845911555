
import "../utils/dateUtil";
export class AvailabilityParameters {

    constructor() {
        this.arrivalDate = new Date(Date.now()).addDays(1);
        this.departureDate = new Date().addDays(3);
        this.peopleCount1 = 2;
        this.peopleCount2 = 0;
        this.quantity = 1;
    }

    public arrivalDate!: Date;
    public departureDate!: Date;
    public quantity!: number;
    public peopleCount1!: number;
    public peopleCount2!: number;
    public unitClassGuid: string | undefined;
    public propertyGuid!: string;
    public cartGuid!: string;
    public unitGuid!: string;
    public defaultMode = true;

    get totalPeople(): number {
        return parseInt(this.peopleCount1.toString()) + parseInt(this.peopleCount2.toString());
    }
    get nights(): number {
        return Math.abs(new Date(this.arrivalDate).daysDiff(this.departureDate));
    }
    get adults(): number {
        return this.peopleCount1;
    }
    set adults(value: number) {
        this.peopleCount1 = value;
    }
    get children(): number {
        return this.peopleCount2;
    }
    set children(value: number) {
        this.peopleCount2 = value;
    }
}
