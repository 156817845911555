
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatPercentage, formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ChargeItem } from "./ChargeItem";
import ChargeItemEdit from "./ChargeItemEdit.vue";
import {
  deleteChargeItem,
  getChargeItems,
  saveChargeItem,
} from "./ChargeItemService";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { ChargeItemEdit } })
export default class ChargeItemTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  private mode = "reservation";
  private loading = true;
  private showChargeItemModal = false;
  private chargeItems: Array<ChargeItem> = [];
  private chargeItemFields: Array<Field> = [];
  private clickedChargeItem!: ChargeItem | null;
  private chargeItemTable: any;
  private selected!: Array<any>;

  async add() {
    this.clickedChargeItem = null;
    this.showChargeItemModal = true;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getTaxRateFormat(amount: number) {
    return formatPercentage(amount, false);
  }
  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.chargeItemTable.selectAllRows();
  }
  clearSelected() {
    this.chargeItemTable.clearSelected();
  }

  async saved(chargeItem: ChargeItem) {
    this.loading = true;
    // if the list doesn't contain the chargeItem, add it
    let found = this.chargeItems.find(
      (a) => a.chargeItemGuid === chargeItem.chargeItemGuid
    );
    if (!found) {
      await this.chargeItems.push(chargeItem);
    }
    let index = await this.chargeItems.findIndex(
      (a) => a.chargeItemGuid === chargeItem.chargeItemGuid
    );
    this.chargeItems[index] = chargeItem;
    await this.chargeItemTable.refresh();
    this.chargeItemTable.selectRow(index);
    this.showChargeItemModal = false;
    this.loading = false;
  }

  private getChargeItemFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    this.chargeItemFields.push(field);

    field = new Field();
    field.key = "preTaxPrice";
    field.label = "Pre Tax Price";
    this.chargeItemFields.push(field);

    for (let i = 0; i <= 10; i++) {
      if (
        this.property.preference &&
        this.property.preference["tax" + i.toString() + "Label"] &&
        this.property.preference["tax" + i.toString() + "Label"] != ""
      ) {
        field = {
          key: "tax" + i.toString() + "Rate",
          label: this.property.preference[
            "tax" + i.toString() + "Label"
          ] as string,
          sortable: true,
          tdClass: "",
        };
        this.chargeItemFields.push(field);
      }
    }

    field = new Field();
    field.key = "postTaxPrice";
    field.label = "Post Tax Price";
    this.chargeItemFields.push(field);

    field = new Field();
    field.key = "accountName";
    field.label = "Account Name";
    this.chargeItemFields.push(field);

    field = new Field();
    field.key = "accountNumber";
    field.label = "Account Number";
    this.chargeItemFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.chargeItemTable = this.$refs.chargeItemTableRef;
    this.getChargeItemFields();
    if (this.property && this.property.propertyGuid) {
      this.chargeItems = await getChargeItems(this.property.propertyGuid);
    } else {
      this.chargeItems = new Array<ChargeItem>();
    }
    this.loading = false;
  }

  getActiveChargeItem() {
    if (this.clickedChargeItem) {
      return this.clickedChargeItem.chargeItemGuid;
    } else {
      return "";
    }
  }

  async deleteChargeItem() {
    // put up warning confirmation first?
    if (this.selected && this.selected.length > 0) {
      let deleted = this.selected[0];
      let result = await deleteChargeItem(deleted.chargeItemGuid.toString());
      if (result) {
        if (this.chargeItems) {
          let index = this.chargeItems.findIndex(
            (a) => a.chargeItemGuid === deleted.chargeItemGuid
          );
          this.chargeItems.splice(index, 1);
        }
      }
    }

    await this.chargeItemTable.refresh();
  }

  edit(chargeItem: any, index: any, event: any) {
    if (chargeItem) {
      this.clickedChargeItem = chargeItem;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedChargeItem = this.selected[0];
    }
    this.showChargeItemModal = true;
  }

  closeChargeItemModal() {
    this.showChargeItemModal = false;
  }
}
