import { IDrawable } from "./IDrawable";
import { Point } from "./Point";

export class DrawableSelection {
    board: HTMLElement | null;
    id: any;
    wrappedDrawable: IDrawable;
    rectElement!: SVGRectElement;
    corners!: { upperLeft: SVGCircleElement; upperRight: SVGCircleElement; lowerLeft: SVGCircleElement; lowerRight: SVGCircleElement };
    edges!: { left: SVGCircleElement; right: SVGCircleElement; up: SVGCircleElement; down: SVGCircleElement };
    width: number;
    height: number;
    startingPos: Point;
    clicked: boolean;
    mouseDown: boolean;
    resizing: boolean;
    constructor(drawable) {
        this.id = drawable.drawableId;
        this.wrappedDrawable = drawable;
        this.board = document.getElementById("board");
        this.width = 0;
        this.height = 0;
        this.startingPos = { x: 0, y: 0 };
        this.clicked = true;
        this.mouseDown = false;
        this.resizing = false;
        this._createdrawablerectElement();
        // this._addEventHandlers();
    }

    _createdrawablerectElement() {
        const svgns = "http://www.w3.org/2000/svg"; //variable for the namespace
        const selectionrectElement = document.createElementNS(svgns, "rect");

        this.startingPos.x = this.wrappedDrawable.upperLeftCorner.x - 1;
        this.startingPos.y = this.wrappedDrawable.upperLeftCorner.y - 1;
        this.width = this.wrappedDrawable.width + 2;
        this.height = this.wrappedDrawable.height + 2;


        selectionrectElement.setAttribute("x", this.startingPos.x.toString());
        selectionrectElement.setAttribute("y", this.startingPos.y.toString());
        selectionrectElement.setAttribute("rx", "0");
        selectionrectElement.setAttribute("ry", "0");
        selectionrectElement.setAttribute("width", this.width.toString());
        selectionrectElement.setAttribute("height", this.height.toString());
        selectionrectElement.setAttribute("fill", "transparent");
        selectionrectElement.setAttribute("stroke", "#4262ff");
        selectionrectElement.setAttribute("stroke-width", "1");
        selectionrectElement.setAttribute("id", `drawable-selector-${this.wrappedDrawable.cssId}`);

        selectionrectElement.style.zIndex = "1";// this.wrappedDrawable.zIndex;
        selectionrectElement.style.userSelect = 'none';
        // this.board?.appendChild(selectionrectElement);
        this.rectElement = selectionrectElement;

        this._createCorners();
        this._createEdgesSelectors();
    }

    _createCircularSelector(centerX, centerY) {
        const svgns = "http://www.w3.org/2000/svg"; //variable for the namespace
        const cornerSelector = document.createElementNS(svgns, "circle");


        cornerSelector.setAttribute("cx", centerX.toString());
        cornerSelector.setAttribute("cy", centerY.toString());
        cornerSelector.setAttribute("r", "4");
        cornerSelector.setAttribute("fill", '#4262ff');
        cornerSelector.setAttribute("stroke", "none");
        cornerSelector.setAttribute("class", `selector-corner-${this.wrappedDrawable.cssId}`);

        cornerSelector.style.zIndex = this.wrappedDrawable.zIndex;
        cornerSelector.style.userSelect = 'none';
        this.board?.appendChild(cornerSelector);

        return cornerSelector;
    }

    _createCorners() {
        this.corners = {
            upperLeft: this._createCircularSelector(this.startingPos.x, this.startingPos.y),
            lowerLeft: this._createCircularSelector(this.startingPos.x, this.startingPos.y + this.height),
            upperRight: this._createCircularSelector(this.startingPos.x + this.width, this.startingPos.y),
            lowerRight: this._createCircularSelector(this.startingPos.x + this.width, this.startingPos.y + this.height)
        };

    }

    _createEdgesSelectors() {
        const middlepoints = this.getEdgesMiddlePoints();

        this.edges = {
            left: this._createCircularSelector(middlepoints.leftEdge.x, middlepoints.leftEdge.y),
            right: this._createCircularSelector(middlepoints.rightEdge.x, middlepoints.rightEdge.y),
            up: this._createCircularSelector(middlepoints.upperEdge.x, middlepoints.upperEdge.y),
            down: this._createCircularSelector(middlepoints.lowerEdge.x, middlepoints.lowerEdge.y)
        };
    }

    updatePos(x, y) {
        this.startingPos.x = x;
        this.startingPos.y = y;
        this.rectElement.setAttribute("x", this.startingPos.x.toString());
        this.rectElement.setAttribute("y", this.startingPos.y.toString());
        this._updateCorners();
        this._updateEgesSelectors();
    }

    updateWidth(width) {
        this.width = width;
        this.rectElement.setAttribute("width", this.width.toString());
    }

    updateHeight(height) {
        this.height = height;
        this.rectElement.setAttribute("height", this.height.toString());
    }

    _updateCorners() {
        this.corners.upperLeft.setAttribute("cx", this.startingPos.x.toString());
        this.corners.upperLeft.setAttribute("cy", this.startingPos.y.toString());

        this.corners.lowerLeft.setAttribute("cx", this.startingPos.x.toString());
        this.corners.lowerLeft.setAttribute("cy", (this.startingPos.y + this.height).toString());

        this.corners.upperRight.setAttribute("cx", (this.startingPos.x + this.width).toString());
        this.corners.upperRight.setAttribute("cy", this.startingPos.y.toString());

        this.corners.lowerRight.setAttribute("cx", (this.startingPos.x + this.width).toString());
        this.corners.lowerRight.setAttribute("cy", (this.startingPos.y + this.height).toString());
    }

    _updateEgesSelectors() {
        const middlepoints = this.getEdgesMiddlePoints();

        this.edges.left.setAttribute("cx", middlepoints.leftEdge.x.toString());
        this.edges.left.setAttribute("cy", middlepoints.leftEdge.y.toString());

        this.edges.right.setAttribute("cx", middlepoints.rightEdge.x.toString());
        this.edges.right.setAttribute("cy", middlepoints.rightEdge.y.toString());

        this.edges.up.setAttribute("cx", middlepoints.upperEdge.x.toString());
        this.edges.up.setAttribute("cy", middlepoints.upperEdge.y.toString());

        this.edges.down.setAttribute("cx", middlepoints.lowerEdge.x.toString());
        this.edges.down.setAttribute("cy", middlepoints.lowerEdge.y.toString());
    }

    _addEventHandlers() {
        this.rectElement.onmousedown = () => this._mouseDownAction();
        this.rectElement.onmouseup = () => this._mouseUpAction();

        this.corners.upperLeft.onmousedown = () => this._cornerMouseDownAction(this.wrappedDrawable.upperLeftCorner.x + this.wrappedDrawable.width,
            this.wrappedDrawable.upperLeftCorner.y + this.wrappedDrawable.height);
        this.corners.upperLeft.onmouseover = () => this._resizingMouseOVerAction("nwse-resize");
        this.corners.upperLeft.onmouseleave = () => this._resizingMouseLeaveAction();
        this.corners.upperLeft.onmouseup = () => this._resizingMouseUpAction();

        this.corners.upperRight.onmousedown = () => this._cornerMouseDownAction(this.wrappedDrawable.upperLeftCorner.x,
            this.wrappedDrawable.upperLeftCorner.y + this.wrappedDrawable.height);
        this.corners.upperRight.onmouseover = () => this._resizingMouseOVerAction("nesw-resize");
        this.corners.upperRight.onmouseleave = () => this._resizingMouseLeaveAction();
        this.corners.upperRight.onmouseup = () => this._resizingMouseUpAction();

        this.corners.lowerLeft.onmousedown = () => this._cornerMouseDownAction(this.wrappedDrawable.upperLeftCorner.x + this.wrappedDrawable.width,
            this.wrappedDrawable.upperLeftCorner.y);
        this.corners.lowerLeft.onmouseover = () => this._resizingMouseOVerAction("nesw-resize");
        this.corners.lowerLeft.onmouseleave = () => this._resizingMouseLeaveAction();
        this.corners.lowerLeft.onmouseup = () => this._resizingMouseUpAction();

        this.corners.lowerRight.onmousedown = () => this._cornerMouseDownAction(this.wrappedDrawable.upperLeftCorner.x,
            this.wrappedDrawable.upperLeftCorner.y);
        this.corners.lowerRight.onmouseover = () => this._resizingMouseOVerAction("nwse-resize");
        this.corners.lowerRight.onmouseleave = () => this._resizingMouseLeaveAction();
        this.corners.lowerRight.onmouseup = () => this._resizingMouseUpAction();

        this.edges.right.onmousedown = () => this._edgeMouseDownAction(this.wrappedDrawable.upperLeftCorner.x, true)
        this.edges.right.onmouseover = () => this._resizingMouseOVerAction("ew-resize");
        this.edges.right.onmouseleave = () => this._resizingMouseLeaveAction();
        this.edges.right.onmouseup = () => this._resizingMouseUpAction();

        this.edges.left.onmousedown = () => this._edgeMouseDownAction(this.wrappedDrawable.upperLeftCorner.x + this.wrappedDrawable.width, true)
        this.edges.left.onmouseover = () => this._resizingMouseOVerAction("ew-resize");
        this.edges.left.onmouseleave = () => this._resizingMouseLeaveAction();
        this.edges.left.onmouseup = () => this._resizingMouseUpAction();

        this.edges.up.onmousedown = () => this._edgeMouseDownAction(this.wrappedDrawable.upperLeftCorner.y + this.wrappedDrawable.height, false)
        this.edges.up.onmouseover = () => this._resizingMouseOVerAction("ns-resize");
        this.edges.up.onmouseleave = () => this._resizingMouseLeaveAction();
        this.edges.up.onmouseup = () => this._resizingMouseUpAction();

        this.edges.down.onmousedown = () => this._edgeMouseDownAction(this.wrappedDrawable.upperLeftCorner.y, false)
        this.edges.down.onmouseover = () => this._resizingMouseOVerAction("ns-resize");
        this.edges.down.onmouseleave = () => this._resizingMouseLeaveAction();
        this.edges.down.onmouseup = () => this._resizingMouseUpAction();
    }

    _clickAcion() {
        this.clicked = !this.clicked;
        if (this.clicked) {
            this.disablePrevSelector();
            this.enable();
            //store.commit('setSelecting', true);
        }
        else {
            this.disable();
            this.mouseDown = false;
            // store.commit('setSelecting', false);
        }
    }

    //edge resizing
    _edgeMouseDownAction(ref, lockY) {
        this.resizing = true;
        this.disable();

        const tracker = setInterval(() => {
            if (!this.resizing) {// || !store.getters.boardMouseDown) {
                clearInterval(tracker);
                this.enable();
                //updatedrawablePosAndSize(this.wrappedDrawable);
            }
            else {
                if (lockY) { //change the width = x
                    const updatedX = window.screenX// window.screenX;
                    const newUpperLeftCornerX = Math.min(ref, updatedX);
                    const currentWidth = Math.abs(updatedX - ref);
                    this.wrappedDrawable.updateWidth(currentWidth);
                    this.updateWidth(currentWidth + 2);
                    this.wrappedDrawable.updatePos(newUpperLeftCornerX, this.wrappedDrawable.upperLeftCorner.y);
                    this.updatePos(newUpperLeftCornerX - 1, this.wrappedDrawable.upperLeftCorner.y - 1);
                }
                else {
                    const updatedY = window.screenY - 110;
                    const newUpperLeftCornerY = Math.min(ref, updatedY);
                    const currentHeight = Math.abs(updatedY - ref);
                    this.wrappedDrawable.updateHeight(currentHeight);
                    this.updateHeight(currentHeight + 2);
                    this.wrappedDrawable.updatePos(this.wrappedDrawable.upperLeftCorner.x, newUpperLeftCornerY);
                    this.updatePos(this.wrappedDrawable.upperLeftCorner.x - 1, newUpperLeftCornerY - 1);
                }
            }
        }, 10);
    }

    //corner resinzing
    _cornerMouseDownAction(refX, refY) {
        this.resizing = true;
        this.disable();

        const tracker = setInterval(() => {
            if (!this.resizing) { //|| !store.getters.boardMouseDown) {
                clearInterval(tracker);
                this.enable();
                //updatedrawablePosAndSize(this.wrappedDrawable);
            }
            else {
                const updatedX = window.screenX
                const updatedY = window.screenY - 110;
                const newUpperLeftCornerX = Math.min(refX, updatedX);
                const newUpperLeftCornerY = Math.min(refY, updatedY);
                const currentWidth = Math.abs(updatedX - refX);
                const currentHeight = Math.abs(updatedY - refY);

                this.wrappedDrawable.updateWidth(currentWidth);
                this.wrappedDrawable.updateHeight(currentHeight);
                this.updateWidth(currentWidth + 2);
                this.updateHeight(currentHeight + 2);

                this.wrappedDrawable.updatePos(newUpperLeftCornerX, newUpperLeftCornerY);
                this.updatePos(newUpperLeftCornerX - 1, newUpperLeftCornerY - 1);
            }
        }, 10);

    }
    //resining actions
    _resizingMouseUpAction() {
        this.resizing = false;
    }

    _resizingMouseLeaveAction() {
        // store.commit("setResizing", false);
    }

    _resizingMouseOVerAction(mosueDirection) {
        //store.commit("setResizing", true);
        // store.commit("setDirection", mosueDirection);
    }
    //moving the drawable
    _mouseDownAction() {
        this.mouseDown = true;
        this.disable();
        this._trackMovement();
    }

    _mouseUpAction() {
        this._clickAcion();
        this.mouseDown = false;
    }

    _trackMovement() {
        //if (store.getters.isDrawing) return;

        let prevX = window.screenX;
        let prevY = window.screenY - 110;

        const oldUpperLeftCornerX = this.wrappedDrawable.upperLeftCorner.x;
        const oldUpperLeftCornerY = this.wrappedDrawable.upperLeftCorner.y;

        const tracker = setInterval(() => {
            if (!this.mouseDown) {// || !store.getters.boardMouseDown) {
                if (this.wrappedDrawable.upperLeftCorner.x !== oldUpperLeftCornerX ||
                    this.wrappedDrawable.upperLeftCorner.y !== oldUpperLeftCornerY) {
                    // updatedrawablePosAndSize(this.wrappedDrawable);
                }
                clearInterval(tracker);
            }
            else {
                const updatedX = window.screenX;
                const updatedY = window.screenY - 110;
                const xShift = updatedX - prevX;
                const yShift = updatedY - prevY;
                const newUpperLeftCornerX = this.wrappedDrawable.upperLeftCorner.x + xShift;
                const newUpperLeftCornerY = this.wrappedDrawable.upperLeftCorner.y + yShift;
                prevX = window.screenX;
                prevY = window.screenY - 110;
                this.wrappedDrawable.updatePos(newUpperLeftCornerX, newUpperLeftCornerY);
                this.updatePos(newUpperLeftCornerX - 1, newUpperLeftCornerY - 1);
            }
        }, 1);
    }
    //sub actions
    disable() {
        this.rectElement.style.stroke = 'none';

        this.corners.upperLeft.style.fill = 'none';
        this.corners.upperRight.style.fill = 'none';
        this.corners.lowerLeft.style.fill = 'none';
        this.corners.lowerRight.style.fill = 'none';

        this.edges.left.style.fill = 'none';
        this.edges.right.style.fill = 'none';
        this.edges.up.style.fill = 'none';
        this.edges.down.style.fill = 'none';
    }
    enable() {
        this.rectElement.style.stroke = '#4262ff';

        this.corners.upperLeft.style.fill = '#4262ff';
        this.corners.upperRight.style.fill = '#4262ff';
        this.corners.lowerLeft.style.fill = '#4262ff';
        this.corners.lowerRight.style.fill = '#4262ff';

        this.edges.left.style.fill = '#4262ff';
        this.edges.right.style.fill = '#4262ff';
        this.edges.up.style.fill = '#4262ff';
        this.edges.down.style.fill = '#4262ff';
    }
    disablePrevSelector() {
        // const prevSelector = store.getters.currentSelector;
        // if (prevSelector !== null) {
        //     prevSelector.disable();
        //     prevSelector.mouseDown = false;
        //     if (prevSelector.id !== this.id) prevSelector.clicked = false;
        // }
        // store.commit('setSelector', this);
    }

    removeSelection() {
        if (this.board?.contains(this.corners.lowerLeft)) {
            this.board?.removeChild(this.corners.lowerLeft);
        }
        // this.board?.removeChild(this.corners.lowerLeft);
        this.rectElement.remove();
        this.corners.lowerLeft.remove();
        this.corners.lowerRight.remove();
        this.corners.upperLeft.remove();
        this.corners.upperRight.remove();

        this.edges.left.remove();
        this.edges.right.remove();
        this.edges.up.remove();
        this.edges.down.remove();
    }

    //calculations
    getCornersCoordinates() {
        return {
            upperLeftCorner: {
                x: this.startingPos.x,
                y: this.startingPos.y,
            },

            upperRightcorner: {
                x: this.startingPos.x + this.width,
                y: this.startingPos.y,
            },

            lowerLeftCorner: {
                x: this.startingPos.x,
                y: this.startingPos.y + this.height,
            },

            lowerRightCorner: {
                x: this.startingPos.x + this.width,
                y: this.startingPos.y + this.height,
            },
        }
    }

    getEdgesMiddlePoints() {
        const corners = this.getCornersCoordinates();
        return {
            leftEdge: {
                x: (corners.upperLeftCorner.x + corners.lowerLeftCorner.x) / 2,
                y: (corners.upperLeftCorner.y + corners.lowerLeftCorner.y) / 2,
            },

            rightEdge: {
                x: (corners.upperRightcorner.x + corners.lowerRightCorner.x) / 2,
                y: (corners.upperRightcorner.y + corners.lowerRightCorner.y) / 2,
            },

            upperEdge: {
                x: (corners.upperLeftCorner.x + corners.upperRightcorner.x) / 2,
                y: (corners.upperLeftCorner.y + corners.upperRightcorner.y) / 2,
            },

            lowerEdge: {
                x: (corners.lowerLeftCorner.x + corners.lowerRightCorner.x) / 2,
                y: (corners.lowerLeftCorner.y + corners.lowerRightCorner.y) / 2,
            },
        }
    }
}