
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class StyleThumb extends Vue {
  @Prop({ default: "" }) public image!: string;
  getImage() {
    return this.image;
  }
}
