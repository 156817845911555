
import { Vue, Component } from "vue-property-decorator";
import SearchBar from "./SearchBar.vue";
import UnitCard from "./UnitCard.vue";
import { Unit } from "@/components/Cloud/Units/Unit";
import { filterUnits, getUnits } from "../Cloud/Units/UnitService";
import { getProperty } from "../Cloud/Settings/PropertyService";
import Property from "@/models/Property";
import CompanyDetails from "./CompanyDetails.vue";
@Component({
  components: { SearchBar, UnitCard, CompanyDetails },
})
export default class Company extends Vue {
  private busy = false;
  private units = new Array<Unit>();
  private propertyGuid!: string;
  private pickedDate: Date = new Date();
  private property = new Property();
  private showOverlay = false;

  calendarPicked(arg: any) {}

  navToSearch() {
    this.$router.push({
      name: "Search",
      query: { ...this.$router.currentRoute.query },
    });
  }

  // async categoriesChanged(arg: any) {
  //   if (!this.loaded) {
  //     return;
  //   }
  //   this.selectedCategories = arg;
  //   let names = this.selectedCategories.map((c) => c.name);
  //   this.$router.replace({
  //     query: {
  //       ...this.$router.currentRoute.query,
  //       categories: names.join(","),
  //     },
  //   });
  // }

  async datePicked(arg: any) {
    // this.pickedDate = new Date(arg);
    this.$router.replace({
      query: {
        ...this.$router.currentRoute.query,
        date: new Date(this.pickedDate).dashedDate(),
      },
    });
  }

  async created() {}

  async mounted() {
    this.showOverlay = true;
    if (this.$route.query.prop) {
      this.propertyGuid = this.$route.query.prop.toString();
    }
    if (this.$route.params.propertyGuid) {
      this.propertyGuid = this.$route.params.propertyGuid.toString();
    }
    this.property = await getProperty(this.propertyGuid);
    this.units = await filterUnits(this.propertyGuid);
    this.showOverlay = false;
  }
}
