
import { ImageModel } from "../../../models/ImageModel";
import { Amenity } from "../Amenity/Amenity";
import { GridDay } from "../../../models/GridDay";
import { Rate } from "@/components/Cloud/Rates/Rate";

export class UnitTypes {
    public static Accommodation: string = "Accommodation";
    public static Event: string = "Event";
    public static Rental: string = "Rental";
}

export class Unit {
    constructor() {
        this.photos = new Array<ImageModel>();
        this.imageGuids = new Array<string>();
        this.unitGroupName = ``;
        this.maximumNights = 30;
        this.minimumNights = 1;
        this.capacity = 0;
        this.unitType = UnitTypes.Accommodation;
        this.description = '';
        this.rates = new Array<Rate>();
        this.capacity = 2;
        this.minimumPeople = 1;
    }
    public selected: boolean = false;
    public unitGuid!: string;
    public propertyGuid!: string;
    public unitName!: string;
    public description!: string;
    public policies!: string;
    public capacity: number;
    public quantity: number = 1; 
    public minimumPeople!: number;
    public maximumNights!: number;
    public minimumNights!: number;
    public active!: boolean;
    public checked!: boolean;
    public notes!: string;
    public sortPosition!: number;
    public unitGroupGuid!: string;
    public unitGroupName: string;
    public createdDate!: Date;
    public modifiedDate!: Date;
    public showOnline!: boolean;
    public bookOnline!: boolean;
    ///rental, or event, or accommodation
    public unitType!: string;
    public isRental!: boolean;
    public imageGuids: Array<string>;
    public gridDays!: Array<GridDay>;
    public photos: Array<ImageModel>;
    public amenityObjects!: Array<Amenity>;
    public rates: Array<Rate>;
    public category!: string;
    public get bestRate(): Rate {
        return this.rates[0];
    }
    public thumb!: string;
    public startingPrice!: number;

    public get peopleDetails(): string {
        if (this.minimumPeople == 0) {
            this.minimumPeople = 1;
        }
        if (this.capacity == 0) {
            this.capacity = 1;
        }
        //can be guests or nights
        let result = "people";
        if (this.capacity == 1) {
            result = "person";
        }
        if (this.capacity === this.minimumPeople) {
            result = this.capacity + " " + result;
        } else {
            result =
                this.minimumPeople +
                " to " +
                this.capacity +
                " " +
                result;
        }
        return result;
    }

   public get nightsDetails(): string {
        if (this.minimumNights == 0) {
            this.minimumNights = 1;
        }
        //can be guests or nights
        let result = "night";
        if (this.maximumNights === this.minimumNights) {
            result = this.maximumNights + " " + result;
        } else {
            result =
                this.minimumNights +
                " to " +
                this.maximumNights +
                " " +
                result;
        }
        if (this.maximumNights != 1) {
            result += "s";
        }
        return result;
    }
}