
import { formatter } from "@/utils/currencyUtils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { UnitTypes } from "../Cloud/Units/Unit";
import {
  getTotal,
  getTotalAddOns,
  getTotalDelivery,
  getTotalDeposit,
  getTotalDue,
  getTotalStripeFee,
  getTotalAppFee,
  getTotalPrice,
  getTotalTaxAmount,
} from "./Cart";
import CartItem from "./CartItem";

@Component({
  components: {},
})
export default class CartSummary extends Vue {
  @Prop({ default: null }) public cis!: Array<CartItem>;

  @Watch("cis", { immediate: false, deep: true })
  onDateChanged(newCartItems: Array<CartItem>, oldValues: Array<CartItem>) {
    this.cartItems = newCartItems;
  }
  private cartItems: Array<CartItem> = [];
  created() {
    this.cartItems = this.cis;
  }
  formatCurrency(num: number) {
    if (!this.cartItems || this.cartItems.length == 0) {
      return "$--.--";
    }
    if (isNaN(num)) {
      return "$--.--";
    }

    return formatter(num, this.cartItems[0].currencyCode);
  }
  getTotalApplicationFee() {
    return getTotalAppFee(this.cartItems);
  }
  getTotalStripeFees() {
    return getTotalStripeFee(this.cartItems);
  }
  getTotalTaxAmount() {
    return getTotalTaxAmount(this.cartItems);
  }
  getTotalDeposit() {
    return getTotalDeposit(this.cartItems);
  }
  getTotalDue() {
    return getTotalDue(this.cartItems);
  }
  getTotal() {
    return getTotal(this.cartItems);
  }
  getItemDateStrings(cartItem: CartItem) {
    let str = "";
    if (cartItem.unitType == UnitTypes.Accommodation) {
      str = `${new Date(
        cartItem.inDateTime
      ).formatDayOfWeekMMDDYYYY()} to ${new Date(
        cartItem.outDateTime
      ).formatDayOfWeekMMDDYYYY()} for ${new Date(cartItem.inDateTime).daysDiff(
        cartItem.outDateTime
      )} night(s)`;
    } else if (cartItem.unitType == UnitTypes.Rental) {
      if (new Date(cartItem.inDateTime).areSameDay(cartItem.outDateTime)) {
        str = `${new Date(
          cartItem.inDateTime
        ).formatDayOfWeekMMDDYYYY()} at ${new Date(cartItem.inDateTime).time(
          true
        )} to ${new Date(cartItem.outDateTime).time(true)}`;
      }
    }

    return str;
  }
}
