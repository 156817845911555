
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { UnitGroup } from "@/models/UnitGroup";
import ComboBox from "@/components/Controls/ComboBox.vue";
import { getUnitGroup, getAllUnitGroups, saveUnitGroup } from "./UnitGroupService";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { ComboBox },
})
export default class UnitGroupEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public paramUnitGroupGuid!: string;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  public unitGroupGuid!: string;
  private showEditModal = false;
  private loading = false;
  public unitGroup!: UnitGroup;
  private busy = false;
  private unitGroups: Array<UnitGroup> = [];
  private expanded = false;

  validateName() {
    return true;
  }

  public cancel() {
    this.$emit("close", this.unitGroup);
  }

  getUnitGroup() {
    return this.unitGroup;
  }

  async mounted() {
    this.loading = true;
    if (this.paramUnitGroupGuid) {
      this.unitGroupGuid = this.paramUnitGroupGuid;
    } else if (this.$route.params.unitGroupGuid) {
      this.unitGroupGuid = this.$route.params.id.toString();
    } else if (this.$route.query.unitGroupGuid) {
      this.unitGroupGuid = this.$route.query.unitGroupGuid.toString();
    }
    if (this.unitGroupGuid) {
      this.unitGroup = await getUnitGroup(this.unitGroupGuid.toString());
    }
    if (!this.unitGroup) {
      this.createNew();
    }

    this.unitGroups = await getAllUnitGroups(this.property.propertyGuid);
    this.loading = false;
  }

  async addAnother() {
    this.busy = true;
    this.loading = true;
    await this.save(false);
    await this.createNew();
    this.busy = false;
    this.loading = false;
  }

  private createNew() {
    this.unitGroup = new UnitGroup();
    this.unitGroup.name = "";
    this.unitGroup.unitGroupGuid = Guid.newGuid();
    this.unitGroup.propertyGuid = this.property.propertyGuid;
    this.unitGroup.createdDate = new Date();
  }

  async save(close: boolean) {
    this.busy = true;
    await saveUnitGroup(this.unitGroup);
    this.$emit("success", this.unitGroup);
    if (close && close == true) {
      this.$emit("close");
    }
    this.busy = false;
  }
}
