
import Property from "@/models/Property";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { sendAllCompanyInvitations, sendCompanyInvitation } from "../Templates/EmailService";
import { User } from "./User";
const Auth = namespace("AuthStore");
const PropertyStore = namespace("PropertyStore");

@Component
export default class Profile extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Auth.State("user")
  private currentUser!: User;

  switchProperty(newPropertyGuid: string) {
    localStorage.setItem("propertyGuid", newPropertyGuid);
    window.location.reload();
  }
  async sendAllCompanyInvitations() {
    await sendAllCompanyInvitations();
  }

  async sendCompanyInvitation(propertyGuid: string) {
    await sendCompanyInvitation(propertyGuid);
  }

  formatDate(dateFor: Date) {
    return new Date(dateFor).formatDayOfWeekMMDDYYYY();
  }

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  }
}
