import { render, staticRenderFns } from "./BoisBrule.vue?vue&type=template&id=5cc1edf1&scoped=true&"
import script from "./BoisBrule.vue?vue&type=script&lang=ts&"
export * from "./BoisBrule.vue?vue&type=script&lang=ts&"
import style0 from "./BoisBrule.vue?vue&type=style&index=0&id=5cc1edf1&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cc1edf1",
  null
  
)

export default component.exports