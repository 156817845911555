
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";
import { namespace } from "vuex-class";
import UnitTable from "./UnitTable.vue";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { ConfigurationNavMenu, UnitTable },
})
export default class Units extends Vue {
  @PropertyStore.State
  public property!: Property;

  navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }
}
