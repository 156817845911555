import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import AuthService from '@/components/Cloud/Auth/AuthService';
import { User } from './User';
import { LoginCredentials } from './LoginCredentials';
import { RegisterCredentials } from './RegisterCredentials';

const storedUser: string | null = AuthService.getStorage()?.getItem('user');

@Module({ namespaced: true })
class AuthStore extends VuexModule {
  public status = storedUser ? { loggedIn: true } : { loggedIn: false };
  public user: User | null = storedUser ? JSON.parse(storedUser) : null;

  @Mutation
  public loginSuccess(user: User): void {
    this.status.loggedIn = true;
    this.user = user;
  }

  @Mutation
  public loginFailure(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  public logout(): void {
    this.status.loggedIn = false;
    this.user = null;
  }

  @Mutation
  public registerSuccess(): void {
    this.status.loggedIn = false;
  }

  @Mutation
  public registerFailure(): void {
    this.status.loggedIn = false;
  }

  @Action({ rawError: true })
  public async login(data: LoginCredentials): Promise<User> {
    return AuthService.login(data.email, data.password).then(
      user => {
        this.context.commit('loginSuccess', user);
        return Promise.resolve(user);
      },
      error => {
        this.context.commit('loginFailure');
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  @Action
  signOut(): void {
    AuthService.logout();
    this.context.commit('logout');
  }

  @Action({ rawError: true })
  public async register(regCreds: RegisterCredentials): Promise<string> {
    return AuthService.register(regCreds).then(
      response => {
        this.context.commit('registerSuccess');
        return Promise.resolve(response.data);
      },
      error => {
        this.context.commit('registerFailure');
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }
}

export default AuthStore;