import { Filter } from "./Filter";

export class PagingParameters {
    constructor() {
       this.sortBy = "";
    }
    public pageSize: number | undefined;
    public currentPage: number | undefined;
    public sortBy: string | undefined;
    public sortDirection: string | undefined;
    public filters!: Array<Filter>;
}