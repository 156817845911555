import { Invoice } from "@/components/Cloud/Invoices/Invoice";
import { Assign } from "@/components/Cloud/Assign/Assign";
import { ResStatus } from "@/components/Cloud/Reservations/ResStatus";
import { Activity } from "../Activity/Activity";
import { PersonInReservation } from "./PersonInReservation";
import { ConfirmationMethod } from "./ConfirmationMethod";
import { Answer } from "../CustomQuestions/Answer";

export class Reservation {

    public primaryContact() {
        return this.personInReservations.find((pir) => { pir.isPrimaryContact });
    }
    constructor() {
        this.confirmationNumber = '';
        this.status = ResStatus.Pending;

    }

    public reservationGuid!: string;
    public propertyGuid!: string;
    public status!: ResStatus;
    public name!: string;
    public dateMade!: Date;
    public cancelDate!: Date;
    public takenBy!: string;
    public arrivalDate!: Date;
    public departureDate!: Date;
    public numberInParty!: number;
    public numberOfPeople1!: number;
    public numberOfPeople2!: number;
    public numberOfPeople3!: number;
    public numberOfPeople4!: number;
    public get totalPeople(): number {
        return this.numberOfPeople1 + this.numberOfPeople2 + this.numberOfPeople3 + this.numberOfPeople4;
    }
    public confirmationMethod!: ConfirmationMethod;
    public confirmationNumber!: string;
    public depositAmount!: number;
    public depositDate!: Date;
    public creditCardNumber!: string;
    public creditCardExp!: Date;
    public creditCardKind!: number; //new CardKind class?
    public creditCardName!: string;
    public notes!: string;
    public requests!: string;
    public groupNotes!: string;
    public unitAssignments!: string;
    public groupNames!: string;
    public amenityList!: string;
    public userField1!: string;
    public userField2!: string;
    public userField3!: string;
    public userField4!: string;
    public userField5!: string;
    public userField6!: string;
    public userCheck1!: boolean;
    public userCheck2!: boolean;
    public lockUnits!: boolean;
    public numberOfNights!: number;
    public totalCharges!: number;
    public totalCredits!: number;
    public totalDiscounts!: number;

    public totalDeposits!: number;
    public totalCertificates!: number;
    public totalPayments!: number;
    public totalRefunds!: number;
    public preTaxBaseRate!: number;

    public balance!: number;
    public selected!: boolean;
    public cancellationFee!: number;
    public createdDate!: Date;
    public modifiedDate!: Date;

    //collections
    public personInReservations = new Array<PersonInReservation>;
    public activities = new Array<Activity>;
    public assigns = new Array<Assign>;
    public invoices = new Array<Invoice>;
    public questionnaire = new Array<Answer>;

}