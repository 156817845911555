
import Multiselect from "vue-multiselect";
import { Loader } from "@googlemaps/js-api-loader";
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: { Multiselect },
})
export default class AddressSuggest extends Vue {
  // Initialize and add the map

  async mounted() {
    const loader = new Loader({
      apiKey: "AIzaSyDVYoGJ1NaKgqdrfVviGXh7rqVhY1uZe0g",
      version: "weekly",
      libraries: ["places"],
    });

    const slp = { lat: 44.935198, lng: -93.333792 };
    const australia = { lat: -34.397, lng: 150.644 };
    const mapEl = document.getElementById("map") as HTMLElement;
    await loader.load().then((google) => {
      const map = new google.maps.Map(mapEl, {
        center: slp,
        zoom: 8,
      });

      const marker = new google.maps.Marker({ position: slp, map: map });

      const autocomplete = new google.maps.places.Autocomplete(
        this.$refs["autocomplete"] as HTMLInputElement
      );
      const autocompleteCity = new google.maps.places.Autocomplete(
        this.$refs["city"] as HTMLInputElement
      );

      autocomplete.addListener("place_changed", () =>
        this.handleAddressChanged(autocomplete, map, google)
      );
      autocompleteCity.addListener("place_changed", () =>
        this.handleAddressChanged(autocompleteCity, map, google)
      );
    });
  }

  handleAddressChanged(
    field: google.maps.places.Autocomplete,
    map: google.maps.Map,
    google: any
  ) {
    {
      const place = field.getPlace();
      if (place.geometry) {
        map.panTo(place.geometry.location as google.maps.LatLng);
        const markerPos = place.geometry.location;
        const marker = new google.maps.Marker({
          position: markerPos,
          map: map,
        });
        this.fillInAddress(field); //place.address_components?:[3]["long_name"]);
      }
    }
  }

  fillInAddress(autocomplete: google.maps.places.Autocomplete) {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    let address1 = "";
    let postcode = "";

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case "route": {
          address1 += component.short_name;
          break;
        }

        case "postal_code": {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case "postal_code_suffix": {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case "locality":
          // (document.querySelector("#locality") as HTMLInputElement).value =
          //   component.long_name;
          break;

        case "administrative_area_level_1": {
          // (document.querySelector("#state") as HTMLInputElement).value =
          //   component.short_name;
          break;
        }

        case "country":
          // (document.querySelector("#country") as HTMLInputElement).value =
          //   component.long_name;
          break;
      }
    }

    // address1Field.value = address1;
    // postalField.value = postcode;

    // After filling the form with address components from the Autocomplete
    // prediction, set cursor focus on the second address line to encourage
    // entry of subpremise information such as apartment, unit, or floor number.
    // address2Field.focus();
  }

  // initialize() {
  //   var input = document.getElementById("searchTextField");
  //   new google.maps.places.Autocomplete(input);
  // }
}
