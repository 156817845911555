
import Property from "@/models/Property";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({
  components: {},
})
export default class CompanyCard extends Vue {
  @Prop({ default: () => null }) public property!: Property;

  @Watch("property", { immediate: false, deep: true })
  onPropChanged(newVal: Property, oldVal: Property) {
    this.property = newVal;
  }

  getButtonText() {
    return "Search Availability";
  }

  formatDate(dateFor: Date) {
    return new Date(dateFor).formatDayOfWeekMMDDYYYY();
  }

  getDistance() {
     return this.property.distance;
  }

  goToUnitListingsPage(propertyGuid: string) {
    // go to a company page
    // show the related items to the search
    // this.$router.push({
    //   name: "Units",
    //   params: { propertyGuid: propertyGuid },
    //   query: { propertyGuid: propertyGuid },
    // });

    this.$router.push({
      name: "Company",
      params: { propertyGuid: propertyGuid },
      query: {
        ...this.$router.currentRoute.query,
      },
    });
  }
}
