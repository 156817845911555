
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { Amenity } from "@/components/Cloud/Amenity/Amenity";
import {
  deleteAmenityInUnitsByAmenity,
  getAmenity,
  getUnitsByAmenity,
  saveAmenity,
  saveAmenityInUnits,
} from "@/components/Cloud/Amenity/AmenityService";
import { Unit } from "@/components/Cloud/Units/Unit";
import { getUnits } from "../Units/UnitService";
import { RateInUnit } from "../Rates/RateInUnit";
import { AmenityInUnit } from "./AmenityInUnit";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class AmenityEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public amenityGuid!: string;
  private showEditModal = false;
  private loading = false;
  public amenity!: Amenity;
  private busy = false;
  private units: Array<Unit> = [];
  private selectedUnits: Array<Unit> = [];

  async selectAllUnits() {
    this.busy = true;
    if (this.allAreChecked()) {
      await this.units.forEach((a) => {
        a.checked = false;
      });
    } else {
      await this.units.forEach((a) => {
        a.checked = true;
      });
    }
    this.busy = false;
  }

  async saveUnits() {
    let aius = new Array<AmenityInUnit>();
    for (const unit of this.units) {
      if (unit.checked) {
        let aiu = new AmenityInUnit();
        aiu.amenityGuid = this.amenity.amenityGuid;
        aiu.propertyGuid = this.property.propertyGuid;
        aiu.unitGuid = unit.unitGuid;
        aius.push(aiu);
      }
    }

    await deleteAmenityInUnitsByAmenity(this.amenity.amenityGuid);
    await saveAmenityInUnits(aius);
  }

  allOrNone() {
    if (this.allAreChecked()) {
      return "None";
    } else {
      return "All";
    }
  }

  allAreChecked() {
    let result = this.units.every((a) => a.checked);
    return result;
  }

  validateName() {
    return true;
  }

  public cancel() {
    this.$emit("close");
  }

  getAmenity() {
    return this.amenity;
  }

  async mounted() {
    this.loading = true;
    if (this.amenityGuid) {
      this.amenityGuid = this.amenityGuid;
    } else if (this.$route.params.amenityGuid) {
      this.amenityGuid = this.$route.params.id.toString();
    } else if (this.$route.query.amenityGuid) {
      this.amenityGuid = this.$route.query.amenityGuid.toString();
    }
    if (this.amenityGuid) {
      this.amenity = await getAmenity(this.amenityGuid.toString());
    }
    if (!this.amenity) {
      this.amenity = new Amenity();
      this.amenity.amenityGuid = Guid.newGuid();
      this.amenity.propertyGuid = this.property.propertyGuid;
      this.amenity.createdDate = new Date();
    }

    this.units = await getUnits(this.property.propertyGuid);
    this.selectedUnits = await getUnitsByAmenity(this.amenity.amenityGuid);
    for (const u of this.units) {
      u.checked = this.isEnabled(u);
    }

    this.loading = false;
  }

  isEnabled(unit: Unit) {
    let found = this.selectedUnits.find((u) => u.unitGuid === unit.unitGuid);
    if (!found) {
      return false;
    }
    return true;
  }

  async saveAndClose() {
    this.busy = true;
    await this.saveUnits();
    await saveAmenity(this.amenity).then(() =>
      this.$emit("success", this.amenity)
    );
    this.busy = false;
  }
}
