import { Assign } from "@/components/Cloud/Assign/Assign";
import { ReqAssign } from "./ReqAssign";
import { ReqCharge } from "./ReqCharge";

export class ConfirmationViewModel {
    public billingAddress1!: string;
    public billingCity!: string;
    public billingState!: string;
    public billingPostalCode!: string;
    public billingCountry!: string;
    public billingCurrency!: string;
    public totalCharges!: number;
    public totalCredits!: number;
    public depositAmount!: number;
    public appFee!: number;
    public stripeFee!: number;
    public arrivalDate!: Date;
    public departureDate!: Date;
    public guests!: number;
    public peopleCount1!: number;
    public peopleCount2!: number;
    public peopleCount3!: number;
    public peopleCount4!: number;
    public promoCode!: string;
    public propertyGuid!: string;
    public reqCharges!: Array<ReqCharge>;
    public reqAssigns!: Array<ReqAssign>;
    public assigns!: Array<Assign>;
    public name!: string;
    public billingAddressFormatted!: string;
    public phone!: string;
    public email!: string;
    public cardNumberMasked!: string;
    public cardExpiration!: string;
    public nameOnCard!: string;
    public cardType!: string;
    public comments!: string;
}
