const COMPONENT_STYLE = {
    base: {
        color: '',
        fontSize: '16px',
        fontFamily: '',
        fontSmoothing: 'antialiased',
        fontWeight: '500',
        lineHeight: '24px',
        '::placeholder': { color: '' },
        ':-webkit-autofill': { color: '' }
    },
    complete: {
        color: '',
        iconColor: '',
        ':-webkit-autofill': { color: '' }
    },
    invalid: {
        color: '',
        iconColor: '',
        ':-webkit-autofill': { color: '' }
    }
}

export const COMPONENT_OPTIONS = {
    style: COMPONENT_STYLE,
    classes: {
        base: 'form-stripe-element form-control',
        focus: 'form-stripe-element--focus',
        disabled: 'form-stripe-element--disabled',
        complete: 'form-stripe-element--is-success',
        invalid: 'form-stripe-element--is-error',
        empty: 'form-stripe-element form-control'
    }
}