
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";
// import RateEdit from "./RateEdit.vue";
// import { Field } from "@/models/interfaces/Field";
import { namespace } from "vuex-class";
// import { Guid } from "@/utils/Guid";
// import ConfirmDeleteModal from "../ConfirmDeleteModal.vue";
// import { StringUtil } from "@/utils/StringUtil";
// import { formatNumber } from "@/utils/currencyUtils";
import RateTable from "./RateTable.vue";
// import { deleteRates, getRates } from "./RateService";
// import { Rate } from "./Rate";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { RateTable, ConfigurationNavMenu },
})
export default class Rates extends Vue {
  @PropertyStore.State
  public property!: Property;

   navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }

  // private loading = false;
  // private showEditModal = false;
  // private fields: Array<Field> = [];
  // private infoModal!: {
  //   id: "info-modal";
  //   title: string;
  //   content: string;
  // };
  // private selected!: Array<any>;
  // private clickedRate!: Rate | null;
  // private clickedIndex = 0;
  // private showConfirmModal = false;
  // private ratesTable: any;
  // private rates!: Array<Rate>;

  // formatNumber(num: number) {
  //   return formatNumber(num);
  // }

  // phoneFormatter(value: string) {
  //   const result = StringUtil.formatPhoneNumber(value);
  //   return result;
  // }

  // getReadableDate(dateToFormat: Date) {
  //   return new Date(dateToFormat).readableDateLocalized();
  // }

  // confirmDeleteRates() {
  //   this.showConfirmModal = true;
  // }
  // cancelDeletion() {
  //   this.showConfirmModal = false;
  // }

  // async deleteRates() {
  //   const arrayofRatesGuids = new Array<Guid>();
  //   this.selected.forEach((p) => {
  //     arrayofRatesGuids.push(p.rateGuid);
  //   });
  //   await deleteRates(arrayofRatesGuids);
  //   await this.retrieveRates();
  //   this.showConfirmModal = false;
  // }

  // selectAllRows() {
  //   this.ratesTable.selectAllRows();
  // }
  // clearSelected() {
  //   this.ratesTable.clearSelected();
  // }
  // selectThirdRow() {
  //   this.ratesTable.selectRow(2);
  // }
  // unselectThirdRow() {
  //   this.ratesTable.unselectRow(2);
  // }

  // selectMyRow(uniqueID) {
  //   const row = this.rates.findIndex((x) => x.rateGuid === uniqueID);
  //   this.ratesTable.selectRow(row);
  // }

  // selectedItemsCount() {
  //   return this.selected && this.selected.length > 0;
  // }

  // onRowSelected(items: any) {
  //   this.selected = items;
  //   this.$forceUpdate();
  // }

  // getActiveRate() {
  //   if (this.clickedRate) {
  //     return this.clickedRate;
  //   } else {
  //     return null;
  //   }
  // }
  // getActiveRateGuid() {
  //   if (this.clickedRate) {
  //     return this.clickedRate.rateGuid;
  //   } else {
  //     return null;
  //   }
  // }

  // onRowDoubleClicked(rate: Rate, index: any, event: any) {
  //   this.clickedRate = this.rates[index];
  //   this.showEditModal = true;
  // }

  // async saved(rate: Rate) {
  //   let index = this.rates.findIndex((p) => p.rateGuid == rate.rateGuid);
  //   this.rates[index] = rate;
  //   await this.ratesTable.refresh();
  //   this.ratesTable.selectRow(index);
  //   this.showEditModal = false;
  // }

  // cancel() {
  //   this.showEditModal = false;
  // }

  // addRate() {
  //   this.clickedRate = null;
  //   this.showEditModal = true;
  // }

  // async created() {
  //   await this.retrieveRates();
  // }

  // mounted() {
  //   this.ratesTable = this.$refs.ratesTableRef;
  //   this.getFields();
  // }

  // private getFields() {
  //   let field = new Field();
  //   field.key = "name";
  //   field.label = "Name";
  //   this.fields.push(field);

  //   field = new Field();
  //   field.key = "description";
  //   field.label = "Description";
  //   this.fields.push(field);
  // }

  // async retrieveRates() {
  //   this.loading = true;
  //   this.rates = await getRates(this.property.propertyGuid);
  //   this.loading = false;
  // }
}
