
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MaskedInput from "@/components/Controls/MaskedInput.vue";
import { savePreference } from "@/models/Preference";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { MaskedInput },
})
export default class PeopleSettings extends Vue {
  @PropertyStore.State
  public property!: Property;
  private busy = false;

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  async save() {
    //save the preference and property
    this.busy = true;
    await savePreference(this.property.preference);
    this.navigate("Settings");
    this.busy = false;
  }
}
