
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { getUnit, saveUnit } from "@/components/Cloud/Units/UnitService";
import { getAllUnitGroups } from "@/components/Cloud/Units/UnitGroupService";
import { getCategories } from "@/components/Marketplace/Category";
import { Unit, UnitTypes } from "@/components/Cloud/Units/Unit";
import { Document } from "../Documents/Document";
import ComboBox from "@/components/Controls/ComboBox.vue";
import { UnitGroup } from "@/models/UnitGroup";
import {
  deleteDocument,
  getDocumentsByPerson,
  getDocumentsByUnit,
  saveDocument,
  uploadDocument,
} from "../Documents/DocumentService";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import RateTable from "../Rates/RateTable.vue";
import AmenityTable from "../Amenity/AmenityTable.vue";
import {
  deleteAmenityInUnitsByUnit,
  getAmenities,
  getAmenitiesByUnit,
  saveAmenity,
  saveAmenityInUnits,
} from "../Amenity/AmenityService";
import { Amenity } from "../Amenity/Amenity";
import { AmenityInUnit } from "../Amenity/AmenityInUnit";
import AmenityModal from "../Amenity/AmenityModal.vue";
import { VueEditor } from "vue2-editor";
import Category from "@/components/Marketplace/Category";
import {
  deleteDeliveryOptionInUnitsByUnit,
  DeliveryOption,
  DeliveryOptionInUnit,
  getDeliveryOption,
  getDeliveryOptionsByProperty,
  getDeliveryOptions,
  saveDeliveryOption,
  saveDeliveryOptionInUnits,
} from "../DeliveryOptions/DeliveryOption";
import { formatter } from "@/utils/currencyUtils";
import {
  CustomQuestion,
  CustomQuestionInUnit,
  deleteQuestionInUnitsByUnit,
  getCustomQuestion,
  getCustomQuestions,
  getCustomQuestionsByProperty,
  saveCustomQuestion,
  saveQuestionInUnits,
} from "../CustomQuestions/CustomQuestion";
import { getCharge } from "../Invoices/ChargeService";
import { getChargeItems } from "../ChargeItems/ChargeItemService";
import { ChargeItem } from "../ChargeItems/ChargeItem";
import {
  AddOnInUnit,
  deleteAddOnInUnit,
  getAddOnsInUnit,
  saveAddOnInUnit,
} from "../ChargeItems/AddOnInUnit";
import ToolbarSettings from "../Settings/ToolbarSettings";
import CustomQuestionTable from "../CustomQuestions/CustomQuestionTable.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    ComboBox,
    ConfirmDeleteModal,
    RateTable,
    AmenityTable,
    AmenityModal,
    VueEditor,
    CustomQuestionTable,
  },
})
export default class UnitEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public paramUnitGuid!: string;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  public unitGuid!: string;
  private showEditModal = false;
  private loading = false;
  public unit!: Unit;
  private busy = false;
  private unitGroups: Array<UnitGroup> = [];
  private documents!: Array<Document>;
  private amenities!: Array<Amenity>;
  private selectedAmenities!: Array<Amenity>;
  private images = new Array<File>();
  private showLargeView = false;
  private currentDoc!: Document;
  private runAnimation = false;
  private showConfirmModal = false;
  private showNewAmenity = false;
  private expanded = false;
  private categories = new Array<Category>();
  private deliveryOptions = new Array<DeliveryOption>();
  private questions = new Array<CustomQuestion>();
  private unitType = "";
  private toolbar = new ToolbarSettings();
  private selectedDeliveryOptions = new Array<DeliveryOption>();
  private showNewDeliveryOption = false;
  private selectedQuestions = new Array<CustomQuestion>();
  private showNewQuestion = false;
  private chargeItems = new Array<ChargeItem>();
  private addOnInUnits = new Array<AddOnInUnit>();
  private showQuestionModal = false;

  IsRental() {
    return this.unitType == UnitTypes.Rental;
  }

  formatCurrency(num: number) {
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  async unitTypeChanged(unitType: string) {
    this.unit.unitType = unitType;
    this.unitType = unitType;
  }

  ///
  ////Custom Questions
  ////

  allQuestionsOrNone() {
    if (this.allQuestionsAreChecked()) {
      return "None";
    } else {
      return "All";
    }
  }

  allAddOnsAreChecked() {
    return this.chargeItems.every((c) => c.checked);
  }

  allQuestionsAreChecked() {
    let result = this.questions.every((a) => a.checked);
    return result;
  }

  async selectAllQuestions() {
    this.busy = true;
    if (this.allQuestionsAreChecked()) {
      await this.questions.forEach((a) => {
        a.checked = false;
      });
    } else {
      await this.questions.forEach((a) => {
        a.checked = true;
      });
    }

    this.busy = false;
  }
  async selectAllAddOns() {
    this.busy = true;
    if (this.allAddOnsAreChecked()) {
      await this.chargeItems.forEach((a) => {
        a.checked = false;
      });
    } else {
      await this.chargeItems.forEach((a) => {
        a.checked = true;
      });
    }

    this.busy = false;
  }

  async saveNewQuestion(a: CustomQuestion) {
    this.busy = true;
    a.checked = true;
    await saveCustomQuestion(a);
    await this.questions.push(a);
    this.busy = false;
    this.showNewQuestion = false;
  }

  async refreshQuestions() {
    this.questions = await getCustomQuestions(this.unit.unitGuid);
  }

  async addAnotherQuestionOption() {
    // show the question table in a modal
    this.showQuestionModal = true;
  }

  changedQuestion(question: CustomQuestion) {
    if (question.checked) {
      this.selectedQuestions.push(question);
    }
  }

  ///
  ////Delivery Options
  ////

  allDeliveryOptionsOrNone() {
    if (this.allDeliveryOptionsAreChecked()) {
      return "None";
    } else {
      return "All";
    }
  }

  allOrNoneAddOns() {
    if (this.addAddOnsAreChecked()) {
      return "None";
    } else {
      return "All";
    }
  }

  allDeliveryOptionsAreChecked() {
    let result = this.deliveryOptions.every((a) => a.checked);
    return result;
  }

  addAddOnsAreChecked() {
    let result = this.chargeItems.every((a) => a.checked);
    return result;
  }

  async selectAllDeliveryOptions() {
    this.busy = true;
    if (this.allDeliveryOptionsAreChecked()) {
      await this.deliveryOptions.forEach((a) => {
        a.checked = false;
      });
    } else {
      await this.deliveryOptions.forEach((a) => {
        a.checked = true;
      });
    }

    this.busy = false;
  }

  async saveNewDeliveryOption(a: DeliveryOption) {
    this.busy = true;
    a.checked = true;
    await saveDeliveryOption(a);
    await this.deliveryOptions.push(a);
    this.busy = false;
    this.showNewDeliveryOption = false;
  }

  async addAnotherDeliveryOption() {
    this.$router.push("DeliveryOptions");
  }

  changedDeliveryOption(deliveryOption: DeliveryOption) {
    if (deliveryOption.checked) {
      this.selectedDeliveryOptions.push(deliveryOption);
    }
  }

  allOrNone() {
    if (this.allAreChecked()) {
      return "None";
    } else {
      return "All";
    }
  }

  allAreChecked() {
    let result = this.amenities.every((a) => a.checked);
    return result;
  }

  async selectAllAmenities() {
    this.busy = true;
    if (this.allAreChecked()) {
      await this.amenities.forEach((a) => {
        a.checked = false;
      });
    } else {
      await this.amenities.forEach((a) => {
        a.checked = true;
      });
    }

    this.busy = false;
  }

  async saveNewAmenity(a: Amenity) {
    this.busy = true;
    a.checked = true;
    await saveAmenity(a);
    await this.amenities.push(a);
    this.busy = false;
    this.showNewAmenity = false;
  }

  addAnotherAddOn() {
    this.$router.push("ChargeItems");
  }

  async addAnotherAmenity() {
    this.showNewAmenity = true;
  }

  changedAmenity(amenity: Amenity) {
    if (amenity.checked) {
      this.selectedAmenities.push(amenity);
    }
  }

  isEnabled(amenity: Amenity) {
    let found = this.selectedAmenities.find(
      (a) => a.amenityGuid === amenity.amenityGuid
    );
    if (!found) {
      return false;
    }
    return true;
  }

  isDeliveryOptionEnabled(opt: DeliveryOption) {
    let found = this.selectedDeliveryOptions.find(
      (a) => a.deliveryOptionGuid === opt.deliveryOptionGuid
    );
    if (!found) {
      return false;
    }
    return true;
  }

  isQuestionEnabled(q: CustomQuestion) {
    let found = this.selectedQuestions.find(
      (a) => a.customQuestionGuid === q.customQuestionGuid
    );
    if (!found) {
      return false;
    }
    return true;
  }

  isAddOnEnabled(c: ChargeItem) {
    let found = this.addOnInUnits.find(
      (a) => a.chargeItemGuid === c.chargeItemGuid
    );
    if (!found) {
      return false;
    }
    return true;
  }

  async saveAmenities() {
    // take all the amenties that are selected and create Amenity in units and then save the array
    let aius = new Array<AmenityInUnit>();
    this.amenities.forEach((amenity) => {
      if (amenity.checked) {
        let aiu = new AmenityInUnit();
        aiu.propertyGuid = this.property.propertyGuid;
        aiu.unitGuid = this.unit.unitGuid;
        aiu.amenityGuid = amenity.amenityGuid;
        aius.push(aiu);
      }
    });
    await deleteAmenityInUnitsByUnit(this.unit.unitGuid);
    await saveAmenityInUnits(aius);
  }

  async saveDeliveryOptions() {
    // take all the amenties that are selected and create Amenity in units and then save the array
    let dius = new Array<DeliveryOptionInUnit>();
    this.deliveryOptions.forEach((opt) => {
      if (opt.checked) {
        let diu = new DeliveryOptionInUnit();
        diu.unitGuid = this.unit.unitGuid;
        diu.deliveryOptionGuid = opt.deliveryOptionGuid;
        dius.push(diu);
      }
    });
    await deleteDeliveryOptionInUnitsByUnit(this.unit.unitGuid);
    await saveDeliveryOptionInUnits(dius);
  }

  async saveCustomQuestions() {
    // take all the amenties that are selected and create Amenity in units and then save the array
    let qius = new Array<CustomQuestionInUnit>();
    this.questions.forEach((q) => {
      if (q.checked) {
        let qiu = new CustomQuestionInUnit();
        qiu.unitGuid = this.unit.unitGuid;
        qiu.customQuestionGuid = q.customQuestionGuid;
        qius.push(qiu);
      }
    });
    await deleteQuestionInUnitsByUnit(this.unit.unitGuid);
    await saveQuestionInUnits(qius);
  }

  async saveAddOns() {
    this.chargeItems.forEach(async (q) => {
      if (q.checked) {
        await saveAddOnInUnit(this.unit.unitGuid, q.chargeItemGuid);
      } else {
        await deleteAddOnInUnit(this.unit.unitGuid, q.chargeItemGuid);
      }
    });
  }

  expand() {
    // navigate to invoice edit page with invoice guid as param
    this.navigate("UnitEdit", this.unit.unitGuid.toString());
  }

  navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }

  validateMaxGuests() {
    return null;
  }
  validateMinGuests() {
    return null;
  }
  validateMaxNights() {
    return null;
  }
  validateMinNights() {
    return null;
  }

  handleCloseClick() {
    this.showLargeView = false;
    this.runAnimation = false;
  }

  clickedDeleteDoc(doc: Document) {
    // warn
    this.currentDoc = doc;
    this.showConfirmModal = true;
  }

  async addAnother() {
    this.busy = true;
    this.loading = true;
    await this.save(false);
    await this.createNew();
    this.busy = false;
    this.loading = false;
  }

  private createNew() {
    this.unit = new Unit();
    this.unit.unitName = "";
    this.unit.unitGuid = Guid.newGuid();
    this.unit.propertyGuid = this.property.propertyGuid;
    this.unit.createdDate = new Date();
  }

  async confirmDeleteDocument() {
    //do the delete
    this.busy = true;
    let result = await deleteDocument(this.currentDoc.documentGuid);
    //refresh
    if (result) {
      const index = await this.documents.indexOf(this.currentDoc, 0);
      if (index > -1) {
        await this.documents.splice(index, 1);
      }
    }

    this.busy = false;
    this.showConfirmModal = false;
  }

  cancelDeletion() {
    this.showConfirmModal = false;
  }

  setCurrent(doc: Document) {
    this.currentDoc = doc;
    this.showLargeView = true;
    this.runAnimation = true;
  }

  async imagesUploaded(files: Array<File>) {
    this.busy = true;
    let newdocs = Array<Document>();
    var formData = new FormData();
    for (var i = 0; i != files.length; i++) {
      let doc = new Document();
      doc.documentGuid = Guid.newGuid();
      doc.unitGuid = this.unit.unitGuid;
      doc.propertyGuid = this.property.propertyGuid;
      doc.createdDate = new Date();
      doc.path = files[i].name;
      await saveDocument(doc);
      newdocs.push(doc);
      let extension = doc.path.slice(-4);
      formData.append("files", files[i], doc.documentGuid + extension);
    }

    console.log(formData);
    await uploadDocument(formData);
    await newdocs.forEach((d) => {
      this.documents.push(d);
    });
    this.busy = false;
  }

  getImageUrl(doc: Document) {
    if (!doc) {
      return "";
    }
    return (
      "https://s3.us-east-2.amazonaws.com/service.lodgevault/" +
      doc.documentGuid.toString() +
      ".jpg"
    );
  }

  validateName() {
    return true;
  }

  public cancel() {
    // if we're editing in expanded mode, go to the list of units in the config section
    if (this.expanded == true) {
      this.navigate("Units", "");
    } else {
      this.$emit("close");
    }
    // otherwise, just close the modal with an even
  }

  getUnit() {
    return this.unit;
  }

  async mounted() {
    this.loading = true;
    this.expanded = false;
    this.categories = await getCategories();
    if (this.paramUnitGuid) {
      this.unitGuid = this.paramUnitGuid;
    } else if (this.$route.params.id) {
      this.unitGuid = this.$route.params.id.toString();
      this.expanded = true;
    } else if (this.$route.query.unitGuid) {
      this.unitGuid = this.$route.query.unitGuid.toString();
    }
    if (this.unitGuid) {
      this.unit = await getUnit(this.unitGuid.toString());
    }
    if (!this.unit) {
      this.createNew();
    }

    this.chargeItems = await getChargeItems(this.property.propertyGuid);
    this.addOnInUnits = await getAddOnsInUnit(this.unit.unitGuid);
    await this.chargeItems.forEach((a) => {
      a.checked = this.isAddOnEnabled(a);
    });
    this.unitGroups = await getAllUnitGroups(this.property.propertyGuid);
    this.deliveryOptions = await getDeliveryOptionsByProperty(
      this.property.propertyGuid
    );
    this.questions = await getCustomQuestionsByProperty(
      this.property.propertyGuid
    );
    this.documents = await getDocumentsByUnit(
      this.unit.unitGuid
    );
    this.amenities = await getAmenities(this.property.propertyGuid);

    this.selectedQuestions = await getCustomQuestions(this.unit.unitGuid);
    await this.questions.forEach((a) => {
      a.checked = this.isQuestionEnabled(a);
    });

    this.selectedAmenities = await getAmenitiesByUnit(this.unit.unitGuid);
    this.selectedDeliveryOptions = await getDeliveryOptions(this.unit.unitGuid);
    await this.deliveryOptions.forEach((a) => {
      a.checked = this.isDeliveryOptionEnabled(a);
    });
    await this.amenities.forEach((a) => {
      a.checked = this.isEnabled(a);
    });
    if (this.documents) {
      this.currentDoc = this.documents[0];
    }
    this.unitType = this.unit.unitType;
    this.loading = false;
  }

  async save(close: boolean) {
    this.busy = true;
    await this.saveAmenities();
    await this.saveDeliveryOptions();
    await this.saveCustomQuestions();
    await this.saveAddOns();
    await saveUnit(this.unit);
    this.$emit("success", this.unit);
    if (close && close == true) {
      this.$emit("close");
    }
    if (this.expanded) {
      this.navigate("Units", "");
    }
    this.busy = false;
  }
}
