import { roundTo } from "@/utils/currencyUtils";
import { IInvoiceItem } from "./IInvoiceItem";
import { InvoiceItemKind } from "./Invoice";

export enum PaymentType {
    Check,
    Cash,
    CreditCard,
    Other
}

export class Payment implements IInvoiceItem {
    constructor() {
    }
    public itemGuid!: string;
    public isEdit!: boolean;
    public quantity!: number;
    public invoiceGuid!: string;
    public paymentGuid!: string;
    public reservationGuid!: string;
    public personGuid!: string;
    public itemName!: string;
    public takenBy!: string;
    public preTaxPrice!: number;
    public discount!: number;
    /// Type is Credit card, cash, check, others. Defined by Payment.PaymentType enum
    public type!: number;
    public chargeDate!: Date;
    public postingDate!: Date;
    public preTaxSubTotal!: number;
    public exported!: number;
    public kind!: InvoiceItemKind;
    public createdDate!: Date;
    public modifiedDate!: Date;
    public stripeId!: string;
    public totalPrice!: number;
    public stripeFee!: number;
    public applicationFee!: number;
    public cardStripeId!: string;

    // not persisted
    public ccKind!: number;
    public ccNumber!: string;
    public ccExpDate!: Date;
    public ccNameOnCard!: string;
    public ccCvc!: string;
}


export function calculateStripeFee(payment: Payment): number {

    // formula
    //($100.00 + $0.30) / (1 - 2.9 %) = $100.30 / 0.971 = $103.30.
    if (payment.kind != InvoiceItemKind.Payment && payment.kind != InvoiceItemKind.Deposit){
        return 0;
    }
    if (payment.totalPrice == 0) {
        return 0;
    }
    var totalPrice = Math.abs(payment.totalPrice);
    totalPrice = roundTo((totalPrice), 2);
    var totalAmountToCharge = (totalPrice + 0.3) / 0.971;
    totalAmountToCharge = roundTo(totalAmountToCharge, 2);
    var stripeFee = totalAmountToCharge - totalPrice;
    stripeFee = Math.abs(roundTo(stripeFee, 2));
    if (isNaN(stripeFee)) {
        stripeFee = 0;
    }
    return stripeFee;
}


