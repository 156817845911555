
import Property from "@/models/Property";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CartButton from "../components/Cart/CartButton.vue";
import Breadcrumb from "../components/SiteBook/Breadcrumb.vue";
import { AvailabilityParameters } from "@/models/AvailabilityParameters";
import CloseModal from "@/components/SiteBook/CloseModal.vue";
import { getConfirmation } from "@/components/Cloud/Reservations/ReservationService";
import { Guid } from "@/utils/Guid";
import { getUnit } from "@/components/Cloud/Units/UnitService";
import ToggleNavigation from "@/components/SiteBook/ToggleNavigation.vue";

const cartStore = namespace("CartStore");
const PropertyStore = namespace("PropertyStore");
const AvailabilityParametersStore = namespace("AvailabilityParametersStore");

@Component({
  components: {
    CartButton,
    Breadcrumb,
    CloseModal,
    ToggleNavigation,
  },
})
class LayoutBookNow extends Vue {
  private loading = true;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;

  getLogo() {
    if (
      this.property.logo &&
      this.property.imageGuid &&
      this.property.imageGuid != Guid.emptyGuid()
    ) {
      return this.property.logo;
    } else {
      return false;
    }
  }

  propertyName() {
    if (!this.property) {
      return "";
    }
    return this.property.name;
  }

  onWindowResized() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  async created() {
    this.loading = true;
    let tempPropGuid = "";
    if (localStorage.getItem("propertyGuid")) {
      tempPropGuid = localStorage.getItem("propertyGuid")!.toString();
    }
    if (this.$route.query.unit) {
      const unitGuid = this.$route.query.unit.toString();
      let unit = await getUnit(unitGuid);
      if (unit) {
        if (tempPropGuid != unit.propertyGuid) {
          localStorage.setItem("propertyGuid", unit.propertyGuid);
          tempPropGuid = unit.propertyGuid;
          window.location.reload();
        }
      }
    }
    if (this.$route.query.req) {
      const reqReservationGuid = this.$route.query.req.toString();
      getConfirmation(reqReservationGuid).then((data) => {
        const confModel = data;

        if (tempPropGuid != confModel.propertyGuid) {
          localStorage.setItem("propertyGuid", confModel.propertyGuid);
          tempPropGuid = confModel.propertyGuid;
          window.location.reload();
        }
      });
    }
    await this.getProperty(tempPropGuid);
    this.scrollToTop();
  }

  async getProperty(propGuid: string) {
    if (propGuid === "") {
      return;
    }
    this.getPropertyFromServer(propGuid)
      .then((p) => {
        this.setProperty(p);
        localStorage.setItem("propertyGuid", p.propertyGuid);

        if (p.name) {
          document.title = p.name;
        }
        this.availParams.propertyGuid = p.propertyGuid;
        this.set(this.availParams);
      })
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => (this.loading = false));
  }

  scrollToTop() {
    const myDiv = document.getElementById("book-wrap");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  closeParentiFrame(stay: boolean) {
    this.showOrHideCloseModal();
    if (!stay) {
      window.parent.postMessage('{ "type": "lodgevault.close" }', "*");
      window.postMessage('{ "type": "lodgevault.close" }', "*");
    }
  }

  @PropertyStore.State
  public property!: Property;

  @PropertyStore.State
  public defaultPropertyGuid!: string;

  @PropertyStore.Action
  public setProperty!: (prop: Property) => void;

  @AvailabilityParametersStore.Action
  public set!: (params: AvailabilityParameters) => Promise<boolean>;

  @AvailabilityParametersStore.State
  private availParams!: AvailabilityParameters;

  @PropertyStore.Action
  public getPropertyFromServer!: (propGuid: string) => Promise<Property>;

  @cartStore.State
  public showPopupCloseModal!: boolean;

  @cartStore.Action
  public showOrHideCloseModal!: () => void;
}
export default LayoutBookNow;
