
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class EditorToolbar extends Vue {
  erase() {
  }
  // data () {
  //   let {btns, selects} = getToolbar()
  //   return {
  //     btns,
  //     selects,
  //     lang: getLang(),
  //     config: getConfig('toolbar')
  //   }
  // },
  // computed: {
  //   states: function () {
  //     return this.$store.state.toolbar
  //   },
  //   view: function () {
  //     return this.$store.state.view
  //   }
  // },
  // watch: {
  //   'view': function (val) {
  //     let states = {}
  //     let exArr = ['sourceCode', 'markdown', 'fullscreen', 'divider', '|']
  //     this.config.forEach(item => {
  //       if (exArr.indexOf(item) === -1) {
  //         states[item] = val === 'design' ? 'default' : 'disabled'
  //       }
  //     })
  //     this.$store.dispatch('updateButtonStates', states)
  //   }
  // },
  // methods: {
  //   btnHandler (event, name) {
  //     if (this.states[name].status === 'disabled') return
  //     let btn = this.btns[name]
  //     if (btn.action) {
  //       if (btn.native) {
  //         this.$store.dispatch('execCommand', { name: name, value: null })
  //       } else {
  //         this.$store.dispatch('callMethod', { name: name, params: null })
  //       }
  //     }
  //     this.updateStates(name)
  //     this.showPopup(name, event.currentTarget)
  //   },
  //   selectHandler (event, name) {
  //     if (this.states[name].status === 'disabled') return
  //     this.updateStates(name)
  //     this.showPopup(name, event.currentTarget)
  //   },
  //   showPopup (name, obj) {
  //     this.$store.dispatch('updatePopupDisplay', this.states[name].showPopup !== undefined ? {name, display: !this.states[name].showPopup} :  {} )
  //     this.$store.dispatch('updateRect', {
  //       left: obj.offsetLeft,
  //       top: obj.offsetTop,
  //       width: obj.offsetWidth,
  //       height: obj.offsetHeight + parseInt(window.getComputedStyle(obj).marginBottom)
  //     })
  //   },
  //   updateStates (name) {
  //     let states = {}
  //     // update no action btn status, no action means click on it will toggle a popover menu;
  //     if (this.view === 'design') {
  //       for (let item in this.btns) {
  //         if (!this.btns[item].action && this.states[item] && this.states[item].status === 'actived') {
  //           states[item] = 'default'
  //         }
  //       }
  //     }
  //     if (['sourceCode', 'markdown'].indexOf(name) !== -1) {
  //       this.states['sourceCode'] && (states['sourceCode'] = 'default')
  //       this.states['markdown'] && (states['markdown'] = 'default')
  //     }
  //     this.states[name].status === 'actived' ? states[name] = 'default' : states[name] = 'actived'
  //     this.$store.dispatch('updateButtonStates', states)
  //   }
  // }
}
