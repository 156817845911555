import Vuex from 'vuex';
import Vue from 'vue';
import UnitGroupStore from './modules/UnitGroupStore';
import UnitStore from './modules/UnitStore';
import globalvalues from './modules/globalvalues';
import CartStore from './modules/CartStore';
import PropertyStore from './modules/PropertyStore';
import ChargeItemStore from './modules/ChargeItemStore';
import AvailabilityParametersStore from './modules/AvailabilityParametersStore';
import AuthStore from "@/components/Cloud/Auth/AuthStore";
import AddReservationStore from './modules/AddReservationStore';
import SourceStore from '@/components/Cloud/Source/Source';

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
    modules: {
        globalvalues,
        UnitGroupStore,
        UnitStore,
        CartStore,
        PropertyStore,
        SourceStore,
        ChargeItemStore,
        AvailabilityParametersStore,
        AuthStore,
        AddReservationStore,
    }
});
