
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatPercentage, formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { TimeSlotCollection } from "./TimeSlotCollection";
import TimeSlotCollectionEdit from "./TimeSlotCollectionEdit.vue";
import {
  deleteTimeSlotCollection,
  getTimeSlotCollections,
  saveTimeSlotCollection,
} from "./TimeSlotCollection";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { TimeSlotCollectionEdit, ConfirmDeleteModal } })
export default class TimeSlotCollectionTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  private loading = true;
  private showTimeSlotCollectionModal = false;
  private timeSlotCollections: Array<TimeSlotCollection> = [];
  private timeSlotCollectionFields: Array<Field> = [];
  private clickedTimeSlotCollection!: TimeSlotCollection | null;
  private timeSlotCollectionTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;

  async add() {
    this.clickedTimeSlotCollection = null;
    this.showTimeSlotCollectionModal = true;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getTaxRateFormat(amount: number) {
    return formatPercentage(amount, false);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.timeSlotCollectionTable.selectAllRows();
  }
  clearSelected() {
    this.timeSlotCollectionTable.clearSelected();
  }

  async saved(timeSlotCollection: TimeSlotCollection) {
    this.loading = true;
    // if the list doesn't contain the timeSlotCollection, add it
    let found = this.timeSlotCollections.find(
      (a) =>
        a.timeSlotCollectionGuid === timeSlotCollection.timeSlotCollectionGuid
    );
    if (!found) {
      await this.timeSlotCollections.push(timeSlotCollection);
    }
    let index = await this.timeSlotCollections.findIndex(
      (a) =>
        a.timeSlotCollectionGuid === timeSlotCollection.timeSlotCollectionGuid
    );
    this.timeSlotCollections[index] = timeSlotCollection;
    await this.timeSlotCollectionTable.refresh();
    this.timeSlotCollectionTable.selectRow(index);
    this.showTimeSlotCollectionModal = false;
    this.loading = false;
  }

  private getTimeSlotCollectionFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Time Slot Collection Name";
    this.timeSlotCollectionFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.timeSlotCollectionTable = this.$refs.timeSlotCollectionTableRef;
    this.getTimeSlotCollectionFields();
    if (this.property && this.property.propertyGuid) {
      this.timeSlotCollections = await getTimeSlotCollections(
        this.property.propertyGuid
      );
    } else {
      this.timeSlotCollections = new Array<TimeSlotCollection>();
    }
    this.loading = false;
  }

  getActiveTimeSlotCollection() {
    if (this.clickedTimeSlotCollection) {
      return this.clickedTimeSlotCollection.timeSlotCollectionGuid;
    }
  }

  confirmDelete() {
    this.showConfirmModal = true;
  }

  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async deleteTimeSlotCollections() {
    if (this.selected && this.selected.length > 0) {
      for (const collection of this.selected) {
        let result = await deleteTimeSlotCollection(
          collection.timeSlotCollectionGuid.toString()
        );
        if (result) {
          if (this.timeSlotCollections) {
            let index = this.timeSlotCollections.findIndex(
              (a) =>
                a.timeSlotCollectionGuid === collection.timeSlotCollectionGuid
            );
            this.timeSlotCollections.splice(index, 1);
          }
        }
      }
      this.showConfirmModal = false;
    }

    await this.timeSlotCollectionTable.refresh();
  }

  edit(timeSlotCollection: any, index: any, event: any) {
    if (timeSlotCollection) {
      this.clickedTimeSlotCollection = timeSlotCollection;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedTimeSlotCollection = this.selected[0];
    }
    this.showTimeSlotCollectionModal = true;
  }

  closeTimeSlotCollectionModal() {
    this.showTimeSlotCollectionModal = false;
  }
}
