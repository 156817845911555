
import CartButton from "@/components/Cart/CartButton.vue";
import RegisterGuestModal from "@/components/Cloud/Auth/RegisterGuestModal.vue";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import LoginModal from "../Auth/LoginModal.vue";
import { User } from "../Auth/User";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");

@Component({
  components: { CartButton, RegisterGuestModal, LoginModal },
})
class MpHeader extends Vue {
  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.State("user")
  private currentUser!: User;

  @Auth.Action
  private signOut!: () => void;

  private loading = true;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;

  flipToSignUp() {
    this.$bvModal.hide("modal-login");
    this.$bvModal.show("modal-guest-register");
  }
  flipToLogIn() {
    this.$bvModal.hide("modal-guest-register");
    this.$bvModal.show("modal-login");
  }

  navigateToNewTab(page: string) {
    window.open(page, "_blank");
  }

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  getUser() {
    if (this.currentUser) {
      return this.currentUser;
    } else {
      return null;
    }
  }

  logOut() {
    this.signOut();
    // this.$router.push({
    //   name: "Login",
    // });
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  created() {
    this.loading = true;
    this.loading = false;
  }
}
export default MpHeader;
