
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MaskedInput from "@/components/Controls/MaskedInput.vue";
import { savePreference } from "@/models/Preference";
import { DepositMethod } from "../Reservations/DepositMethod";
import ToolbarSettings from "../Settings/ToolbarSettings";
import { VueEditor } from "vue2-editor";
import { saveProperty } from "./PropertyService";
import { saveCheckoutSettings } from "@/models/CheckoutSettings";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { MaskedInput, VueEditor },
})
export default class ReservationSettings extends Vue {
  @PropertyStore.State
  public property!: Property;
  private busy = false;
  private depositMethods: Array<any> = [];
  private toolbar = new ToolbarSettings();
  private terms = "";

  private defaultArrivalTime: string | undefined = "00:00:00";
  private defaultDepartureTime: string | undefined = "00:00:00";

  arrivalTimeChanged(arg: any) {
    this.defaultArrivalTime = arg.toString();
  }
  departureTimeChanged(arg: any) {
    this.defaultDepartureTime = arg.toString();
  }

  depositAmountChanged(arg: any) {
    this.property.defaultDepositAmount = arg;
  }

  async mounted() {
    await this.getDepositMethods();
    if (this.property.checkoutSettings.cancellationPolicy) {
      this.terms = this.property.checkoutSettings.cancellationPolicy;
    }
    this.defaultArrivalTime = new Date(this.property.defaultArrivalTime).time(
      false
    );
    this.defaultDepartureTime = new Date(this.property.defaultDepartureTime).time(
      false
    );
  }

  async getDepositMethods() {
    for (var method in DepositMethod) {
      var isValueProperty = Number(method) >= 0;
      if (isValueProperty) {
        this.depositMethods.push({
          id: Number(method),
          name: DepositMethod[method],
        });
      }
    }
  }

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  async save() {
    //save the preference and property
    this.busy = true;
    if (this.defaultArrivalTime) {
      this.property.defaultArrivalTime = new Date(
        new Date(this.property.defaultArrivalTime).toDateString() +
          " " +
          this.defaultArrivalTime
      );
    }
    if (this.defaultDepartureTime) {
      this.property.defaultDepartureTime = new Date(
        new Date(this.property.defaultDepartureTime).toDateString() +
          " " +
          this.defaultDepartureTime
      );
    }
    this.property.checkoutSettings.cancellationPolicy = this.terms;
    await saveProperty(this.property);
    await savePreference(this.property.preference);
    await saveCheckoutSettings(this.property.checkoutSettings);
    this.navigate("Settings");
    this.busy = false;
  }
}
