
import PasswordInput from "@/components/Controls/PasswordInput.vue";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "./User";
const AuthStore = namespace("AuthStore");

@Component({ components: { PasswordInput } })
export default class Login extends Vue {
  private user: any = { email: "", password: "" };
  private errors!: Array<string>;
  private showProgressOverlay = false;
  private submitClicked = false;

  @AuthStore.Getter
  private isLoggedIn!: boolean;

  @AuthStore.Action
  private login!: (data: any) => Promise<any>;

  created() {
    this.errors = [];
    // if (this.isLoggedIn) {
    //   this.$router.push("/profile");
    // }

    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
      }

      if (e.key === "Enter") {
        this.handleLogin();
      }
    });
  }

  updatePassword(val) {
    this.user.password = val;
  }

  validateEmail() {
    if (this.submitClicked) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.user.email);
    }
    return null;
  }

  validate(): boolean {
    this.errors = [];
    if (!this.user.email) {
      this.errors.push("Please enter a valid email address");
    }
    if (!this.user.password) {
      this.errors.push("Please enter a password");
    }
    //check that both fields are filled out
    //maybe check that the email address is a real email address via regex
    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }

  navigateToSignUp() {
    this.$emit("flip-to-signup");
  }

  navigateToForgotPassword() {
    this.$bvModal.hide("modal-guest-register");
    this.$bvModal.hide("modal-login");
    this.$router.push({
      name: "forgotpassword",
      query: { email: this.user.email },
    });
  }

  async handleLogin() {
    this.submitClicked = true;
    this.showProgressOverlay = true;
    if (this.validate()) {
      await this.login(this.user).then(
        (response) => {
          if (response && response.data && response.data.error) {
            this.showProgressOverlay = false;
            this.errors.push(response.data.error);
          } else {
            this.$emit("login-successful");
          }
        },
        (error) => {
          this.errors.push(error);
          this.showProgressOverlay = false;
        }
      );
    } else {
      this.showProgressOverlay = false;
    }
  }
}
