
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import SearchBar from "./SearchBar.vue";
const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({
  components: { SearchBar },
})
export default class Home extends Vue {}
