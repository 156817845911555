
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AddOn } from "../ChargeItems/AddOn";
import { DeliveryOption } from "../DeliveryOptions/DeliveryOption";
import { Reservation } from "./Reservation";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class RentalDetails extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: null }) public res!: Reservation;

  @Watch("res", { immediate: false, deep: true })
  onDateChanged(val: Reservation, oldValue: Reservation) {
    this.res = val;
  }

  formatCurrency(num: number) {
    if (isNaN(num)) {
      return "0.00";
    }

    return formatter(num, this.property.currencyCode);
  }
}
