import AddOnInRes from "../ChargeItems/AddOnInRes";
import DeliveryOptionInRes from "../DeliveryOptions/DeliveryOptionInRes";

export class Assign {
    public assignGuid!: string;
    public propertyGuid!: string;
    public inDateTime!: Date;
    public inDateTimeIso!: string;
    public outDateTime!: Date;
    public reservationGuid!: string;
    public unitGuid!: string;
    public outOfServiceGuid!: string;
    public createdDate!: Date;
    public modifiedDate!: Date;
    public unitName: String = "";
    public nights: Number = 0;
    public positionIndex: number = 0;

    //rental fields
    public unitType!: string;
    public addOns!: Array<AddOnInRes>;
    public deliveryOptions!: Array<DeliveryOptionInRes>;
}