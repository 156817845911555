
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Unit } from "@/components/Cloud/Units/Unit";
import { getUnits } from "../Cloud/Units/UnitService";
import DatePicker from "./DatePicker.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    DatePicker,
  },
})
class SiteBook extends Vue {
  @PropertyStore.Action
  public setProperty!: (prop: Property) => void;

  @PropertyStore.State
  public property!: Property;

  @PropertyStore.Action
  public getPropertyFromServer!: (propGuid: string) => Promise<Property>;

  private loading = false;
  private propertyGuid!: string;
  private errors!: Array<string>;
  private arrival: Date = new Date().addDays(7);
  private adults = 2;
  private nights = 2;
  private children = 0;
  private isLocal = window.location.href.indexOf("localhost") > -1;
  private baseUrl = this.isLocal
    ? "http://localhost:8080/datesearch"
    : "https://book.lodgevault.com/datesearch";
  private units: Array<Unit> = [];
  private nightCounts: Array<number> = [];
  private guestCounts: Array<number> = [];
  private maxNights = 0;
  private minNights = 0;
  private maxGuests = 0;
  private minGuests = 0;
  private nightsCountCeiling = 100;

  clickedSubmit() {
    //see if window parent has a link to the embed script. if so, do the message, else
    //just open a new tab
    window.parent.postMessage(
      `{ "type": "lodgevault.open", "embedId": "sitebook", "url": "${this.getUrl()}" }`,
      "*"
    );
  }

  async created() {
    this.loading = true;
    if (this.$route.query.property) {
      this.propertyGuid = this.$route.query.property.toString();
    }
    if (this.$route.query.propertyGuid) {
      this.propertyGuid = this.$route.query.propertyGuid.toString();
    }
    await this.getProperty(this.propertyGuid);

    this.units = await getUnits(this.propertyGuid);

    let maximumNightsArray = this.units.map((o) => o.maximumNights);
    let minimumNightsArray = this.units.map((o) => o.minimumNights);
    let maximumGuestsArray = this.units.map((o) => o.capacity);
    this.maxNights = Math.max(...maximumNightsArray);
    // putting in a ceiling of 30 days ?? make it more??
    if (this.maxNights > this.nightsCountCeiling) {
      this.maxNights = this.nightsCountCeiling;
    }
    this.minNights = Math.min(...minimumNightsArray);
    this.maxGuests = Math.max(...maximumGuestsArray);
    this.minGuests = 1;
    for (let i = this.minNights; i <= this.maxNights; i++) {
      this.nightCounts.push(i);
    }
    for (let i = this.minGuests; i <= this.maxGuests; i++) {
      this.guestCounts.push(i);
    }
  }

  async getProperty(propGuid: string) {
    if (propGuid === "") {
      return;
    }
    this.getPropertyFromServer(propGuid)
      .then((p) => {
        this.setProperty(p);
        localStorage.setItem("propertyGuid", p.propertyGuid);

        if (p.name) {
          document.title = p.name;
        }
      })
      .catch((ex) => {
        console.error(ex);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get adultCount() {
    return this.adults;
  }
  set adultCount(value: number) {
    this.adults = Number(value);
  }
  get childCount() {
    return this.children;
  }
  set childCount(value: number) {
    this.children = Number(value);
  }
  get nightCount() {
    return this.nights;
  }
  set nightCount(value: number) {
    this.nights = Number(value);
  }

  getUrl() {
    this.arrival =
      new Date(this.arrival).toString() !== "" ? this.arrival : new Date();
    const newQuery =
      this.baseUrl +
      "?property=" +
      this.propertyGuid +
      "&ArrivalDate=" +
      this.arrival +
      "&NumberOfNights=" +
      this.nights +
      "&PeopleCount1=" +
      this.adults +
      "&PeopleCount2=" +
      this.children;
    return newQuery;
  }

  getButtonText() {
    return this.arrival ? new Date(this.arrival).toDateString() : "";
  }

  datePickedHandler(datePicked: Date) {
    this.arrival = datePicked;
  }

  isValid() {
    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }
}
export default SiteBook;
