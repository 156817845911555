
import { Field } from "@/models/interfaces/Field";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { Amenity } from "./Amenity";
import AmenityModal from "./AmenityModal.vue";
import {
  deleteAmenity,
  getAmenities,
  getAmenitiesByUnit,
  saveAmenity,
} from "./AmenityService";

@Component({ components: { AmenityModal, ConfirmDeleteModal } })
export default class AmenityTable extends Vue {
  @Prop({ default: null }) public unitGuid!: string;
  @Prop({ default: null }) public propertyGuid!: string;
  private loading = true;
  private showAmenityModal = false;
  private amenities: Array<Amenity> = [];
  private amenityFields: Array<Field> = [];
  private clickedAmenity!: Amenity | null;
  private amenityTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;

  cancelDeletion() {
    this.showAmenityModal = false;
  }

  async add() {
    this.clickedAmenity = null;
    this.showAmenityModal = true;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.amenityTable.selectAllRows();
  }
  clearSelected() {
    this.amenityTable.clearSelected();
  }

  async saved(amenity: Amenity) {
    this.loading = true;
    // if the list doesn't contain the amenity, add it
    let found = this.amenities.find(
      (a) => a.amenityGuid === amenity.amenityGuid
    );
    if (!found) {
      await this.amenities.push(amenity);
    }
    let index = await this.amenities.findIndex(
      (a) => a.amenityGuid === amenity.amenityGuid
    );
    this.amenities[index] = amenity;
    await this.amenityTable.refresh();
    this.amenityTable.selectRow(index);
    this.showAmenityModal = false;
    this.loading = false;
  }

  private getAmenityFields() {
    let field = new Field();
    field.key = "description";
    field.label = "Description";
    this.amenityFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.amenityTable = this.$refs.amenityTableRef;
    this.getAmenityFields();
    if (this.unitGuid) {
      this.amenities = await getAmenitiesByUnit(this.unitGuid);
    } else if (this.propertyGuid) {
      this.amenities = await getAmenities(this.propertyGuid);
    } else {
      this.amenities = new Array<Amenity>();
    }
    this.loading = false;
  }

  getActiveAmenity() {
    if (this.clickedAmenity) {
      return this.clickedAmenity.amenityGuid;
    } else {
      return "";
    }
  }

  async deleteAmenities() {
    if (this.selected && this.selected.length > 0) {
      await this.selected.forEach(async (d) => {
        let deleted = d;
        let result = await deleteAmenity(deleted.amenityGuid.toString());
        if (result) {
          if (this.amenities) {
            let index = this.amenities.findIndex(
              (a) => a.amenityGuid === deleted.amenityGuid
            );
            this.amenities.splice(index, 1);
          }
        }
      });
      this.showConfirmModal = false;
    }

    await this.amenityTable.refresh();
  }

  edit(amenity: any, index: any, event: any) {
    if (amenity) {
      this.clickedAmenity = amenity;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedAmenity = this.selected[0];
    }
    this.showAmenityModal = true;
  }

  closeAmenityModal() {
    this.showAmenityModal = false;
  }
}
