
// import toolbar from "./toolbar.vue";
// import design from "./design.vue";

// import color from "./color.vue";
// import fontName from "./fontname.vue";
// import fontSize from "./fontsize.vue";
// import sourceCode from "./sourcecode.vue";
// import code from "./code.vue";
// import element from "./element.vue";
// import table from "./table.vue";
// import undo from "./undo.vue";
// import link from "./link.vue";
// import picture from "./picture.vue";
// import markdown from "./markdown.vue";
// import fullscreen from "./fullscreen.vue";

// import "../style/style.less";
import { Vue, Component, Prop } from "vue-property-decorator";
import EditorToolbar from "./EditorToolbar.vue";
import EditorDesign from "./EditorDesign.vue";

@Component({
  components: { EditorToolbar, EditorDesign },
})
export default class Editor extends Vue {
  @Prop({ default: "" }) public html!: string;

  erase() {
  }
  doSomething() {
  }

  fullscreen() {
    return this.$store.state.fullscreen;
  }
  save() {
   // saveTemplate(this.)
  }

  setContent(content) {
    this.$store.dispatch("updateContent", content);
  }
  getContent() {
    return this.$store.state.content;
  }

  private components!: {
    // "ve-toolbar": toolbar;
    // "ve-design": design;
    // "ve-sourcecode": sourceCode;
    // "ve-code": code;
    // "ve-forecolor": color;
    // "ve-backcolor": color;
    // "ve-fontname": fontName;
    // "ve-fontsize": fontSize;
    // "ve-element": element;
    // "ve-table": table;
    // "ve-link": link;
    // "ve-undo": undo;
    // "ve-picture": picture;
    // "ve-markdown": markdown;
    // "ve-fullscreen": fullscreen;
  };
}
