
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Login from "./Login.vue";
import { User } from "./User";
const Auth = namespace("AuthStore");
@Component({
  components: { Login },
})
export default class LoginPage extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  private isLocal = window.location.href.indexOf("localhost") > -1;

  flipToSignUp() {
    this.$emit("flip-to-signup");
    this.$router.push({
      path: "/register",
    });
  }

  loginSuccess() {
    let redirect = "/";
    if (this.$route.query.redirect && this.$route.query.redirect !== "/") {
      redirect = this.$route.query.redirect.toString();
    } else if (this.currentUser && this.currentUser.roles) {
      if (
        this.currentUser.roles.includes("ADMINISTRATOR") ||
        this.currentUser.roles.includes("EMPLOYEE") ||
        this.currentUser.roles.includes("SITEADMINISTRATOR")
      ) {
        redirect = "/grid";
        if (this.isLocal) {
        //  redirect = "/dashboard";
        } else {
       //   window.open("https://cloud.lodgevault.com/dashboard", "_blank");
        }
      }
    }
    this.$router.push(redirect);
    this.$nextTick(() => {
      this.$bvModal.hide("modal-login");
    });
  }
}
