import { Reservation } from "@/components/Cloud/Reservations/Reservation";


export class ReservationsPagedModel {

    public ReservationsPagedModel() {
        //default values
        this.page = 1;
        this.itemStart = 1;
        this.itemEnd = 20;
    }

    public reservations!: Array<Reservation>;
    public page!: number;
    public itemStart!: number;
    public itemEnd!: number;
    public totalCount!: number;
    public statusCounts!: Array<number>;
}