export function formatNumber(num: number): string {
    var value = num.toLocaleString(
        undefined, // leave undefined to use the visitor's browser
        // locale or a string like 'en-US' to override it.
        { minimumFractionDigits: 0 }
    );
    return value;
}

export function formatPercentage(num: number, round?: boolean): string {
    return Number(num / 100).toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: (round === true) ? 0 : 2,
    });
}

export function formatter(amount: number, currencyCode?: string): string {

    if (!currencyCode || currencyCode === '' || typeof currencyCode === 'undefined') {
        currencyCode = 'USD';
    }
    const formatterInstance = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    });
    return formatterInstance.format(amount);
}

export function roundToDollar(amount: number, currencyCode?: string): string {

    if (!currencyCode || currencyCode === '' || typeof currencyCode === 'undefined') {
        currencyCode = 'USD';
    }
    const formatterInstance = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatterInstance.format(amount);
}

export function roundTo(n, digits): number {
    if (digits === undefined) {
      digits = 0;
    }
  
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    var test =(Math.round(n) / multiplicator);
    return +(test.toFixed(digits));
  }