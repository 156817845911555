export class Document {
    constructor() {
    }
    public documentGuid!: string;
    public unitGuid!: string;
    public personGuid!: string;
    public path!: string;
    public modifiedDate!: Date;
    public createdDate!: Date;
    public propertyGuid!: string;
    public fileContents!: ArrayBuffer;
    public fileBlob!: any;
}
