import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { ChargeItem } from './ChargeItem';


export async function deleteChargeItems(chargeItemsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/chargeItem/delete`,
    chargeItemsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the chargeItem from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteChargeItem(chargeItemGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/chargeItem/` + chargeItemGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the chargeItem from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function saveChargeItem(ci: ChargeItem): Promise<boolean> {
  const response = await axiosRetry(
    `/api/chargeItem`,
    ci,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the chargeItem from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getChargeItems(propertyGuid: string): Promise<Array<ChargeItem>> {
  let url = `/api/chargeItem/getchargeItems/${propertyGuid}`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<ChargeItem>>;
  } else {
    const err =
      "An error occurred getting the chargeItem from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getChargeItem(chargeItemGuid: string): Promise<ChargeItem> {
  const response = await axiosRetry(
    `/api/chargeItem/getchargeItem/${chargeItemGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<ChargeItem>;
  } else {
    const err =
      "An error occurred getting the chargeItem from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

