import { fetchRetry } from "@/utils/fetchUtil";
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

export class Source {
    constructor() {
        this.name = "";
    }
    public sourceGuid!: string;
    public propertyGuid!: string;
    public name!: string;
    public createdDate!: Date;
    public modifiedDate!: Date;
}


@Module({ namespaced: true })
class SourceStore extends VuexModule {
    public sources!: Array<Source>;
    public fetchComplete = false;

    @Action
    public async getSources(propGuid: string): Promise<Array<Source>> {
        if (this.sources) {
            return this.sources;
        }
        const response = await fetchRetry(
            `/api/person/sources/${propGuid}`,
            null,
            "GET"
        );
        if (response.ok) {
            return response.json() as Promise<Array<Source>>;
        } else {
            const err =
                "An error occurred retrieving the sources from the server." +
                `The error code was ${response.status} (${response.statusText}).`;
            throw Error(err);
        }
    }

    @Action
    public setSources(sources: Array<Source>): void {
        this.context.commit('updateSources', sources);
    }

    @Mutation
    public updateSources(sources: Array<Source>): void {
        this.sources = sources;
    }
}
export default SourceStore