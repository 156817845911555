
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import {
  getTimeSlotCollection,
  saveTimeSlotCollection,
  TimeSlotCollection,
} from "./TimeSlotCollection";
import TimeSlotTable from "./TimeSlotTable.vue";
import { TimeSlot } from "./TimeSlot";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { TimeSlotTable },
})
export default class TimeSlotCollectionEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public paramTimeSlotCollectionGuid!: string;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  private showEditModal = false;
  private loading = false;
  public timeSlotCollection!: TimeSlotCollection;
  private busy = false;
  private expanded = false;
  private timeSlots: Array<TimeSlot> = [];

  timeSlotsChanged(arg: any) {
    this.timeSlots = arg;
  }
  validateName() {
    return true;
  }

  public cancel() {
    //emit a cancel event
    this.$emit("close");
  }

  getTimeSlotCollection() {
    return this.timeSlotCollection;
  }

  async mounted() {
    this.loading = true;

    let guid = "";
    if (this.paramTimeSlotCollectionGuid) {
      guid = this.paramTimeSlotCollectionGuid;
    } else if (this.$route.params.timeSlotCollectionGuid) {
      guid = this.$route.params.id.toString();
    } else if (this.$route.query.timeSlotCollectionGuid) {
      guid = this.$route.query.timeSlotCollectionGuid.toString();
    }
    if (guid) {
      this.timeSlotCollection = await getTimeSlotCollection(guid);
    }
    if (!this.timeSlotCollection) {
      this.timeSlotCollection = new TimeSlotCollection();
      this.timeSlotCollection.timeSlotCollectionGuid = Guid.newGuid();
      this.timeSlotCollection.propertyGuid = this.property.propertyGuid;
    }
    this.loading = false;
  }

  async saveAndClose() {
    this.busy = true;
    this.timeSlotCollection.timeSlots = this.timeSlots;
    await saveTimeSlotCollection(this.timeSlotCollection).then(() =>
      this.$emit("success", this.timeSlotCollection)
    );
    this.busy = false;
  }
}
