import { render, staticRenderFns } from "./AmenityModal.vue?vue&type=template&id=d5d60d48&scoped=true&"
import script from "./AmenityModal.vue?vue&type=script&lang=ts&"
export * from "./AmenityModal.vue?vue&type=script&lang=ts&"
import style0 from "./AmenityModal.vue?vue&type=style&index=0&id=d5d60d48&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5d60d48",
  null
  
)

export default component.exports