
import { Vue, Component as VueComponent, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { Rate } from "@/components/Cloud/Rates/Rate";
import {
  deleteRateInUnit,
  deleteRateInUnitsForRate,
  getComponentsByRate,
  getRate,
  getRateInUnitsByRate,
  getSeasonsByRate,
  saveRate,
  saveRateInUnits,
} from "@/components/Cloud/Rates/RateService";
import { Account } from "../Accounts/Account";
import { getUnits } from "../Units/UnitService";
import { getAccounts } from "../Accounts/AccountService";
import { Season } from "./Seasons/Season";
import { RateInUnit } from "./RateInUnit";
import { Component as Component } from "./Component";
import { Unit, UnitTypes } from "@/components/Cloud/Units/Unit";
import { PlanType } from "./PlanType";
import { DepositMethod } from "../Reservations/DepositMethod";
import MaskedInput from "@/components/Controls/MaskedInput.vue";
import { Field } from "@/models/interfaces/Field";
import { formatter } from "@/utils/currencyUtils";
import TimeSlotTable from "../TimeSlots/TimeSlotTable.vue";
import { saveTimeSlot, TimeSlot } from "../TimeSlots/TimeSlot";
import {
  getTimeSlotCollection,
  getTimeSlotCollections,
  TimeSlotCollection,
} from "../TimeSlots/TimeSlotCollection";
import { DayOfWeek } from "@/models/DayOfWeek";

const PropertyStore = namespace("PropertyStore");
@VueComponent({
  components: { MaskedInput, TimeSlotTable },
})
export default class RateEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public paramRateGuid!: string;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  public rateGuid!: string;
  private showEditModal = false;
  private loading = false;
  public rate!: Rate;
  public accounts: Array<Account> = [];
  private busy = false;
  private seasons: Array<Season> = [];
  private components: Array<Component> = [];
  private rateInUnits: Array<RateInUnit> = [];
  private units: Array<Unit> = [];
  private planTypes: Array<any> = [];
  private depositMethods: Array<any> = [];
  private disabled = 1;
  private seasonFields: Array<Field> = [];
  private componentFields: Array<Field> = [];
  private timeSlots: Array<TimeSlot> = [];
  private timeSlotCollections: Array<TimeSlotCollection> = [];
  private timeSlotCollection: string | undefined;
  private midnightTime = "00:00:00";
  private expanded = false;
  private planType = PlanType.Hourly;
  private timeSlotsKey = Guid.newGuid();
  private canStartOnMonday = false;
  private canStartOnTuesday = false;
  private canStartOnWednesday = false;
  private canStartOnThursday = false;
  private canStartOnFriday = false;
  private canStartOnSaturday = false;
  private canStartOnSunday = false;

  dayOfWeekChecked(dayOfWeek: DayOfWeek, allowed: boolean) {
    if (!this.rate.daysOfWeek) {
      this.rate.daysOfWeek = new Array<DayOfWeek>();
    }
    if (allowed && !this.rate.daysOfWeek.includes(dayOfWeek)) {
      this.rate.daysOfWeek.push(dayOfWeek);
    }
    if (!allowed && this.rate.daysOfWeek.includes(dayOfWeek)) {
      const index = this.rate.daysOfWeek.indexOf(dayOfWeek);
      const x = this.rate.daysOfWeek.splice(index, 1);
    }
  }

  changedTimeSlotCollection(arg: any) {
    this.rate.timeSlotCollectionGuid = arg;

    // need to refresh the table
    this.timeSlotsKey = Guid.newGuid();
  }

  getTimeSlotsKey() {
    return this.timeSlotsKey;
  }

  timeSlotsChanged(arg: any) {
    this.timeSlots = arg;
  }

  planTypeChanged(arg: any) {
    this.planType = arg;
  }

  isExpandedMode() {
    return this.$route.name?.toLowerCase() == "rateedit";
  }

  expand() {
    // navigate to invoice edit page with invoice guid as param
    this.navigate("RateEdit", this.rate.rateGuid.toString());
  }

  navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }

  isTimeSlotRate() {
    return this.planType == PlanType.TimeSlot;
  }

  startDateChanged(effectiveStartDate: Date) {
    this.rate.effectiveStartDate = new Date(
      effectiveStartDate + " " + this.midnightTime
    );
  }
  endDateChanged(effectiveEndDate: Date) {
    this.rate.effectiveEndDate = new Date(
      effectiveEndDate + " " + this.midnightTime
    );
  }

  getReadableDateTime(dateToFormat: Date) {
    if (new Date(dateToFormat).isDate() == false) {
      return "";
    }
    return new Date(dateToFormat).readableDateLocalized();
  }

  getCurrency(amount: number) {
    if (!amount) {
      return "";
    }
    let isNum = typeof amount === "number" && amount === amount;
    if (!isNum) {
      return "";
    }
    return formatter(amount);
  }

  private getSeasonFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    field.sortable = true;
    this.seasonFields.push(field);

    field = new Field();
    field.key = "startDate";
    field.label = "Start";
    field.sortable = true;
    this.seasonFields.push(field);

    field = new Field();
    field.key = "endDate";
    field.label = "End Date";
    field.sortable = true;
    this.seasonFields.push(field);

    field = new Field();
    field.key = "baseRate";
    field.label = "Base Rate";
    field.sortable = true;
    this.seasonFields.push(field);
  }

  private getComponentFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    field.sortable = true;
    this.componentFields.push(field);

    field = new Field();
    field.key = "preTaxPrice";
    field.label = "Pre Tax";
    field.sortable = true;
    this.componentFields.push(field);

    field = new Field();
    field.key = "total";
    field.label = "Total";
    field.sortable = true;
    this.componentFields.push(field);
  }

  checked(id: Number) {
    return this.rate && this.rate.depositMethod == id;
  }

  depositAmountChanged(arg: any) {
    this.rate.depositAmount = arg;
  }

  defaultDepositPolicyChanged(arg: any) {
    let bool = Boolean(arg);
    if (bool == true) {
      this.rate.depositMethod = -1;
      this.disabled = 1;
    } else {
      this.rate.depositMethod = DepositMethod.Percentage;
      this.disabled = 0;
    }
  }

  valueOfDefaultDepositPolicyCheckbox() {
    return this.rate.depositMethod == DepositMethod.None;
  }

  getPlanTypes() {
    for (var plan in PlanType) {
      var isValueProperty = Number(plan) >= 0;
      if (isValueProperty) {
        this.planTypes.push({ id: Number(plan), name: PlanType[plan] });
      }
    }
  }
  getDepositMethods() {
    for (var method in DepositMethod) {
      var isValueProperty = Number(method) >= 0;
      if (isValueProperty) {
        this.depositMethods.push({
          id: Number(method),
          name: DepositMethod[method],
        });
      }
    }
  }

  validateName() {
    return true;
  }

  public cancel() {
    if (this.expanded == true) {
      this.navigate("Rates", "");
    } else {
      this.$emit("close");
    }
  }

  getRate() {
    return this.rate;
  }

  async selectAllUnits() {
    this.busy = true;
    if (this.allAreChecked()) {
      await this.units.forEach((a) => {
        a.checked = false;
      });
    } else {
      await this.units.forEach((a) => {
        a.checked = true;
      });
    }

    this.busy = false;
  }

  allOrNone() {
    if (this.allAreChecked()) {
      return "None";
    } else {
      return "All";
    }
  }

  allAreChecked() {
    let result = this.units.every((a) => a.checked);
    return result;
  }

  async mounted() {
    this.loading = true;
    this.expanded = false;
    if (this.paramRateGuid) {
      this.rateGuid = this.paramRateGuid;
    } else if (this.$route.params.rateGuid) {
      this.rateGuid = this.$route.params.id.toString();
    } else if (this.$route.query.rateGuid) {
      this.rateGuid = this.$route.query.rateGuid.toString();
      this.expanded = true;
    } else if (this.$route.params.id) {
      this.rateGuid = this.$route.params.id.toString();
      this.expanded = true;
    }
    if (this.rateGuid) {
      this.rate = await getRate(this.rateGuid.toString());
    }
    if (!this.rate) {
      this.createNew();
    }

    this.timeSlotCollections = await getTimeSlotCollections(
      this.rate.propertyGuid
    );

    await Promise.all([
      (this.seasons = await getSeasonsByRate(this.rate.rateGuid)),
      (this.units = await getUnits(this.property.propertyGuid)),
      (this.rateInUnits = await getRateInUnitsByRate(this.rate.rateGuid)),
      (this.accounts = await getAccounts(this.property.propertyGuid)),
      (this.components = await getComponentsByRate(this.rate.rateGuid)),
    ]);

    await this.units.forEach((a) => {
      a.checked = this.isEnabled(a);
    });

    this.getPlanTypes();
    this.getDepositMethods();
    this.disabled = this.rate.depositMethod ? 0 : 1;
    this.getSeasonFields();
    this.getComponentFields();
    this.planType = this.rate.planType;
    if (this.rate.daysOfWeek) {
      this.canStartOnMonday =
        this.rate.daysOfWeek.findIndex((d) => d == DayOfWeek.Monday) != -1;
      this.canStartOnTuesday =
        this.rate.daysOfWeek.findIndex((d) => d == DayOfWeek.Tuesday) != -1;
      this.canStartOnWednesday =
        this.rate.daysOfWeek.findIndex((d) => d == DayOfWeek.Wednesday) != -1;
      this.canStartOnThursday =
        this.rate.daysOfWeek.findIndex((d) => d == DayOfWeek.Thursday) != -1;
      this.canStartOnFriday =
        this.rate.daysOfWeek.findIndex((d) => d == DayOfWeek.Friday) != -1;
      this.canStartOnSaturday =
        this.rate.daysOfWeek.findIndex((d) => d == DayOfWeek.Saturday) != -1;
      this.canStartOnSunday =
        this.rate.daysOfWeek.findIndex((d) => d == DayOfWeek.Sunday) != -1;
    }

    this.loading = false;
  }

  filterUnits() {
    if (this.rate.planType == PlanType.TimeSlot) {
      return this.units.filter(
        (u) => u.unitType == UnitTypes.Event || u.unitType == UnitTypes.Rental
      );
    } else {
      return this.units.filter((u) => u.unitType == UnitTypes.Accommodation);
    }
  }

  isEnabled(unit: Unit) {
    if (!this.rateInUnits){
      return false;
    }
    let found = this.rateInUnits.find((a) => a.unitGuid === unit.unitGuid);
    if (!found) {
      return false;
    }
    return true;
  }

  async addAnother() {
    this.busy = true;
    this.loading = true;
    await this.save(false);
    await this.createNew();
    if (this.expanded) {
      this.navigate("Rates", "");
    }
    this.busy = false;
    this.loading = false;
  }

  private createNew() {
    this.rate = new Rate();
    this.rate.name = "";
    this.rate.rateGuid = Guid.newGuid();
    this.rate.propertyGuid = this.property.propertyGuid;
    this.rate.createdDate = new Date();
  }

  async saveUnits() {
    let rius = new Array<RateInUnit>();
    this.units.forEach((unit) => {
      if (unit.checked) {
        let riu = new RateInUnit();
        riu.rateInUnitGuid = Guid.newGuid();
        riu.propertyGuid = this.property.propertyGuid;
        riu.unitGuid = unit.unitGuid;
        riu.rateGuid = this.rate.rateGuid;
        rius.push(riu);
      }
    });

    await deleteRateInUnitsForRate(this.rate);
    await saveRateInUnits(rius);
  }

  async save(close: boolean) {
    this.busy = true;
    
    this.rate = await saveRate(this.rate);
    await this.saveUnits();
    for (const ts of this.timeSlots) {
      await saveTimeSlot(ts);
    }
    this.$emit("success", this.rate);
    if (close && close == true) {
      this.$emit("close");
    }
    if (this.expanded) {
      this.navigate("Rates", "");
    }
    this.busy = false;
  }
}
