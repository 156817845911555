export class RegisterCredentials {
    constructor() {
        this.email = "";
        this.password = "";
        this.token = "";
        this.fullname = "";
        this.companyName = "";
    }
    public email!: string;
    public password!: string;
    public token!: string;
    public invitationGuid!: string;
    public fullname!: string;
    public companyName!: string;

    public firstName!: string;
    public lastName!: string;
    public postalCode!: string;

    public mode: string = "host"; // or guest
}