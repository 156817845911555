
export const exportToCsv = (filename: string, rows: object[], headers?: string[]): void => {
    if (!rows || !rows.length) {
        return;
    }
    const separator: string = ",";

    const keys: string[] = Object.keys(rows[0]);

    let columHearders: string[];

    if (headers) {
        columHearders = headers;
    } else {
        columHearders = keys;
    }

    const csvContent =
        "sep=,\n" +
        columHearders.join(separator) +
        '\n' +
        rows.map(row => {
            return keys.map(k => {
                let cell = row[k] === null || row[k] === undefined ? '' : row[k];

                cell = cell instanceof Date
                    ? cell.toLocaleString()
                    : cell.toString().replace(/"/g, '""');

                if (navigator['msSaveBlob']) {
                    cell = cell.replace(/[^\x00-\x7F]/g, ""); //remove non-ascii characters
                }
                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = `"${cell}"`;
                }
                return cell;
            }).join(separator);
        }).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    if (navigator['msSaveBlob']) { // In case of IE 10+
        // navigator.msSaveBlob(blob, filename);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}



function download_csv(csv, filename) {
    var csvFile;
    var downloadLink;

    // CSV FILE
    csvFile = new Blob([csv], { type: "text/csv" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    // Lanzamos
    downloadLink.click();
}

function cleanupCell(cell: any): string {
    //let cell = row[k] === null || row[k] === undefined ? '' : row[k];
    if (!cell) {
        return '';
    }

    cell = cell instanceof Date
        ? cell.toLocaleString()
        : cell.toString().replace(/"/g, '""');

    if (navigator['msSaveBlob']) {
        cell = cell.replace(/[^\x00-\x7F]/g, ""); //remove non-ascii characters
    }
    if (cell.search(/("|,|\n)/g) >= 0) {
        cell = `"${cell}"`;
    }
    return cell;
}

export function export_table_to_csv(html, filename) {
    const separator: string = ",";
    var csv: Array<any> = [];
    var rows = html.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
        var row: Array<any> = [];
        let cols = rows[i].querySelectorAll("td");
        let headerCols = rows[i].querySelectorAll("th");

        for (var j = 0; j < headerCols.length; j++) {
            let htmlEl = headerCols[j] as HTMLElement;
            let childNode = htmlEl.childNodes[0] as HTMLElement;
            let cell = '';
            if (childNode) {
                cell = childNode.innerText;
            }
            cell = cleanupCell(cell);

            row.push(childNode.innerText);
        }

        for (var j = 0; j < cols.length; j++) {
            let htmlEl = cols[j] as HTMLElement;
            let childNode = htmlEl.childNodes[0] as HTMLElement;
            let cell = '';
            if (childNode) {
                if (childNode.nodeName == '#text') {
                    cell = childNode.textContent as string;
                } else {
                    cell = childNode.innerText;
                }
            }
            cell = cleanupCell(cell);
            row.push(cell);
        }
        csv.push(row.join(separator));
    }

    // Download CSV
    download_csv(csv.join("\n"), filename);
}


