import { axiosRetry } from "@/utils/fetchUtil";

export class Preference {
    constructor() {
    }
    public propertyGuid!: string;
    public release!: number;
    public serialNumber!: string;
    public numberOfUses!: number;
    public addrName1!: string;
    public addrName2!: string;
    public freeFormatPhone!: boolean;
    public otherNames!: boolean;
    public company1!: boolean;
    public company2!: boolean;
    public relation1!: string;
    public relation2!: string;
    public relation3!: string;
    public relation4!: string;
    public relation5!: string;
    public relation6!: string;
    public peopleUserField1!: string;
    public peopleUserField2!: string;
    public peopleUserField3!: string;
    public peopleUserField4!: string;
    public peopleUserField5!: string;
    public peopleUserField6!: string;
    public includeTimes!: boolean;
    public requireUnitAssignments!: boolean;
    public peopleName1!: string;
    public peopleName2!: string;
    public peopleName3!: string;
    public peopleName4!: string;
    public daysInWeek!: string;
    public history!: number;
    public defaultDepositIncludeTax!: boolean;
    public autoConfirmationNumbers!: boolean;
    public columnSize!: number;
    public mapOrientation!: number;
    public defaultClassID!: string;
    public resUserField1!: string;
    public resUserField2!: string;
    public resUserField3!: string;
    public resUserField4!: string;
    public resUserField5!: string;
    public resUserField6!: string;
    public resUserCheck1!: string;
    public resUserCheck2!: string;
    public includesTax!: boolean;
    public showPackageDetail!: boolean;
    public defaultCreditKind!: number;
    public tax1Label!: string;
    public tax2Label!: string;
    public tax3Label!: string;
    public tax4Label!: string;
    public tax5Label!: string;
    public tax6Label!: string;
    public tax7Label!: string;
    public tax8Label!: string;
    public tax9Label!: string;
    public tax10Label!: string;
    public backupWarning!: boolean;
    public helpTips!: boolean;
    public totals!: boolean;
    public exitWarning!: boolean;
    public useGC!: boolean;
    public modifyHistory!: boolean;
    public continuousEntry!: boolean;
    public businessWebsiteURL!: string;
    public cancellationPolicy!: string;
    public currencyConversion!: string;
    public modifiedDate!: Date;
    public createdDate!: Date;
}


export async function savePreference(pref: Preference): Promise<boolean> {
    const response = await axiosRetry(
        `/api/property/savepreference`,
        pref,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred saving the preference to the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
