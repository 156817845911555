
import PasswordInput from "@/components/Controls/PasswordInput.vue";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "./AuthService";
const AuthStore = namespace("AuthStore");

@Component({
  components: { PasswordInput },
})
export default class ResetPassword extends Vue {
  private newPassword = "";
  private confirmPassword = "";
  private resetComplete = false;
  private errors!: Array<string>;
  private showProgressOverlay = false;
  private email = "";
  private token = "";
  private user: any = { email: "", password: "" };

  @AuthStore.Getter
  private isLoggedIn!: boolean;

  @AuthStore.Action
  private login!: (data: any) => Promise<any>;

  updateNewPassword(val) {
    this.newPassword = val;
  }
  updateConfirmPassword(val) {
    this.confirmPassword = val;
  }

  created() {
    this.errors = [];
    if (this.isLoggedIn) {
      this.$router.push("/profile");
    }
  }

  validateMatch(): boolean {
    this.errors = [];

    if (!this.newPassword || !this.confirmPassword) {
      this.errors.push("Password must not be empty");
    }
    if (!this.newPassword || this.newPassword != this.confirmPassword) {
      this.errors.push("The passwords do not match");
    }
    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }

  async loginAndGoToDashboard() {
    this.user.password = this.newPassword;
    this.user.email = this.email;
    await this.login(this.user).then(
      (data) => {
        this.$router.push("/dashboard");
      },
      (error) => {
        this.errors.push(error);
      }
    );
  }

  async resetPassword() {
    this.showProgressOverlay = true;
    if (this.validateMatch()) {
      this.token = this.$route.query.code.toString();
      this.email = this.$route.query.email.toString();
      await AuthService.resetPassword(
        this.newPassword,
        this.email,
        this.token
      ).then(
        (response) => {
          if (response.data.error) {
            this.showProgressOverlay = false;
            this.errors.push(response.data.error);
          } else {
            this.resetComplete = true;
          }

          this.resetComplete = true;
        },
        (error) => {
          this.errors.push("Reset password failed: " + error);
          this.showProgressOverlay = false;
        }
      );
    } else {
      this.showProgressOverlay = false;
      return;
    }
  }
}
