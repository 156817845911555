import { AddOn } from "@/components/Cloud/ChargeItems/AddOn";
import { CustomQuestion } from "@/components/Cloud/CustomQuestions/CustomQuestion";
import { DeliveryOption } from "@/components/Cloud/DeliveryOptions/DeliveryOption";
import { axiosRetry, fetchRetry } from "@/utils/fetchUtil";
import { UnitTypes } from "../Cloud/Units/Unit";

export default class CartItem {
    constructor() {
        this.image = "";
        this.appFee = 0;
        this.stripeFee = 0;
        this.price = 0;
        this.taxAmount = 0;
        this.adults = 2;
        this.children = 0;
    }
    public cartItemGuid!: string | undefined;
    public timeSlotGuid!: string | undefined;
    public rateGuid!: string | undefined;
    public cartGuid!: string;

    // maps to RateInUnitGuid
    public recordGuid!: string;
    public rartInUnitGuid!: string;
    public propertyGuid!: string;
    public currencyCode!: string;
    public unitGuid!: string;
    public unitName!: string;
    public unitType!: UnitTypes;
    public kind!: string;
    public createdDate!: Date;
    public modifiedDate!: Date;
    /// this is all the items added up. this is pre tax. so price = item * quantity 
    public price!: number;
    /// total of all taxes for all items
    public taxAmount!: number;
    public description!: string;
    public quantity: number = 1;
    public image!: string;
    public adults!: number;
    public children!: number;
    public inDateTime: Date = new Date().addDays(7);
    public outDateTime: Date = new Date().addDays(9);
    public appFee!: number;
    public stripeFee!: number;
    public dueToday: number = 0;
    // if this has value, use this for payment on the checkout book page
    public deposit!: number;
    public addOns!: Array<AddOn>;
    public deliveryOptions!: Array<DeliveryOption>;
    public questionnaire!: Array<CustomQuestion>;
    public nights: number = 0;
}

export function getDelivery(ci: CartItem): number {
    let sum = 0;
    if (ci.deliveryOptions && ci.deliveryOptions.length > 0) {
        let delivery = ci.deliveryOptions
            .filter((item) => item.checked)
            .reduce((sum, current) => sum + current.price, 0);
        sum += delivery;
    }
    return sum;
}

export function getAddOns(ci: CartItem): number {
    let sum = 0;
    if (ci.addOns && ci.addOns.length > 0) {
        sum = ci.addOns.filter((addon) => addon.quantity > 0)
            .reduce(
                (sum, current) => sum + current.price * current.quantity,
                0
            );
    }
    return sum;
}

export async function recalcCartItem(cartItem: CartItem): Promise<CartItem> {
   
    const response = await axiosRetry(
        "/api/cart/recalccartitem",
        cartItem,
        "POST"
    );

    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<CartItem>;
    } else {
        const err =
            "An error occurred getting the cart item from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}