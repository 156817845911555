export class Amenity {
    constructor() {
    }
    public amenityGuid!: string;
    public propertyGuid!: string;
    public modifiedDate!: Date;
    public createdDate!: Date;
    public description!: string;
    public checked: boolean = true;
}
