
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import CartPopup from "../Cart/CartPopup.vue";
import MaskBackground from "../Cart/MaskBackground.vue";

const cartStore = namespace("CartStore");

@Component({
  components: {
    CartPopup,
    MaskBackground,
  },
})
class CartButton extends Vue {
  @Prop({ default: false }) private cartIcon!: boolean;
  @Prop({ default: "btn" }) public btnClass!: string;
  @Prop({ default: true }) public visible!: boolean;

  setCartIcon(value: boolean) {
    this.cartIcon = value;
  }

  togglePopupCart() {
    this.showOrHidePopupCart();
  }

  hasProduct() {
    return this.cartItems.length > 0;
  }

  @cartStore.State
  public showPopupCart!: boolean;

  @cartStore.Action
  public showOrHidePopupCart!: () => void;

  @cartStore.State
  public cartItems!: Array<any>;
}
export default CartButton;
