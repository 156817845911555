
import { GlobalSearchParameters } from "@/models/GlobalSearchParameters";
import { Person } from "@/components/Cloud/People/Person";
import Property from "@/models/Property";
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import { getGlobalSearchResults } from "@/components/Cloud/Reservations/ReservationService";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class SearchBox extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: "all" }) public mode!: string; // can be 'all', 'people', 'reservations', 'etc....'

  private searchResults = new Array<any>();
  private placeholder = "foo";
  private showAutocompleteDropdown = false;
  private selectedIndex = 0;
  private selectedItem: any;
  private showSearchProgress = false;
  private timer!: number | undefined;

  typed(event) {
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => this.search(event), 1000);
  }

  async search(event) {
    this.searchResults = [];
    this.showSearchProgress = true;
    let searchParams = new GlobalSearchParameters();
    searchParams.searchTerm = event;
    searchParams.mode = this.mode;
    if (searchParams.searchTerm) {
      let results = await getGlobalSearchResults(
        this.property.propertyGuid,
        searchParams
      );
      results.forEach((r) => {
        this.searchResults.push(r);
      });
    }

    if (this.searchResults && this.searchResults.length > 0) {
      this.showAutocompleteDropdown = true;
    }

    this.showSearchProgress = false;
  }

  isAPerson(item: any) {
    return item.hasOwnProperty("personGuid") || item instanceof Person;
  }
  isAReservation(item: any) {
    return (
      item.hasOwnProperty("reservationGuid") || item instanceof Reservation
    );
  }

  getDisplay(item: any) {
    if (this.isAPerson(item)) {
      const person: Person = item;
      return person.firstSpaceLast + " - " + person.city + ', ' + person.state + ' - ' + person.email;
    }
    if (this.isAReservation(item)) {
      const res: Reservation = item;
      return (
        "Reservation - " +
        res.name +
        " arrives: " +
        new Date(res.arrivalDate).readableDateLocalized()
      );
    }
  }

  mounted() {
    const searchbox = document.getElementById("combobox-input");
    if (searchbox) {
      searchbox.addEventListener("search", this.clearHandler);
      document.body.addEventListener("click", this.hideIfNoFocus);
    }
  }

  clearHandler() {
    this.$emit("valuechanged", "");
  }

  hideIfNoFocus() {
    const inputBox = document.getElementById("combobox-input");
    if (inputBox) {
      if (inputBox === document.activeElement) {
        this.showAutocompleteDropdown = true;
      } else {
        this.showAutocompleteDropdown = false;
      }
    }
  }

  handleBackspace() {
    this.showAutocompleteDropdown = true;
  }

  select(index: number) {
    this.showAutocompleteDropdown = false;
    this.selectedIndex = index;
    this.selectedItem = this.searchResults[index];

    // just emit an event instead of doing this
    this.$emit("searchResultSelected", this.selectedItem);
    if (this.mode == "all") {
      if (this.isAPerson(this.selectedItem)) {
        this.navigate("PersonEdit", this.selectedItem.personGuid);
      }
      if (this.isAReservation(this.selectedItem)) {
        this.navigate("ReservationEdit", this.selectedItem.reservationGuid);
      }
    }
  }

  navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }

  selectNext() {
    if (this.showAutocompleteDropdown) {
      if (this.selectedIndex < this.searchResults.length - 1) {
        this.selectedIndex++;
      } else {
        this.selectedIndex = 0;
      }
    } else {
      this.showAutocompleteDropdown = true;
    }
    this.select(this.selectedIndex);
  }

  selectPrev() {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    } else {
      this.selectedIndex = this.searchResults.length - 1;
    }
    this.select(this.selectedIndex);
  }
}
