var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-nav',{attrs:{"vertical":""}},[_c('div',{staticClass:"vertical-nav-top-spacer"}),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('Units'),
    },on:{"click":function($event){return _vm.navigate('Units')}}},[_vm._v("Units")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('UnitGroups'),
    },on:{"click":function($event){return _vm.navigate('UnitGroups')}}},[_vm._v("Unit Groups")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('Rates'),
    },on:{"click":function($event){return _vm.navigate('Rates')}}},[_vm._v("Rates")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('Accounts'),
    },on:{"click":function($event){return _vm.navigate('Accounts')}}},[_vm._v("Revenue Accounts")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('ChargeItems'),
    },on:{"click":function($event){return _vm.navigate('ChargeItems')}}},[_vm._v("Charge (POS) Items")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('TimeSlotCollections'),
    },on:{"click":function($event){return _vm.navigate('TimeSlotCollections')}}},[_vm._v("Time Slots")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('DeliveryOptions'),
    },on:{"click":function($event){return _vm.navigate('DeliveryOptions')}}},[_vm._v("Delivery Options")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('CustomQuestions'),
    },on:{"click":function($event){return _vm.navigate('CustomQuestions')}}},[_vm._v("Custom Questions/Waiver")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('Amenities'),
    },on:{"click":function($event){return _vm.navigate('Amenities')}}},[_vm._v("Amenities")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('Activities'),
    },on:{"click":function($event){return _vm.navigate('Activities')}}},[_vm._v("Activity Log")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }