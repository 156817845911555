
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "./AuthService";
import PasswordInput from "@/components/Controls/PasswordInput.vue";
const Auth = namespace("AuthStore");
@Component({
  components: { PasswordInput },
})
export default class VerifyEmail extends Vue {
  private errors!: Array<string>;
  private submitted = false;
  private successful = false;
  private accountInvite = "";
  private loading = false;
  private password = "";
  private message = "";
  private emailTextBox = document.getElementById(
    "email-textbox"
  ) as HTMLElement;
  private email = "";

  @Auth.Getter
  private isLoggedIn!: boolean;

  async mounted() {
    this.loading = true;
    if (this.isLoggedIn) {
      this.$router.push("/profile");
    }
    this.errors = [];
    this.loading = false;
  }

  async validate() {
    return true;
  }
}
