
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "../Auth/User";

const Auth = namespace("AuthStore");

@Component({
  components: {},
})
class MpFooter extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  @Auth.Action
  private signOut!: () => void;

  private loading = true;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  getUser() {
    if (this.currentUser) {
      return this.currentUser;
    } else {
      return null;
    }
  }

  logOut() {
    this.signOut();
    this.$router.push({
      name: "Login",
    });
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  created() {
    this.loading = true;
    this.loading = false;
  }
}
export default MpFooter;
