
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { Reservation } from '../Reservations/Reservation';
import { IInvoiceItem } from './IInvoiceItem';
import { Invoice } from './Invoice';

export async function getPrimaryInvoice(res: Reservation): Promise<Invoice> {
  //iterate the invoiceObjects on the res and choose the one with the same contact id as the 
  //primary contact on the reservation
  //iterate the contacts and find the primary then iterate the invoices and find the contact id
  if (!res || !res.personInReservations || !res.invoices) {
    // nothing to do here
    throw Error('failed to get a primary invoice');
  }
  const personInRes = res.personInReservations?.find((pir) => pir.isPrimaryContact);
  const personGuid = personInRes?.personGuid;
  const primaryInvoice = res.invoices.find((i) => i.personGuid == personGuid);
  if (primaryInvoice) {
    return primaryInvoice;
  } else {
    const err =
      "An error occurred getting the Invoice.";
    throw Error(err);
  }
}

export async function getInvoicesByReservation(reservationGuid: string): Promise<Array<Invoice>> {
  const response = await axiosRetry(
    `/api/Invoice/getbyreservation/${reservationGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Invoice>>;
  } else {
    const err =
      "An error occurred getting the Invoices from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveInvoiceItem(item: IInvoiceItem): Promise<boolean> {

  //save an invidivudal charge or payment
  const response = await axiosRetry(
    `/api/Invoice/saveitem/${item.itemGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the Invoice from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deleteInvoices(invoicesGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/invoice/delete`,
    invoicesGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the invoice from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deleteInvoice(invoiceGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/invoice/${invoiceGuid}`,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the invoice from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveInvoice(invoice: Invoice): Promise<Invoice> {
  const response = await axiosRetry(
    `/api/invoice/saveinvoice`,
    invoice,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Invoice>;
  } else {
    const err =
      "An error occurred getting the invoice from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}


export async function getInvoices(personGuid?: string, reservationGuid?: string, propertyGuid?: string, startDate?: Date, endDate?: Date): Promise<Array<Invoice>> {
  let url = '';
  if (personGuid) {
    url = `/api/invoice/getbyperson/${personGuid}`;
  } else if (reservationGuid) {
    url = `/api/invoice/getbyreservation/${reservationGuid}`;
  } else {
    if (startDate && endDate) {
      let strStart = new Date(startDate).toDateString();
      let strEnd = new Date(endDate).toDateString();
      url = `/api/invoice/getbyproperty/${propertyGuid}/${strStart}/${strEnd}`;
    }
  }

  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Invoice>>;
  } else {
    const err =
      "An error occurred getting the invoice from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getInvoicesByPerson(personGuid: string): Promise<Array<Invoice>> {
  const response = await axiosRetry(
    `/api/invoice/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Invoice>>;
  } else {
    const err =
      "An error occurred getting the invoice from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getInvoice(invoiceGuid: string): Promise<Invoice> {
  const response = await axiosRetry(
    `/api/invoice/getinvoice/${invoiceGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Invoice>;
  } else {
    const err =
      "An error occurred getting the invoice from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}


