import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import { Assign } from "../components/Cloud/Assign/Assign";
import { OutOfService } from "../components/Cloud/OutOfService/OutOfService";
import { DrawableRectangle } from "./Drawable/DrawableRectangle";

export class GridAssign {
    public assign!: Assign;
    public reservation!: Reservation;
    public outOfService!: OutOfService;
    public drawable!: DrawableRectangle;
}