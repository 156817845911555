
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { getAssign, saveAssign } from "../Reservations/ReservationService";
import { Unit } from "@/components/Cloud/Units/Unit";
import { getUnits } from "../Units/UnitService";
import { Assign } from "./Assign";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class AssignEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public assignGuid!: string;

  private units: Array<Unit> = [];
  private showEditModal = false;
  private loading = false;
  public assign = new Assign();
  private busy = false;
  private inTime: string = "00:00:00";
  private outTime: string = "00:00:00";
  private initialInDate = new Date();
  private initialOutDate = new Date();

  inTimeChanged(arg: any) {
    this.inTime = arg.toString();
  }
  outTimeChanged(arg: any) {
    this.outTime = arg.toString();
  }

  unitChanged(arg: any) {
    this.assign.unitGuid = arg;
  }

  inDateChanged(arg: any) {
    this.assign.inDateTime = new Date(arg + " " + this.inTime);
  }

  outDateChanged(arg: any) {
    this.assign.outDateTime = new Date(arg + " " + this.outTime);
  }

  public cancel() {
    //emit a cancel event
  }

  async mounted() {
    this.loading = true;

    if (this.$route.params.assignGuid) {
      this.assignGuid = this.$route.params.id.toString();
    } else if (this.$route.query.assignGuid) {
      this.assignGuid = this.$route.query.assignGuid.toString();
    }
    if (this.assignGuid) {
      this.assign = await getAssign(this.assignGuid.toString());
    }
    if (!this.assign) {
      this.assign = new Assign();
      this.assign.assignGuid = Guid.newGuid();
      this.assign.propertyGuid = this.property.propertyGuid;
      this.assign.createdDate = new Date();
    }

    this.initialInDate = this.assign.inDateTime;
    this.initialOutDate = this.assign.outDateTime;
    this.inTime = new Date(this.assign.inDateTime).time(false);
    this.outTime = new Date(this.assign.outDateTime).time(false);
    this.units = await getUnits(this.property.propertyGuid);
    this.loading = false;
  }

  async saveAndClose() {
    let tmpDate = this.assign.inDateTime;
    this.busy = true;
    let arrdateString = new Date(tmpDate).toDateString();
    tmpDate = new Date(arrdateString + " " + this.inTime);
    this.assign.inDateTime = tmpDate;
    this.assign.inDateTimeIso = tmpDate.toISOString();
    this.assign.inDateTime = tmpDate;
    let depdateString = new Date(this.assign.outDateTime).toDateString();
    this.assign.outDateTime = new Date(depdateString + " " + this.outTime);

    let unit = this.units.find((u) => u.unitGuid == this.assign.unitGuid);
    if (unit) {
      this.assign.unitName = unit.unitName;
    }

    await saveAssign(this.assign).then(() =>
      this.$emit("success", this.assign)
    );
    this.busy = false;
  }
}
