
import GwsHeader from "@/components/Cloud/partials/GwsHeader.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { GwsHeader },
})
class LayoutGws extends Vue {
  private loading = true;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;

  onWindowResized() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  // created() {
  //   this.loading = true;
  //   this.toggleSmallScreenMenu();
  //   this.loading = false;
  // }
  // navigate(page: string) {
  //   this.toggleSmallScreenMenu();
  //   this.$router.push(page);
  // }

  scrollToTop() {
    const myDiv = document.getElementById("gws-panel");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }
}
export default LayoutGws;
