import { AvailabilityParameters } from '@/models/AvailabilityParameters';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
@Module({ namespaced: true })
class AvailabilityParametersStore extends VuexModule {
    public availParams: AvailabilityParameters = new AvailabilityParameters();

    @Mutation
    public update(availParams: AvailabilityParameters): void {
        this.availParams = availParams;
    }

    @Action
    public async set(availParams: AvailabilityParameters): Promise<boolean> {
        this.context.commit('update', availParams);
        return true;
    }
}
export default AvailabilityParametersStore