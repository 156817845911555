export class Field {
    tdClass: string;
    constructor() {
        this.sortable = true;
        this.key = "";
        this.label = "";
        this.tdClass = "";
    }
    key: string;
    label: string;
    sortable: boolean;
}