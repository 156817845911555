
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class Breadcrumb extends Vue {
  @PropertyStore.State
  public property!: Property;
  //used for breadcrumb links only/// need to revist this
  navigate(page: string) {
    this.$router.push({ name: page });
  }
}
