
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Invoice, InvoiceItemKind } from "./Invoice";
import { getInvoice, saveInvoice } from "./InvoiceService";
import { getProperty } from "../Settings/PropertyService";
import { Reservation } from "../Reservations/Reservation";
import {
  getReservation,
  saveReservation,
} from "../Reservations/ReservationService";
import { formatter } from "@/utils/currencyUtils";
import { Person } from "../People/Person";
import { getPerson } from "../People/PersonService";
import { ELEMENT_CONFIG, StripeElement } from "@/models/StripeElement";
import { COMPONENT_OPTIONS } from "@/models/StripeStyle";
import Billing from "@/models/Billing";
import AuthService from "../Auth/AuthService";
import { fetchRetry, fetchSimple, xAccessToken } from "@/utils/fetchUtil";
import { LoginCredentials } from "../Auth/LoginCredentials";
import {
  calculateStripeFee,
  Payment,
  PaymentType,
} from "./Payment";
import { Guid } from "@/utils/Guid";
import { processPayment, savePayment } from "./PaymentService";
import { Charge } from "./Charge";
import { ResStatus } from "../Reservations/ResStatus";
import { CardTransactionResult } from "@/models/CardTransactionResult";
import {
  getMergedTemplate,
  mergeInvoiceTable,
  replacePropertyFields,
} from "@/models/Template";
import axios from "axios";
import { FeeBillingPlan } from "@/models/CheckoutSettings";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({
  components: {},
})
export default class InvoicePay extends Vue {
  private property: Property = new Property();
  private invoice: Invoice = new Invoice();
  private reservation!: Reservation;
  private person: Person = new Person();
  private stripeInstance;
  private showCartItemRemoveModal = false;
  private errorDetected = false;
  private showProgressOverlay = false;
  private cardNumberError;
  private cardNumberIsCompleted;
  private cardNumberEl;
  private cardExpiryError;
  private cardExpiryIsCompleted;
  private cardExpiryEl;
  private cardCvcIsCompleted;
  private cardCvcEl;
  private billingModel = new Billing();
  private errors = new Array<string>();
  private submitClicked = false;
  private currentIndex = 0;
  private collapseExpand = "collapsed";
  private readonly testStripePublishableKey =
    "pk_test_TiLjR6XavA0qw6492vNcxYzr";
  private cardResult!: CardTransactionResult;

  private printHtml =
    "<html><body><b>this is some</b> stuff to print</body></html>";

  timer = 1000;

  private payment = new Payment();

  isFeePlanCustomerAddOn() {
    return (
      this.property &&
      this.property.checkoutSettings &&
      this.property.checkoutSettings.feeBillingPlan ==
        FeeBillingPlan.CustomerAddOn
    );
  }

  PrintElem(html) {
    let w = window.innerWidth;
    let h = window.innerHeight;
    let mywindow = window.open(
      "",
      "PRINT",
      "heigh=" + h.toString() + ",width=" + w.toString()
    );
    if (mywindow && html) {
      mywindow.document.write(html);
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
      return true;
    }
  }

  async print() {
    this.showProgressOverlay = true;
    let template = await getMergedTemplate(
      this.property.propertyGuid,
      "12F72DB4-A30C-4486-98BF-20BF70CAE380",
      this.invoice.reservationGuid,
      this.invoice.personGuid,
      this.invoice.invoiceGuid
    );

    // get a merged template
    let printDiv = document.getElementById("print-div");
    this.timer = setTimeout(async () => {
      if (printDiv && template) {
        printDiv.innerHTML = template.fileContent;
        printDiv.innerHTML = await replacePropertyFields(
          printDiv.innerHTML,
          this.property
        );
        await this.mergeInvoiceTable();
        this.PrintElem(printDiv.innerHTML);
      }
    }, this.timer);
    this.showProgressOverlay = false;
  }

  async mergeInvoiceTable() {
    let table = document.getElementById("invoice-table") as HTMLTableElement;
    if (table && this.invoice) {
      let headerRow = table.rows[1];
      if (headerRow) {
        let headerCells = headerRow.cells;
        table.rows[1].remove();
        this.invoice.items = [];
        for (const charge of this.invoice.charges) {
          this.invoice.items.push(charge);
        }
        for (const payment of this.invoice.payments) {
          this.invoice.items.push(payment);
        }
        for (const item of this.invoice.items) {
          var row = table.insertRow();
          for (const cell of headerCells) {
            let cell1 = row.insertCell();
            let field = cell.innerText
              .replace("{{", "")
              .replace("charge.", "")
              .replace("payment.", "")
              .replace("}}", "");
            let isMoney = typeof item[field] === "number";
            let isDate = new Date(item[field]).isDate();
            if (isMoney && field !== "quantity") {
              cell1.innerText = this.formatCurrency(item[field]);
              cell1.style.textAlign = "right";
            } else if (isDate) {
              cell1.innerHTML = this.formatDate(item[field]);
            } else {
              if (item[field]) {
                cell1.innerHTML = item[field];
                cell1.style.textAlign = cell.style.textAlign;
              }
            }
            cell1.style.border = cell.style.border;
            cell1.style.padding = cell.style.padding;
            cell1.style.backgroundColor = cell.style.backgroundColor;
            cell1.style.height = cell.style.height;
          }
        }
      }
    }
  }

  logoIsValid() {
    if (
      this.property.logo &&
      this.property.logo.indexOf(Guid.emptyGuid().toString()) == -1
    ) {
      return true;
    }
    return false;
  }

  validate() {
    this.errors = [];
    if (!this.invoice) {
      this.errors.push("Invoice cannot be found.");
    }

    if (!this.errors.length) {
      return true;
    } else {
      return false;
    }
  }

  getTotalChargesPreTax() {
    let sum: number = 0;
    if (this.invoice.charges) {
      for (const c of this.invoice.charges) {
        sum += c.preTaxSubTotal;
      }
      // this.invoice.charges.forEach((i) => {
      //   if (
      //     i.kind == InvoiceItemKind.Charge ||
      //     i.kind == InvoiceItemKind.UnitRate
      //   ) {

      //   }
      // });
    }
    return sum;
  }

  getPaidAmount() {
    let sum: number = 0;
    if (this.invoice.payments) {
      this.invoice.payments.forEach((i) => {
        sum += i.totalPrice;
      });
    }
    return sum;
  }

  getTotalTax() {
    // all charges where the discount is not zero
    let sum: number = 0;
    if (this.invoice.charges) {
      this.invoice.charges.forEach((charge) => {
        sum +=
          charge.tax1Total +
          charge.tax2Total +
          charge.tax3Total +
          charge.tax4Total +
          charge.tax5Total +
          charge.tax6Total +
          charge.tax7Total +
          charge.tax8Total +
          charge.tax9Total +
          charge.tax10Total;
      });
    }
    return sum;
  }

  isPaid(): boolean {
    let result = false;
    if (this.getDueAmount() == 0) {
      result = true;
    }
    return result;
  }

  getReadableInvoiceId(id: string) {
    if (!id) {
      return "";
    }
    return (
      "#" + id.toString().replaceAll("-", "").substring(0, 6).toUpperCase()
    );
  }

  async complete() {
    this.errors = [];
    this.showProgressOverlay = true;
    this.submitClicked = true;
    let response = await this.stripeInstance.createToken(this.cardNumberEl);

    if (response.error) {
      this.errors.push(response.error.message);
      this.showProgressOverlay = false;
    } else {
      const token = response.token.id;
      this.payment.stripeId = token;
      this.payment.preTaxPrice = -this.payment.preTaxPrice;
      this.payment.totalPrice = -this.payment.totalPrice;
      this.payment.stripeFee = -this.payment.stripeFee;
      this.payment.personGuid = this.person.personGuid;

      try {
        this.cardResult = await processPayment(this.payment);
        if (this.cardResult.successful && this.cardResult.authCode) {
          let cardTokenResponse = await this.stripeInstance.createToken(
            this.cardNumberEl
          );
          const cardToken = cardTokenResponse.token.id;
          this.payment.stripeId = cardToken;
          this.payment = await savePayment(this.payment);
          // do this because we're not in calculation mode
          this.payment.stripeFee = 0;

          this.invoice = await saveInvoice(this.invoice);
          if (this.reservation) {
            this.reservation = await saveReservation(this.reservation);
          }
        } else {
          this.errors.push(this.cardResult.error);
        }
      } catch (err) {
        this.errors.push("Error processing payment-" + err);
      }
      this.showProgressOverlay = false;
    }
  }

  async getAccessTokenFromServer(creds: LoginCredentials) {
    await AuthService.getAccessToken(creds);
  }

  async initCardComponents(): Promise<void> {
    if (
      this.property &&
      this.property.checkoutSettings &&
      (this.property.checkoutSettings.isTestMode ||
        (this.property.checkoutSettings.stripePublishableKey &&
          this.property.checkoutSettings.stripeConnectAccountId))
    ) {
      this.stripeInstance = (window as any).Stripe(
        this.property.checkoutSettings &&
          this.property.checkoutSettings.isTestMode
          ? this.testStripePublishableKey
          : this.property.checkoutSettings.stripePublishableKey
      );
      const elements = this.stripeInstance.elements();
      this.cardNumberEl = elements.create(StripeElement.CARD_NUMBER, {
        ...COMPONENT_OPTIONS,
        ...ELEMENT_CONFIG[StripeElement.CARD_NUMBER],
      });
      this.cardExpiryEl = elements.create(StripeElement.CARD_EXPIRY, {
        ...COMPONENT_OPTIONS,
        ...ELEMENT_CONFIG[StripeElement.CARD_EXPIRY],
      });
      this.cardCvcEl = elements.create(StripeElement.CARD_CVC, {
        ...COMPONENT_OPTIONS,
        ...ELEMENT_CONFIG[StripeElement.CARD_CVC],
      });

      this.cardNumberEl.mount("#stripe-card-number-element");
      this.cardExpiryEl.mount("#stripe-card-expiry-element");
      this.cardCvcEl.mount("#stripe-card-cvc-element");
    }
  }

  getDueDate() {
    // if the invoice has it set, use that
    // otherwise use the reservation's
    if (
      this.invoice &&
      new Date(this.invoice.depositDueDate).isMinDate() == false
    ) {
      return this.invoice.depositDueDate;
    } else if (
      this.reservation &&
      new Date(this.reservation.depositDate).isMinDate() == false
    ) {
      return this.reservation.depositDate;
    } else if (this.reservation && this.reservation.arrivalDate) {
      return this.reservation.arrivalDate;
    }
    return 0;
  }

  getFeeTotal() {
    if (this.payment && this.payment.stripeFee) {
      return this.payment.stripeFee;
    }
    return 0;
  }

  getBalance() {
    return this.invoice.balance;
  }

  getDueAmount() {
    return this.invoice.due;
  }

  formatDate(dateTo: Date) {
    if (new Date(dateTo).isMinDate()) {
      return "";
    }
    return new Date(dateTo).readableDate(false);
  }

  formatCurrency(num: number) {
    if (!this.property) {
      return "";
    }
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  async mounted() {
    this.showProgressOverlay = true;
    let creds = new LoginCredentials();
    creds.email = process.env.VUE_APP_CLIENT_SECRET;
    creds.password = process.env.VUE_APP_CLIENT_PASS;
    try {
      await this.getAccessTokenFromServer(creds);
    } catch {
      this.errors.push("No Access");
    }
    if (this.$route.params.id) {
      let invoiceGuid = this.$route.params.id.toString();
      try {
        this.invoice = await getInvoice(invoiceGuid);
      } catch (err) {
        this.showProgressOverlay = false;
        this.errors.push("Invoice not found");
      }
      if (this.invoice) {
        this.property = await getProperty(this.invoice.propertyGuid);
        if (
          this.invoice.reservationGuid &&
          this.invoice.reservationGuid != Guid.emptyGuid.toString()
        ) {
          if (this.invoice.reservationGuid !== Guid.emptyGuid().toString()) {
            this.reservation = await getReservation(
              this.invoice.reservationGuid
            );
          }
        }
        try {
          this.person = await getPerson(this.invoice.personGuid);
        } catch {}
        if (this.isPaid() == false) {
          await this.initCardComponents();

          this.payment.paymentGuid = Guid.newGuid();
          this.payment.invoiceGuid = this.invoice.invoiceGuid;
          this.payment.type = PaymentType.CreditCard;
          if (
            this.reservation &&
            this.reservation.depositAmount == this.getDueAmount()
          ) {
            this.payment.kind = InvoiceItemKind.Deposit;
            this.payment.itemName = "Deposit";
          } else {
            this.payment.kind = InvoiceItemKind.Payment;
            this.payment.itemName = "Payment";
          }
          this.payment.chargeDate = new Date();
          this.payment.createdDate = new Date();
          if (this.reservation) {
            this.payment.reservationGuid = this.reservation.reservationGuid;
          }

          if (this.person) {
            this.payment.itemName =
              this.payment.itemName + " - " + this.person.firstSpaceLast;
            this.payment.personGuid = this.person.personGuid;
          }
          this.payment.preTaxPrice = this.getDueAmount();
          this.payment.totalPrice = this.payment.preTaxPrice;
          if (
            this.property.checkoutSettings.feeBillingPlan ==
            FeeBillingPlan.CustomerAddOn
          ) {
            this.payment.stripeFee = calculateStripeFee(this.payment);
          } else {
            this.payment.stripeFee = 0;
          }
        }
      } else {
        this.errors.push("Invoice not found");
      }
    }

    this.showProgressOverlay = false;
  }
}
