
import SearchBox from "@/components/Controls/SearchBox.vue";
import Property from "@/models/Property";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "../Auth/User";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");

@Component({
  components: { SearchBox },
})
class GwsHeader extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Auth.State("user")
  private currentUser!: User;
  s;

  @Auth.Action
  private signOut!: () => void;

  private loading = true;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;

  navigateTo(page: string) {
    window.open(page, "_blank");
  }

  toggleSmallScreenMenu() {
    var x = document.getElementById("navDemo");
    if (x) {
      if (x.className.indexOf("lv-show") == -1) {
        x.className += " lv-show";
      } else {
        x.className = x.className.replace(" lv-show", "");
      }
    }
  }

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  getUser() {
    if (this.currentUser) {
      return this.currentUser;
    } else {
      return null;
    }
  }

  logOut() {
    this.signOut();
    this.$router.push({
      name: "Login",
    });
  }

  propertyName() {
    if (!this.property) {
      return "";
    }
    return this.property.name;
  }

  onWindowResized() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  created() {
    this.loading = true;
    this.loading = false;
  }

  scrollToTop() {
    const myDiv = document.getElementById("book-wrap");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }
}
export default GwsHeader;
