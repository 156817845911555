export class UnitGroup {
   
    constructor() {
        this.name = "";
    }
    public unitGroupGuid!: string;
    public propertyGuid!: string;
    public name!: string;
    public thumb!: string;
    public description!: string;
    public createdDate!: Date;
}