
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
@Component({})
export default class ConfigurationNavMenu extends Vue {
  @Prop({ default: null }) public active!: string;

  isActiveTab(section: string) {
    return section.toLowerCase() == this.$route.name?.toLowerCase();
  }

  navigate(page: string) {
    this.$router.push({ name: page });
  }
}
