
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MaskedInput from "@/components/Controls/MaskedInput.vue";
import CheckoutSettings, {
  FeeBillingPlan,
  getCheckoutSettings,
  PaymentMethod,
  saveCheckoutSettings,
} from "@/models/CheckoutSettings";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { MaskedInput },
})
export default class BillingSettings extends Vue {
  @PropertyStore.State
  public property!: Property;
  @PropertyStore.Action
  public setProperty!: (prop: Property) => void;
  @PropertyStore.Action
  public getPropertyFromServer!: (propGuid: string) => Promise<Property>;
  private busy = false;
  private testMode = false;

  async mounted() {
    this.property.checkoutSettings = await getCheckoutSettings(
      this.property.propertyGuid
    );
    this.testMode = this.property.checkoutSettings.isTestMode;
  }

  testModeChanged(arg: any) {
    this.property.checkoutSettings.isTestMode = arg;
  }

  marketplaceEnabledChanged(arg: any) {
    this.property.checkoutSettings.marketplaceEnabled = arg;
  }
  usingDesktopChanged(arg: any) {
    this.property.checkoutSettings.usingDesktop = arg;
  }

  feeBillingPlanChanged(arg: any) {
    if (arg) {
      if (arg == "3") {
        this.property.checkoutSettings.feeBillingPlan =
          FeeBillingPlan.CustomerAddOn;
      } else if (arg == "4") {
        this.property.checkoutSettings.feeBillingPlan =
          FeeBillingPlan.Inclusive;
      }
    }
  }

  paymentMethodChanged(arg: any) {
    if (arg) {
      if (arg == "5") {
        this.property.checkoutSettings.paymentMethod =
          PaymentMethod.StripeConnect;
      }
    }
  }

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  async save() {
    //save the preference and property
    this.busy = true;
    await saveCheckoutSettings(this.property.checkoutSettings);
    await this.setProperty(this.property);
    this.navigate("Settings");
    this.busy = false;
  }
}
