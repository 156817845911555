import { GroupMemberStatus } from "./ResStatus";

export class PersonInReservation {
    constructor() {
    }

    public personGuid!: string;
    public reservationGuid!: string;
    public isPrimaryContact!: boolean;
    public unitGuid!: string | null;
    public status!: GroupMemberStatus;
    public name!: string;

    // calculated fields
    public unitName!: string;
    public charges!: number;
    public payments!: number;
    public balance!: number;
}