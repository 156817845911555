import { axiosRetry } from "@/utils/fetchUtil";

export class AddOn {
    constructor() {
    }
    public chargeItemGuid!: string;
    public maxQuantity!: number;
    public quantity!: number;
    public description!: string;
    public unitGuid!: string;
    public price!: number
}

export async function getAddOns(unitGuid: string, start?: Date, end?: Date): Promise<Array<AddOn>> {
    const response = await axiosRetry(
        `/api/chargeitem/getaddons/${unitGuid}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<AddOn>>;
    } else {
        const err =
            "An error occurred getting the customQuestion from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}