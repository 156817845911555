
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  getProperty,
  saveProperty,
} from "@/components/Cloud/Settings/PropertyService";
import MaskedInput from "@/components/Controls/MaskedInput.vue";
import { Guid } from "@/utils/Guid";
import { Document } from "../Documents/Document";
import { saveDocument, uploadDocument } from "../Documents/DocumentService";
import timezoneJson from "../../Controls/TimeZones.json";
import {
  getVerificationStatus,
  sendAwsVerificationEmail,
  SendEmailParams,
} from "../Templates/EmailService";
import { CurrencyCodes } from "../Invoices/Invoice";
import { VueEditor } from "vue2-editor";
import { saveCheckoutSettings } from "@/models/CheckoutSettings";
import ToolbarSettings from "./ToolbarSettings";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { MaskedInput, VueEditor },
})
export default class Business extends Vue {
  @PropertyStore.State
  private property!: Property;
  // @PropertyStore.Action
  // public getPropertyFromServer!: (propGuid: string) => Promise<Property>;
  @PropertyStore.Action
  public setProperty!: (prop: Property) => void;
  private toolbar = new ToolbarSettings();

  public thisProperty = new Property();
  private isTest = location.href.includes("localhost");
  private loading = true;

  private LIVE_CLIENT_ID = "ca_By8DNqQKKZWt7AcEg5uNsymIm89GxZ9w";
  private TEST_CLIENT_ID = "ca_By8D7wUp4xOY6oyzGZWPqd8O44NnI1Ex";

  private LIVE_REDIRECT_URI = "https://cloud.lodgevault.com/stripecomplete";
  private TEST_REDIRECT_URI = "http://localhost:8080/stripecomplete";

  private clientId = this.isTest ? this.TEST_CLIENT_ID : this.LIVE_CLIENT_ID;
  private redirectUri = this.isTest
    ? this.TEST_REDIRECT_URI
    : this.LIVE_REDIRECT_URI;

  private primaryColor = "";
  private secondaryColor = "";
  private timezones = new Array<string>();
  private currencycodes = new Array<string>();
  private verificationStatus = "Pending";
  private timer = 100;
  private verifyMessage = "";
  private buttonHref = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${this.clientId}&scope=read_write&redirect_uri=${this.redirectUri}`;
  terms: string = "";

  clearSeasonDates() {
    this.thisProperty.seasonCloseDate = undefined;
    this.thisProperty.seasonOpenDate = undefined;
  }

  goToDkim() {
    this.$router.push({
      name: "VerifySuccess",
      query: { hideMessage: "true" },
    });
  }

  phoneChanged(arg: any) {
    this.thisProperty.phone = arg;
  }
  async sendVerification() {
    let params = new SendEmailParams();
    params.recipientEmail = this.thisProperty.email;
    await sendAwsVerificationEmail(params);
    this.verifyMessage = "Sent";
  }

  // poller for verifivation status
  async getVerificationStatus() {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.verificationStatus = await getVerificationStatus(
        this.thisProperty.email
      );
      if (this.verificationStatus == "Success") {
        this.abortTimer();
      }
    }, this.timer);
  }

  abortTimer() {
    clearTimeout(this.timer);
  }

  getCheckoutUrl() {
    return (
      "https://book.lodgevault.com?property=" + this.thisProperty.propertyGuid
    );
  }
  navigateToCheckout() {
    window.open(this.getCheckoutUrl(), "_blank");
  }

  async imageUploaded(file: File) {
    this.loading = true;
    var formData = new FormData();
    let doc = new Document();
    doc.documentGuid = Guid.newGuid();
    doc.propertyGuid = this.thisProperty.propertyGuid;
    doc.createdDate = new Date();
    doc.modifiedDate = new Date();
    doc.path = file.name;
    await saveDocument(doc);
    formData.append("files", file, doc.documentGuid + ".jpg");
    await uploadDocument(formData);
    this.thisProperty.imageGuid = doc.documentGuid;
    this.thisProperty.logo =
      "https://s3.us-east-2.amazonaws.com/service.lodgevault/" +
      this.thisProperty.imageGuid +
      ".jpg";
    this.loading = false;
  }

  validateName() {
    return null;
  }

  async mounted() {
    this.thisProperty = await getProperty(this.property.propertyGuid);
    if (this.thisProperty.checkoutSettings.cancellationPolicy) {
      this.terms = this.thisProperty.checkoutSettings.cancellationPolicy;
    }
    this.currencycodes = [CurrencyCodes.USD, CurrencyCodes.CAD];
    this.timezones = timezoneJson;
    await this.getVerificationStatus();
  }

  // async getProperty(propGuid: string) {
  //   await this.getPropertyFromServer(propGuid)
  //     .then((p) => {
  //       this.setProperty(p);
  //     })
  //     .catch((ex) => {
  //       console.error(ex);
  //     })
  //     .finally(() => (this.loading = false));
  // }

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  async save() {
    //save the property info
    this.thisProperty.checkoutSettings.cancellationPolicy = this.terms;
    await saveProperty(this.thisProperty);
    await saveCheckoutSettings(this.thisProperty.checkoutSettings);
    await this.setProperty(this.thisProperty);
    // navigate to teh settings page
    this.navigate("Settings");
  }

  async cancel() {
    this.navigate("Settings");
  }
}
