
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { Activity, ActivityKind } from "@/components/Cloud/Activity/Activity";
import {
  getActivity,
  saveActivity,
} from "@/components/Cloud/Activity/ActivityService";
import TemplateEdit from "../Templates/TemplateEdit.vue";
import { User } from "../Auth/User";
import AuthService from "../Auth/AuthService";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { TemplateEdit },
})
export default class ActivityEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public activityGuid!: string;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  private users = new Array<User>();
  private showTemplateModal = false;
  private showEditModal = false;
  private loading = false;
  public activity = new Activity();
  private busy = false;
  private createdTime: string = "00:00:00";
  private initialCreatedDate = new Date();

  createdTimeChanged(arg: any) {
    this.createdTime = arg.toString();
  }

  createdDateChanged(arg: any) {
    this.activity.createdDate = new Date(arg + " " + this.createdTime);
  }

  getKindString(kind: ActivityKind) {
    if (kind) {
      let nameOfKind = ActivityKind[kind]; // "A"
      return nameOfKind;
    }
  }

  showEditButton() {
    if (this.activity.fileContent) {
      return true;
    }
    return false;
  }

  getActivityGuid() {
    if (this.activity) {
      return this.activity.activityGuid;
    } else {
      return "";
    }
  }

  // ?? I don't think we need these?
  getReservationGuid() {}
  getPersonGuid() {}
  getSendToEmailAddress() {}

  validateName() {
    return true;
  }

  public cancel() {
    //emit a cancel event
    this.$emit("close");
  }

  async edit() {
    // open a tmeplate edit screen in edit mode that shows the file content of this activity
    this.showTemplateModal = true;
  }

  getActivity() {
    return this.activity;
  }

  async mounted() {
    this.loading = true;
    if (this.activityGuid) {
      this.activityGuid = this.activityGuid;
    } else if (this.$route.params.activityGuid) {
      this.activityGuid = this.$route.params.id.toString();
    } else if (this.$route.query.activityGuid) {
      this.activityGuid = this.$route.query.activityGuid.toString();
    }
    if (this.activityGuid) {
      this.activity = await getActivity(this.activityGuid.toString());
    }
    if (!this.activity.activityGuid) {
      this.activity = new Activity();
      this.activity.activityGuid = Guid.newGuid();
      this.activity.propertyGuid = this.property.propertyGuid;
      if (this.personGuid) {
        this.activity.personGuid = this.personGuid;
      }
      if (this.reservationGuid) {
        this.activity.reservationGuid = this.reservationGuid;
      }
      this.activity.createdDate = new Date();
    }
    this.users = await AuthService.getUsersForProperty(
      this.property.propertyGuid
    );

    this.initialCreatedDate = this.activity.createdDate;
    this.createdTime = new Date(this.activity.createdDate).time(false);

    this.loading = false;
  }

  async saveAndClose() {
    this.busy = true;
    //const nameTextBox = this.$refs["name-textbox"];
    // this.activity.description = (nameTextBox as any).localValue;
    await saveActivity(this.activity).then(() =>
      this.$emit("success", this.activity)
    );
    this.busy = false;
  }
}
