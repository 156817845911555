
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Activity } from "./Activity";
import ActivityEdit from "./ActivityEdit.vue";

@Component({ components: { ActivityEdit } })
class ActivityModal extends Vue {
  @Prop({ default: null }) public activityGuid!: Guid;
  @Prop({ default: null }) public personGuid!: Guid;
  @Prop({ default: null }) public reservationGuid!: Guid;
  private loading = false;

  async success(activity: Activity) {
    this.$emit("success", activity);
  }
  async close() {
    this.$emit("close");
  }
}
export default ActivityModal;
