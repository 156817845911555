
import { Vue, Component } from "vue-property-decorator";
import SearchBar from "./SearchBar.vue";
import UnitCard from "./UnitCard.vue";
import { Unit } from "@/components/Cloud/Units/Unit";
import { getUnits } from "../Cloud/Units/UnitService";
@Component({
  components: { SearchBar, UnitCard },
})
export default class UnitListings extends Vue {
  private busy = false;
  private unitListings = new Array<Unit>();
  private propertyGuid!: string;
  private pickedDate: Date = new Date();

  // async categoriesChanged(arg: any) {
  //   if (!this.loaded) {
  //     return;
  //   }
  //   this.selectedCategories = arg;
  //   let names = this.selectedCategories.map((c) => c.name);
  //   this.$router.replace({
  //     query: {
  //       ...this.$router.currentRoute.query,
  //       categories: names.join(","),
  //     },
  //   });
  // }

  async datePicked(arg: any) {
    // this.pickedDate = new Date(arg);
    this.$router.replace({
      query: {
        ...this.$router.currentRoute.query,
        date: new Date(this.pickedDate).dashedDate(),
      },
    });
  }

  async created() {
    if (this.$route.query.prop) {
      this.propertyGuid = this.$route.query.prop.toString();
    }
  }

  async mounted() {
    this.unitListings = await getUnits(this.propertyGuid);
  }
}
