
import { Vue, Component } from "vue-property-decorator";
@Component({
  components: {},
})
export default class App extends Vue {
  public layout = `div`;

  created() {
    document.title = "Lodge Vault";
  }
}
