import { render, staticRenderFns } from "./AssignTable.vue?vue&type=template&id=5569c64d&scoped=true&"
import script from "./AssignTable.vue?vue&type=script&lang=ts&"
export * from "./AssignTable.vue?vue&type=script&lang=ts&"
import style0 from "./AssignTable.vue?vue&type=style&index=0&id=5569c64d&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5569c64d",
  null
  
)

export default component.exports