import { Person } from "../components/Cloud/People/Person";

export class PeoplePagedModel {

    public PeoplePagedModel() {
        //default values
        this.page = 1;
        this.itemStart = 1;
        this.itemEnd = 20;
    }

    public people!: Array<Person>;
    public page!: number;
    public itemStart!: number;
    public itemEnd!: number;
    public totalCount!: number;
    public relationshipCounts!: Array<number>;
}