import { Unit } from "../Cloud/Units/Unit";
import CartItem, { getAddOns, getDelivery } from "./CartItem";

export class Cart {
    constructor() {
        this.billingCurrency = `USD`;
        this.units = new Array<Unit>();
    }
    public cartGuid!: string;
    public modifiedDate!: Date;
    public createdDate!: Date;
    public billingCurrency: string;
    public items!: Array<CartItem>;
    public firstNightTotal: number = 0;
    public unitCharges: number = 0;
    public tax: number = Sum(this.items, 'TaxAmount');
    public deposit: number = 0;
    public fee: number = 0;
    public propertyGuid!: string;
    public units: Array<Unit>;
    public total: number = this.tax + this.unitCharges;
    public balance: number = this.total + this.deposit;
}

export function getTotalDue(cartItems: Array<CartItem>): number {
    let total = 0;

    if (!cartItems) {
        return 0;
    }

     for (const cartItem of cartItems) {
        total += cartItem.dueToday;
    }
    return total;
}


export function getTotal(cartItems: Array<CartItem>): number {
    let total = 0;

    if (!cartItems) {
        return 0;
    }
    // add up all cart items totals inlcuding taxes and fees
    for (const cartItem of cartItems) {
        let deliv = getDelivery(cartItem);
        let addons = getAddOns(cartItem);
        total += (deliv + addons + Math.abs(cartItem.appFee) + Math.abs(cartItem.stripeFee) + cartItem.price + cartItem.taxAmount);
    }
    return total;
}

export function getTotalDelivery(cartItems: Array<CartItem>): number {
    let sum = 0;
    if (cartItems && cartItems.length > 0) {
        for (const ci of cartItems) {
            let delivery = getDelivery(ci);
            sum += delivery;
        }
    }
    return sum;
}

export function getTotalAddOns(cartItems: Array<CartItem>): number {
    let sum = 0;
    if (cartItems && cartItems.length > 0) {
        for (const ci of cartItems) {
            if (ci.addOns && ci.addOns.length > 0) {
                let addOnsTotal = getAddOns(ci);
                sum += addOnsTotal;
            }
        }
    }
    return sum;
}

export function getTotalTaxAmount(cartItems: Array<CartItem>): number {
    let sum: number = 0;
    if (cartItems && cartItems.length > 0) {
        sum = cartItems
            .map((a) => a.taxAmount)
            .reduce(function (a, b) {
                return a + b;
            });
    }
    return sum;
}

export function getTotalAppFee(cartItems: Array<CartItem>): number {
    let sum: number = 0;
    if (cartItems && cartItems.length > 0) {
        sum = cartItems
            .map((a) => a.appFee)
            .reduce(function (a, b) {
                return a + b;
            });
    }
    return isNaN(sum) ? 0 : Math.abs(sum);
}

export function getTotalStripeFee(cartItems: Array<CartItem>): number {
    let sum: number = 0;
    if (cartItems && cartItems.length > 0) {
        sum = cartItems
            .map((a) => a.stripeFee)
            .reduce(function (a, b) {
                return a + b;
            });
    }
    return isNaN(sum) ? 0 : Math.abs(sum);
}

export function getTotalPrice(cartItems: Array<CartItem>): number {
    let sum: number = 0;
    if (cartItems && cartItems.length > 0) {
        sum = cartItems
            .map((a) => a.price)
            .reduce(function (a, b) {
                return a + b;
            });
    }
    return sum;
}

export function getTotalDeposit(cartItems: Array<CartItem>): number {
    let sum: number = 0;
    if (cartItems && cartItems.length > 0) {
        sum = cartItems
            .map((a) => a.deposit)
            .reduce(function (a, b) {
                return a + b;
            });
    }
    return isNaN(sum) ? 0 : Math.abs(sum);
}

function Sum(Items: CartItem[], prop: string): number {
    if (!Items) {
        return 0;
    }
    var total = 0;
    for (var i = 0, _len = Items.length; i < _len; i++) {
        total += Items[i][prop];
    }
    return total;
}
