
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import "@/utils/dateUtil";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import StyleThumb from "../Marketplace/StyleThumb.vue";
import CartItem from "./CartItem";

const cartStore = namespace("CartStore");
const PropertyStore = namespace("PropertyStore");
@Component({ components: { StyleThumb } })
class CartPopup extends Vue {
  @PropertyStore.State
  public property!: Property;

  goToCart() {
    this.showOrHidePopupCart();
    this.$router.push({
      name: "Cart",
    });
  }

  arrivalDateAndTime(ci: CartItem) {
    return new Date(ci.inDateTime).formatDayOfWeekMMDDYY();
  }
  nights(ci: CartItem) {
    let nights = new Date(ci.inDateTime).daysDiff(new Date(ci.outDateTime));
    if (nights == 0) {
      return " @ " + new Date(ci.inDateTime).time(true);
    }
    return nights + " nights";
  }
  hasCartItem() {
    return this.cartItems.length > 0;
  }

  showPopupCart() {
    this.showOrHidePopupCart();
  }

  totalPrice() {
    return this.cartItems.reduce((current, next) => current + next.price, 0);
  }

  formatCurrency(num: number) {
    return formatter(num, this.cartItems[0].currencyCode);
  }

  @cartStore.State
  public cartItems!: Array<CartItem>;

  @cartStore.Action
  public showOrHidePopupCart!: () => void;
}
export default CartPopup;
