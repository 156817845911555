var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-nav',{attrs:{"vertical":""}},[_c('div',{staticClass:"vertical-nav-top-spacer"}),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('Revenue'),
    },on:{"click":function($event){return _vm.navigate('Revenue')}}},[_vm._v("Revenue")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('Payments'),
    },on:{"click":function($event){return _vm.navigate('Payments')}}},[_vm._v("Payments")]),_c('b-nav-item',{class:{
      active: _vm.isActiveTab('Invoices'),
    },on:{"click":function($event){return _vm.navigate('Invoices')}}},[_vm._v("Invoices")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }