
import { fetchRetry } from "@/utils/fetchUtil";
import { GridModel } from "@/models/GridModel";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { GridUnit } from "@/models/GridUnit";
import { Unit, UnitTypes } from "@/components/Cloud/Units/Unit";
import Property from "@/models/Property";
import { GridDay } from "@/models/GridDay";
import { AvailabilityParameters } from "@/models/AvailabilityParameters";
import { formatter, roundToDollar } from "@/utils/currencyUtils";
import MobilePopup from "@/components/SiteBook/MobilePopup.vue";

const PropertyStore = namespace("PropertyStore");
const AvailabilityParametersStore = namespace("AvailabilityParametersStore");
@Component({
  components: { MobilePopup },
})
export default class MobileGrid extends Vue {
  @PropertyStore.State
  public property!: Property;

  private showOverlay = false;
  private gridModel = new GridModel();
  private firstClickedDate!: Date | null;
  private secondClickedDate!: Date | null;
  private yesterday = new Date().addDays(-1);
  private showMobilePopup = false;
  private clickedGridUnit: GridUnit | undefined;
  private skipUnavailable = false;

  formatCurrency(num: number) {
    let result = roundToDollar(num);
    if (this.property.currencyCode === "CAD") {
      result = "CA" + result;
    }
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  showAsAvailableDay(gridDay: GridDay) {
    return (!gridDay.booked && !gridDay.isInPast) || gridDay.arriving;
  }

  showAsBooked(gridDay: GridDay) {
    return (
      (gridDay.booked || gridDay.isInPast || gridDay.isToday) &&
      !gridDay.arriving
    );
  }

  displayPrice(gridDay: GridDay) {
    if (gridDay.nightlyPrice) {
      return gridDay.nightlyPrice;
    }
  }

  shouldShowPrice(gridDay: GridDay): boolean {
    return (
      this.property.checkoutSettings.showPricesOnGrid &&
      !gridDay.booked &&
      !gridDay.isInPast &&
      !gridDay.isToday &&
      gridDay.inMonth &&
      (gridDay.nightlyPrice > 0 || gridDay.weeklyPrice > 0) &&
      !gridDay.arriving &&
      !gridDay.isSecondDate
    );
  }

  isSelected(gDay: GridDay): boolean {
    const result = gDay.selected && gDay.selected === true;
    return result;
  }
  firstDate() {
    return this.firstClickedDate ? this.firstClickedDate : "";
  }

  secondDate() {
    return this.secondClickedDate ? new Date(this.secondClickedDate) : "";
  }

  minMaxNightsDisplay(gridUnit: GridUnit) {
    if (gridUnit.unit.minimumNights == 0) {
      gridUnit.unit.minimumNights = 1;
    }
    if (gridUnit.unit.maximumNights == 0) {
      gridUnit.unit.maximumNights = gridUnit.unit.minimumNights;
    }
    //can be guests or nights
    let result = "night";
    if (gridUnit.unit.maximumNights === gridUnit.unit.minimumNights) {
      result = gridUnit.unit.maximumNights + " " + result;
    } else {
      result =
        gridUnit.unit.minimumNights +
        " to " +
        gridUnit.unit.maximumNights +
        " " +
        result;
    }
    if (gridUnit.unit.maximumNights != 1) {
      result += "s";
    }
    return result;
  }

  minMaxPeopleDisplay(gridUnit: GridUnit) {
    if (gridUnit.unit.minimumPeople == 0) {
      gridUnit.unit.minimumPeople = 1;
    }
    if (gridUnit.unit.capacity == 0) {
      gridUnit.unit.capacity = gridUnit.unit.minimumPeople;
    }
    //can be guests or nights
    let result = "people";
    if (gridUnit.unit.capacity == 1) {
      result = "person";
    }
    if (gridUnit.unit.capacity === gridUnit.unit.minimumPeople) {
      result = gridUnit.unit.capacity + " " + result;
    } else {
      result =
        gridUnit.unit.minimumPeople +
        " to " +
        gridUnit.unit.capacity +
        " " +
        result;
    }
    return result;
  }

  adults() {
    return this.availParams ? this.availParams.adults : 2;
  }

  children() {
    return this.availParams ? this.availParams.children : 0;
  }

  private highlightDateRange(
    firstDate: Date,
    secondDate: Date,
    grdUnit: GridUnit
  ) {
    if (!this.$el) {
      return;
    }

    const container = document.querySelector(
      "#unit-" + grdUnit.unit.unitGuid.toString()
    );
    if (container) {
      const children = container.querySelectorAll(".grid-mobile-day-button");
      for (const cell of children) {
        const cellElement = cell as HTMLTextAreaElement;
        const thisCellData = cellElement?.getAttribute("data-date")?.toString();
        if (thisCellData) {
          const thisCellsDate = new Date(thisCellData);
          const gridDay = this.getGridDay(thisCellsDate, grdUnit);
          if (!gridDay) {
            //disregard; it's nothing
          } else {
            if (thisCellsDate.valueOf() === secondDate.valueOf()) {
              gridDay.selected = true;
              gridDay.isSecondDate = true;
              gridDay.isFirstDate = false;
              gridDay.inRange = false;
            } else if (thisCellsDate.valueOf() === firstDate.valueOf()) {
              gridDay.selected = true;
              gridDay.isFirstDate = true;
              gridDay.isSecondDate = false;
              gridDay.inRange = false;
            } else if (
              (thisCellsDate.valueOf() < secondDate.valueOf() &&
                thisCellsDate.valueOf() > firstDate.valueOf()) ||
              (thisCellsDate.valueOf() > secondDate.valueOf() &&
                thisCellsDate.valueOf() < firstDate.valueOf())
            ) {
              gridDay.inRange = true;
              gridDay.selected = false;
              gridDay.isFirstDate = false;
              gridDay.isSecondDate = false;
            } else {
              gridDay.inRange = false;
              gridDay.selected = false;
              gridDay.isFirstDate = false;
              gridDay.isSecondDate = false;
            }
          }
        }
      }
    }
  }

  async scroll(months: number) {
    if (months === 0) {
      this.gridModel.startDate = new Date();
    } else {
      this.gridModel.startDate = new Date(this.gridModel.startDate).addMonths(
        months
      );
    }
    this.skipUnavailable = false;
    this.populateGrid();
  }

  closeMobilePopup() {
    this.clear();
    this.showMobilePopup = false;
  }

  toggleMobilePopup() {
    this.showMobilePopup = !this.showMobilePopup;
  }

  dateClick(gridDay: GridDay, gridUnit: GridUnit) {
    const dateClicked = gridDay.dateFor;

    if (this.clickedGridUnit != gridUnit) {
      this.clear();
      this.clickedGridUnit = gridUnit;
    }

    if (this.firstClickedDate != null && this.secondClickedDate != null) {
      this.firstClickedDate = null;
      this.secondClickedDate = null;
      this.clear();
    }

    if (
      gridUnit.unit.unitType == UnitTypes.Event ||
      gridUnit.unit.unitType == UnitTypes.Rental
    ) {
      this.firstClickedDate = dateClicked;
      this.secondClickedDate = dateClicked;
      gridDay.selected = true;
      gridDay.isFirstDate = true;
      gridDay.isSecondDate = true;
      this.availParams.arrivalDate = this.firstClickedDate;
      this.availParams.departureDate = this.secondClickedDate;
      this.availParams.defaultMode = false;
      this.set(this.availParams);
      this.showMobilePopup = true;
    }

    if (!this.firstClickedDate) {
      if (!gridDay.arriving) {
        this.firstClickedDate = new Date(dateClicked);
        this.secondClickedDate = null;
        gridDay.selected = true;
        gridDay.isFirstDate = true;
      }
    } else if (!this.secondClickedDate) {
      this.secondClickedDate = new Date(dateClicked);
      gridDay.selected = true;
      gridDay.isSecondDate = true;
      //swap
      if (
        new Date(this.firstClickedDate).valueOf() >
        this.secondClickedDate.valueOf()
      ) {
        const firstDateTemp = this.firstClickedDate;
        this.firstClickedDate = this.secondClickedDate;
        this.secondClickedDate = firstDateTemp;
      }
    }

    if (this.firstClickedDate && this.secondClickedDate) {
      // do some validation
      if (
        !this.validate(this.firstClickedDate, this.secondClickedDate, gridUnit)
      ) {
        this.clear();
      } else {
        //successful date selection
        this.availParams.arrivalDate = this.firstClickedDate;
        this.availParams.departureDate = this.secondClickedDate;
        this.availParams.defaultMode = false;
        this.set(this.availParams);

        this.highlightDateRange(
          this.firstClickedDate,
          this.secondClickedDate,
          gridUnit
        );

        this.showMobilePopup = true;
      }
    }
  }

  validate(firstDate: Date, secondDate: Date, grdUnit: GridUnit): boolean {
    if (!this.$el) {
      return false;
    }

    const container = document.querySelector(
      "#unit-" + grdUnit.unit.unitGuid.toString()
    );

    firstDate = new Date(firstDate).removeTime();
    secondDate = new Date(secondDate).removeTime();

    if (container) {
      const children = container.querySelectorAll(".grid-mobile-day-button");
      for (const cell of children) {
        const cellElement = cell as HTMLTextAreaElement;
        const thisCellData = cellElement?.getAttribute("data-date")?.toString();
        if (thisCellData) {
          const thisCellsDate = new Date(thisCellData).removeTime();

          const gridDay = this.getGridDay(thisCellsDate, grdUnit);

          if (!gridDay) {
            //disregard; it's nothing
            //return false;
          } else {
            if (thisCellsDate.valueOf() === secondDate.valueOf()) {
              // departure
              if (gridDay.booked && !gridDay.arriving) {
                return false;
              }
            } else if (thisCellsDate.valueOf() === firstDate.valueOf()) {
              //arrival
              if (gridDay.booked || gridDay.arriving) {
                return false;
              }
            } else if (
              (thisCellsDate.valueOf() < secondDate.valueOf() &&
                thisCellsDate.valueOf() > firstDate.valueOf()) ||
              (thisCellsDate.valueOf() > secondDate.valueOf() &&
                thisCellsDate.valueOf() < firstDate.valueOf())
            ) {
              // in range
              if (gridDay.booked || gridDay.arriving) {
                return false;
              }
            } else {
              //out of range
            }
          }
        }
      }
    }
    return true;
  }

  private getGridDay(
    forDate: Date,
    forGridUnit: GridUnit
  ): GridDay | undefined {
    for (const gridUnit of this.gridModel.gridUnits) {
      if (gridUnit.unit.unitGuid === forGridUnit.unit.unitGuid) {
        for (const week of forGridUnit.weeks) {
          for (const gridDay of week) {
            if (new Date(gridDay.dateFor).areSameDay(forDate)) {
              return gridDay;
            }
          }
        }
      }
    }
  }

  private clear() {
    this.firstClickedDate = null;
    this.secondClickedDate = null;
    for (const gridUnit of this.gridModel.gridUnits) {
      for (const week of gridUnit.weeks) {
        for (const gridDay of week) {
          gridDay.selected = false;
          gridDay.inRange = false;
          gridDay.isFirstDate = false;
          gridDay.isSecondDate = false;
        }
      }
    }
    this.showOverlay = false;
  }

  async populateGrid() {
    this.showOverlay = !this.showOverlay;
    try {
      this.gridModel = await this.getAvailability();
    } catch (err) {
      throw new Error();
    }
    this.showOverlay = !this.showOverlay;
  }

  private async getAvailability(): Promise<GridModel> {
    const params = {
      PropertyGuid: this.property.propertyGuid,
      StartDate: this.gridModel.startDate,
      Length: this.gridModel.length,
      IsMobile: true,
      SkipUnavailable: this.skipUnavailable,
    };
    const response = await fetchRetry("/api/book/grid", params, "POST");
    if (response.ok) {
      return response.json() as Promise<GridModel>;
    } else {
      const err =
        "An error occurred retrieving pricing from the server." +
        `The error code was ${response.status} (${response.statusText}).`;
      throw Error(err);
    }
  }

  async mounted() {
    this.skipUnavailable = false;
    this.gridModel.startDate = this.availParams
      ? this.availParams.arrivalDate
      : new Date();
    this.gridModel.length = new Date(this.gridModel.startDate).daysInMonth();
    this.populateGrid().then(() => {
      this.clear();
    });
  }

  @AvailabilityParametersStore.State
  private availParams!: AvailabilityParameters;

  @AvailabilityParametersStore.Action
  public set!: (params: AvailabilityParameters) => Promise<boolean>;
}
