export class StringUtil {

    static isNumericInput(event) {
        const key = event.keyCode;
        return ((key >= 48 && key <= 57) || // Allow number line
            (key >= 96 && key <= 105) // Allow number pad
        );
    }
    
    static isModifierKey (event) {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    }
    
    static enforceFormat (event) {
        // Input must be of a valid number format or a modifier key, and not longer than ten digits
        if(!StringUtil.isNumericInput(event) && !StringUtil.isModifierKey(event)){
            event.preventDefault();
        }
    }
    
    static formatToPhone (event)  {
        if(StringUtil.isModifierKey(event)) {return;}
    
        const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
        const areaCode = input.substring(0,3);
        const middle = input.substring(3,6);
        const last = input.substring(6,10);
    
        if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
        else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
        else if(input.length > 0){event.target.value = `(${areaCode}`;}
    }

    static formatPhoneNumber(phoneNumberString) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
      }


    //static isNumber(n: string) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

    // The first 2 constiations return a Boolean
    // They just work the opposite way around
    static isNumber(val: any) {
        // IsInteger
        if (Number.isInteger(val)) {
            // It is indeed a number
            return true;
        }

        // isNaN (is not a number)
        if (isNaN(val)) {
            // It is not a number
            return false;
        }

        // Another option is typeof which return a string
        if (typeof (val) === 'number') {
            // Guess what, it's a bloody number!
            return true;
        }

        return false;
    }

    // static isNumber(n) {
    //     return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    //   }

}