
import { Field } from "@/models/interfaces/Field";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import {
  deleteTimeSlot,
  getTimeSlotsByCollection,
  getTimeSlotsByRate,
  TimeSlot,
} from "./TimeSlot";
import {
  getTimeSlotCollection,
  TimeSlotCollection,
} from "./TimeSlotCollection";

@Component({ components: {} })
export default class TimeSlotTable extends Vue {
  @Prop({ default: null }) public rateGuid: string | undefined;
  @Prop({ default: null }) public timeSlotCollectionGuid: string | undefined;
  @Prop({ default: null }) public propertyGuid!: string;
  private mode = "reservation";
  private loading = true;
  private showTimeSlotModal = false;
  private timeSlots: Array<TimeSlot> = [];
  private timeSlotFields: Array<Field> = [];
  private clickedTimeSlot!: TimeSlot | null;
  private timeSlotTable: any;
  private selected!: Array<any>;
  private timeSlotCollection = new TimeSlotCollection();

  timeSlotChanged(arg: any) {
    this.changed();
  }

  changed() {
    this.$emit("time-slots-changed", this.timeSlots);
  }

  async add() {
    this.clickedTimeSlot = null;
    let ts = new TimeSlot();
    ts.timeSlotGuid = Guid.newGuid();
    ts.propertyGuid = this.propertyGuid;
    if (this.rateGuid) {
      ts.rateGuid = this.rateGuid;
    }
    if (this.timeSlotCollectionGuid) {
      ts.timeSlotCollectionGuid = this.timeSlotCollectionGuid;
    }
    ts.durationDays = 0;
    this.timeSlots.push(ts);
    this.changed();
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.timeSlotTable.selectAllRows();
  }
  clearSelected() {
    this.timeSlotTable.clearSelected();
  }

  async saved(timeSlot: TimeSlot) {
    this.loading = true;
    // if the list doesn't contain the timeSlot, add it
    let found = this.timeSlots.find(
      (a) => a.timeSlotGuid === timeSlot.timeSlotGuid
    );
    if (!found) {
      await this.timeSlots.push(timeSlot);
    }
    let index = await this.timeSlots.findIndex(
      (a) => a.timeSlotGuid === timeSlot.timeSlotGuid
    );
    this.timeSlots[index] = timeSlot;
    await this.timeSlotTable.refresh();
    this.timeSlotTable.selectRow(index);
    this.showTimeSlotModal = false;
    this.loading = false;
  }

  private getTimeSlotFields() {
    let field = new Field();
    field.key = "startTimeOfDayString";
    field.label = "Start Time";
    this.timeSlotFields.push(field);

    field = new Field();
    field.key = "endTimeOfDayString";
    field.label = "End Time";
    this.timeSlotFields.push(field);

    field = new Field();
    field.key = "durationDays";
    field.label = "Days";
    this.timeSlotFields.push(field);

    field = new Field();
    field.key = "startDate";
    field.label = "Start Date";
    this.timeSlotFields.push(field);

    field = new Field();
    field.key = "endDate";
    field.label = "End Date";
    this.timeSlotFields.push(field);

    field = new Field();
    field.key = "actions";
    field.label = "Actions";
    this.timeSlotFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.timeSlotTable = this.$refs.timeSlotTableRef;
    this.getTimeSlotFields();
    if (this.rateGuid) {
      this.timeSlots = await getTimeSlotsByRate(this.rateGuid);
    } else if (this.timeSlotCollectionGuid) {
      this.timeSlots = await getTimeSlotsByCollection(
        this.timeSlotCollectionGuid
      );
      if (this.timeSlots && this.timeSlots.length > 0) {
        this.timeSlotCollection = await getTimeSlotCollection(
          this.timeSlotCollectionGuid
        );
      }
    } else {
      this.timeSlots = new Array<TimeSlot>();
    }
    this.loading = false;
  }

  getActiveTimeSlot() {
    if (this.clickedTimeSlot) {
      return this.clickedTimeSlot.timeSlotGuid;
    } else {
      return "";
    }
  }

  async deleteTimeSlot(arg: any) {
    // put up warning confirmation first?
    if ((this.selected && this.selected.length > 0) || arg) {
      let deleted =
        this.selected && this.selected.length > 0 ? this.selected[0] : arg;
      let result = await deleteTimeSlot(deleted.timeSlotGuid.toString());
      if (result) {
        if (this.timeSlots) {
          let index = this.timeSlots.findIndex(
            (a) => a.timeSlotGuid === deleted.timeSlotGuid
          );
          this.timeSlots.splice(index, 1);
        }
      }
    }
    this.changed();
    await this.timeSlotTable.refresh();
  }

  edit(timeSlot: any, index: any, event: any) {
    if (timeSlot) {
      this.clickedTimeSlot = timeSlot;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedTimeSlot = this.selected[0];
    }
    this.showTimeSlotModal = true;
  }

  closeTimeSlotModal() {
    this.showTimeSlotModal = false;
  }
}
