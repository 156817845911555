
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { Account } from "@/components/Cloud/Accounts/Account";
import {
  getAccount,
  saveAccount,
} from "@/components/Cloud/Accounts/AccountService";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class AccountEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public paramAccountGuid!: string;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  public accountGuid!: string;
  private showEditModal = false;
  private loading = false;
  public account!: Account;
  private busy = false;
  private expanded = false;
  validateName() {
    return true;
  }

  public cancel() {
    //emit a cancel event
    this.$emit("close");
  }

  getAccount() {
    return this.account;
  }

  async mounted() {
    this.loading = true;
    if (this.paramAccountGuid) {
      this.accountGuid = this.paramAccountGuid;
    } else if (this.$route.params.accountGuid) {
      this.accountGuid = this.$route.params.id.toString();
    } else if (this.$route.query.accountGuid) {
      this.accountGuid = this.$route.query.accountGuid.toString();
    }
    if (this.accountGuid) {
      this.account = await getAccount(this.accountGuid.toString());
    }
    if (!this.account) {
      this.account = new Account();
      this.account.accountGuid = Guid.newGuid();
      this.account.propertyGuid = this.property.propertyGuid;
      this.account.createdDate = new Date();
    }
    this.loading = false;
  }

  async saveAndClose() {
    this.busy = true;
    //const nameTextBox = this.$refs["name-textbox"];
    // this.account.description = (nameTextBox as any).localValue;
    await saveAccount(this.account).then(() =>
      this.$emit("success", this.account)
    );
    this.busy = false;
  }
}
