
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { Charge, recalculate, setTaxRates } from "./Charge";
import { getCharge, saveCharge } from "./ChargeService";
import { getRatePlanLabel, Rate } from "../Rates/Rate";
import { ChargeItem } from "../ChargeItems/ChargeItem";
import { getChargeItems } from "../ChargeItems/ChargeItemService";
import { getRates } from "../Rates/RateService";
import { InvoiceItemKind } from "./Invoice";
import MaskedInput from "@/components/Controls/MaskedInput.vue";
import ChargeItemEdit from "../ChargeItems/ChargeItemEdit.vue";
import RateEdit from "../Rates/RateEdit.vue";
import { formatter } from "@/utils/currencyUtils";
import { getAccounts } from "../Accounts/AccountService";
import { Account } from "../Accounts/Account";
import { User } from "../Auth/User";
import { Assign } from "../Assign/Assign";
import { Season } from "../Rates/Seasons/Season";
import PriceQuote from "@/models/PriceQuote";
import { getChargesForPriceQuote } from "../Units/UnitService";
import { Reservation } from "../Reservations/Reservation";
import { PlanType } from "../Rates/PlanType";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({
  components: { MaskedInput, ChargeItemEdit, RateEdit },
})
export default class AddCharge extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Auth.State("user")
  private currentUser!: User;

  @Prop({ default: null }) public invoiceGuid!: string;
  @Prop({ default: null }) public reservation!: Reservation;
  public localInvoiceGuid!: string;
  private loading = false;
  private chargeItems: Array<ChargeItem> = [];
  private assigns: Array<Assign> = [];
  private accounts: Array<Account> = [];
  private seasons: Array<Season> = [];
  private selectedSeason = new Season();
  private unitRates: Array<Rate> = [];
  private selectedChargeItem = new ChargeItem();
  private selectedAssign = new Assign();
  private selectedRate = new Rate();
  private account = new Account();
  private selected = "chargeItem"; // or unitRate
  private showEditModal = false;
  private emptyGuid = Guid.emptyGuid();
  private newCharges: Array<Charge> = []; // add to this array when Add New is clicked and return this in emit
  private quoteCharges: Array<Charge> = []; // add to this array for quote
  private preTaxPrice = 0;
  private quantity = 1;
  private numPeople = 2;
  private discount = 0;

  getReadablePlanType(rate: Rate) {
    return getRatePlanLabel(rate);
  }

  addAnother() {
    // copy charges over from quotedCharges to newcharges
    for (const quoteCharge of this.quoteCharges) {
      this.newCharges.push(quoteCharge);
    }
    this.quoteCharges = [];
    this.selectedChargeItem = new ChargeItem();
    this.account = new Account();
    this.selectedRate = new Rate();

    this.selectedSeason = new Season();
    this.selectedAssign = new Assign();
  }

  getPreTaxPrice() {
    let sum = 0;
    for (const charge of this.quoteCharges) {
      sum = sum + charge.preTaxPrice;
    }
    return sum;
  }
  getTotalTax() {
    let sum = 0;
    for (const charge of this.quoteCharges) {
      sum = sum + charge.totalTax;
    }
    return sum;
  }
  getTotalPrice() {
    let sum = 0;
    for (const charge of this.quoteCharges) {
      sum = sum + charge.totalPrice;
    }
    return sum;
  }

  formatCurrency(num: number) {
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  getActiveRate() {
    if (this.selectedRate && this.selectedRate.name != "") {
      return this.selectedRate;
    }
  }
  getActiveRateGuid() {
    if (this.selectedRate) {
      return this.selectedRate.rateGuid;
    }
  }

  cancelEdit() {
    this.showEditModal = false;
  }

  async savedChargeItem(arg: any) {
    this.chargeItems = await getChargeItems(this.property.propertyGuid);
    if (arg) {
      this.selectedChargeItem == arg;
    }
    this.showEditModal = false;
  }

  async savedRate(arg: any) {
    this.unitRates = await getRates(this.property.propertyGuid);
    if (arg) {
      this.selectedRate == arg;
    }
    this.showEditModal = false;
  }

  getActiveChargeItemGuid() {
    if (this.selectedChargeItem) {
      return this.selectedChargeItem.chargeItemGuid;
    }
  }

  getActiveChargeItem() {
    if (this.selectedChargeItem && this.selectedChargeItem.name != "") {
      return this.selectedChargeItem;
    }
  }

  async chargeItemSelected(arg: any) {
    if (!arg) {
      return;
    }
    this.loading = true;
    this.selectedChargeItem = arg;
    this.quoteCharges = [];
    this.preTaxPrice = this.selectedChargeItem.preTaxPrice;
    this.quantity = 1;
    let found = this.accounts.find(
      (a) => a.accountGuid == this.selectedChargeItem.accountGuid
    );
    if (found) {
      this.account = found;
    }
    this.recalculate();
    this.loading = false;
  }

  rateSelected(arg: any) {
    this.quoteCharges = [];
    if (!arg || !arg.name) {
      return;
    }
    this.selectedRate = arg;
    this.preTaxPrice = this.selectedRate.baseRate;
    this.quantity = 1;
    let found = this.accounts.find(
      (a) => a.accountGuid == this.selectedRate.accountGuid
    );
    if (found) {
      this.account = found;
    }
    this.recalculate();
  }

  assignSelected(arg: any) {
    if (!arg) {
      return;
    }
    this.selectedAssign = arg;
    this.recalculate();
  }

  async qtyChanged(arg: any) {
    await this.recalculate();
  }
  async discountChanged(arg: any) {
    if (!arg || arg == "") {
      this.discount = 0;
    }

    await this.recalculate();
  }
  numPeopleChanged(arg: any) {
    this.recalculate();
  }
  preTaxAmountChanged(arg: any) {
    this.recalculate();
  }

  addNewUnitRate() {
    // add a new unit rate
    this.showEditModal = true;
  }
  addNewChargeItem() {
    // open the charge item edit modal in add mode
    this.showEditModal = true;
  }

  validateName() {
    return true;
  }

  private cancel() {
    this.$emit("cancel");
  }

  async mounted() {
    this.loading = true;
    if (this.invoiceGuid) {
      this.localInvoiceGuid = this.invoiceGuid;
    } else if (this.$route.params.invoiceGuid) {
      this.localInvoiceGuid = this.$route.params.id.toString();
    } else if (this.$route.query.invoiceGuid) {
      this.localInvoiceGuid = this.$route.query.invoiceGuid.toString();
    }

    await Promise.all([
      (this.unitRates = await getRates(this.property.propertyGuid)),
      (this.chargeItems = await getChargeItems(this.property.propertyGuid)),
      (this.accounts = await getAccounts(this.property.propertyGuid)),
    ]);
    if (this.reservation && this.reservation.assigns) {
      this.assigns = this.reservation.assigns;
      this.selectedAssign = this.assigns[0];
    }
    this.selectedChargeItem.chargeItemGuid = this.emptyGuid;
    this.loading = false;
  }

  async recalculate() {
    this.loading = true;
    this.quoteCharges = [];
    // this.newCharge.reservationGuid = ??;
    // this.newCharge.personGuid = ??
    if (this.selected == "unitRate") {
      // if the assign is null, should we create a new dummy one?

      let priceQuote = new PriceQuote();
      priceQuote.discount = this.discount * 0.01;
      priceQuote.numberOfPeople = this.numPeople;
      priceQuote.changedBaseRate = this.preTaxPrice;
      priceQuote.selectedRateQuantity = this.quantity;
      priceQuote.assign = this.selectedAssign;
      priceQuote.rateSelected = this.selectedRate;
      priceQuote.charges = await getChargesForPriceQuote(priceQuote);
      await this.clear();
      if (priceQuote && priceQuote.charges && priceQuote.charges.length > 0) {
        for (const charge of priceQuote.charges) {
          charge.invoiceGuid = this.invoiceGuid;
          this.quoteCharges.push(charge);
        }
      }
    } else {
      let newCharge = new Charge();
      newCharge.itemName = this.selectedChargeItem.name;
      newCharge.preTaxPrice = this.preTaxPrice;
      newCharge.quantity = this.quantity;
      newCharge.invoiceGuid = this.invoiceGuid;
      newCharge.accountGuid = this.account.accountGuid;
      newCharge.createdDate = new Date();
      newCharge.chargeDate = new Date();
      newCharge.chargeGuid = Guid.newGuid();
      newCharge.takenBy = this.currentUser.userName;
      newCharge.discount = this.discount * 0.01;
      newCharge.kind = InvoiceItemKind.Charge;
      newCharge = setTaxRates(newCharge, this.account);
      newCharge = recalculate(newCharge);
      this.quoteCharges.push(newCharge);
    }

    this.loading = false;
  }

  async clear() {
    this.quoteCharges = [];
  }

  async saveAndClose() {
    this.loading = true;
    for (const quoteCharge of this.quoteCharges) {
      this.newCharges.push(quoteCharge);
    }
    this.$emit("charges-added", this.newCharges);
    this.$emit("close");
    this.loading = false;
  }
}
