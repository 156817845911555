import { UnitGroup } from '@/models/UnitGroup';
import { axiosRetry } from '@/utils/fetchUtil';

export async function deleteUnitGroup(unitGroupGuid: string): Promise<boolean> {
    const response = await axiosRetry(
        `/api/unitgroup/` + unitGroupGuid,
        null,
        "DELETE"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred deleting the unitGroup from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function saveUnitGroup(unitGroup: UnitGroup): Promise<boolean> {
    const response = await axiosRetry(
        `/api/unitgroup/`,
        unitGroup,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the unitGroup from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function getUnitGroups(propertyGuid: string): Promise<Array<UnitGroup>> {
    let url = `/api/unitgroup/getunitgroups/${propertyGuid}`;
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<UnitGroup>>;
    } else {
        const err =
            "An error occurred getting the unitGroup from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function getAllUnitGroups(propertyGuid: string): Promise<Array<UnitGroup>> {
    let url = `/api/unitgroup/getallunitgroups/${propertyGuid}`;
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<UnitGroup>>;
    } else {
        const err =
            "An error occurred getting the unitGroup from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function getUnitGroup(unitGroupGuid: string): Promise<UnitGroup> {
    const response = await axiosRetry(
        `/api/unitgroup/getunitgroup/${unitGroupGuid}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<UnitGroup>;
    } else {
        const err =
            "An error occurred getting the unitGroup from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}