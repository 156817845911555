import { Preference } from "@/models/Preference";


export class RelationshipKeyValuePair {
  constructor(key: number, value: string) {
    this.key = key;
    this.value = value;
  }
  public key: number;
  public value: string;
}

export enum PersonRelationship {
  None,
  Inquiry,
  NewGuest,
  RepeatGuest,
  Personal,
  Professional,
  Misc,
}

export function getRelationships(pref: Preference): Array<RelationshipKeyValuePair> {
  const result = new Array<RelationshipKeyValuePair>;
  result.push(new RelationshipKeyValuePair(0, ""));
  result.push(new RelationshipKeyValuePair(1, pref.relation1));
  result.push(new RelationshipKeyValuePair(2, pref.relation2));
  result.push(new RelationshipKeyValuePair(3, pref.relation3));
  result.push(new RelationshipKeyValuePair(4, pref.relation4));
  result.push(new RelationshipKeyValuePair(5, pref.relation5));
  result.push(new RelationshipKeyValuePair(6, pref.relation6));
  return result;
}

export function getRelationshipFromPreference(pref: Preference, val: PersonRelationship) {
  let result = "";
  switch (val) {
    case PersonRelationship.None:
      result = "";
      break;
    case PersonRelationship.Inquiry: //inquiry
      result = pref.relation1;
      break;
    case PersonRelationship.NewGuest: //New Guest
      result = pref.relation2;
      break;
    case PersonRelationship.RepeatGuest: //repeat guest
      result = pref.relation3;
      break;
    case PersonRelationship.Personal: //personal
      result = pref.relation4;
      break;
    case PersonRelationship.Professional: //pr0fessional 
      result = pref.relation5;
      break;
    case PersonRelationship.Misc: //misc
      result = pref.relation6;
      break;
    default:
      result = "";
      break;
  }
  return result;
}

export function getPersonRelationshipColorVariant(val: PersonRelationship): string {
  let result = "";
  switch (val) {
    case PersonRelationship.None:
      result = "light";
      break;
    case PersonRelationship.Inquiry: //inquiry
      result = "dark";
      break;
    case PersonRelationship.NewGuest: //New Guest
      result = "newguest";
      break;
    case PersonRelationship.RepeatGuest: //repeat guest
      result = "primary";
      break;
    case PersonRelationship.Personal: //personal
      result = "success";
      break;
    case PersonRelationship.Professional: //prefessional 
      result = "danger";
      break;
    case PersonRelationship.Misc: //misc
      result = "warning";
      break;
    default:
      result = "primary";
      break;
  }
  return result;
}