
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "./User";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({})
export default class VerifySuccess extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Auth.State("user")
  private currentUser!: User;

  private hideMessage = false;
  private host = "";
  private busy = false;

  getEmailDomain(email) {
    var parts = email.split("@");
    if (parts.length === 2) {
      if (parts[1]) {
        return parts[1];
      }
    }
    return null;
  }

  async mounted() {
    this.busy = true;
    if (this.$route.query.hideMessage) {
      this.hideMessage = true;
    } else {
      this.hideMessage = false;
    }

    this.host = this.getEmailDomain(this.property.email);
    this.busy = false;
  }
}
