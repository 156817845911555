import { render, staticRenderFns } from "./MpHeader.vue?vue&type=template&id=23c36bd7&scoped=true&"
import script from "./MpHeader.vue?vue&type=script&lang=ts&"
export * from "./MpHeader.vue?vue&type=script&lang=ts&"
import style1 from "./MpHeader.vue?vue&type=style&index=1&id=23c36bd7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c36bd7",
  null
  
)

export default component.exports