
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import {
  deleteDeliveryOption,
  DeliveryOption,
  DeliveryOptionType,
  getDeliveryOptionsByProperty,
  getDeliveryOptions,
  saveDeliveryOption,
} from "./DeliveryOption";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { ConfirmDeleteModal } })
export default class DeliveryOptionTable extends Vue {
  @Prop({ default: null }) public unitGuid: string | undefined;
  @Prop({ default: null }) public deliveryOptionGuid: string | undefined;
  @Prop({ default: null }) public propertyGuid!: string;

  @PropertyStore.State
  public property!: Property;

  private showConfirmModal = false;
  private loading = true;
  private deliveryOptions: Array<DeliveryOption> = [];
  private deliveryOptionFields: Array<Field> = [];
  private activeOpt!: DeliveryOption | null;
  private deliveryOptionTable: any;
  private selected!: Array<any>;
  private timer!: number | undefined;

  confirmDelete(opt: DeliveryOption) {
    this.activeOpt = opt;
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  getTypeStr(opt: DeliveryOption) {
    return opt.type;
  }

  changeType(opt: DeliveryOption, val: string) {
    this.loading = true;
    opt.type = val;
    this.save(opt);
    this.loading = false;
  }

  async optChanged(opt: DeliveryOption) {
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => this.save(opt), 1000);
  }

  async add() {
    this.activeOpt = null;
    let option = new DeliveryOption();
    option.deliveryOptionGuid = Guid.newGuid();
    option.description = "";
    option.type = DeliveryOptionType.Pickup;
    option.propertyGuid = this.property.propertyGuid;
    this.deliveryOptions.push(option);
    await saveDeliveryOption(option);
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.deliveryOptionTable.selectAllRows();
  }
  clearSelected() {
    this.deliveryOptionTable.clearSelected();
  }

  private getDeliveryOptionFields() {
    let field = new Field();
    field.key = "Description";
    field.label = "Description";
    this.deliveryOptionFields.push(field);

    field = new Field();
    field.key = "Location";
    field.label = "Location (address or city)";
    this.deliveryOptionFields.push(field);

    field = new Field();
    field.key = "Type";
    field.label = "Type";
    this.deliveryOptionFields.push(field);

    field = new Field();
    field.key = "Price";
    field.label = "Price";
    this.deliveryOptionFields.push(field);

    field = new Field();
    field.key = "Coordinates";
    field.label = "GPS Coordinates";
    this.deliveryOptionFields.push(field);

    field = new Field();
    field.key = "actions";
    field.label = "Actions";
    this.deliveryOptionFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.deliveryOptionTable = this.$refs.deliveryOptionTableRef;
    this.getDeliveryOptionFields();
    if (this.unitGuid) {
      this.deliveryOptions = await getDeliveryOptions(this.unitGuid);
    } else {
      this.deliveryOptions = await getDeliveryOptionsByProperty(
        this.property.propertyGuid
      );
    }
    this.loading = false;
  }

  getActiveDeliveryOption() {
    if (this.activeOpt) {
      return this.activeOpt.deliveryOptionGuid;
    } else {
      return "";
    }
  }

  async save(opt: DeliveryOption) {
    await saveDeliveryOption(opt);
    await this.deliveryOptionTable.refresh();
  }

  async deleteOption() {
    let opt = this.activeOpt as DeliveryOption;
    if (opt) {
      let result = await deleteDeliveryOption(opt.deliveryOptionGuid);
      if (result) {
        if (this.deliveryOptions) {
          let index = this.deliveryOptions.findIndex(
            (a) => a.deliveryOptionGuid === opt.deliveryOptionGuid
          );
          this.deliveryOptions.splice(index, 1);
        }
      }
      await this.deliveryOptionTable.refresh();
    }
    this.showConfirmModal = false;
  }

  edit(deliveryOption: any, index: any, event: any) {
    if (deliveryOption) {
      this.activeOpt = deliveryOption;
    } else if (this.selected && this.selected.length > 0) {
      this.activeOpt = this.selected[0];
    }
  }
}
