
import { RegisterCredentials } from "@/components/Cloud/Auth/RegisterCredentials";
import { Invitation } from "@/components/Cloud/Auth/Invitation";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "./AuthService";
import PasswordInput from "@/components/Controls/PasswordInput.vue";
const Auth = namespace("AuthStore");
@Component({
  components: { PasswordInput },
})
export default class RegisterHost extends Vue {
  @Prop({ default: "host" }) public mode!: string; // or guest

  private registerCreds = new RegisterCredentials();
  private errors: Array<string> = [];
  private showProgressOverlay = false;
  private successful = false;
  private accountInvite = "";
  private invitation!: Invitation;
  private loading = false;
  private validPass = false;

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private register!: (regCreds: RegisterCredentials) => Promise<any>;

  @Auth.Action
  private login!: (data: any) => Promise<any>;

  validatePassword(arg) {
    this.validPass = arg;
  }

  getEmailDisabled() {
    if (this.invitation) {
      return true;
    } else {
      return false;
    }
  }

  getInvitation() {
    if (this.invitation) {
      return true;
    } else {
      return false;
    }
  }

  async mounted() {
    this.loading = true;
    this.registerCreds = new RegisterCredentials();
    if (this.$route.query.account_invite) {
      this.accountInvite = this.$route.query.account_invite.toString();
      this.invitation = await AuthService.getInvitation(this.accountInvite);
      this.registerCreds.email = this.invitation.inviteeEmail;
    }
    if (this.isLoggedIn) {
      this.$router.push("/profile");
    }
    this.errors = [];
    this.loading = false;
  }

  disableCreateButton() {
    if (
      !this.registerCreds.fullname ||
      !this.registerCreds.email ||
      !this.registerCreds.password
    ) {
      return true;
    } else {
      return false;
    }
  }

  updatePassword(val) {
    this.registerCreds.password = val;
    this.disableCreateButton();
  }
  updateEmail(val) {
    this.registerCreds.email = val;
    this.disableCreateButton();
  }
  updateFullName(val) {
    this.registerCreds.fullname = val;
    this.disableCreateButton();
  }

  async createAccount() {
    if (this.validPass == false) {
      return;
    }
    this.errors = [];
    this.showProgressOverlay = true;

    if (this.invitation) {
      this.registerCreds.invitationGuid = this.invitation.invitationGuid;
    }

    await this.register(this.registerCreds).then(
      (response) => {
        if (response && response.data && response.data.error) {
          this.successful = false;
          this.showProgressOverlay = false;
          this.errors.push(response.data.error);
        } else {
          this.successful = true;
          this.showProgressOverlay = false;
          this.loginAndGoToDashboard();
        }
      },
      (error) => {
        this.errors.push(error);
        this.successful = false;
        this.showProgressOverlay = false;
      }
    );
  }

  async loginAndGoToDashboard() {
    const user: any = { email: "", password: "" };
    user.password = this.registerCreds.password;
    user.email = this.registerCreds.email;
    await this.login(user).then(
      (data) => {
        this.$router.push("/dashboard");
      },
      (error) => {
        this.errors.push(error);
      }
    );
  }
}
