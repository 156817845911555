
import { VueEditor } from "vue2-editor";
import { Vue, Component, Prop } from "vue-property-decorator";
import { uploadDocument } from "../Cloud/Documents/DocumentService";
import { Guid } from "@/utils/Guid";

@Component({
  components: { VueEditor },
})
export default class Editor extends Vue {
  private htmlForEditor = "";

  getImageUrl(docGuid: string) {
    //src="https://s3.us-east-2.amazonaws.com/service.lodgevault/906a6569-9e26-4c4f-bfee-15360e63f52c.jpg"
    docGuid = "906a6569-9e26-4c4f-bfee-15360e63f52c";
    return (
      "https://s3.us-east-2.amazonaws.com/service.lodgevault/" +
      docGuid +
      ".jpg"
    );
  }

  async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
    // An example of using FormData
    // NOTE: Your key could be different such as:
    // formData.append('file', file)

    // add a doc and get a new guid

    var formData = new FormData();
    formData.append("image", file);

    let result = await uploadDocument(formData);
    var guid = Guid.newGuid();
    const url = this.getImageUrl(guid); // result.data.url; // Get url from response
    Editor.insertEmbed(cursorLocation, "image", url);
    resetUploader();

    // axios({
    //   url: "https://fakeapi.yoursite.com/images",
    //   method: "POST",
    //   data: formData,
    // })
  }

  async mounted() {
    this.htmlForEditor = `<h1>Htm<strong>l F</strong><strong style="color: rgb(230, 0, 0);">or Editor</strong></h1>
    
    <p class="ql-align-justify">{{reservation.name}}</p>
    <p style="border:0;border-collapse:collapse;margin:0;padding:0;width:100%;text-align:center;background-color:#009571;border-radius:6px;height:44px">
       <span style="font-family:-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Ubuntu,sans-serif;text-decoration:none;text-decoration:none;font-weight:500;font-size:16px;line-height:44px;color:rgb(255,255,255)">
          Pay this invoice
      </span></p>
      `;
  }
}
