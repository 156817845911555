
import { Vue, Component } from "vue-property-decorator";

@Component({ components: {} })
export default class BoisBrule extends Vue {
  mounted() {
    let embedsScript = document.createElement("script");
    embedsScript.setAttribute(
      "src",
      "https://book.lodgevault.com/embeds/embed.js"
    );
    document.head.appendChild(embedsScript);
  }
}
