import { PersonRelationship } from "./PersonRelationship";

export class Person {
    constructor() {
        this.firstName = "";
        this.lastName = "";
        this.otherNames = "";
        this.addr1Line1 = "";
        this.addr1Line2 = "";
        this.city = "";
        this.state = "";
        this.zip = "";
        this.country = "";
        this.relationship = PersonRelationship.NewGuest;
    }
    public selected: boolean = false;
    public personGuid!: string;
    public propertyGuid!: string;
    public lastName!: string;
    public firstName!: string;
    public otherNames!: string;
    public lastCommaFirst!: string;
    public fullName!: string;
    public firstSpaceLast!: string;
    public namePrefix!: string;
    public relationship!: PersonRelationship;
    public primaryAddress!: string;
    public company!: string;
    public addr1Line1!: string;
    public addr1Line2!: string;
    public city!: string;
    public state!: string;
    public zip!: string;
    public country!: string;
    public company2!: string;
    public addr2Line1!: string;
    public addr2Line2!: string;
    public city2!: string;
    public state2!: string;
    public zip2!: string;
    public cityStateZip!: string;
    public readableAddress!: string;
    public country2!: string;
    public homePhone!: string;
    public workPhone!: string;
    public cellPhone!: string;
    public faxPhone!: string;
    public email!: string;
    public email2!: string;
    public notes!: string;
    public firstContact!: Date | null;
    public lastContact!: Date | null;
    public lastStay!: Date;
    public nextStay!: Date;
    public callBack!: Date | null;
    public source!: string;
    public userField1!: string;
    public userField2!: string;
    public userField3!: string;
    public userField4!: string;
    public userField5!: string;
    public userField6!: string;
    public allInterests!: string;
    public international!: boolean;
    public createdDate!: Date;
    public modifiedDate!: Date;
    public stripeId!: string;
}
