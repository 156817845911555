import { axiosRetry } from '@/utils/fetchUtil';
import { Invitation } from './Invitation';
import { LoginCredentials } from './LoginCredentials';
import { RegisterCredentials } from './RegisterCredentials';
import { User } from './User';
class AuthService {

    public getStorage() {
        var storage;
        try {
            storage = window.sessionStorage || {};
        } catch (e) {
            try {
                storage = window.localStorage;
            } catch (e) {
            }
        }
        return storage;
    }

    public async getAccessToken(creds: LoginCredentials): Promise<any> {
        const response = await axiosRetry(`/api/auth/getaccesstoken`,
            creds
            , "POST");
        if (response && response.data && !response.data.error && response.status == 200) {
            if (response.data) {
                this.getStorage().setItem('access-token', JSON.stringify(response.data.accessToken));
            }
            return response.data.accessToken as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    public async confirmUser(userid: string, token: string): Promise<any> {
        const response = await axiosRetry(`/api/auth/confirmemail?userid=${userid}&code=${token}`,
            null
            , "GET");

        if (response && response.data && !response.data.error && response.status == 200) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    public async resendConfirmEmail(userid: string): Promise<any> {
        const response = await axiosRetry(`/api/auth/resendconfirmemail?userid=${userid}`,
            null
            , "GET");

        if (response && response.data && !response.data.error && response.status == 200) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }


    public async getUsersForProperty(propertyGuid: string): Promise<Array<User>> {
        return axiosRetry(`/api/auth/usersinproperty/${propertyGuid}`, null, "GET")
            .then(response => {
                return response.data;
            });
    }
    public async inviteTeamMember(login: LoginCredentials, requestedRole: string, propertyGuid: string, inviterEmail: string, inviterCompanyName: string, inviterUserGuid: string): Promise<boolean> {
        return axiosRetry(`/api/auth/inviteteammember`, { login, requestedRole, propertyGuid, inviterEmail, inviterCompanyName, inviterUserGuid }, "POST")
            .then(response => {
                return response.data;
            });
    }
    public async deleteTeamMember(email: string): Promise<boolean> {
        const login = new LoginCredentials();
        login.email = email;
        return axiosRetry(`/api/auth/deleteteammember`, { email }, "POST")
            .then(response => {
                return response.data;
            });
    }


    public async getInvitation(invitationGuid: string): Promise<Invitation> {
        return axiosRetry(`/api/auth/invitation/${invitationGuid}`, null, "GET")
            .then(response => {
                return response.data;
            });
    }

    public async login(email: string, password: string): Promise<User> {
        const response = await axiosRetry('/api/auth/login', { email, password }, "POST");
        if (response && response.data && !response.data.error && response.status == 200) {
            const userobj: User = response.data;
            if (userobj.accessToken) {
                this.getStorage().setItem('user', JSON.stringify(response.data));
                let propertyGuid = localStorage.getItem('propertyGuid');

                if (!userobj.properties.find((p) => p.propertyGuid == propertyGuid)) {
                    //this property is not in the list of assigned properties for this user
                    //what to do??
                    propertyGuid = '';
                    localStorage.setItem('propertyGuid', '');
                }

                if (userobj.properties) {
                    if (!propertyGuid && userobj.properties.length > 0) {
                        //see if this is in the array
                        localStorage.setItem('propertyGuid', userobj.properties[0].propertyGuid);
                    } else {
                        const foundProperty = userobj.properties.find((p) => p.propertyGuid == propertyGuid);
                        if (foundProperty) {
                            localStorage.setItem('propertyGuid', foundProperty.propertyGuid);
                        }
                    }
                }
            }
            return userobj;
        } else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    logout() {
        this.getStorage().removeItem('user');
    }

    public async register(registerCreds: RegisterCredentials): Promise<any> {
        const response = await axiosRetry('/api/auth/register',
            registerCreds
            , "POST");

        if (response && response.data && !response.data.error && response.status == 200) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    public async forgotPassword(email: string): Promise<any> {
        const response = await axiosRetry(`/api/auth/forgotpassword`, { email }, "POST");
        if (response && response.data && !response.data.error && response.status == 200) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    public async resetPassword(password: string, email: string, token: string): Promise<any> {
        const response = await axiosRetry(`/api/auth/resetpassword`, { password, email, token }, "POST");
        if (response && response.data && !response.data.error && response.status == 200) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("no response");
        }
    }
}

export default new AuthService();
