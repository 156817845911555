
import { axiosRetry } from "@/utils/fetchUtil";
import { Vue, Component, Prop } from "vue-property-decorator";
import Editor from "../Cloud/Editor/Editor.vue";
@Component({
  components: { Editor },
})
export default class EmailTester extends Vue {

  changeContent(changed: any){
    this.content = changed;
  }
  private content =
    "<h1>Some initial content</h1><img src='https://book.lodgevault.com/img/lv-small.7679d46f.png'/>";
  async sendEmail() {
    return axiosRetry(
      `/api/emailsender/sendemail`,
      {
        recipientEmail: "noahjcaron@gmail.com",
        recipientFirstName: "Noah",
        body: this.content,
      },
      "POST"
    ).then((response) => {
      console.log(response.data);
    });
  }
}
