var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"lv-row-padding lv-padding-64 lv-container"},[_c('div',{staticClass:"lv-content"},[_c('div',{staticClass:"lv-row-padding lv-padding-64 lv-container"},[_c('div',{staticClass:"lv-section"},[_c('ul',{staticClass:"lv-ul lv-card lv-hover-shadow"},[_c('li',{staticClass:"lv-teal lv-xlarge lv-padding-32"},[_vm._v(" Lodge Vault Desktop ")]),_vm._m(0),_c('li',{staticClass:"lv-padding-16"},[_vm._v(" Resilient during internet outages or slowness ")]),_c('li',{staticClass:"lv-padding-16"},[_vm._v(" Install on as many computers as needed ")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',{staticClass:"lv-light-grey lv-padding-24"},[_c('router-link',{staticClass:"lv-button lv-teal lv-padding-large lv-large lv-margin-top",attrs:{"to":"purchasedesktop"}},[_vm._v(" Purchase Lodge Vault ")])],1)])])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"lv-padding-16"},[_c('b',[_vm._v("Full-featured")]),_vm._v(" desktop application ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"lv-padding-16"},[_c('b',[_vm._v("Website Booking Engine")]),_vm._v(" can be optionally added ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"lv-padding-16"},[_c('b',[_vm._v("Continuous Cloud Sync")]),_vm._v(" to keep data safe ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"lv-padding-16"},[_c('b',[_vm._v("Endless")]),_vm._v(" Support including remote emergency support and phone calls ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"lv-padding-16"},[_c('h2',{staticClass:"lv-opacity"},[_vm._v("$499 per year")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('i',[_vm._v("Lodge Vault is committed to the best possible customer service and will work with you to resolve any technical issues you may have. If you are not fully satisfied, we offer a full refund within 30 days of your purchase date.")])])
}]

export { render, staticRenderFns }