
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { OutOfService } from "@/components/Cloud/OutOfService/OutOfService";
import {
  getOutOfService,
  saveOutOfService,
} from "@/components/Cloud/OutOfService/OutOfService";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class OutOfServiceEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public outOfServiceGuid!: string;
  public outOfService = new OutOfService();
  private busy = false;
  private isNew = false;

  validateName() {
    return true;
  }

  private cancel() {
    this.$emit("cancel");
  }

  getOutOfService() {
    return this.outOfService;
  }

  async mounted() {
    this.busy = true;
    let oosGuid = "";
    if (this.outOfServiceGuid) {
      oosGuid = this.outOfServiceGuid;
    } else if (this.$route.params.outOfServiceGuid) {
      oosGuid = this.$route.params.id.toString();
    } else if (this.$route.query.outOfServiceGuid) {
      oosGuid = this.$route.query.outOfServiceGuid.toString();
    }
    if (oosGuid && oosGuid != "") {
      this.outOfService = await getOutOfService(oosGuid.toString());
    } else {
      this.isNew = true;
      this.outOfService = new OutOfService();
      this.outOfService.outOfServiceGuid = Guid.newGuid();
      this.outOfService.propertyGuid = this.property.propertyGuid;
      this.outOfService.createdDate = new Date();
    }
    this.busy = false;
  }

  async saveAndClose() {
    this.busy = true;
    await saveOutOfService(this.outOfService);

    this.busy = false;

    if (this.isNew) {
      this.$emit("save-new", this.outOfService);
    } else {
      this.$emit("save-existing", this.outOfService);
    }
  }
}
