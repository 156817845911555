
import { Reservation } from '@/components/Cloud/Reservations/Reservation';
import { axiosRetry } from '@/utils/fetchUtil';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
@Module({ namespaced: true })
class AddReservationStore extends VuexModule {
    public reservation!: Reservation;
    @Action
    public saveNewReservationToStore(res: Reservation): void {
        this.context.commit('SAVE_NEW_RESERVATION_TO_STORE', res);
    }

    @Mutation
    public SAVE_NEW_RESERVATION_TO_STORE(res: Reservation): void {
        this.reservation = res;
    }
}
export default AddReservationStore
