
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import SearchBar from "./SearchBar.vue";
import Property, { getProperties } from "@/models/Property";
import CompanyCard from "./CompanyCard.vue";
import { getCategories } from "@/components/Marketplace/Category";
import Category from "./Category";
import { filterProperties } from "../Cloud/Units/UnitService";
import { Guid } from "@/utils/Guid";
import DatePicker from "../SiteBook/DatePicker.vue";
@Component({
  components: { SearchBar, CompanyCard, DatePicker },
})
export default class Search extends Vue {
  @Watch("listings", { immediate: false, deep: true })
  onListingsChanged(newVal: any, oldVal: any) {
    this.listings = newVal;
  }

  private loaded = false;
  private busy = false;
  private listings = new Array<Property>();
  private geocoder!: any;
  private addressString!: string;
  private loc!: string;
  private categories = new Array<Category>();
  private selectedCategories = new Array<Category>();
  private pickedDate: Date = new Date();
  private map: any;

  async categoriesChanged(arg: any) {
    if (!this.loaded) {
      return;
    }
    this.selectedCategories = arg;
    let names = this.selectedCategories.map((c) => c.name);
    this.$router.replace({
      query: {
        ...this.$router.currentRoute.query,
        categories: names.join(","),
      },
    });
  }
  async locationChanged(arg: any) {
    this.loc = arg;
    this.$router.replace({
      query: {
        ...this.$router.currentRoute.query,
        geo: this.loc,
      },
    });
    this.addressString = this.loc;
    this.centerMapAtSelectedLocation();
  }

  async datePicked(arg: any) {
    // this.pickedDate = new Date(arg);
    this.$router.replace({
      query: {
        ...this.$router.currentRoute.query,
        date: new Date(this.pickedDate).dashedDate(),
      },
    });
  }

  async getLocationData(address, callback) {
    if (this.geocoder) {
      this.geocoder.geocode({ address: address }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results) {
            callback(results[0]);
          }
        }
      });
    }
  }

  // test distance matrix
  async addMarker(biz: Property) {
    if (!this.geocoder) {
      return;
    }
    await this.getLocationData(biz.readableAddress, (results) => {
      var Lat = results.geometry.location.lat();
      var Lng = results.geometry.location.lng();
      var center = new google.maps.LatLng(Lat, Lng);
      var myOptions = {
        zoom: 11,
        center: center,
      };
      this.addMarkerToMap(Lat, Lng, biz);
    });
  }

  addressLatLongCallBack(results) {}

  // test distance matrix
  async centerMapAtSelectedLocation() {
    if (!this.geocoder) {
      return;
    }
    this.geocoder.geocode(
      {
        address: this.addressString,
      },
      this.centerAtAddressCallBack
    );
  }

  centerAtAddressCallBack(results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
      var Lat = results[0].geometry.location.lat();
      var Lng = results[0].geometry.location.lng();
      var center = new google.maps.LatLng(Lat, Lng);

      if (this.map) {
        this.map.panTo(center);
      } else {
        var myOptions = {
          zoom: 5,
          center: center,
        };

        let el = document.getElementById("map");
        if (el) {
          this.map = new google.maps.Map(el, myOptions);
        } else {
          console.log("Something got wrong " + status);
        }
      }
    }
  }

  async mounted() {}

  async getResults() {
    this.busy = true;
    if (this.$route.query) {
      if (this.$route.query.geo) {
        this.addressString = this.$route.query.geo.toString();
      }
      if (this.$route.query.date) {
        this.pickedDate = new Date(this.$route.query.date.toString()).addDays(
          1
        );
      }
      if (this.$route.query.categories) {
        let typeArray = this.$route.query.categories.toString().split(",");
        for (const name of typeArray) {
          let found = this.categories.find((c) => c.name == name);
          if (found) {
            this.selectedCategories.push(found);
          }
        }
      }
    }
    this.centerMapAtSelectedLocation();
    this.listings = await filterProperties();
    this.getDistanceMatrix();
    for (const biz of this.listings) {
      await this.addMarker(biz);
    }
    this.busy = false;
  }

  async created() {
    this.categories = await getCategories();
    const loader = new Loader({
      apiKey: "AIzaSyDVYoGJ1NaKgqdrfVviGXh7rqVhY1uZe0g",
      version: "weekly",
      libraries: ["places"],
    });

    let google = await loader.load();

    this.geocoder = new google.maps.Geocoder();
    await this.getResults();
    this.loaded = true;
  }

  async getDistanceMatrix() {
    var service = new google.maps.DistanceMatrixService();
    //var origin1 = new google.maps.LatLng(55.930385, -3.118425);
    // var origin2 = "Greenwich, England";
    let destinationArray = new Array<string>();
    for (const business of this.listings) {
      destinationArray.push(business.readableAddress);
    }
    //  var destinationA = "Stockholm, Sweden";
    // var destinationB = new google.maps.LatLng(50.087692, 14.42115);
    service.getDistanceMatrix(
      {
        origins: [this.addressString],
        destinations: destinationArray,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        avoidHighways: false,
        avoidTolls: false,
      },
      this.callbackDistanceMatrix
    );
  }

  callbackDistanceMatrix(response, status) {
    let i = 0;
    for (const business of this.listings) {
      let dist = response.rows[0].elements[i].distance;
      if (dist && dist.text) {
        business.distance = dist.text;
        console.log(dist);
      }
      i++;
    }
  }

  private getContentString(prop: Property) {
    let hasLogo =
      prop.logo && prop.imageGuid && prop.imageGuid != Guid.emptyGuid();
    let button = ""; // `<button class='btn-secondary'>BOOK</button>`;
    let content = `<div style="padding: 3px;">${button}</div>`;
    content += `<div><h5>${prop.name}</h5>`;
    if (hasLogo) {
      content += `<img style="height: 30px;" src='${prop.logo}'>`;
    }

    content += `</div><div id='bodyContent'>${prop.categories.join(",")}</div>`;

    return content;
  }

  addMarkerToMap(lat: number, long: number, prop: Property) {
    var point = { lat: lat, lng: long };
    if (this.map) {
      var map = this.map;

      let infowindow = new google.maps.InfoWindow({
        content: this.getContentString(prop),
        maxWidth: 200,
        ariaLabel: prop.name,
      });

      let marker = new google.maps.Marker({
        position: point,
        map,
        title: prop.name,
      });

      marker.addListener("mouseover", function () {
        infowindow.open({
          anchor: marker,
          map,
        });
      });
      marker.addListener("click", function () {
        window.open("/company/" + prop.propertyGuid, "_blank");
        infowindow.open({
          anchor: marker,
          map,
        });
      });
      infowindow.addListener("click", function () {
      });

      google.maps.event.addListener(marker, "mouseout", function () {
        setTimeout(function () {
          infowindow.close();
        }, 3000);
      });
    }
  }
}
