
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

import { Loader } from "@googlemaps/js-api-loader";
import axios from "axios";
import { getCategories } from "../Marketplace/Category";
import MultiSelect from "vue-multiselect";
import Category from "./Category";
import DatePicker from "../SiteBook/DatePicker.vue";
const Auth = namespace("AuthStore");
@Component({
  components: { DatePicker, MultiSelect },
})
export default class SearchBar extends Vue {
  @Prop({ default: () => "filter" }) public mode!: string; //search (home page), filter (filter)
  private locationTextBox!: HTMLInputElement;
  private busy = false;
  private geo = "";
  private type = "";
  private pickedDate: Date | undefined;
  private geocoder!: any;
  private categories = new Array<Category>();
  private selectedCategories = new Array<Category>();
  private loaded = false;

  @Watch("selectedCategories", { immediate: true })
  async oncategoriesChanged(
    newselectedCategories: any,
    oldselectedCategories: string
  ) {
    this.selectedCategories = newselectedCategories;
    this.$emit("categories-changed", this.selectedCategories);
  }
  get pickedDateComputed() {
    return this.pickedDate;
  }
  set pickedDateComputed(value: Date | undefined) {
    this.pickedDate = value;
  }

  dateDisabled(ymd, date) {
    //past dates should be disabled
    return new Date(date).valueOf() < new Date().valueOf();
  }

  addTag(newTag) {
    let category = new Category(
      newTag,
      newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
    );
    this.selectedCategories.push(category);
  }

  codeLatLng(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);
    this.geocoder.geocode({ latLng: latlng }, this.getCityState);
  }

  getCityState(results, status) {
    if (status == google.maps.GeocoderStatus.OK) {
      if (results[1]) {
        //formatted address
        let city!: any;
        let state!: any;
        //find country name
        for (var i = 0; i < results[0].address_components.length; i++) {
          for (
            var b = 0;
            b < results[0].address_components[i].types.length;
            b++
          ) {
            if (results[0].address_components[i].types[b] == "locality") {
              //this is the object you are looking for
              city = results[0].address_components[i];
              break;
            }
            if (
              results[0].address_components[i].types[b] ==
              "administrative_area_level_1"
            ) {
              //this is the object you are looking for
              state = results[0].address_components[i];
              break;
            }
          }
        }
        this.geo = city.long_name + " " + state.long_name;
      } else {
        console.log("No results found");
      }
    } else {
      console.log("Geocoder failed due to: " + status);
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition);
    }
  }

  async showPosition(position) {
    this.geo = await this.getStreetAddressFrom(
      position.coords.latitude,
      position.coords.longitude
    );

    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    this.codeLatLng(lat, lng);
  }

  async mounted() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
      }

      if (e.key === "Enter") {
        this.goToSearchPage();
      }
    });
    this.categories = await getCategories();
    if (this.$route.query) {
      if (this.$route.query.geo) {
        this.geo = this.$route.query.geo.toString();
      }
      if (this.$route.query.date) {
        this.pickedDate = new Date(this.$route.query.date.toString());
      }
      if (this.$route.query.categories) {
        let typeArray = this.$route.query.categories.toString().split(",");
        for (const name of typeArray) {
          let found = this.categories.find((c) => c.name == name);
          if (found) {
            this.selectedCategories.push(found);
          }
        }
      }
    }
    this.loaded = true;
    const loader = new Loader({
      apiKey: "AIzaSyDVYoGJ1NaKgqdrfVviGXh7rqVhY1uZe0g",
      version: "weekly",
      libraries: ["places"],
    });

    var options = {
      types: ["(cities)"],
      componentRestrictions: { country: ["us", "ca"] },
    };
    let google = await loader.load();
    this.geocoder = new google.maps.Geocoder();
    this.locationTextBox = document.getElementById(
      "location"
    ) as HTMLInputElement;

    const locationAutocomplete = new google.maps.places.Autocomplete(
      this.locationTextBox,
      options
    );

    locationAutocomplete.addListener("place_changed", () =>
      this.handleLocationChanged(locationAutocomplete, google)
    );
    this.busy = false;

    if (this.geo == "") {
      await this.getLocation();
    }
  }

  async getStreetAddressFrom(lat, long) {
    let result!: any;
    try {
      var { data } = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          lat +
          "," +
          long +
          "&key=AIzaSyDVYoGJ1NaKgqdrfVviGXh7rqVhY1uZe0g"
      );
      if (data.error_message) {
        console.log(data.error_message);
      } else {
        result = data.results[0].formatted_address;
        return result;
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleLocationChanged(field: google.maps.places.Autocomplete, google: any) {
    {
      const place = field.getPlace();
      if (place.geometry) {
        this.fillInLocation(place);
      }
    }
  }

  fillInLocation(place: google.maps.places.PlaceResult) {
    let address1Number = "";
    let address1Route = "";
    let address1 = "";
    let city = "";
    let state = "";
    let postcode = "";
    let country = "";

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1Number = component.long_name;
          break;
        }

        case "route": {
          address1Route = component.long_name;
          break;
        }

        case "postal_code": {
          postcode = component.long_name;
          break;
        }
        case "postal_code_prefix": {
          postcode = component.long_name + " " + postcode;
          break;
        }

        case "locality":
          city = component.long_name;
          break;

        case "administrative_area_level_1": {
          //   component.short_name;
          state = component.long_name;
          break;
        }

        case "country":
          country = component.short_name;
          break;
      }
    }

    if (city && city.trim()) {
      this.geo = city.trim();
    }
    if (state && state.trim()) {
      this.geo = this.geo + ", " + state.trim();
    }
    if (country && country.trim()) {
      this.geo = this.geo + ", " + country.trim();
    }
    this.$emit("location-changed", this.geo);
  }

  goToSearchPage() {
    if (!this.pickedDate) {
      this.pickedDate = new Date();
    }
    let categories = this.selectedCategories.map((c) => c.name);
    let names = categories.join(",");
    this.$router.push({
      name: "Search",
      query: {
        categories: names,
        geo: this.geo,
        date: new Date(this.pickedDate as Date).dashedDate(),
      },
    });
  }
}
