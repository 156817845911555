import { DayOfWeek } from "@/models/DayOfWeek";
import { DepositMethod } from "../Reservations/DepositMethod";
import { TimeSlot } from "../TimeSlots/TimeSlot";
import { PlanType } from "./PlanType";
import { Season } from "./Seasons/Season";


export class Rate {
    constructor() {
        this.baseRate = 0;
    }
    public rateGuid!: string;
    public propertyGuid!: string;
    public name!: string;
    public description!: string;
    public planType!: PlanType;
    public baseRate: number;
    public weekendRate!: number;
    public forUpTo!: number;
    public extraPersonCharge!: number;
    public nights!: number;
    public minimumNights!: number;
    public rateOption1!: boolean;
    public get includesTax(): boolean {
        return this.rateOption1;
    }
    public rateOption2!: boolean;
    public get hasWeekends(): boolean {
        return this.rateOption2;
    }
    public rateOption3!: boolean;
    public rateOption4!: boolean;
    public serviceChargePercent!: number;
    public serviceChargeAccountGuid!: string;
    public accountGuid!: string;
    public accountName!: string;
    public hasUnits!: boolean;
    public hasSeasons!: boolean;
    public hasComponents!: boolean;
    public balanced!: boolean;
    public interval!: number;
    public createdDate!: Date;
    public modifiedDate!: Date;
    public unitGuid!: string;
    public enabledOnline!: boolean;
    public depositMethod!: number;
    public depositAmount!: number;
    public priceForSelections!: number;
    public priceAdjustment!: number;
    public priceAdjustmentExpires!: Date;
    public effectiveStartDate!: Date;
    public effectiveEndDate!: Date;
    public startDayOfWeek!: string;
    public timeSlots!: Array<TimeSlot>;
    public get recurring(): boolean {
        return this.startDayOfWeek != null;
    }
    public seasonObjects: Array<Season> | undefined;
    public timeSlotCollectionGuid: string | undefined;
    public daysOfWeek = new Array<DayOfWeek>();
    public disabled: boolean = false;
}


export function getRatePlanLabel(rate: Rate): string {
    let result = "";
    switch (rate.planType) {
        case PlanType.Monthly:
            result = "per month";
            break;
        case PlanType.Nightly:
            if (rate.rateOption2) {
                result = "(weekend)";
            }
            result = "per night";
            break;
        case PlanType.Weekly:
            result = "per week";
            break;
        case PlanType.PerPerson:
            result = "per person";
            break;
        case PlanType.Special:
            result = "special";
            break;
        case PlanType.PerAdultPerChild:
            result = "per person";
            break;
        case PlanType.OtherTimePeriod:
            result = "special";
            break;
        case PlanType.Hourly:
            result = "special";
            break;
        case PlanType.TimeSlot:
            result = "time slot";
            break;
        default:
            result = "special";
            break;
    }
    return result;
}