
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ components: {} })
export default class Photo extends Vue {
  @Prop({ default: "" }) public fileName!: string;
  @Prop({ default: "" }) public thumb!: string;
  private photo = { filename: "" };
  private open = false;
  //private photos = new Array<any>;
  // photo() {
  //   return this.photos.find((photo) => {
  //     return photo.id === Number(this.$route.params.id);
  //   });
  // }
  async mounted() {
    this.photo.filename = this.fileName;
  }

  photoUrl(filename) {
    //return `../assets/images/${filename}`;
    return filename;
  }
  closeLightbox() {
    //this.$router.push("/");
    this.open = false;
  }
}
