
import { Filter } from "@/models/Filter";
import { Field } from "@/models/interfaces/Field";
import { PagingParameters } from "@/models/PagingParameters";
import Property from "@/models/Property";
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import { ReservationsPagedModel } from "@/components/Cloud/Reservations/ReservationsPagedModel";
import {
  getReservationStatusColorVariant,
  getReservationStatusString,
  getResStatusColorVariant,
  ResStatus,
} from "@/components/Cloud/Reservations/ResStatus";
import {
  getReservationsPaged,
  getReservationsByPerson,
  deleteReservations,
  deleteReservation,
} from "@/components/Cloud/Reservations/ReservationService";
import { formatter, formatNumber } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import ReservationEdit from "./ReservationEdit.vue";
import { export_table_to_csv } from "@/utils/exportToCsv";
import SearchBox from "@/components/Controls/SearchBox.vue";
import ReservationListContextMenu from "./ContextMenus/ReservationListContextMenu.vue";
import { getMergedTemplate, Template } from "@/models/Template";
import { sendEmail, SendEmailParams } from "../Templates/EmailService";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    ReservationEdit,
    ConfirmDeleteModal,
    SearchBox,
    ReservationListContextMenu,
  },
})
export default class Reservations extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: null }) public personGuid!: string;
  private loading = true;
  private resFields: Array<Field> = [];
  private pageSize = 100;
  private page = 1;
  private reservationIndex = 0;
  private searchTitle = "";
  private reservationsPagedModel = new ReservationsPagedModel();
  private selected!: Array<any>;
  private showResModal = false;
  private clickedItem!: Reservation | null;
  private clickedIndex = 0;
  private showConfirmModal = false;
  private resTable: any;
  private filters!: Array<Filter>;
  private status!: ResStatus | null;
  private pageOptions = [
    5,
    10,
    20,
    50,
    100,
    500,
    1000,
    {
      value: 0,
      text: "show all",
    },
  ];
  private isNewRes = false;
  private isContextMenuVisible = false;
  private popoverOptions = {
    popoverReference: null as any,
    placement: "auto",
    offset: "0,0",
  };

  async reSelectSelectedRows() {
    let index = 0;
    for (const item of this.resTable.items) {
      if (item.selected) {
        this.resTable.selectRow(index);
      }
      index++;
    }
  }

  selectAllColumnHeaderClicked(arg: any) {
    if (arg) {
      this.selectAll();
    } else {
      this.selectNone();
    }
  }

  allSelected() {
    if (this.reservationsPagedModel.reservations) {
      let result = this.reservationsPagedModel.reservations.every(
        (a) => a.selected
      );
      return result;
    }
    return false;
  }

  selectChanged(arg: any) {
    if (arg.item.selected) {
      this.resTable.selectRow(arg.index);
    } else {
      this.resTable.unselectRow(arg.index);
    }
  }

  selectAll() {
    if (this.allSelected()) {
      this.selectNone();
      return;
    }
    for (const res of this.reservationsPagedModel.reservations) {
      res.selected = true;
    }
    this.resTable.selectAllRows();
  }
  selectNone() {
    for (const res of this.reservationsPagedModel.reservations) {
      res.selected = false;
    }
    this.resTable.clearSelected();
  }

  selectMyRow(uniqueID) {
    const row = this.reservationsPagedModel.reservations.findIndex(
      (x) => x.reservationGuid === uniqueID
    );
    this.resTable.selectRow(row);
  }

  selectedItemsCount() {
    return this.selected && this.selected.length > 0;
  }

  onRowSelected(items: any) {
    for (const res of this.reservationsPagedModel.reservations) {
      res.selected = false;
    }
    for (const r of items) {
      r.selected = true;
    }
    this.selected = items;
    this.$forceUpdate();
  }

  onRowDoubleClicked(res: Reservation, index: any, event: any) {
    this.clickedItem = res;
    this.isNewRes = false;
    this.showResModal = true;
  }

  async sendEmails(template: Template) {
    // merge and send emails with progress bar to the
    //selected reservations
    this.loading = true;
    if (this.selected && this.selected.length > 0) {
      let param = new SendEmailParams();
      for (const res of this.selected) {
        let thisRes: Reservation = res;
        await sendEmail(param);
      }
    }
    this.loading = false;
  }

  async showContextMenu(res: Reservation, index: any, event: any) {
    await this.closeContextMenu;
    await this.openPopover(event);
    this.isContextMenuVisible = true;
  }

  async openPopover(event: any) {
    let element = event.srcElement ?? event.srcElement;
    if (element) {
      if (element.parentElement) {
        element = element.parentElement;
      }
      this.popoverOptions.popoverReference = element;
      this.popoverOptions.placement = "top-start";
    }
  }

  async closeContextMenu() {
    this.isContextMenuVisible = false;
    this.popoverOptions.popoverReference = null;
  }

  sortCompare(
    aRow,
    bRow,
    key,
    sortDesc,
    formatter,
    compareOptions,
    compareLocale
  ) {
    const a = aRow[key]; // or use Lodash `_.get()`
    const b = bRow[key];
    if (
      (typeof a === "number" && typeof b === "number") ||
      (a instanceof Date && b instanceof Date)
    ) {
      // If both compared fields are native numbers or both are native dates
      return a < b ? -1 : a > b ? 1 : 0;
    } else {
      // Otherwise stringify the field data and use String.prototype.localeCompare
      return a
        .toString()
        .localeCompare(b.toString(), compareLocale, compareOptions);
    }
  }

  sortChanged(arg: any) {}

  pageSizeChanged(arg: any) {
    this.page = 1;
    this.retrieve();
  }

  closeReservationModal() {
    this.showResModal = false;
  }

  navigateToResSearchResult(arg: any) {
    this.clickedItem = arg;
    this.isNewRes = false;
    this.showResModal = true;
  }

  async exportTableToExcel() {
    this.loading = true;
    var csvTable = this.$el.querySelector("#resTableRef"); // this is ID
    if (csvTable) {
      await export_table_to_csv(csvTable, "Reservations.csv");
    }
    this.loading = false;
  }

  formatNumber(val: number) {
    return formatNumber(val);
  }

  navigateToGrid() {
    this.$router.push({
      name: "Grid",
      params: {},
    });
  }

  getStatusCount(status: ResStatus) {
    if (
      !this.reservationsPagedModel ||
      !this.reservationsPagedModel.statusCounts
    ) {
      return 0;
    }
    if (status == null) {
      //this is ALL reservations
      return this.formatNumber(this.reservationsPagedModel.statusCounts[10]);
    }
    return this.formatNumber(this.reservationsPagedModel.statusCounts[status]);
  }

  activeStatusFilter(status: ResStatus) {
    return this.status;
  }

  async filterResStatus(status: ResStatus) {
    this.page = 1;
    this.filters = [];
    if (status != null) {
      this.status = status;
      const filter = new Filter();
      filter.field = "Status";
      filter.value = status.toString();
      this.filters.push(filter);
    } else {
      this.status = null;
    }
    await this.retrieve();
  }

  formatCurrency(num: number) {
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  confirmDeleteItem() {
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async confirmDelete() {
    this.loading = true;
    if (this.selected && this.selected.length > 0) {
      this.selected.forEach(async (s) => {
        let deleted = s;
        let result = await deleteReservation(
          deleted.reservationGuid.toString()
        );
        if (result) {
          if (this.reservationsPagedModel.reservations) {
            let index = this.reservationsPagedModel.reservations.findIndex(
              (a) => a.reservationGuid === deleted.reservationGuid
            );
            this.reservationsPagedModel.reservations.splice(index, 1);
          }
        }
      });
    }
    this.showConfirmModal = false;
    await this.resTable.refresh();
    this.loading = false;
  }

  getActiveReservationGuid() {
    if (this.clickedItem) {
      return this.clickedItem?.reservationGuid;
    } else {
      return null;
    }
  }

  async saveAndClose(res: Reservation) {
    let found = this.reservationsPagedModel.reservations.find(
      (r) => r.reservationGuid === res.reservationGuid
    );
    if (!found) {
      await this.reservationsPagedModel.reservations.push(res);
    }
    let index = await this.reservationsPagedModel.reservations.findIndex(
      (r) => r.reservationGuid === res.reservationGuid
    );
    this.reservationsPagedModel.reservations[index] = res;
    await this.resTable.refresh();
    this.resTable.selectRow(index);
    this.reSelectSelectedRows();
    this.showResModal = false;
  }

  handlePageChange(value) {
    this.page = value;
    this.retrieve();
  }

  getReadableDateTime(dateToFormat: Date) {
    if (new Date(dateToFormat).isMinDate()) {
      return "";
    }
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  getReadableDate(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateLocalized();
  }

  getTotalPeople(val: any) {
    const res: Reservation = val.item;
    return (
      res.numberOfPeople1 +
      res.numberOfPeople2 +
      res.numberOfPeople3 +
      res.numberOfPeople4
    );
  }

  getResStatusVariantFromString(statusString: string) {
    return getResStatusColorVariant(statusString);
  }

  getReservationStatusColor(val: any) {
    return getReservationStatusColorVariant(val.item);
  }
  getReservationStatusString(val: any) {
    return getReservationStatusString(val.item);
  }

  private getResFields() {
    let field = new Field();
    field.sortable = true;
    field.key = "selected";
    field.label = "Select";
    this.resFields.push(field);

    field = new Field();
    field.key = "name";
    field.label = "Name";
    this.resFields.push(field);

    field = new Field();
    field.key = "balance";
    field.label = "Balance";
    this.resFields.push(field);

    field = new Field();
    field.key = "arrivalDate";
    field.label = "Arrives";
    this.resFields.push(field);

    field = new Field();
    field.key = "departureDate";
    field.label = "Departs";
    this.resFields.push(field);

    field = new Field();
    field.key = "status";
    field.label = "Status";
    this.resFields.push(field);

    field = new Field();
    field.key = "totalPeople";
    field.label = "People";
    this.resFields.push(field);

    field = new Field();
    field.key = "unitAssignments";
    field.label = "Units";
    this.resFields.push(field);

    field = new Field();
    field.key = "requests";
    field.label = "Requests";
    this.resFields.push(field);

    field = new Field();
    field.key = "notes";
    field.label = "Notes";
    this.resFields.push(field);
  }

  getPagingParams(
    searchTitle: string,
    page: number,
    pageSize: number,
    propertyGuid: string
  ) {
    const params = new PagingParameters();

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["CurrentPage"] = page;
    }

    if (pageSize) {
      params["PageSize"] = pageSize;
    }

    if (propertyGuid) {
      params["PropertyGuid"] = propertyGuid;
    }
    params["SortBy"] = "ArrivalDate";
    params.filters = this.filters;
    return params;
  }

  async mounted() {
    this.resTable = this.$refs.resTableRef;
    this.getResFields();
    this.retrieve();
  }

  async retrieve() {
    this.loading = true;
    const pagingParams = this.getPagingParams(
      this.searchTitle,
      this.page,
      this.pageSize,
      this.property.propertyGuid
    );
    if (this.personGuid) {
      this.reservationsPagedModel.reservations = await getReservationsByPerson(
        this.personGuid
      );
    } else if (this.property.propertyGuid) {
      this.reservationsPagedModel = await getReservationsPaged(
        this.property.propertyGuid,
        pagingParams
      );
    } else {
      this.reservationsPagedModel.reservations = new Array<Reservation>();
    }
    this.loading = false;
  }

  addRes() {
    this.isNewRes = true;
    this.clickedItem = null;
    this.showResModal = true;
  }
}
