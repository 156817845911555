import { axiosRetry } from "@/utils/fetchUtil";
import EmailSettings from "../Settings/EmailSettings";
import { Template, getMergedTemplate, replacePropertyFields } from "@/models/Template";
import { Activity, ActivityKind } from "../Activity/Activity";
import { Guid } from "@/utils/Guid";
import { Person } from "../People/Person";
import { Reservation } from "../Reservations/Reservation";
import { saveActivity } from "../Activity/ActivityService";
import { Invoice } from "../Invoices/Invoice";
import Property from "@/models/Property";

export class SendEmailParams {
    constructor() {
    }
    public recipientEmail = "";
    public recipientFirstName = "";
    public fromEmail = "";
    public fromName = "";
    public subject = "";
    public body = "";
    public CC = "";
    public BCC = "";
}

export async function mergeAndSend(
    template: Template,
    params: SendEmailParams,
    property: Property,
    person?: Person,
    res?: Reservation,
    invoice?: Invoice,
    takenBy?: string) {
    if (!params.recipientEmail || params.recipientEmail.indexOf('@') == -1) {
        return;
    }

    let resGuid: string | undefined = (res) ? res.reservationGuid : undefined;
    let invoiceGuid: string | undefined = (invoice) ? invoice?.invoiceGuid : undefined;
    let personGuid: string | undefined = (person) ? person.personGuid : undefined;

    template = await getMergedTemplate(
        property.propertyGuid,
        template.templateGuid,
        resGuid,
        personGuid,
        invoiceGuid
    );
    template.fileContent = await replacePropertyFields(template.fileContent, property);
    params.body = template.fileContent;
    let response = "";
    if (property.propertyGuid == '1C77120C-F4EB-4BD2-88EB-171112E7D5FD' &&
        (params.recipientEmail != "noahcaron@msn.com" && params.recipientEmail != "noahjcaron@gmail.com")) {
        // bois brule test lodge send it??
        response == "Email Sent Successfully";
    } else {
        response = await sendEmail(params);
    }
    if (response && response == "Email Sent Successfully") {
        let activity = new Activity();
        activity.activityGuid = Guid.newGuid();
        if (person) {
            activity.personGuid = person?.personGuid;
            activity.propertyGuid = person?.propertyGuid;
        }
        if (res) {
            activity.reservationGuid = res.reservationGuid;
        }
        activity.createdDate = new Date();
        activity.fileContent = params.body;
        activity.description = template.name + " emailed to guest";
        activity.takenBy = takenBy ? takenBy : '';
        activity.notes =
            "Email sent - " +
            template.name +
            " - " +
            template.description;
        activity.kind = ActivityKind.Email;
        await saveActivity(activity);
    } else if (response) {
        // failed
    }
}

export async function updateVerificationEmailTemplate(): Promise<string> {
    const response = await axiosRetry(
        `/api/emailsender/UpdateVerificationEmailTemplate`,
        null,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}

export async function sendAwsVerificationEmail(params: SendEmailParams): Promise<string> {
    const response = await axiosRetry(
        `/api/emailsender/SendAwsVerificationEmail`,
        params,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}

export async function configureDkim(email: string): Promise<Array<string>> {
    let params = new SendEmailParams();
    params.recipientEmail = email;
    const response = await axiosRetry(
        `/api/emailsender/ConfigureDkim`,
        params,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {

        return response.data as Promise<Array<string>>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}

export async function getVerificationStatus(email: string): Promise<string> {
    const response = await axiosRetry(`/api/emailsender/getverificationstatus?emailaddress=${email}`,
        null
        , "GET");

    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data.value as Promise<string>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}

export async function sendEmail(params: SendEmailParams): Promise<string> {
    const response = await axiosRetry(
        `/api/emailsender/sendemail`,
        params,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}
export async function sendTestEmail(params: SendEmailParams, settings: EmailSettings): Promise<string> {
    const response = await axiosRetry(
        `/api/emailsender/sendtestemail`,
        params,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}
export async function sendCompanyInvitation(propertyGuid: string): Promise<string> {
    const response = await axiosRetry(
        `/api/auth/sendcompanyinvitation/${propertyGuid}`,
        null,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}
export async function sendAllCompanyInvitations(): Promise<string> {
    const response = await axiosRetry(
        `/api/auth/sendallinvitations`,
        null,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<string>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}

export async function sendDownloadRequestEmail(params: SendEmailParams): Promise<boolean> {
    const response = await axiosRetry(
        `/api/emailsender/senddownloadrequestemail`,
        params,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}
export async function sendPurchaseRequestEmail(params: SendEmailParams): Promise<boolean> {
    const response = await axiosRetry(
        `/api/emailsender/sendpurchaserequestemail`,
        params,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}

export async function submitDemoRequest(params: SendEmailParams): Promise<boolean> {
    const response = await axiosRetry(
        `/api/emailsender/submitdemorequest`,
        params,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    }
    else if (response && response.data.error) {
        throw Error(response.data.error);
    }
    else {
        throw Error("send failed");
    }
}