import { axiosRetry } from "@/utils/fetchUtil";
import { Guid } from "@/utils/Guid";
import Property from "./Property";
import { Invoice } from "@/components/Cloud/Invoices/Invoice";
import { formatter } from "@/utils/currencyUtils";

export class TemplateKinds {
    public static SiteAdmin: string = "Site Admin"; // never assigned to a business other than platform
    public static Business: string = "Business"; //already assigned to a business
    public static Sample: string = "Sample"; // could possible be assigned to a business
}


export class Template {
    static this: any;
    constructor() {
        this.fileContent = "<html><head></head><body></body></html>";
    }
    public templateGuid!: string;
    public propertyGuid!: string;
    public name!: string;
    public description!: string;
    public kind!: TemplateKinds;
    public section!: string;// misc, invoice, letter
    public autoLog!: boolean;
    public fileContent!: string;
    public emailSubject!: string;
    public active!: boolean;
}


export async function mergeInvoiceTable(table: HTMLTableElement, invoice: Invoice, currencyCode: string) {
    if (table && invoice) {
        let headerRow = table.rows[1];
        if (headerRow) {
            let headerCells = headerRow.cells;
            table.rows[1].remove();
            invoice.items = [];
            for (const charge of invoice.charges) {
                invoice.items.push(charge);
            }
            for (const payment of invoice.payments) {
                invoice.items.push(payment);
            }
            for (const item of invoice.items) {
                var row = table.insertRow();
                for (const cell of headerCells) {
                    let cell1 = row.insertCell();
                    let field = cell.innerText
                        .replace("{{", "")
                        .replace("charge.", "")
                        .replace("payment.", "")
                        .replace("}}", "");
                    let isMoney = typeof item[field] === "number";
                    let isDate = new Date(item[field]).isDate();
                    if (isMoney && field !== "quantity") {
                        cell1.innerText = formatCurrency(item[field], currencyCode);
                        cell1.style.textAlign = "right";
                    } else if (isDate) {
                        cell1.innerHTML = formatDate(item[field]);
                    } else {
                        if (item[field]) {
                            cell1.innerHTML = item[field];
                            cell1.style.textAlign = cell.style.textAlign;
                        }
                    }
                    cell1.style.border = cell.style.border;
                    cell1.style.padding = cell.style.padding;
                    cell1.style.backgroundColor = cell.style.backgroundColor;
                    cell1.style.height = cell.style.height;
                }
            }
        }
    }
}

function formatDate(dateTo: Date) {
    if (new Date(dateTo).isMinDate()) {
        return "";
    }
    return new Date(dateTo).readableDate(false);
}

function formatCurrency(num: number, currencyCode: string) {
    let result = formatter(num, currencyCode);
    if (num < 0) {
        //remove the negative sign
        while (result.charAt(0) === "-") {
            result = result.substring(1);
        }

        result = "(" + result + ")";
    }
    return result;
}

export async function replacePropertyFields(stringContent: string, property: Property): Promise<string> {
    if (!stringContent) {
        return "";
    }
    if (property.logo && property.imageGuid && property.imageGuid != Guid.emptyGuid()) {
        stringContent = stringContent.replaceAll(
            "{{property.logo}}",
            "<img src='" + property.logo + "'/>"
        );

        stringContent = stringContent.replaceAll(
            "{{logo}}",
            "<img src='" + property.logo + "'/>"
        );
    } else {
        stringContent = stringContent.replaceAll(
            "{{property.logo}}",
            "<img src=''/>"
        );

        stringContent = stringContent.replaceAll(
            "{{logo}}",
            "<img src=''/>"
        );
    }
    stringContent = stringContent.replaceAll(
        "{{primaryColor}}",
        property.primaryColor
    );
    stringContent = stringContent.replaceAll(
        "{{property.primaryColor}}",
        property.primaryColor
    );
    stringContent = stringContent.replaceAll(
        "{{property.secondaryColor}}",
        property.secondaryColor
    );
    stringContent = stringContent.replaceAll(
        "{{secondaryColor}}",
        property.secondaryColor
    );
    return stringContent;
}

export async function addSamples(propertyGuid?: string): Promise<boolean> {
    let url = '';

    url = `/api/template/addsamples/${propertyGuid}`;

    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the template from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}


export async function getTemplates(propertyGuid: string, all: boolean = false): Promise<Array<Template>> {
    let url = `/api/template/getbyproperty/${propertyGuid}`;
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        var templates = response.data as Promise<Array<Template>>;
        if (all == true) {
            return templates;

        } else {
            return (await templates).filter(t => t.active == true);
        }
    } else {
        const err =
            "An error occurred getting the template from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
export async function getSendInvoiceTemplate(invoiceGuid: string): Promise<Template> {
    let url = `/api/template/getsendinvoicetemplate/${invoiceGuid}`;
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Template>;
    } else {
        const err =
            "An error occurred getting the template from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getSampleTemplates(): Promise<Array<Template>> {
    let url = `/api/template/getsamples`;
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<Template>>;
    } else {
        const err =
            "An error occurred getting the template from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getSiteAdminTemplates(): Promise<Array<Template>> {
    let url = `/api/template/getsiteadmin`;
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<Template>>;
    } else {
        const err =
            "An error occurred getting the template from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getTemplate(templateGuid: string): Promise<Template> {
    const response = await axiosRetry(
        `/api/template/${templateGuid}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Template>;
    } else {
        const err =
            "An error occurred getting the template from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getMergedTemplate(propertyGuid: string, templateGuid: string, reservationGuid?: string, personGuid?: string, invoiceGuid?: string): Promise<Template> {
    let url = `/api/template/getmergedtemplate/${propertyGuid}/${templateGuid}`;
    let empty = Guid.emptyGuid();
    if (reservationGuid) {
        url = url + `/${reservationGuid}`;
    } else {
        url = url + `/${empty}`;
    }
    if (personGuid) {
        url = url + `/${personGuid}`;
    } else {
        url += `/${empty}`;
    }
    if (invoiceGuid) {
        url = url + `/${invoiceGuid}`;
    }
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && !response.error && response.status == 200) {
        return response.data as Promise<Template>;
    } else {
        const err =
            "An error occurred getting the template from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function saveTemplate(template: Template): Promise<boolean> {
    const response = await axiosRetry(
        `/api/template`,
        template,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the template from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function deleteTemplate(templateGuid: string): Promise<boolean> {
    const response = await axiosRetry(
        `/api/template/` + templateGuid,
        null,
        "DELETE"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the amenity from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}