import { axiosRetry, fetchRetry } from "@/utils/fetchUtil";
import { GridMonth } from "./GridMonth";
import { GridUnit } from "./GridUnit";
import { Guid } from "@/utils/Guid";

export class GridParameters {
    public startDate!: Date;
    public length!: number;
    public propertyGuid!: string;
    public unitGuid!: string | null;
    public isMobile!: boolean;
    public skipUnavailable!: boolean;
}

export class GridModel {
    constructor() {
        this.gridUnits = [];
        this.gridMonths = [];
    }
    public startDate: Date = new Date();
    public length!: number;
    public gridUnits!: Array<GridUnit>;
    public gridMonths!: Array<GridMonth>;
}

export async function getAvailabilityForNextYear(propertyGuid: string, unitGuid: string): Promise<GridModel> {
    const params = {
        PropertyGuid: propertyGuid,
        UnitGuid: unitGuid,
        StartDate: new Date(), //now
        Length: 365,
        IsMobile: false,
        SkipUnavailable: false,
    };

    const response = await axiosRetry(
        `/api/book/grid`,
        params,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<GridModel>;
    } else {
        const err =
            "An error occurred getting the account from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getAllAvailability(propertyGuid: string, startDate: Date, days: number, skipUnavailable: boolean): Promise<GridModel> {
    const param: GridParameters = {
        propertyGuid: propertyGuid,
        unitGuid: '',
        startDate: startDate,
        length: days,
        isMobile: false,
        skipUnavailable: skipUnavailable,
    };
    const response = await axiosRetry(
        `/api/book/grid`,
        param,
        "POST"
    );

    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<GridModel>;
    } else {
        const err =
            "An error occurred getting the account from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getAvailability(params: GridParameters): Promise<GridModel> {
    const response = await axiosRetry(
        `/api/book/grid`,
        params,
        "POST"
    );

    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<GridModel>;
    } else {
        const err =
            "An error occurred getting the account from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}