import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { Charge } from './Charge';


export async function deleteCharges(chargesGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/charge/delete`,
    chargesGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the charge from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteCharge(chargeGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/invoice/deletecharge/` + chargeGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the charge from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveCharge(charge: Charge): Promise<Charge> {
  const response = await axiosRetry(
    `/api/invoice/savecharge`,
    charge,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Charge>;
  } else {
    const err =
      "An error occurred getting the charge from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function saveCharges(charge: Array<Charge>): Promise<Array<Charge>> {
  const response = await axiosRetry(
    `/api/invoice/savecharges`,
    charge,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Charge>>;
  } else {
    const err =
      "An error occurred getting the charge from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}


export async function getCharges(personGuid?: string, reservationGuid?: string, propertyGuid?: string, startDate?: Date, endDate?: Date): Promise<Array<Charge>> {
  let url = '';
  if (personGuid) {
    url = `/api/charge/getbyperson/${personGuid}`;
  } else if (reservationGuid) {

    url = `/api/charge/getbyreservation/${reservationGuid}`;
  } else if (startDate && endDate) {
    let strStart = new Date(startDate).toDateString();
    let strEnd = new Date(endDate).toDateString();
    url = `/api/charge/getbyproperty/${propertyGuid}/${strStart}/${strEnd}`;
  }
  else {

    url = `/api/charge/getbyperson/${personGuid}`;
  }

  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Charge>>;
  } else {
    const err =
      "An error occurred getting the charge from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getChargesByPerson(personGuid: string): Promise<Array<Charge>> {
  const response = await axiosRetry(
    `/api/charge/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Charge>>;
  } else {
    const err =
      "An error occurred getting the charge from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getCharge(chargeGuid: string): Promise<Charge> {
  const response = await axiosRetry(
    `/api/charge/getcharge/${chargeGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Charge>;
  } else {
    const err =
      "An error occurred getting the charge from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

