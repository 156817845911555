export class Account {
    constructor() {
    }
    public accountGuid!: string;
    public propertyGuid!: string;
    public modifiedDate!: Date;
    public createdDate!: Date;
    public name!: string;
    public number!: string;
    public tax1Rate: number = 0;
    public tax2Rate: number = 0;
    public tax3Rate: number = 0;
    public tax4Rate: number = 0;
    public tax5Rate: number = 0;
    public tax6Rate: number = 0;
    public tax7Rate: number = 0;
    public tax8Rate: number = 0;
    public tax9Rate: number = 0;
    public tax10Rate: number = 0;
    }
    