
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { getPerson } from "../People/PersonService";
import { PersonInReservation } from "../Reservations/PersonInReservation";
import { Reservation } from "../Reservations/Reservation";
import { getReservation } from "../Reservations/ReservationService";
import { Invoice } from "./Invoice";
import InvoiceEdit from "./InvoiceEdit.vue";
import InvoiceModal from "./InvoiceModal.vue";
import { export_table_to_csv } from "@/utils/exportToCsv";

import {
  deleteInvoice,
  getInvoices,
  getInvoicesByPerson,
  getInvoicesByReservation,
  saveInvoice,
} from "./InvoiceService";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { InvoiceEdit, ConfirmDeleteModal } })
export default class InvoiceTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  @Prop({ default: "reservation" }) public mode!: string; // 'person', 'property', 'daterange' ??
  private loading = true;
  private showInvoiceModal = false;
  private invoices: Array<Invoice> = [];
  private invoiceFields: Array<Field> = [];
  private activeInvoice!: Invoice | null;
  private invoiceTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;
  private reservation!: Reservation;
  private startDate = new Date().addDays(-7);
  private endDate = new Date();
  private invoicesTotalsKey = Guid.newGuid();

  showDeleteConfirm() {
    if (this.selected && this.selected.length > 0) {
      this.showConfirmModal = true;
    }
  }

  formatCurrency(num: number) {
    let isNum = typeof num === "number" && num === num;
    if (!isNum) {
      return "";
    }

    // to do: do we need the invoice currency code here?
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  sum(fieldName: string) {
    let sum = 0;
    for (const invoice of this.invoices) {
      sum = sum + invoice[fieldName];
    }
    return sum;
  }
  getTotalCharges() {
    let sum = 0;
    for (const invoice of this.invoices) {
      sum = sum + invoice.totalCharges;
    }
    return sum;
  }
  getTotalCredits() {
    let sum = 0;
    for (const invoice of this.invoices) {
      sum = sum + invoice.totalCredits;
    }
    return sum;
  }
  getTotalBalance() {
    let sum = 0;
    for (const invoice of this.invoices) {
      sum = sum + invoice.balance;
    }
    return sum;
  }

  async exportTableToExcel() {
    this.loading = true;
    var csvTable = this.$el.querySelector("#invoiceTableRef"); // this is the ID of the table to export
    if (csvTable) {
      await export_table_to_csv(csvTable, "Invoices.csv");
    }
    this.loading = false;
  }

  async startDateChanged(arg: Date) {
    this.startDate = new Date(arg.toString() + " 00:00:00");
    await this.loadInvoices();
  }
  async endDateChanged(arg: Date) {
    this.endDate = new Date(arg.toString() + " 00:00:00");
    await this.loadInvoices();
  }

  async loadInvoices() {
    this.loading = true;
    this.invoices = await getInvoices(
      "",
      "",
      this.property.propertyGuid,
      this.startDate,
      this.endDate
    );
    this.loading = false;
  }

  async cancelDeletion() {
    this.showConfirmModal = false;
  }

  async deleteInvoices() {
    this.loading = true;
    if (this.selected && this.selected.length > 0) {
      for (const deletedInv of this.selected) {
        let result = await deleteInvoice(deletedInv.invoiceGuid.toString());
        if (result) {
          if (this.invoices) {
            let index = this.invoices.findIndex(
              (a) => a.invoiceGuid === deletedInv.invoiceGuid
            );
            this.invoices.splice(index, 1);
          }
        }
      }
      this.showConfirmModal = false;
    }
    await this.invoiceTable.refresh();
    this.$emit("invoices-changed");
    this.loading = true;
  }

  async add() {
    this.loading = true;
    let invoice = new Invoice();
    invoice.invoiceGuid = Guid.newGuid();
    invoice.propertyGuid = this.property.propertyGuid;
    invoice.createdDate = new Date();
    if (this.reservationGuid) {
      invoice.reservationGuid = this.reservationGuid;
    }
    if (this.reservation) {
      let pir = this.reservation.personInReservations.find(
        (p) => p.isPrimaryContact
      );
      if (pir) {
        let person = await getPerson(pir.personGuid);
        invoice.personGuid = pir.personGuid;
        invoice.personName = person?.fullName;
      }
    }

    if (this.personGuid) {
      invoice.personGuid = this.personGuid;
    }

    invoice = await saveInvoice(invoice);
    this.activeInvoice = invoice;
    this.invoices.push(invoice);
    this.loading = false;
    this.showInvoiceModal = true;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.invoiceTable.selectAllRows();
  }
  clearSelected() {
    this.invoiceTable.clearSelected();
  }

  async saved(invoice: Invoice) {
    this.loading = true;
    // if the list doesn't contain the invoice, add it
    let found = this.invoices.find(
      (a) => a.invoiceGuid === invoice.invoiceGuid
    );
    if (!found) {
      await this.invoices.push(invoice);
    }
    let index = await this.invoices.findIndex(
      (a) => a.invoiceGuid === invoice.invoiceGuid
    );
    this.invoices[index] = invoice;
    await this.invoiceTable.refresh();
    this.invoiceTable.selectRow(index);
    this.$emit("invoices-changed");
    this.showInvoiceModal = false;
    this.loading = false;
  }

  private getInvoiceFields() {
    let field = new Field();
    field.key = "personName";
    field.label = "Person";
    this.invoiceFields.push(field);

    field = new Field();
    field.key = "createdDate";
    field.label = "Created";
    this.invoiceFields.push(field);

    if (this.mode == "property") {
      // add a bunch more fields
      field = new Field();
      field.key = "reservationName";
      field.label = "Reservation";
      this.invoiceFields.push(field);

      field = new Field();
      field.key = "totalCharges";
      field.label = "Total Charges";
      this.invoiceFields.push(field);

      field = new Field();
      field.key = "totalTax";
      field.label = "Total Taxes";
      this.invoiceFields.push(field);

      field = new Field();
      field.key = "totalCredits";
      field.label = "Total Credits";
      this.invoiceFields.push(field);
    }
    field = new Field();
    field.key = "balance";
    field.label = "Balance";
    this.invoiceFields.push(field);
  }

  async mounted() {
    this.invoicesTotalsKey = Guid.newGuid();
    this.loading = true;
    this.invoiceTable = this.$refs.invoiceTableRef;
    this.getInvoiceFields();
    if (this.personGuid) {
      this.invoices = await getInvoicesByPerson(this.personGuid);
      this.$emit("invoices-loaded", this.invoices);
    } else if (this.reservationGuid) {
      try {
        this.reservation = await getReservation(this.reservationGuid);
      } catch (e) {
        console.log(e);
      }
      if (this.reservation) {
        this.invoices = await getInvoicesByReservation(this.reservationGuid);
      }
      this.$emit("invoices-loaded", this.invoices);
    } else if (this.mode == "property") {
      await this.loadInvoices();
    } else {
      this.invoices = new Array<Invoice>();
    }

    this.loading = false;
  }

  getActiveInvoice() {
    if (this.activeInvoice) {
      return this.activeInvoice.invoiceGuid;
    } else {
      return "";
    }
  }

  edit(invoice: any) {
    if (invoice) {
      this.activeInvoice = invoice;
    } else if (this.selected && this.selected.length > 0) {
      this.activeInvoice = this.selected[0];
    }
    this.showInvoiceModal = true;
  }

  async closeInvoiceModal() {
    this.loading = true;
    await this.invoiceTable.refresh();
    this.showInvoiceModal = false;
    this.invoicesTotalsKey = Guid.newGuid();
    this.$emit("invoices-changed");
    this.loading = false;
  }
}
