
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";
import { namespace } from "vuex-class";
import UnitGroupTable from "./UnitGroupTable.vue";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    ConfigurationNavMenu,
    UnitGroupTable
  },
})
export default class UnitGroups extends Vue {
  @PropertyStore.State
  public property!: Property;

}
