import axios, { AxiosResponse } from 'axios';
import store from '@/store/VuexStore';

export default async function authHeader(): Promise<string> {

    var storage;
    try {
        storage = window.sessionStorage || {};
    } catch (e) {
        try {
            storage = window.localStorage;
        } catch (e) {
        }
    }
    if (storage) {
        const storedUser = await storage.getItem('user');
        const accessToken = await storage.getItem('access-token');
        if (storedUser) {
            const user = JSON.parse(storedUser);
            if (user && user.accessToken) {
                return 'Bearer ' + user.accessToken;
            }
        }
        else if (accessToken) {
            const accessTokenParsed = JSON.parse(accessToken);
            if (accessTokenParsed) {
                return 'Bearer ' + accessTokenParsed;
            }
        }
    }
    return "";
}

export async function xAccessToken(): Promise<string> {
    var storage;
    try {
        storage = window.sessionStorage || {};
    } catch (e) {
        try {
            storage = window.localStorage;
        } catch (e) {
        }
    }
    if (storage) {
        const accessToken = await storage.getItem('access-token');
        if (accessToken) {
            const accessTokenParsed = JSON.parse(accessToken);
            if (accessTokenParsed) {
                return accessTokenParsed;
            }
        }
    }
    return "";
}

const retrycount = 3;
const milliseconds = 6 * 1000;
const options: any = {
    method: "POST",
    credentials: "same-origin",
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,PUT,PATCH,DELETE",
        "Access-Control-Allow-Headers": "Content-Type",
        'Authorization': authHeader(),
        'x-access-token': xAccessToken(),
    }
};



function delay(milliseconds: number, val: any): Promise<Response> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(val);
        }, milliseconds);
    });
}

const axiosCall = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: { "Access-Control-Allow-Origin": "*" }
});
axiosCall.interceptors.request.use(request => {
    store.dispatch('globalvalues/setLoading', true);
    return request;
})
axiosCall.interceptors.response.use(
    (response) => {
        store.dispatch('globalvalues/setLoading', false);
        return response;
    },
    (error) => {
        store.dispatch('globalvalues/setLoading', false);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
)

function axiosDelay(milliseconds: number, val: any): Promise<AxiosResponse<any>> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(val);
        }, milliseconds);
    });
}

export async function axiosRetry(
    url: string,
    data: any,
    method: string,
    body2?: any
): Promise<any> {
    let response: AxiosResponse<any>;
    const opts: any = {
        method: "POST",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,PUT,PATCH,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            'Authorization': authHeader(),
            'x-access-token': xAccessToken(),
        }
    };

    opts.method = method.toUpperCase();
    opts.data = data;
    if (body2) {
        opts.body = body2;
    } else {
        opts.body = data;
    }
    opts.headers.Authorization = await authHeader();
    url = process.env.VUE_APP_BASE_URL + url;
    for (let i = 1; i <= retrycount; i++) {
        if (i === 1) {
            response = await axiosCall(url, opts);
        } else {
            response = await axiosDelay(milliseconds, axiosCall(url, opts));
        }
        if (response && response.status === 401 || response.status === 403) {
            //unauthorized 
            window.location.href = "/login";
        }
        else {
            return response;
        }
    }
}

///DEPRECATED
export async function fetchSimple(
    url: string,
    params: any,
    method: string
): Promise<any> {

    try {

        options.method = method;
        options.headers.Authorization = await authHeader();
        if (params != null) {
            const body = JSON.stringify(params);
            options.body = body;
        } else {
            options.body = null;
        }
        url = process.env.VUE_APP_BASE_URL + url;

        const response = await fetch(url, options);
        if (response.statusText === "OK" || response.status === 200) {
            return response;
        } else if (response.status === 401 || response.status === 403) {
            //unauthorized   
            window.location.href = "/login";
        }
    } catch (err) {
        console.error(err);
    }
}

// DEPRECATED - for a number of reasons (mainly scrolling), don't use this. Use Axios retry.
export async function fetchRetry(
    url: string,
    params: any,
    method: string
): Promise<any> {
    let response: Response;

    const opts: any = {
        method: "POST",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,PUT,PATCH,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            'Authorization': authHeader(),
            'x-access-token': xAccessToken(),
        }
    };

    opts.method = method;
    opts.headers.Authorization = await authHeader();
    if (params != null) {
        const body = JSON.stringify(params);
        opts.body = body;
    } else {
        opts.body = null;
    }
    url = process.env.VUE_APP_BASE_URL + url;
    for (let i = 1; i <= retrycount; i++) {
        try {
            if (i === 1) {
                response = await fetch(url, opts);
            } else {
                response = await delay(milliseconds, fetch(url, opts));
            }
            if (response.statusText === "OK" || response.status === 200) {
                return response;
            } else if (response.status === 401 || response.status === 403) {
                //unauthorized 
                window.location.href = "/login";
            }
        } catch (err) {
            console.error(err);
        }
    }
}