
import { Person } from "@/components/Cloud/People/Person";
import { Source } from "@/components/Cloud/Source/Source";
import {
  getPersonRelationshipColorVariant,
  getRelationshipFromPreference,
  getRelationships,
  PersonRelationship,
  RelationshipKeyValuePair,
} from "@/components/Cloud/People/PersonRelationship";
import { Preference } from "@/models/Preference";
import Property from "@/models/Property";
import { getPerson, savePerson } from "@/components/Cloud/People/PersonService";
import { Guid } from "@/utils/Guid";
import { Loader } from "@googlemaps/js-api-loader";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ComboBox from "../../Controls/ComboBox.vue";
import MaskedInput from "../../Controls/MaskedInput.vue";
import ActivityTable from "../Activity/ActivityTable.vue";
import InvoiceTable from "../Invoices/InvoiceTable.vue";
import ReservationsTable from "../Reservations/ReservationsTable.vue";
import TemplateEdit from "../Templates/TemplateEdit.vue";
import { getTemplates, Template } from "@/models/Template";
import ActivityModal from "../TimeSlots/TimeSlotModal.vue";
import DocumentTable from "../Documents/DocumentTable.vue";
import { Invoice } from "../Invoices/Invoice";
import { getInvoicesByPerson } from "../Invoices/InvoiceService";
import { formatter } from "@/utils/currencyUtils";
import DatePicker from "@/components/SiteBook/DatePicker.vue";

const PropertyStore = namespace("PropertyStore");
const SourceStore = namespace("SourceStore");
@Component({
  components: {
    ComboBox,
    MaskedInput,
    ReservationsTable,
    InvoiceTable,
    ActivityTable,
    DatePicker,
    TemplateEdit,
    DocumentTable,
  },
})
class PersonEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @SourceStore.State
  public sources!: Array<Source>;

  @SourceStore.Action
  public getSources!: (propGuid: string) => Promise<Array<Source>>;

  @SourceStore.Action
  public setSources!: (sources: Array<Source>) => void;

  @Prop({ default: null }) public personGuid!: string;
  private busy = false;
  private person = new Person();
  private invoice = new Invoice();
  private prefixes = ["Dr.", "Mr.", "Ms.", "Mrs."];
  private relationships = Array<RelationshipKeyValuePair>();
  private addr1line1TextBox!: HTMLInputElement;
  private addr1line2TextBox!: HTMLInputElement;
  private cityTextBox!: HTMLInputElement;
  private zipTextBox!: HTMLInputElement;
  private stateTextBox!: HTMLInputElement;
  private countryTextBox!: HTMLInputElement;
  private isNew = false;
  private selectedRelationship = new RelationshipKeyValuePair(0, "");
  private selectedSource = new Source();
  public templateSelected!: Template | null;
  private showTemplateSendModal = false;
  private templates: Template[] = [];
  private activitiesKey = Guid.newGuid();
  private invoicesKey = Guid.newGuid();
  private documentsKey = Guid.newGuid();
  private reservationsKey = Guid.newGuid();
  private invoices = new Array<Invoice>();
  private expanded = false;
  private templateMode = "email";

  getTemplateMode() {
    return this.templateMode;
  }

  formatCurrency(num: number) {
    return formatter(num, this.property.currencyCode);
  }

  formatDate(dateTo: Date) {
    if (new Date(dateTo).isMinDate()) {
      return "";
    }
    return new Date(dateTo).readableDate(false);
  }

  async customEmail() {
    this.templateMode = "send";
    this.templateSelected = null;
    this.showTemplateSendModal = true;
  }

  async updateAllLists() {
    this.activitiesKey = Guid.newGuid();
    this.invoicesKey = Guid.newGuid();
    this.reservationsKey = Guid.newGuid();
  }

  getActivitiesKey() {
    return this.activitiesKey;
  }
  getReservationsKey() {
    return this.reservationsKey;
  }
  getInvoicesKey() {
    return this.invoicesKey;
  }
  getDocumentsKey() {
    return this.documentsKey;
  }

  isExpandedMode() {
    return this.$route.name?.toLowerCase() == "personedit";
  }

  expand() {
    // navigate to invoice edit page with invoice guid as param
    this.navigate("PersonEdit", this.person.personGuid.toString());
  }

  navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }

  prefixChanged(arg: any) {
    this.person.namePrefix = arg;
  }

  homePhoneChanged(arg: any) {
    this.person.homePhone = arg;
  }
  cellPhoneChanged(arg: any) {
    this.person.cellPhone = arg;
  }
  workPhoneChanged(arg: any) {
    this.person.workPhone = arg;
  }

  async mergeAndDisplayTemplate(template: Template, inv: Invoice) {
    this.templateMode = "send";
    await (this.invoice = inv);
    await (this.templateSelected = template);
    await (this.showTemplateSendModal = true);
  }
  async printTemplate(template: Template, inv: Invoice) {
    this.templateMode = "print";
    await (this.invoice = inv);
    await (this.templateSelected = template);
    await (this.showTemplateSendModal = true);
  }

  async templatesent() {
    this.showTemplateSendModal = false;
    await this.updateAllLists();
  }

  getSelectedTemplateGuid() {
    if (this.templateSelected) {
      return this.templateSelected.templateGuid;
    } else {
      return null;
    }
  }

  getPersonGuid() {
    if (this.person && this.person.personGuid != Guid.emptyGuid()) {
      return this.person.personGuid;
    }
  }
  getInvoiceGuid() {
    if (this.invoice && this.invoice.invoiceGuid != Guid.emptyGuid()) {
      return this.invoice.invoiceGuid;
    }
  }
  getReservationGuid() {
    if (this.invoice && this.invoice.invoiceGuid != Guid.emptyGuid()) {
      return this.invoice.reservationGuid;
    }
  }

  getSendToEmailAddress() {
    return this.person?.email;
  }

  getRelationshipString(pref: Preference, val: PersonRelationship) {
    return getRelationshipFromPreference(pref, val);
  }

  getPersonRelationshipColorVariant(rel: PersonRelationship) {
    return getPersonRelationshipColorVariant(rel);
  }

  handleAddressChanged(field: google.maps.places.Autocomplete, google: any) {
    {
      const place = field.getPlace();
      if (place.geometry) {
        this.fillInAddress(field);
      }
    }
  }

  fillInAddress(autocomplete: google.maps.places.Autocomplete) {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    let address1Number = "";
    let address1Route = "";
    let address1 = "";
    let city = "";
    let state = "";
    let postcode = "";
    let country = "";

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    // place.address_components are google.maps.GeocoderAddressComponent objects
    // which are documented at http://goo.gle/3l5i5Mr
    for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address1Number = component.long_name;
          break;
        }

        case "route": {
          address1Route = component.long_name;
          break;
        }

        case "postal_code": {
          postcode = component.long_name;
          break;
        }
        case "postal_code_prefix": {
          postcode = component.long_name + " " + postcode;
          break;
        }

        case "locality":
          city = component.long_name;
          break;

        case "administrative_area_level_1": {
          //   component.short_name;
          state = component.long_name;
          break;
        }

        case "country":
          country = component.short_name;
          break;
      }
    }

    address1 = `${address1Number} ${address1Route}`;
    if (address1.trim()) {
      this.addr1line1TextBox.value = address1.trim();
    }

    if (city.trim()) {
      this.cityTextBox.value = city.trim();
    }
    if (state.trim()) {
      this.stateTextBox.value = state.trim();
    }
    if (postcode.trim()) {
      this.zipTextBox.value = postcode.trim();
    }
    if (country.trim()) {
      this.countryTextBox.value = country.trim();
    }
  }

  changedMask(e) {
    this.person.international = e;
  }

  async mounted() {
    let localPersonGuid = "";
    this.busy = true;
    if (this.personGuid) {
      localPersonGuid = this.personGuid;
    } else if (this.$route.query.personGuid) {
      localPersonGuid = this.$route.query.personGuid.toString();
    } else if (this.$route.params.id) {
      localPersonGuid = this.$route.params.id.toString();
    }
    if (localPersonGuid) {
      this.person = await getPerson(localPersonGuid);
      this.invoices = await getInvoicesByPerson(localPersonGuid);
      this.invoices.sort();
      this.invoices.sort((a, b) => {
        if (a.createdDate > b.createdDate) {
          return -1;
        }
        if (a.createdDate < b.createdDate) {
          return 1;
        }
        return 0;
      });
    } else {
      //this is a new person
      this.person = new Person();
      this.person.personGuid = Guid.newGuid();
      this.person.propertyGuid = this.property.propertyGuid;
      this.isNew = true;
    }

    if (
      this.person.firstContact &&
      new Date(this.person.firstContact).isMinDate()
    ) {
      this.person.firstContact = null;
    }
    if (
      this.person.lastContact &&
      new Date(this.person.lastContact).isMinDate()
    ) {
      this.person.lastContact = null;
    }
    if (this.person.callBack && new Date(this.person.callBack).isMinDate()) {
      this.person.callBack = null;
    }

    this.relationships = getRelationships(
      this.property.preference as Preference
    );
    if (this.person.relationship) {
      this.selectedRelationship = this.relationships[this.person.relationship];
    } else {
      this.selectedRelationship = this.relationships[0];
    }
    await this.getSources(this.property.propertyGuid).then((s) =>
      this.setSources(s)
    );
    let source = this.sources.find((s) => s.name == this.person.source);
    if (source) {
      this.selectedSource = source;
    }

    this.templates = await getTemplates(this.property.propertyGuid);
    // this.templates = this.templates.filter(
    //   (t) => t.section == null || t.section == "" || t.section == "person"
    // );

    const loader = new Loader({
      apiKey: "AIzaSyDVYoGJ1NaKgqdrfVviGXh7rqVhY1uZe0g",
      version: "weekly",
      libraries: ["places"],
    });

    await loader.load().then((google) => {
      this.addr1line2TextBox = this.$refs[
        "addr1Line2-textbox"
      ] as HTMLInputElement;

      this.addr1line1TextBox = this.$refs[
        "addr1Line1-textbox"
      ] as HTMLInputElement;

      const addr1Line1Autocomplete = new google.maps.places.Autocomplete(
        this.addr1line1TextBox
      );
      this.addr1line1TextBox.value = this.person?.addr1Line1;
      this.addr1line2TextBox.value = this.person?.addr1Line2;
      addr1Line1Autocomplete.addListener("place_changed", () =>
        this.handleAddressChanged(addr1Line1Autocomplete, google)
      );

      this.cityTextBox = this.$refs["city-textbox"] as HTMLInputElement;
      const autocompleteCity = new google.maps.places.Autocomplete(
        this.cityTextBox
      );
      this.cityTextBox.value = this.person?.city;

      autocompleteCity.addListener("place_changed", () =>
        this.handleAddressChanged(autocompleteCity, google)
      );

      this.stateTextBox = this.$refs["state-textbox"] as HTMLInputElement;
      const autocompleteState = new google.maps.places.Autocomplete(
        this.stateTextBox
      );
      this.stateTextBox.value = this.person?.state;

      autocompleteState.addListener("place_changed", () =>
        this.handleAddressChanged(autocompleteState, google)
      );

      this.zipTextBox = this.$refs["zip-textbox"] as HTMLInputElement;
      const autocompleteZip = new google.maps.places.Autocomplete(
        this.zipTextBox
      );
      this.zipTextBox.value = this.person?.zip;
      autocompleteZip.addListener("place_changed", () =>
        this.handleAddressChanged(autocompleteZip, google)
      );

      this.countryTextBox = this.$refs["country-textbox"] as HTMLInputElement;
      const autocompletecountry = new google.maps.places.Autocomplete(
        this.countryTextBox
      );
      this.countryTextBox.value = this.person?.country;
      autocompletecountry.addListener("place_changed", () =>
        this.handleAddressChanged(autocompletecountry, google)
      );
      this.busy = false;
    });
  }

  validateLastName() {
    return true;
  }

  validateFirstName() {
    return true;
  }

  getSaveButtonText() {
    if (this.person && this.person.personGuid != Guid.emptyGuid()) {
      return "Save";
    } else {
      return "Add Person";
    }
  }
  cancel() {
    this.$emit("cancel");
    this.$emit("close");
  }

  async saveAndClose() {
    this.busy = true;

    if (!this.person.lastContact) {
      this.person.lastContact = new Date().minDate();
    }
    this.person.source = this.selectedSource?.name as string;
    this.person.relationship = this.selectedRelationship?.key;
    this.person.addr1Line1 = this.addr1line1TextBox.value;
    this.person.addr1Line2 = this.addr1line2TextBox.value;
    this.person.city = this.cityTextBox.value;
    this.person.zip = this.zipTextBox.value;
    this.person.state = this.stateTextBox.value;
    this.person.country = this.countryTextBox.value;
    this.person.lastCommaFirst =
      this.person.lastName + ", " + this.person.firstName;
    this.person.firstSpaceLast =
      this.person.firstName + " " + this.person.lastName;
    this.person.cityStateZip =
      this.person.city + ", " + this.person.state + " " + this.person.zip;
    await savePerson(this.person);
    this.$emit("success", this.person);
    if (this.isExpandedMode()) {
      this.$router.push({
        name: "People",
      });
    }
    this.busy = false;
  }
}
export default PersonEdit;
