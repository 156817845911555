
import { formatter } from "@/utils/currencyUtils";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import StyleThumb from "../Marketplace/StyleThumb.vue";
import CartItem from "./CartItem";
import "@/utils/dateUtil";
import { UnitTypes } from "../Cloud/Units/Unit";

@Component({
  components: { StyleThumb },
})
export default class CartItemDetail extends Vue {
  @Prop({ default: new CartItem() }) public cartItem!: CartItem;

  @Watch("ci", { immediate: false, deep: true })
  onDateChanged(newCartItem: CartItem, oldValue: CartItem) {
    this.cartItem = newCartItem;
  }
  private item!: CartItem;
  created() {
    this.item = this.cartItem;
  }
  formatCurrency(num: number) {
    if (num < 0) {
      return "$--.--";
    }

    return formatter(num, this.cartItem.currencyCode);
  }
  formatDateTime(item: CartItem) {
    if (item.unitType == UnitTypes.Accommodation) {
      let format = `${new Date(item.inDateTime).readableDate(
        true
      )} for ${new Date(item.inDateTime).daysDiff(item.outDateTime)} night(s)`;
      return format;
    } else {
      return (
        new Date(item.inDateTime).readableDateTime() +
        " to " +
        new Date(item.outDateTime).time(true)
      );
    }
  }
  getNights(item: CartItem) {
    return new Date(item.inDateTime).daysDiff(new Date(item.outDateTime));
  }
  modify() {
    this.$emit("modify-cart-item", this.item);
  }
  deleteItem() {
    this.$emit("delete-cart-item", this.item);
  }
}
