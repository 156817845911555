import { Assign } from "../components/Cloud/Assign/Assign";
import { Charge } from "../components/Cloud/Invoices/Charge";
import { Season } from "../components/Cloud/Rates/Seasons/Season";
import { Rate } from "@/components/Cloud/Rates/Rate";

export default class PriceQuote {
    constructor() {
    }
    public assign!: Assign;
    public numberOfPeople: number = 0;
    public numberOfPeople2: number = 0;
    public numberOfPeople3: number = 0;
    public seasonSelectedForQuote!: Season;
    public changedBaseRate!: number;
    public changedNumNights: number = 0;
    public changedExtraPersonRate: number = 0;
    public discount: number = 0;
    public selectedRateQuantity: number = 0;
    public changedWeekendRate: number = 0;
    public rateSelected!: Rate;
    public seasonSelected!: Season;
    public quote: number = 0;
    public quoteTax: number = 0;
    public quoteServiceCharge: number = 0;
    public charges!: Array<Charge>;
    public availableRates!: Array<Rate>;
}