import { render, staticRenderFns } from "./AssignEdit.vue?vue&type=template&id=45095da2&scoped=true&"
import script from "./AssignEdit.vue?vue&type=script&lang=ts&"
export * from "./AssignEdit.vue?vue&type=script&lang=ts&"
import style0 from "./AssignEdit.vue?vue&type=style&index=0&id=45095da2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45095da2",
  null
  
)

export default component.exports