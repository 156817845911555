
import { Unit } from "@/components/Cloud/Units/Unit";
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class UnitCard extends Vue {
  @Prop({ default: () => null }) public unitObj!: Unit;
  @Prop({ default: () => null }) public property!: Property;

  formatDate(dateFor: Date) {
    return new Date(dateFor).formatDayOfWeekMMDDYYYY();
  }

  goToUnitPage() {
    this.$router.push({
      name: "Book",
      params: {
        unit: this.unitObj.unitGuid,
      },
    });
  }
}
