import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Dashboard from '../components/Cloud/Dashboard/Dashboard.vue';
import Configuration from '../components/Cloud/Settings/Configuration.vue';
import Finance from '../components/Cloud/Finance/Finance.vue';
import GridSettings from '../components/Cloud/Settings/GridSettings.vue';
import Taxes from '../components/Cloud/Settings/Taxes.vue';
import PeopleSettings from '../components/Cloud/Settings/PeopleSettings.vue';
import BillingSettings from '../components/Cloud/Settings/BillingSettings.vue';
import ReservationSettings from '../components/Cloud/Settings/ReservationSettings.vue';
import Rates from '../components/Cloud/Rates/Rates.vue';
import TimeSlotCollections from '../components/Cloud/TimeSlots/TimeSlotCollections.vue';
import TimeSlotCollectionEdit from '../components/Cloud/TimeSlots/TimeSlotCollectionEdit.vue';
import DeliveryOptions from '../components/Cloud/DeliveryOptions/DeliveryOptions.vue';
import CustomQuestions from '../components/Cloud/CustomQuestions/CustomQuestions.vue';
import ChargeItems from '../components/Cloud/ChargeItems/ChargeItems.vue';
import Amenities from '../components/Cloud/Amenity/Amenities.vue';
import Activities from '../components/Cloud/Activity/Activities.vue';
import Invoices from '../components/Cloud/Invoices/Invoices.vue';
import CreditCardModal from '../components/Cloud/Invoices/CreditCardModal.vue';
import Revenue from '../components/Cloud/Finance/Revenue.vue';
import Payments from '../components/Cloud/Finance/Payments.vue';
import Accounts from '../components/Cloud/Accounts/Accounts.vue';
import Units from '../components/Cloud/Units/Units.vue';
import UnitGroups from '../components/Cloud/Units/UnitGroups.vue';
import UnitEdit from '../components/Cloud/Units/UnitEdit.vue';
import UnitGroupEdit from '../components/Cloud/Units/UnitGroupEdit.vue';
import StripeComplete from '../components/Cloud/CardProcessing/StripeComplete.vue';
import StripeConnect from '../components/Cloud/CardProcessing/StripeConnect.vue';
import HealthChecks from '../components/Cloud/Settings/HealthChecks.vue';
import People from '../components/Cloud/People/People.vue';
import OccupancyGrid from '../components/Cloud/Reservations/OccupancyGrid.vue';
import ReservationEdit from '../components/Cloud/Reservations/ReservationEdit.vue';
import PersonEdit from '../components/Cloud/People/PersonEdit.vue';
import InvoiceEdit from '../components/Cloud/Invoices/InvoiceEdit.vue';
import OutOfServiceEdit from '../components/Cloud/OutOfService/OutOfServiceEdit.vue';
import Reservations from '../components/Cloud/Reservations/Reservations.vue';
import Profile from '@/components/Cloud/Auth/Profile.vue';
import LoginPage from '@/components/Cloud/Auth/LoginPage.vue';
import ForgotPassword from '@/components/Cloud/Auth/ForgotPassword.vue';
import ResetPassword from '@/components/Cloud/Auth/ResetPassword.vue';
import Register from '@/components/Cloud/Auth/RegisterHost.vue';
import VerifyEmail from '@/components/Cloud/Auth/VerifyEmail.vue';
import ConfirmEmail from '@/components/Cloud/Auth/ConfirmEmail.vue';
import Team from '@/components/Cloud/Settings/Team.vue';
import Templates from '@/components/Cloud/Templates/Templates.vue';
import TemplateEdit from '@/components/Cloud/Templates/TemplateEdit.vue';
import RateEdit from '@/components/Cloud/Rates/RateEdit.vue';
import Settings from '@/components/Cloud/Settings/Settings.vue';
import Business from '@/components/Cloud/Settings/Business.vue';
import EmailDomains from '@/components/Cloud/Settings/EmailDomains.vue';
import LayoutCloud from '../layouts/LayoutCloud.vue';
import LayoutLogin from '../layouts/LayoutLogin.vue';
import AddressSuggest from '../components/testers/AddressSuggest.vue';
import EmailTester from '../components/testers/EmailTester.vue';
import VerifySuccess from '../components/Cloud/Auth/VerifySuccess.vue';
import Unsubscribe from '../components/Cloud/Auth/Unsubscribe.vue';
import VerifyFailure from '../components/Cloud/Auth/VerifyFailure.vue';
import Editor from '../components/testers/Editor.vue';
import { loadStripeScript } from '@/stripe-import';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/healthz', // Ignore or pass on to server 
    meta: { layout: null }
  },
  {
    path: '/verifysuccess', // verify email address
    name: 'VerifySuccess',
    component: VerifySuccess,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/verifyfailure', // verify email address
    component: VerifyFailure,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/configuration',
    name: 'Configuration',
    component: Configuration,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/finance',
    name: 'Finance',
    component: Finance,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/units',
    name: 'Units',
    component: Units,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/unitgroups',
    name: 'UnitGroups',
    component: UnitGroups,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/rates',
    name: 'Rates',
    component: Rates,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/timeslotcollections',
    name: 'TimeSlotCollections',
    component: TimeSlotCollections,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/timeslotcollections/:collection',
    name: 'TimeSlotCollectionEdit',
    component: TimeSlotCollectionEdit,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/deliveryoptions',
    name: 'DeliveryOptions',
    component: DeliveryOptions,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/customquestions',
    name: 'CustomQuestions',
    component: CustomQuestions,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/chargeitems',
    name: 'ChargeItems',
    component: ChargeItems,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/amenities',
    name: 'Amenities',
    component: Amenities,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/activities',
    name: 'Activities',
    component: Activities,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/grid',
    name: 'Grid',
    component: OccupancyGrid,
    meta: { layout: LayoutCloud, requiresAuth: true },
  },
  {
    path: '/stripecomplete',
    name: 'StripeComplete',
    component: StripeComplete,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/stripeconnect',
    name: 'StripeConnect',
    component: StripeConnect,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/healthchecks',
    name: 'HealthChecks',
    component: HealthChecks,
    meta: { layout: LayoutCloud }
  },
  {
    path: '/people',
    name: 'People',
    component: People,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/reservations',
    name: 'Reservations',
    component: Reservations,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/editor',
    name: 'Editor',
    component: Editor,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/reservationedit/:id',
    name: 'ReservationEdit',
    component: ReservationEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/rateedit/:id',
    name: 'RateEdit',
    component: RateEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/personedit/:id',
    name: 'PersonEdit',
    component: PersonEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/invoiceedit/:id',
    name: 'InvoiceEdit',
    component: InvoiceEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/templateedit/:templateguid',
    name: 'TemplateEdit',
    component: TemplateEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/outofserviceedit/:id',
    name: 'OutOfServiceEdit',
    component: OutOfServiceEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/revenue',
    name: 'Revenue',
    component: Revenue,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/unitedit/:id',
    name: 'UnitEdit',
    component: UnitEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/unitgroupedit/:id',
    name: 'UnitGroupEdit',
    component: UnitGroupEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings/emaildomains',
    name: 'EmailDomains',
    component: EmailDomains,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings/team',
    name: 'Team',
    component: Team,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings/gridsettings',
    name: 'GridSettings',
    component: GridSettings,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings/taxes',
    name: 'Taxes',
    component: Taxes,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings/peoplesettings',
    name: 'PeopleSettings',
    component: PeopleSettings,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings/billingsettings',
    name: 'BillingSettings',
    component: BillingSettings,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings/reservationsettings',
    name: 'ReservationSettings',
    component: ReservationSettings,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/settings/business',
    name: 'Business',
    component: Business,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    name: 'Templates',
    path: '/templates',
    component: Templates,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    name: 'TemplateEdit',
    path: '/templateedit',
    component: TemplateEdit,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/addresssuggest',
    name: 'AddressSuggest',
    component: AddressSuggest,
    meta: { layout: LayoutCloud }
  },
  {
    path: '/login',
    component: LoginPage,
    meta: { layout: LayoutLogin }
  },
  {
    path: '/register',
    component: Register,
    meta: { layout: LayoutLogin }
  },
  {
    path: '/verifyemail',
    component: VerifyEmail,
    meta: { layout: LayoutLogin }
  },
  {
    path: '/confirmemail',
    component: ConfirmEmail,
    meta: { layout: LayoutLogin }
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    component: ForgotPassword,
    meta: { layout: LayoutLogin }
  },
  {
    path: '/ResetPassword',
    component: ResetPassword,
    meta: { layout: LayoutLogin }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/emailtester',
    component: EmailTester,
    meta: { layout: LayoutCloud, requiresAuth: true }
  },
  {
    path: '/unsubscribe',
    component: Unsubscribe,
    meta: { layout: LayoutLogin, requiresAuth: false }
  }
]

const cloudRouter = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

cloudRouter.beforeEach((to, from, next) => {
  var storage;
  try {
    storage = window.sessionStorage || {};
  } catch (e) {
    storage = window.localStorage;
  }

  //Auth
  const userLoginRequired = (to.matched.some((record: any) => record.meta.requiresAuth));
  const loggedIn = storage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if ((userLoginRequired && userLoginRequired == true) && !loggedIn) {
    next({
      path: '/login',
      query: { redirect: to.fullPath.replace("/", "") },
    });
  } else if (to.fullPath == "/") {
    next({
      path: '/login'
    });
  }
  else {
    next();
  }
});



export default cloudRouter;