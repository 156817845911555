<template>
  <div class="clear-background"></div>
</template>

<script>
export default {
};
</script>

<style scoped>
  .clear-background {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1000;
  }
</style>
