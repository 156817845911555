import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { Component } from './Component';
import { Rate } from './Rate';
import { RateInUnit } from './RateInUnit';
import { Season } from './Seasons/Season';


export async function deleteRates(ratesGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/rate/delete`,
    ratesGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteRate(rateGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/rate/` + rateGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteRateInUnit(rateInUnit: RateInUnit): Promise<boolean> {
  const response = await axiosRetry(
    `/api/rate/deleterateinunit`,
    rateInUnit,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteRateInUnitsForRate(rate: Rate): Promise<boolean> {
  const response = await axiosRetry(
    `/api/rate/deleterateinunitsforrate`,
    rate,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function saveRate(rate: Rate): Promise<Rate> {
  const response = await axiosRetry(
    `/api/rate/saverate`,
    rate,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Rate>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getRates(propertyGuid: string): Promise<Array<Rate>> {
  let url = `/api/rate/getrates/${propertyGuid}`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Rate>>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getRatesByUnit(unitGuid: string): Promise<Array<Rate>> {
  const response = await axiosRetry(
    `/api/rate/getbyunit/${unitGuid}`,
    null,
    "GET"
  );
  if (response && response.data && response.status == 200) {
    return response.data as Promise<Array<Rate>>;
  } else if (!response.data) {
    let emptyArray: any;
    return emptyArray as Promise<Array<Rate>>;
  }
  else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getRate(rateGuid: string): Promise<Rate> {
  const response = await axiosRetry(
    `/api/rate/getrate/${rateGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Rate>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}


export async function getSeasonsByRate(rateGuid: string): Promise<Array<Season>> {
  const response = await axiosRetry(
    `/api/rate/getseasonsbyrate/${rateGuid}`,
    null,
    "GET"
  );
  if (response && response.data && response.status == 200) {
    return response.data as Promise<Array<Season>>;
  } else if (!response.data) {
    let emptyArray: any;
    return emptyArray as Promise<Array<Season>>;
  }
  else {
    const err =
      "An error occurred getting seasons from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getComponentsByRate(rateGuid: string): Promise<Array<Component>> {
  const response = await axiosRetry(
    `/api/rate/getcomponentsbyrate/${rateGuid}`,
    null,
    "GET"
  );
  if (response && response.data && response.status == 200) {
    return response.data as Promise<Array<Component>>;
  } else if (!response.data) {
    let emptyArray: any;
    return emptyArray as Promise<Array<Component>>;
  }
  else {
    const err =
      "An error occurred getting Components from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveRateInUnits(rateInUnits: RateInUnit[]): Promise<boolean> {
  const response = await axiosRetry(
    `/api/rate/saverateinunitsforrate`,
    rateInUnits,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the rate from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getRateInUnitsByRate(rateGuid: string): Promise<Array<RateInUnit>> {
  const response = await axiosRetry(
    `/api/rate/getrateinunitsbyrate/${rateGuid}`,
    null,
    "GET"
  );
  if (response && response.data && response.status == 200) {
    return response.data as Promise<Array<RateInUnit>>;
  } else if (!response.data) {
    let emptyArray: any;
    return emptyArray as Promise<Array<RateInUnit>>;
  }
  else {
    const err =
      "An error occurred getting Components from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

