
import { Field } from "@/models/interfaces/Field";
import {
  getAssignsByReservation,
  deleteAssigns,
} from "@/components/Cloud/Reservations/ReservationService";
import { formatter } from "@/utils/currencyUtils";
import { Vue, Component, Prop } from "vue-property-decorator";
import AddAssign from "./AddAssign.vue";
import { Assign } from "./Assign";
import AssignEdit from "./AssignEdit.vue";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import PriceQuote from "@/models/PriceQuote";

@Component({ components: { AddAssign, ConfirmDeleteModal, AssignEdit } })
export default class AssignTable extends Vue {
  @Prop({ default: null }) public reservationGuid!: string;
  @Prop({ default: "reservation" }) public mode!: string; //can be reservation, available, assigned
  private showAssignOverlay = true;
  private assigns: Array<Assign> = [];
  private assignFields: Array<Field> = [];
  private showAddAssignModal = false;
  private showEditAssignModal = false;
  private showConfirmModal = false;
  private selected!: Array<any>;
  private assignTable: any;
  private selectedAssign = new Assign();

  async addAssignCompleted(arg: any) {
    this.showAssignOverlay = true;
    let priceQuotes: Array<PriceQuote> = arg;
    this.$emit("assigns-changed", priceQuotes);
    this.showAddAssignModal = false;
    this.showAssignOverlay = false;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.selectedAssign = this.selected[0];
    this.$forceUpdate();
  }

  confirmDeleteAssigns() {
    this.showConfirmModal = true;
  }

  async deleteSelectedAssigns() {
    let assignsToDelete = new Array<string>();
    if (this.selected && this.selected.length > 0) {
      this.selected.forEach(async (s) => {
        assignsToDelete.push(s.assignGuid.toString());
      });

      let result = await deleteAssigns(assignsToDelete);
      if (result) {
        if (this.assigns) {
          assignsToDelete.forEach((ass) => {
            let index = this.assigns.findIndex((a) => a.assignGuid === ass);
            this.assigns.splice(index, 1);
          });
        }
      }
    }
    this.showAssignOverlay = false;
    this.showConfirmModal = false;
    await this.assignTable.refresh();
    this.$emit("assigns-changed");
  }

  cancelDeletion() {
    this.showConfirmModal = false;
    this.showAssignOverlay = false;
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  private getAssignFields() {
    let field = new Field();
    field.tdClass = "assign-table-td";
    field.key = "unitName";
    field.label = "Unit";
    this.assignFields.push(field);

    field = new Field();
    field.key = "inDateTime";
    field.label = "In";
    this.assignFields.push(field);

    field = new Field();
    field.key = "outDateTime";
    field.label = "Out";
    this.assignFields.push(field);

    field = new Field();
    field.key = "nights";
    field.label = "Nights";
    this.assignFields.push(field);
  }

  getActiveAssign() {
    if (this.selectedAssign) {
      return this.selectedAssign.assignGuid;
    } else {
      return "";
    }
  }

  async mounted() {
    this.showAssignOverlay = true;
    this.getAssignFields();
    if (this.reservationGuid) {
      this.assigns = await getAssignsByReservation(this.reservationGuid);
    } else {
      this.assigns = new Array<Assign>();
    }
    this.assignTable = this.$refs.assignTableRef;
    this.showAssignOverlay = false;
  }

  addAssign(arg: any, index: any, event: any) {
    this.showAddAssignModal = true;
  }
  editAssign(arg: any, index: any, event: any) {
    if (arg) {
      this.selectedAssign = arg;
      this.showEditAssignModal = true;
    } else if (this.selected && this.selected.length > 0) {
      this.selectedAssign = this.selected[0];
      this.showEditAssignModal = true;
    } else {
      // no assign selected
    }
  }

  async saved(assign: Assign) {
    this.showAssignOverlay = true;
    // if the list doesn't contain the assign, add it
    let found = this.assigns.find((a) => a.assignGuid === assign.assignGuid);
    if (!found) {
      await this.assigns.push(assign);
    }
    let index = await this.assigns.findIndex(
      (a) => a.assignGuid === assign.assignGuid
    );
    this.assigns[index] = assign;
    await this.assignTable.refresh();
    this.assignTable.selectRow(index);
    this.selectedAssign = assign;
    this.$emit("assigns-changed");
    this.showEditAssignModal = false;
    this.showAssignOverlay = false;
  }
}
