
import { Component, Vue, Prop } from "vue-property-decorator";
import { RegisterCredentials } from "./RegisterCredentials";
import PasswordInput from "@/components/Controls/PasswordInput.vue";
import { namespace } from "vuex-class";
const Auth = namespace("AuthStore");
@Component({
  components: { PasswordInput },
})
export default class RegisterGuest extends Vue {
  @Auth.Action
  private register!: (regCreds: RegisterCredentials) => Promise<any>;
  @Auth.Action
  private login!: (data: any) => Promise<any>;

  name = "";
  nameState: boolean | null | undefined;
  emailState: boolean | undefined;
  private registerCreds = new RegisterCredentials();
  private errors: Array<string> = [];
  private showProgressOverlay = false;
  private successful = false;
  private validPass = false;
  private submitClicked = false;
  
  navigateToLogIn() {
    this.$emit("flip-to-login");
  }

  created() {
    this.errors = [];

    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
      }

      if (e.key === "Enter") {
        this.createAccount();
      }
    });
  }

  validate() {
    this.errors = [];
    if (!this.validPass) {
      this.errors.push("A password with at least 6 characters is required.");
      return false;
    }
    if (!this.validateFirstName()) {
      return false;
      //this.errors.push("First name is required.");
    }
    if (!this.validateLastName()) {
      return false;
      // this.errors.push("Last name is required.");
    }
    if (!this.validateEmail()) {
      return false;
      // this.errors.push("Email address is required.");
    }
    if (!this.validateZip()) {
      return false;
      // this.errors.push("Billing zip/postal code is required.");
    }
    return true;
  }

  async createAccount() {
    this.submitClicked = true;
    if (!this.validate()) {
      return;
    }
    this.errors = [];
    this.showProgressOverlay = true;
    this.registerCreds.mode = "guest";
    await this.register(this.registerCreds).then(
      (response) => {
        if (response && response.data && response.data.error) {
          this.successful = false;
          this.showProgressOverlay = false;
          this.errors.push(response.data.error);
        } else {
          this.successful = true;
          this.showProgressOverlay = false;
          this.loginGuest();
        }
      },
      (error) => {
        this.errors.push(error);
        this.successful = false;
        this.showProgressOverlay = false;
      }
    );
  }

  async loginGuest() {
    const user: any = { email: "", password: "" };
    user.password = this.registerCreds.password;
    user.email = this.registerCreds.email;
    await this.login(user).then(
      (data) => {
        // successful log in... what to do? this.$router.push("/dashboard");
      },
      (error) => {
        this.errors.push(error);
      }
    );
  }

  validateZip() {
    if (this.submitClicked) {
      if (
        this.registerCreds.postalCode &&
        this.registerCreds.postalCode.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateLastName() {
    if (this.submitClicked) {
      if (
        this.registerCreds.lastName &&
        this.registerCreds.lastName.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateFirstName() {
    if (this.submitClicked) {
      if (
        this.registerCreds.firstName &&
        this.registerCreds.firstName.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
    return null;
  }

  validateEmail() {
    if (this.submitClicked) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.registerCreds.email);
    }
    return null;
  }

  validatePassword(arg) {
    this.validPass = arg;
  }

  updatePassword(val) {
    this.registerCreds.password = val;
  }
  updateEmail(val) {
    this.registerCreds.email = val;
  }
  updateFullName(val) {
    this.registerCreds.fullname = val;
  }
}
