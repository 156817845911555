
import Property from "@/models/Property";
import { Unit } from "@/components/Cloud/Units/Unit";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const PropertyStore = namespace("PropertyStore");
@Component({ components: {} })
class MobilePopup extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: new Date() }) public startDate!: Date;
  @Prop({ default: new Date() }) public endDate!: Date;
  @Prop({ default: null }) public unit!: Unit;
  @Prop({ default: "" }) public imageUrl!: string;
  @Prop({ default: 2 }) public adults!: number;
  @Prop({ default: 0 }) public children!: number;

  private adultCount!: number;
  private childCount!: number;

  book() {
    this.$router.push({
      name: "Book",
      params: {
        unit: this.unit.unitGuid,
      },
      query: {
        date: new Date(this.startDate).dashedDate(),
        length: new Date(this.startDate)
          .daysDiff(new Date(this.endDate))
          .toString(),
        adults: this.adultCount.toString(),
        children: this.childCount.toString(),
      },
    });
  }

  created() {
    this.adultCount = this.adults;
    this.childCount = this.children;
  }

  close() {
    this.$emit("close-mobile-popup");
  }
}
export default MobilePopup;
