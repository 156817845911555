
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IDrawable } from "../../../models/Drawable/IDrawable";
import { DrawableLine } from "../../../models/Drawable/DrawableLine";
import { DrawableRectangle } from "../../../models/Drawable/DrawableRectangle";
import { Point } from "@/models/Drawable/Point";
import { DrawableSelection } from "@/models/Drawable/DrawableSelection";
import { GridModel } from "@/models/GridModel";
import { GridMonth } from "@/models/GridMonth";
import Property from "@/models/Property";
import { namespace } from "vuex-class";
import { Unit, UnitTypes } from "@/components/Cloud/Units/Unit";
import {
  ResStatus,
  ResStatusBackgroundColor,
  ResStatusFontColor,
} from "@/components/Cloud/Reservations/ResStatus";
import { UnitGroup } from "@/models/UnitGroup";
import {
  deleteAssigns,
  deleteReservation,
  deleteReservations,
  getAssign,
  getAssignsByOutOfService,
  getAssignsByReservation,
  getGridAssigns,
  getReservation,
  saveAssign,
  saveNewReservation,
  saveReservation,
} from "@/components/Cloud/Reservations/ReservationService";
import { SearchParams } from "@/models/SearchParams";
import { GridAssign } from "@/models/GridAssign";
import { Guid } from "@/utils/Guid";
import BasePopover from "@/components/Popover/BasePopover.vue";
import BasePopoverContent from "@/components/Popover/BasePopoverContent.vue";
import { MouseClick } from "@/models/MouseClick";
import InvoiceModal from "../Invoices/InvoiceModal.vue";
import {
  getInvoicesByReservation,
  getPrimaryInvoice,
} from "@/components/Cloud/Invoices/InvoiceService";
import { Invoice } from "@/components/Cloud/Invoices/Invoice";
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import ReservationModal from "./ReservationModal.vue";
import AvailableRatesModal from "../Rates/AvailableRatesModal.vue";
import ReservationContextMenu from "./ContextMenus/ReservationContextMenu.vue";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import ReservationEdit from "./ReservationEdit.vue";
import { Assign } from "../Assign/Assign";
import OutOfServiceEdit from "../OutOfService/OutOfServiceEdit.vue";
import { deleteOutOfService, OutOfService } from "../OutOfService/OutOfService";
import AddResContextMenu from "./ContextMenus/AddResContextMenu.vue";
import { getUnitGroups } from "../Units/UnitGroupService";
import { getUnits } from "../Units/UnitService";
import UnitEdit from "../Units/UnitEdit.vue";
import DatePicker from "@/components/SiteBook/DatePicker.vue";
import Category, { getCategories } from "@/components/Marketplace/Category";

const PropertyStore = namespace("PropertyStore");
const AddReservationStore = namespace("AddReservationStore");

@Component({
  components: {
    BasePopover,
    BasePopoverContent,
    DatePicker,
    ReservationModal,
    ReservationContextMenu,
    AddResContextMenu,
    InvoiceModal,
    AvailableRatesModal,
    ConfirmDeleteModal,
    UnitEdit,
    ReservationEdit,
    OutOfServiceEdit,
  },
})
export default class OccupancyGrid extends Vue {
  @PropertyStore.State
  public property!: Property;

  @AddReservationStore.Action
  public saveNewReservationToStore!: (res: Reservation) => void;

  @AddReservationStore.State
  public reservation!: Reservation;

  @Prop({ default: () => new Date() }) public startDate!: Date;
  @Prop({ default: 60 }) public length!: Date;
  private categories = new Array<Category>();

  @Watch("startDate", { immediate: true })
  async onDateChanged(newStartDate: Date, oldStartDate: Date) {
    if (newStartDate && oldStartDate) {
      await this.scrollToDate(newStartDate);
    }
  }

 unitGroups = new Array<UnitGroup>();
  private units = new Array<Unit>();
  private firstPickedDate = new Date();
  private secondPickedDate = new Date();
  private popoverOptions = {
    popoverReference: null as any,
    placement: "bottom",
    offset: "0,0",
  };
  private activeUnitGuid = "";
  private showInvoiceModal = false;
  private showUnitModal = false;
  private showConfirmModal = false;
  private activeInvoice!: Invoice;
  private isAddResContextMenuVisible = false;
  public isResContextMenuVisible = false;
  private mouseDownOnReservation = false;
  private isDown = false;
  private isDrawing!: boolean;
  private resizingDirection = "";
  private isResizing!: boolean;
  private drawables: Array<IDrawable> = [];
  private newSelectionDrawable!: DrawableRectangle;
  private newSelectionDrawables = new Array<DrawableRectangle>();
  private dayWidth = 0;
  private rowHeight = 0;
  private gridModel = new GridModel();
  private clickedPoint = new Point(0, 0);
  private board!: HTMLElement;
  private preventEvent = false;
  private contentLastScrollTop = 0;
  private contentLastScrollLeft = 0;
  private showOverlay = false;
  private unitColumnWidth = 150;
  private gridAssigns!: Array<GridAssign>;
  private rowMargin = 10; //pixels on top and bottom of a rect assign
  private showResModal = false;
  private isNewRes = false;
  private showOosModal = false;
  private activeReservation!: Reservation;
  private activeAssignGuid = new Guid();
  private activeOutOfService!: OutOfService | null;
  private newReservation!: Reservation;
  private selectedColor = "#5d7dfd"; //blue-ish
  private datesHeaderHeight = 35;
  public availableRatesVisible = false;
  private scrollbarWidth = 50;
  private unitsDisplayed: Array<Unit> = [];
  private todayColor: string = "#dc3545"; // red ish

  newOutOfService() {
    this.showOosModal = true;
  }

  closeOosModal() {
    this.showOosModal = false;
  }

  getActiveOosGuid() {
    if (this.activeOutOfService) {
      return this.activeOutOfService.outOfServiceGuid.toString();
    }
  }
  async afterSaveNewOos(newOos: OutOfService) {
    for (var i = 0, len = this.newSelectionDrawables.length; i < len; i++) {
      let rect: DrawableRectangle = this.newSelectionDrawables[i];
      if (rect) {
        let assign = this.getNewAssignFromRect(rect);
        rect.outOfService = newOos;
        assign.outOfServiceGuid = newOos.outOfServiceGuid;
        await saveAssign(assign);
      }
    }
    this.refreshOutOfService(newOos);
  }

  async refreshOutOfService(oos: OutOfService) {
    // refresh the oos
    let gridAssignsForOos = this.gridAssigns.filter((ga) => {
      if (ga.outOfService) {
        return ga.outOfService.outOfServiceGuid == oos.outOfServiceGuid;
      } else {
        return false;
      }
    });
    if (gridAssignsForOos && gridAssignsForOos.length > 0) {
      gridAssignsForOos.forEach((ga) => {
        const index = this.gridAssigns.indexOf(ga, 0);
        if (index > -1) {
          this.gridAssigns.splice(index, 1);
        }
      });
    }

    // now add them back after refreshing
    let assigns = await getAssignsByOutOfService(oos.outOfServiceGuid);
    if (assigns && assigns.length > 0) {
      assigns.forEach((a) => {
        let ga = new GridAssign();
        ga.assign = a;
        if (oos) {
          ga.outOfService = oos;
        }
        this.gridAssigns.push(ga);
      });
    }

    await this.drawOutOfService(oos);
    await this.clearSelection();
    await this.deselectAll();

    this.activeOutOfService = null;
    this.showOosModal = false;
  }

  afterCloseOosModal() {
    // after closing modal
    this.activeOutOfService = null;
  }

  async checkInRes(arg: any) {
    let assignGuid = arg;
    let assign = await getAssign(assignGuid);
    if (assign) {
      let resGuid = assign.reservationGuid;
      let res = await getReservation(resGuid);
      if (res) {
        res.status = ResStatus.CheckedIn;
        res = await saveReservation(res);
        await this.refreshReservation(res);
      }
    }
  }
  async checkOutRes(arg: any) {
    let assignGuid = arg;
    let assign = await getAssign(assignGuid);
    if (assign) {
      let resGuid = assign.reservationGuid;
      let res = await getReservation(resGuid);
      if (res) {
        res.status = ResStatus.CheckedOut;
        res = await saveReservation(res);
        await this.refreshReservation(res);
      }
    }
  }

  async saveUnitModal(unit: Unit) {
    this.showOverlay = true;
    //since only the name is display, just updated that
    await this.unitsDisplayed.forEach((u) => {
      if (u.unitGuid == unit.unitGuid) {
        u.unitName = unit.unitName;
        u.unitGroupName = unit.unitGroupName;
      }
    });
    this.showOverlay = false;
  }

  openUnitModal(unitGuid: string) {
    this.activeUnitGuid = unitGuid;
    this.showUnitModal = true;
  }

  closeUnitModal() {
    this.showUnitModal = false;
  }

  closeInvoice() {
    // just close the modal
    this.showInvoiceModal = false;
  }

  confirmDeleteItem(assignGuid: Guid) {
    this.activeAssignGuid = assignGuid;
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async confirmDelete() {
    let ga = await this.gridAssigns.find(
      (ga) => ga.assign.assignGuid == this.activeAssignGuid
    );
    if (ga) {
      if (ga.reservation) {
        let response = await deleteReservation(ga.reservation.reservationGuid);
        if (response && response.data && response.data.error) {
          // deal with error deleting reservation
        } else {
          // tell UI it's complete and end the progress indicator
          const rectsForRes = this.getAllDrawablesForReservationOrOos(
            ga.reservation.reservationGuid
          );
          rectsForRes.forEach((r) => {
            r.removeRectangle();
          });
          this.showConfirmModal = false;
        }
      } else if (ga.outOfService) {
        await deleteOutOfService(ga.outOfService);
        const rectsForRes = this.getAllDrawablesForReservationOrOos(
          ga.outOfService.outOfServiceGuid
        );
        rectsForRes.forEach((r) => {
          r.removeRectangle();
        });
        this.showConfirmModal = false;
      } else {
        //no oos or es
        /// let's just delete the assign
        let assignGuids = Array<string>();
        assignGuids.push(ga.assign.assignGuid);
        await deleteAssigns(assignGuids);
        ga.drawable.removeRectangle();
        this.showConfirmModal = false;
      }
    }
  }

  async saveAvailableRates() {
    this.activeReservation = this.reservation;
    await saveNewReservation(this.reservation);
    this.reservation.assigns.forEach((a) => {
      let ga = new GridAssign();
      ga.assign = a;
      ga.reservation = this.reservation;
      this.gridAssigns.push(ga);
    });

    //todo: do we need this??
    this.newSelectionDrawables.forEach((d) => {
      d.reservation?.reservationGuid == this.reservation.reservationGuid;
    });
    await this.deselectAll();
    this.isNewRes = true;
    this.availableRatesVisible = false;
    this.showResModal = true;
  }

  closeAvailableRatesModal() {
    this.availableRatesVisible = false;
  }

  getNewResModalTitle() {
    if (
      this.newReservation &&
      this.newReservation.arrivalDate &&
      this.newReservation.departureDate
    ) {
      return (
        "Available Rates for " +
        this.newReservation.arrivalDate.formatDayOfWeekMMDDYYYY() +
        " to " +
        this.newReservation.departureDate.formatDayOfWeekMMDDYYYY()
      );
    }
    return "";
  }

  showAvailableRates() {
    this.newReservation = new Reservation();
    this.newReservation.status = ResStatus.Pending;
    this.newReservation.reservationGuid = Guid.newGuid();
    this.newReservation.propertyGuid = this.property.propertyGuid;
    this.newReservation.assigns = new Array<Assign>();

    this.newSelectionDrawables.forEach((rect) => {
      let newAssign = this.getNewAssignFromRect(rect);

      if (
        !this.newReservation.arrivalDate ||
        newAssign.inDateTime < this.newReservation.arrivalDate
      ) {
        this.newReservation.arrivalDate = newAssign.inDateTime;
      }
      if (
        !this.newReservation.departureDate ||
        newAssign.outDateTime > this.newReservation.departureDate
      ) {
        this.newReservation.departureDate = newAssign.outDateTime;
      }
      newAssign.nights = newAssign.inDateTime.daysDiff(newAssign.outDateTime);

      this.newReservation.assigns.push(newAssign);
    });
    //stuff the res in the store
    this.saveNewReservationToStore(this.newReservation);
    this.availableRatesVisible = true;
  }

  private getNewAssignFromRect(d: DrawableRectangle) {
    const minuteWidth = this.dayWidth / 24 / 60;
    let newAssign = new Assign();
    newAssign.assignGuid = Guid.newGuid();
    newAssign.createdDate = new Date();
    newAssign.modifiedDate = new Date();
    newAssign.propertyGuid = this.property.propertyGuid;
    const unit = this.getUnit(this.getUnitIndex(d.startPoint.y));
    if (unit) {
      newAssign.unitName = unit.unitName;
      newAssign.unitGuid = unit.unitGuid;
    }
    let minutesFromStart = d.startPoint.x / minuteWidth;
    newAssign.inDateTime = new Date(this.gridModel.startDate)
      .removeTime()
      .addMinutes(minutesFromStart);
    let assignMinutesLength = d.width / minuteWidth;
    newAssign.outDateTime = new Date(newAssign.inDateTime).addMinutes(
      assignMinutesLength
    );

    return newAssign;
  }

  async refreshReservation(res: Reservation) {
    await this.deselectAll();
    let gridAssignsForRes = this.gridAssigns.filter((ga) => {
      if (ga.reservation) {
        return ga.reservation.reservationGuid == res.reservationGuid;
      } else {
        return false;
      }
    });
    if (gridAssignsForRes && gridAssignsForRes.length > 0) {
      gridAssignsForRes.forEach((ga) => {
        const index = this.gridAssigns.indexOf(ga, 0);
        if (index > -1) {
          this.gridAssigns.splice(index, 1);
        }
      });
    }

    if (res && res.reservationGuid) {
      // now add them back after refreshing
      let assigns = await getAssignsByReservation(res.reservationGuid);
      if (assigns && assigns.length > 0) {
        assigns.forEach((a) => {
          let ga = new GridAssign();
          ga.assign = a;
          if (res) {
            ga.reservation = res;
          }
          this.gridAssigns.push(ga);
        });
      }

      await this.drawReservation(res);

      this.activeReservation = await getReservation(res.reservationGuid);
    }
  }

  async afterCancelResModal(res: Reservation) {
    this.showResModal = false;
    this.refreshReservation(res);
  }

  async afterSaveResModal(res: Reservation) {
    this.refreshReservation(res);
    await this.clearSelection();
    this.showResModal = false;
  }

  private drawReservation(res: Reservation) {
    const rectsForRes = this.getAllDrawablesForReservationOrOos(
      res.reservationGuid
    );
    rectsForRes.forEach((r) => {
      r.removeRectangle();
    });
    this.gridAssigns.forEach((ga) => {
      if (
        ga.reservation &&
        ga.reservation.reservationGuid == res.reservationGuid
      ) {
        ga.reservation = res;
        const drawable = this.generateAssignDrawable(ga);
        drawable.gridAssign = ga;
        this.drawables.push(drawable);
        this.drawRect(drawable);
      }
    });
  }
  private drawOutOfService(oos: OutOfService) {
    const rects = this.getAllDrawablesForReservationOrOos(oos.outOfServiceGuid);
    rects.forEach((r) => {
      r.removeRectangle();
    });
    const gridAssignsForRes = this.gridAssigns.forEach((ga) => {
      if (
        ga.outOfService &&
        ga.outOfService.outOfServiceGuid == oos.outOfServiceGuid
      ) {
        ga.outOfService = oos;
        const drawable = this.generateAssignDrawable(ga);
        drawable.gridAssign = ga;
        this.drawables.push(drawable);
        this.drawRect(drawable);
      }
    });
  }

  private async scrollToDate(newStartDate: Date) {
    this.showOverlay = true;
    this.clearSelection();
    this.gridModel.startDate = newStartDate;
    await this.getDateSpecificGridItems();
    this.drawGrid();
    this.showOverlay = false;
  }

  async datePickedHandler(newStartDate: Date, newEndDate: Date) {
    this.showOverlay = true;
    this.gridModel.startDate = newStartDate;
    let daysLength = newStartDate.daysDiff(newEndDate);
    this.gridModel.length = daysLength + 1;
    await this.getDateSpecificGridItems();
    this.drawGrid();
    this.showOverlay = false;
  }

  async openInvoice(resGuid: string) {
    this.activeReservation = await getReservation(resGuid);
    this.activeReservation.invoices = await getInvoicesByReservation(resGuid);
    const primaryInvoice = await getPrimaryInvoice(this.activeReservation);
    if (primaryInvoice) {
      this.activeInvoice = primaryInvoice;
      this.showInvoiceModal = true;
    }
  }

  getActiveInvoiceGuid() {
    if (this.activeInvoice) {
      return this.activeInvoice.invoiceGuid;
    }
  }

  async closeContextMenus() {
    this.isResContextMenuVisible = false;
    this.isAddResContextMenuVisible = false;
    this.popoverOptions.popoverReference = null;
  }

  async openPopover(elementId: string) {
    await this.closeContextMenus;
    const element = document.getElementById("svg-" + elementId);
    if (element) {
      this.popoverOptions.popoverReference = element;
    }
  }

  async openAddResContextMenu(elementId: string) {
    await this.closeContextMenus;
    await this.openPopover(elementId);
    this.isAddResContextMenuVisible = true;
  }

  async openResContextMenu(elementId: string) {
    await this.closeContextMenus;
    await this.openPopover(elementId);
    this.isResContextMenuVisible = true;
  }

  scrollGrid(days: number) {
    const newStart = this.gridModel.startDate.addDays(days);
    this.scrollToDate(newStart);
  }

  scrollToToday() {
    let yesterday = new Date().addDays(-1);
    this.scrollToDate(yesterday);
  }

  getGridViewPortWidth() {
    const max = 1400;
    const gridWrapperDiv = document.getElementById("grid-div-wrapper");
    if (gridWrapperDiv) {
      const offsetWidth = gridWrapperDiv.offsetWidth;
      const clientWidth = gridWrapperDiv.clientWidth;
      // return offsetWidth;
    }

    const calculatedWidth = this.gridModel.length * this.dayWidth;
    return calculatedWidth;
  }

  getViewPortHeight() {
    const max = 1400;
    const calculatedHeight = this.getRowCount() * this.rowHeight;
    if (calculatedHeight >= max) {
      return max;
    } else {
      return calculatedHeight;
    }
  }

  // getUnitColumnWidth() {
  //   if (this.unitColumnWidth) {
  //     return this.unitColumnWidth * 0.9;
  //   } else {
  //     return 70;
  //   }
  // }

  // factor in the quantity of each unit
  getRowCount() {
    let count = 0;
    for (const unit of this.unitsDisplayed) {
      count += unit.quantity;
    }
    return count;
  }

  getBoardWidth() {
    if (this.gridModel && this.gridModel.length > 0 && this.dayWidth) {
      return this.gridModel.length * this.dayWidth;
    } else {
      return 0;
    }
  }

  getBoardHeight() {
    if (this.gridModel && this.getRowCount() && this.rowHeight) {
      return this.getRowCount() * this.rowHeight;
    } else {
      return 0;
    }
  }

  getGridMonthWidth(gridMonth: GridMonth) {
    if (!gridMonth) {
      return 0;
    }
    const result = gridMonth.daysDisplayed * this.dayWidth;
    return result;
  }

  getDatesHeaderTotalWidth() {
    if (this.gridModel) {
      return this.gridModel.length * this.dayWidth;
    } else {
      return 0;
    }
  }

  getMonths() {
    //iterate all days between start date and number of days
    const months = new Array<GridMonth>();
    for (let i = 0; i < this.gridModel.length; i++) {
      const thisDate = this.gridModel.startDate.addDays(i);
      const thisMonth = thisDate.getMonthName(false);
      const thisYear = thisDate.getFullYear().toString();

      if (
        !months.find((month) => {
          return month.name === thisMonth && month.year == thisYear;
        })
      ) {
        // month hasn't been added, then add it
        const month = new GridMonth();
        month.name = new Date(thisDate).getMonthName(false);
        month.year = new Date(thisDate).getFullYear().toString();
        const daysInMonth = new Date(
          this.gridModel.startDate
        ).daysInMonthAndYear(
          new Date(thisDate).getMonth() + 1,
          new Date(thisDate).getFullYear()
        );

        if (new Date(thisDate).areSameDay(this.gridModel.startDate)) {
          //start date to last day in month ,
          const lastDayInMonth = new Date(
            new Date(thisDate).getFullYear(),
            new Date(thisDate).getMonth(),
            daysInMonth
          );
          month.daysDisplayed = new Date(thisDate).daysDiff(lastDayInMonth) + 1;
        } else if (
          new Date(thisDate).addDays(daysInMonth) >
          this.gridModel.startDate.addDays(this.gridModel.length)
        ) {
          //or first day in month to end date
          const endDate = this.gridModel.startDate.addDays(
            this.gridModel.length
          );
          month.daysDisplayed =
            new Date(thisDate.removeTime()).daysDiff(endDate) + 1;
        } else {
          //set the daysdisplayed to either all days in month,
          month.daysDisplayed = daysInMonth;
        }

        months.push(month);
      }
    }

    this.gridModel.gridMonths = months;
  }

  getStartDate() {
    if (this.gridModel.startDate) {
      return this.gridModel.startDate.removeTime();
    } else {
      return new Date();
    }
  }
  getEndDate() {
    if (this.gridModel.startDate && this.gridModel.length) {
      let endDate = this.gridModel.startDate
        .removeTime()
        .addDays(this.gridModel.length);
      return endDate;
    } else {
      return new Date();
    }
  }

  getDayCount() {
    if (this.gridModel.startDate && this.gridModel.length) {
      return this.gridModel.length;
    } else {
      return 200;
    }
  }

  getRowHeight(unit: Unit) {
    if (this.rowHeight) {
      return this.rowHeight * unit.quantity;
    } else {
      return 0;
    }
  }

  getDatesHeaderHeight() {
    if (this.datesHeaderHeight) {
      return this.datesHeaderHeight;
    } else {
      return 15;
    }
  }

  getDayWidth() {
    if (this.dayWidth) {
      return this.dayWidth;
    } else {
      return 30;
    }
  }

  onTopScroll = (e) => {
    if (this.preventEvent) {
      this.preventEvent = false;
      return;
    }

    this.preventEvent = true;

    const topScroll = document.getElementById("top-scroll") as HTMLElement;
    const contentScroll = document.getElementById(
      "content-scroll"
    ) as HTMLElement;
    if (contentScroll && topScroll) {
      contentScroll.scrollLeft = e.target.scrollLeft;
      if (contentScroll.scrollLeft != topScroll.scrollLeft) {
        contentScroll.scrollLeft = topScroll.scrollLeft;
      }
    }
  };

  onSidebarScroll = (e) => {
    if (this.preventEvent) {
      this.preventEvent = false;
      return;
    }

    this.preventEvent = true;
    const contentScroll = document.getElementById(
      "content-scroll"
    ) as HTMLElement;
    if (contentScroll) {
      contentScroll.scrollTop = e.target.scrollTop;
    }
  };

  onContentScroll = (e) => {
    if (this.preventEvent) {
      this.preventEvent = false;
      return;
    }

    if (e.target.scrollTop !== this.contentLastScrollTop) {
      this.preventEvent = true;
      const sidebarScroll = document.getElementById(
        "sidebar-scroll"
      ) as HTMLElement;
      if (sidebarScroll) {
        sidebarScroll.scrollTop = e.target.scrollTop;
      }
      this.contentLastScrollTop = e.target.scrollTop;
    }
    if (e.target.scrollLeft !== this.contentLastScrollLeft) {
      this.preventEvent = true;

      const topScroll = document.getElementById("top-scroll") as HTMLElement;
      if (topScroll) {
        topScroll.scrollLeft = e.target.scrollLeft;
      }
      this.contentLastScrollLeft = e.target.scrollLeft;
    }
  };

  /* Get the documentElement (<html>) to display the page in fullscreen */
  /* View in fullscreen */
  openFullscreen(elem: any) {
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
  }

  isFullScreen() {
    const fullScreenDiv = document.getElementById("grid-full-screen-wrapper");
    const divIsSameAsScreen = fullScreenDiv?.scrollHeight == screen.height;
    let result =
      (screen.availHeight || screen.height - 30) <= window.innerHeight;
    return result;
  }

  /* Close fullscreen */
  closeFullscreen() {
    let doc: any;
    doc = document;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.webkitExitFullscreen) {
      /* Safari */
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      /* IE11 */
      doc.msExitFullscreen();
    }
  }

  async mounted() {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    const fullScreenButton = document.getElementById("full-screen-button");
    const fullScreenDiv = document.getElementById("grid-full-screen-wrapper");
    if (fullScreenButton && fullScreenDiv) {
      fullScreenButton.addEventListener("click", () => {
        this.openFullscreen(fullScreenDiv);
      });
    }

    this.showOverlay = true;
    this.gridModel.length = this.property.gridSettings.scrollAmount;
    let yesterday = new Date(new Date().addDays(-1));
    this.gridModel.startDate = yesterday;

    await this.getPropertyItems();
    this.unitsDisplayed = this.units.filter((u) => u.active);
    this.dayWidth = this.property.gridSettings.columnWidth;
    this.rowHeight = this.property.gridSettings.rowHeight;
    await this.getDateSpecificGridItems();
    this.createUserEvents();
    this.drawGrid();
    this.categories = await getCategories();
    this.showOverlay = false;
  }

  async clickedUnitGroup(arg: PointerEvent) {
    this.showOverlay = true;
    if (arg && arg.currentTarget && arg.currentTarget.innerText ) {

      if (arg.currentTarget.innerText == 'All') {
        this.unitsDisplayed = this.units.filter((u) => u.active);
      } else {
          const result = this.units.filter((obj) => {
            return obj.unitGroupName === arg.currentTarget.innerText && obj.active;
          });
          if (result) {
            this.unitsDisplayed = result;
          }
      }
    } else {
      this.unitsDisplayed = this.units.filter((u) => u.active);
    }
    await this.resetScroll();
    await this.drawGrid();
    this.showOverlay = false;
  }

  async clickedCategory(arg: PointerEvent) {
    this.showOverlay = true;
    let dropDown: any = arg.currentTarget;
    if (arg && arg.currentTarget && dropDown.innerText != "Any") {
      // show all unit groups
      if (dropDown && dropDown.innerText) {
        const result = this.units.filter((obj) => {
          return obj.category === dropDown.innerText && obj.active;
        });
        if (result) {
          this.unitsDisplayed = result;
        }
      }
    } else {
      this.unitsDisplayed = this.units.filter((u) => u.active);
    }
    await this.resetScroll();
    await this.drawGrid();
    this.showOverlay = false;
  }

  async resetScroll() {
      const sidebarScroll = window.document.getElementById(
          "sidebar-scroll"
      ) as HTMLElement;
      if (sidebarScroll) {
          sidebarScroll.scrollTop = 0;
      }
      const contentScroll = window.document.getElementById(
          "content-scroll"
      ) as HTMLElement;
      if (contentScroll) {
          contentScroll.scrollLeft = 0;
      }
      const topScroll = window.document.getElementById("top-scroll") as HTMLElement;
      if (topScroll) {
          topScroll.scrollLeft = 0;
      }
  }

  async getDateSpecificGridItems() {
    if (!this.property) {
      return;
    }
    const searchParams = new SearchParams();
    searchParams.arrivalDate = this.gridModel.startDate;
    searchParams.departureDate = this.gridModel.startDate.addDays(
      this.gridModel.length
    );
    searchParams.propertyGuid = this.property.propertyGuid;

    await Promise.all([this.getMonths()]);
    this.gridAssigns = await getGridAssigns(searchParams);
  }

  async getPropertyItems() {
    if (this.property && this.property.name) {
      document.title = this.property.name;
    } else {
      return;
    }

    this.unitGroups = await getUnitGroups(this.property.propertyGuid);
    this.units = await getUnits(this.property.propertyGuid); // exclude inactive
  }

  drawGrid() {
    this.drawables = [];
    this.clearSelection();
    this.clearBoard();

    for (let i = 1; i < this.gridModel.length; i++) {
      //vertical
      const drawableLine = new DrawableLine();
      drawableLine.cssId = i.toString();
      drawableLine.fillColor = "black";
      let isFirstDayInMonth =
        new Date(this.gridModel.startDate.addDays(i)).getDate() == 1;
      drawableLine.thickness = isFirstDayInMonth ? 3 : 1;

      drawableLine.startPoint = new Point(i * this.dayWidth, 0);
      drawableLine.endPoint = new Point(
        i * this.dayWidth,
        this.getRowCount() * this.rowHeight
      );

      this.drawables.push(drawableLine);

      // if it's a weeknd, add another one that is thick and gray
      let isWeekend = this.gridModel.startDate.addDays(i).isWeekend(true);
      if (isWeekend == true) {
        const drawableLine = new DrawableLine();
        drawableLine.cssId = i.toString() + "-weekend";
        drawableLine.zIndex = "0";
        drawableLine.fillColor = "lightgray";
        drawableLine.thickness = this.dayWidth;
        drawableLine.startPoint = new Point(
          i * this.dayWidth + this.dayWidth / 2,
          0
        );
        drawableLine.endPoint = new Point(
          i * this.dayWidth + this.dayWidth / 2,
          this.getRowCount() * this.rowHeight
        );

        this.drawables.push(drawableLine);
      }

      // if it's today, add another one that is thick and red
      let isToday = new Date(this.gridModel.startDate.addDays(i)).areSameDay(
        new Date()
      );
      if (isToday == true) {
        const drawableLine = new DrawableLine();
        drawableLine.cssId = i.toString() + "-today";
        drawableLine.zIndex = "1";
        drawableLine.fillColor = this.todayColor;
        drawableLine.thickness = this.dayWidth;
        drawableLine.startPoint = new Point(
          i * this.dayWidth + this.dayWidth / 2,
          0
        );
        drawableLine.endPoint = new Point(
          i * this.dayWidth + this.dayWidth / 2,
          this.getRowCount() * this.rowHeight
        );

        this.drawables.push(drawableLine);
      }
    }
    let index = 0;
    for (let i = 0; i < this.unitsDisplayed.length; i++) {
      let unit = this.unitsDisplayed[i];
      //horizontal
      for (let y = 1; y <= unit.quantity; y++) {
        index += 1;
        const drawableLine = new DrawableLine();
        drawableLine.isDirty = true;
        drawableLine.cssId = i.toString();
        drawableLine.fillColor = "black";
        drawableLine.thickness = y == unit.quantity ? 1.5 : 0.5;
        drawableLine.startPoint = new Point(0, index * this.rowHeight);
        drawableLine.endPoint = new Point(
          this.getBoardWidth(),
          index * this.rowHeight
        );
        this.drawables.push(drawableLine);
      }
    }

    if (!this.gridAssigns) {
      return;
    }

    this.gridAssigns.forEach((gridAssign) => {
      const drawableRect = this.generateAssignDrawable(gridAssign);
      drawableRect.gridAssign = gridAssign;
      this.drawables.push(drawableRect);
    });

    this.drawAll();
  }

  private generateAssignDrawable(gridAssign: GridAssign) {
    const drawableRect = new DrawableRectangle();
    drawableRect.isDirty = true;
    const startX = this.getAssignX(gridAssign.assign);
    let startY =
      (this.getUnitIndexForAssign(gridAssign) +
        gridAssign.assign.positionIndex) *
      this.rowHeight;
    drawableRect.startPoint = new Point(startX, startY);
    drawableRect.height = this.rowHeight;
    drawableRect.width = this.getAssignWidth(gridAssign.assign); // calculate based on time span of this assign
    drawableRect.strokeWidth = 1;
    drawableRect.strokeColor = "black";
    this.applyFudgeFactor(drawableRect);
    drawableRect.gridSettings = this.property.gridSettings;
    if (gridAssign.reservation) {
      drawableRect.reservation = gridAssign.reservation;
      drawableRect.assign = gridAssign.assign;
    } else if (gridAssign.outOfService) {
      drawableRect.outOfService = gridAssign.outOfService;
    }

    gridAssign.drawable = drawableRect;
    drawableRect.id = gridAssign.assign.assignGuid.toString();
    return drawableRect;
  }

  applyFudgeFactor(drawableRect: DrawableRectangle) {
    //set the drawable rect top left start to y + fudge
    drawableRect.startPoint.y += this.rowMargin;
    //set the drawable rect height to rowhight minus fudge
    drawableRect.height -= this.rowMargin * 2;
  }

  getAssignWidth(assign: Assign) {
    //get the number of minutes of assign
    //get the width of one minute = this.dayWidth / 24 / 60
    const minuteWidth = this.dayWidth / 24 / 60;
    const numberOfMinutesForAssign = new Date(assign.inDateTime).minutesDiff(
      assign.outDateTime
    );
    //return the numMinutes * minuteWidth
    return numberOfMinutesForAssign * minuteWidth;
  }

  getAssignX(assign: Assign) {
    //get the minutes from midnight to start time of assign
    //get the width of one minute = this.dayWidth / 24 / 60
    //return the numMinutes * minuteWidth as the starting X of assign
    //get date index (column) of the assign as days from start
    //x is the number of days from start times dayWidth plus the minutes from midnight & minuteWidth
    const minuteWidth = this.dayWidth / 24 / 60;
    const minutesFromMidnight = new Date(assign.inDateTime)
      .removeTime()
      .minutesDiff(assign.inDateTime);
    const XAtTimeOfDay = minuteWidth * minutesFromMidnight;

    let daysFromStartDate = new Date(this.gridModel.startDate).daysDiff(
      assign.inDateTime
    );
    if (
      new Date(this.gridModel.startDate).getTime() >
      new Date(assign.inDateTime).getTime()
    ) {
      daysFromStartDate = -1 * daysFromStartDate;
    }
    const XAtMidnight = daysFromStartDate * this.dayWidth;
    return XAtMidnight + XAtTimeOfDay;
  }

  // otherOverlapDrawables(g: GridAssign) {
  //   let overlaps = this.drawables
  //     .map((d) => (d as DrawableRectangle).gridAssign)
  //     .filter((ga) => {
  //       //let ga = (d as DrawableRectangle).gridAssign;
  //       if (
  //         ga &&
  //         g &&
  //         ga.unitPosition <= g.unitPosition &&
  //         ga.assign &&
  //         ga.assign.unitGuid == g.assign.unitGuid &&
  //         ga.assign.assignGuid != g.assign.assignGuid
  //       ) {
  //         let betweenStart =
  //           g.assign.inDateTime >= ga.assign.inDateTime &&
  //           g.assign.inDateTime <= ga.assign.outDateTime;
  //         let betweenEnd =
  //           g.assign.outDateTime >= ga.assign.inDateTime &&
  //           g.assign.outDateTime <= ga.assign.outDateTime;
  //         let overEntire =
  //           g.assign.outDateTime >= ga.assign.outDateTime &&
  //           g.assign.inDateTime <= ga.assign.inDateTime;
  //         let insideEntire =
  //           g.assign.inDateTime >= ga.assign.inDateTime &&
  //           g.assign.outDateTime <= ga.assign.outDateTime;
  //         return betweenStart || betweenEnd || overEntire || insideEntire;
  //       } else {
  //         return false;
  //       }
  //     });
  //   return overlaps;
  // }

  getUnitIndexForAssign(g: GridAssign) {
    let index = 0;
    for (const unit of this.unitsDisplayed) {
      if (unit.unitGuid == g.assign.unitGuid) {
        return index;
      }
      index += unit.quantity;
    }
    return index;
  }

  drawAll() {
    //iterate the drawables and draw each one
    if (this.board) {
      this.drawables.forEach((drawable) => {
        this.draw(drawable);
      });
    }
  }

  draw(drawable: IDrawable) {
    if (drawable) {
      if (drawable instanceof DrawableLine) {
        //draw it
        this.drawLine(drawable);
      } else if (drawable instanceof DrawableRectangle) {
        this.drawRect(drawable);
      }
    }
  }

  private clearBoard() {
    if (this.board) {
      while (this.board.firstChild) {
        if (this.board.lastChild) {
          this.board.removeChild(this.board.lastChild);
        }
      }
    }
  }

  private async clearSelection() {
    this.newSelectionDrawables.forEach((drawableSelection) => {
      drawableSelection.removeRectangle();
      if (drawableSelection.selector) {
        drawableSelection.selector.removeSelection();
      }
    });
    this.newSelectionDrawables = [];
  }

  private createUserEvents() {
    this.board = document.getElementById("board") as HTMLElement;
    if (this.board) {
      this.board.addEventListener("mousedown", this.handleBoardMouseDown);
      this.board.addEventListener("mousemove", this.handleBoardMouseMove);
      this.board.addEventListener("mouseup", this.handleBoardMouseUp);
      this.board.addEventListener("mouseout", this.handleBoardMouseOut);
      this.board.addEventListener("touchstart", this.handleBoardMouseDown);
      this.board.addEventListener("touchmove", this.handleBoardMouseMove);
      this.board.addEventListener("touchend", this.handleBoardMouseUp);
      this.board.addEventListener("touchcancel", this.handleBoardMouseOut);
    }
    if (document) {
      const clearEl = document.getElementById("clear");
      if (clearEl) {
        clearEl.addEventListener("click", this.handleClear);
      }
    }
    let days = document.getElementsByName("daysvg") as NodeListOf<HTMLElement>;
    days.forEach((d) => this.makeDraggable(d));
  }

  makeDraggable(svg: any) {
    svg.addEventListener("mousedown", this.handleDayMouseDown);
    svg.addEventListener("mousemove", this.handleDayMouseDrag);
    svg.addEventListener("mouseup", this.handleDayMouseUp);
    svg.addEventListener("mouseleave", this.handleDayMouseUp);
  }

  private async handleDayMouseDown(e: MouseEvent | TouchEvent) {
    //console.log("mousedown on day" + e);
  }
  private async handleDayMouseDrag(e: MouseEvent | TouchEvent) {
    // console.log("drag on day" + e);
  }
  private async handleDayMouseUp(e: MouseEvent | TouchEvent) {
    // console.log("mousedown on day" + e);
  }
  isMouseDownOnOpenArea(): boolean {
    //search for a drawable rectangle where the coordinates are inside the rect

    return false;
  }

  setBoardCursor() {
    if (this.isDrawing) return "crosshair-pointer";
    if (this.isResizing) return `${this.resizingDirection}-pointer`;
    else return "default-pointer";
  }

  private drawLine(drawableLine: DrawableLine) {
    const line = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "polyline"
    );
    line.setAttribute("points", drawableLine.loadPoints());
    line.setAttribute("fill", "none");
    line.setAttribute("stroke-width", drawableLine.thickness.toString());
    line.setAttribute("stroke", drawableLine.fillColor);
    line.setAttribute("class", "line");
    line.setAttribute("id", drawableLine.cssId);
    this.board.appendChild(line);
  }

  private drawRect(drawableRectangle: DrawableRectangle) {
    drawableRectangle.draw();
    drawableRectangle.rectElement.addEventListener(
      "mousedown",
      this.rectMousedown
    );
    drawableRectangle.rectElement.addEventListener("mouseup", this.rectMouseup);
    drawableRectangle.rectElement.addEventListener("click", this.rectClicked);
  }

  deSelectDrawable(rectDrawable: DrawableRectangle) {
    rectDrawable.rectElement.setAttribute("fill", rectDrawable.fill);
    rectDrawable.isSelected = false;
    if (rectDrawable.selector) {
      rectDrawable.selector.removeSelection();
    }
  }

  selectDrawable(rectDrawable: DrawableRectangle) {
    rectDrawable.rectElement.setAttribute("fill", this.selectedColor);
    rectDrawable.isSelected = true;
    rectDrawable.selector = new DrawableSelection(rectDrawable);
  }

  getAllDrawablesForReservationOrOos(
    reservationOrOOSGuid: string
  ): Array<DrawableRectangle> {
    const result = new Array<DrawableRectangle>();
    this.drawables.forEach((drawable) => {
      if (drawable instanceof DrawableRectangle) {
        if (
          drawable?.reservation?.reservationGuid == reservationOrOOSGuid ||
          drawable?.outOfService?.outOfServiceGuid == reservationOrOOSGuid
        ) {
          result.push(drawable as DrawableRectangle);
        }
      }
    });
    return result;
  }

  selectAllForReservation(reservationGuid: string) {
    this.drawables.forEach((drawable) => {
      if (drawable instanceof DrawableRectangle) {
        if (drawable?.reservation?.reservationGuid == reservationGuid) {
          this.selectDrawable(drawable as DrawableRectangle);
        }
      }
    });
  }

  selectAllForOutOfService(oosGuid: string) {
    this.drawables.forEach((drawable) => {
      if (drawable instanceof DrawableRectangle) {
        if (drawable?.outOfService?.outOfServiceGuid == oosGuid) {
          this.selectDrawable(drawable as DrawableRectangle);
        }
      }
    });
  }

  selectAllNewSelections() {
    this.newSelectionDrawables.forEach((drawable) => {
      this.selectDrawable(drawable as DrawableRectangle);
    });
  }

  private deselectAll() {
    this.drawables.forEach((drawable) => {
      if (drawable instanceof DrawableRectangle) {
        this.deSelectDrawable(drawable as DrawableRectangle);
      }
    });
  }

  rectMousedown(e: MouseEvent | TouchEvent) {
    this.closeContextMenus();
    e.preventDefault();
    e.stopPropagation();
    this.clearSelection();
    this.mouseDownOnReservation = true;
    if (e && e.target) {
      const rect = e.target as SVGRectElement;
      const evt = e as MouseEvent;
      const clickedDrawable = this.drawables.find(
        (r) => r.id == rect.id
      ) as DrawableRectangle;
      if (clickedDrawable) {
        this.deselectAll();

        //select all rects with same reservation guid
        if (clickedDrawable?.reservation?.reservationGuid) {
          const res = this.getActiveReservation(
            clickedDrawable?.reservation.reservationGuid
          );
          if (res) {
            this.activeReservation = res;
          }
          this.selectAllForReservation(
            clickedDrawable?.reservation.reservationGuid
          );
        } else if (clickedDrawable?.outOfService?.outOfServiceGuid) {
          this.activeOutOfService = clickedDrawable?.outOfService;
          this.selectAllForOutOfService(
            clickedDrawable?.outOfService.outOfServiceGuid
          );
        }
      }
      if (evt.buttons == MouseClick.Right) {
        //right click on rect assign or new drawable
        this.openResContextMenu(rect.id);
      }
    }
  }

  getActiveReservation(resGuid: string): Reservation | null {
    const ga = this.gridAssigns.find((ga) => {
      if (ga.reservation) {
        return ga.reservation.reservationGuid == resGuid;
      }
    });
    if (ga) {
      return ga.reservation;
    } else {
      return null;
    }
  }

  getActiveReservationGuid() {
    return this.activeReservation?.reservationGuid;
  }

  rectMouseup(e: MouseEvent | TouchEvent) {
    this.mouseDownOnReservation = false;
    if (e && e.target) {
      const rect = e.target as SVGRectElement;
    }
  }

  editReservation(reservationGuid: string) {
    this.selectAllForReservation(reservationGuid);
    this.isNewRes = false;
    this.showResModal = true;
  }

  rectClicked(e: MouseEvent | TouchEvent) {
    this.isDown = false;
    this.closeContextMenus();
    if (e && e.target) {
      const rect = e.target as SVGRectElement;
      const clickedDrawable = this.drawables.find(
        (r) => r.id == rect.id
      ) as DrawableRectangle;
      if (clickedDrawable) {
        this.deselectAll();

        //select all rects with same reservation guid
        if (clickedDrawable?.reservation?.reservationGuid) {
          this.isNewRes = false;
          this.showResModal = true;
          this.selectAllForReservation(
            clickedDrawable?.reservation.reservationGuid
          );
        } else if (clickedDrawable?.outOfService?.outOfServiceGuid) {
          this.activeOutOfService = clickedDrawable?.outOfService;
          this.showOosModal = true;
          this.selectAllForOutOfService(
            clickedDrawable?.outOfService?.outOfServiceGuid
          );
        }
      }
    }
  }

  addNewSelectionEvents(selection: DrawableRectangle) {
    selection.rectElement.addEventListener(
      "mousedown",
      this.selectionMousedown
    );
    selection.rectElement.addEventListener("mouseup", this.selectionMouseup);
    selection.rectElement.addEventListener(
      "click",
      this.selectionDoubleClicked
    );
  }

  selectionMousedown(e: MouseEvent | TouchEvent) {
    this.closeContextMenus();
    e.preventDefault();
    e.stopPropagation();
    this.mouseDownOnReservation = true;
    if (e && e.target) {
      const rect = e.target as SVGRectElement;
      const clickedDrawable = this.newSelectionDrawables.find(
        (r) => r.id == rect.id
      ) as DrawableRectangle;
      if (clickedDrawable) {
        const evt = e as MouseEvent;
        if (evt.buttons === MouseClick.Right) {
          this.openAddResContextMenu(clickedDrawable.id);
        }
        this.selectAllNewSelections();
      }
    }
  }
  selectionDoubleClicked(e: MouseEvent | TouchEvent) {
    this.closeContextMenus();
    e.preventDefault();
    e.stopPropagation();
    this.mouseDownOnReservation = true;
    if (e && e.target) {
      const rect = e.target as SVGRectElement;
      const clickedDrawable = this.newSelectionDrawables.find(
        (r) => r.id == rect.id
      ) as DrawableRectangle;
      if (clickedDrawable) {
        this.selectAllNewSelections();
        this.showAvailableRates();
      }
    }
  }

  selectionMouseup(e: MouseEvent | TouchEvent) {
    if (e && e.target) {
      const rect = e.target as SVGRectElement;
    }
  }

  newDrawableAllowedAtPoint(point: Point): boolean {
    // see if it's down on the margin at the top  or if it's at the bottom marginof a drawable
    // if so return false
    this.rowHeight;
    let rowIndex = this.getUnitIndex(point.y);
    let topMargin = point.y - this.rowHeight * rowIndex;
    let bottomMargin = this.rowHeight * (rowIndex + 1) - point.y;
    let result =
      Math.abs(topMargin) > this.rowMargin &&
      Math.abs(bottomMargin) > this.rowMargin;
    return result;
  }

  private async handleBoardMouseDown(e: MouseEvent | TouchEvent) {
    const evt = e as MouseEvent;
    if (!evt.ctrlKey) {
      //ctl is not down so clear the array
      await this.clearSelection();
    }
    this.closeContextMenus();
    this.clickedPoint.x = this.getMousePos(e).x;
    this.clickedPoint.y = this.getMousePos(e).y;

    if (!this.newDrawableAllowedAtPoint(this.clickedPoint)) {
      return;
    }

    if (evt.buttons === MouseClick.Left) {
      //left click

      await this.deselectAll();
      this.newSelectionDrawable = new DrawableRectangle();

      if (!this.newSelectionDrawable.unit) {
        const unit = this.getUnit(this.getUnitIndex(this.clickedPoint.y));
        this.newSelectionDrawable.unit = unit;

        //don't allow bookings on rentals yet via grid ??
        // if (
        //   (unit && unit.unitType == UnitTypes.Rental) ||
        //   unit.unitType == UnitTypes.Event
        // ) {
        //   return;
        // }
      }

      this.newSelectionDrawable.id = Guid.newGuid();
      this.newSelectionDrawable.fillColor = this.selectedColor;
      this.newSelectionDrawable.strokeWidth = 2;
      this.newSelectionDrawable.strokeColor = "black";
      this.newSelectionDrawable.height = this.rowHeight;
      this.newSelectionDrawable.width = 1;
      this.newSelectionDrawable.startPoint = this.snapToRow(this.clickedPoint);
      this.snapToDefaultArrivalTime(this.newSelectionDrawable);
      this.applyFudgeFactor(this.newSelectionDrawable);
      this.newSelectionDrawable.draw();
      this.addNewSelectionEvents(this.newSelectionDrawable);
      this.newSelectionDrawables.push(this.newSelectionDrawable);
      this.isDown = true;
    }
  }

  snapToRow(point: Point): Point {
    //the row index is the clickedpoint y  divided by the rowheight
    const rowIndex = Math.floor(point.y / this.rowHeight);
    const newPoint = new Point(point.x, rowIndex * this.rowHeight);
    return newPoint;
  }

  snapToDefaultArrivalTime(selectionDrawable: DrawableRectangle) {
    if (
      this.property.defaultArrivalTime &&
      this.property.defaultDepartureTime &&
      selectionDrawable.unit.unitType == UnitTypes.Accommodation
    ) {
      const minuteWidth = this.dayWidth / 24 / 60;
      const todayFloorX =
        this.getDateIndex(selectionDrawable.startPoint.x) * this.dayWidth;
      const arrivalAtMidnight = new Date(
        new Date(this.property.defaultArrivalTime).removeTime()
      );

      const minutesFromFloorToArrivalTime = arrivalAtMidnight.minutesDiff(
        this.property.defaultArrivalTime
      );
      const xForArrivalTimeToday =
        todayFloorX + minutesFromFloorToArrivalTime * minuteWidth;

      selectionDrawable.startPoint.x = xForArrivalTimeToday;
    }
  }

  snapToDefaultDepartureTime(
    selectionDrawable: DrawableRectangle,
    mouseX: number
  ) {
    if (
      this.property.defaultArrivalTime &&
      this.property.defaultDepartureTime &&
      selectionDrawable.unit.unitType == UnitTypes.Accommodation
    ) {
      const minuteWidth = this.dayWidth / 24 / 60;
      const departDayIndex = this.getDateIndex(
        selectionDrawable.startPoint.x + selectionDrawable.width
      );
      const departDayFloorX = departDayIndex * this.dayWidth;
      const departureDateAtMidnight = new Date(
        new Date(this.property.defaultDepartureTime).removeTime()
      );

      const minutesFromFloorToDepartureTime =
        departureDateAtMidnight.minutesDiff(this.property.defaultDepartureTime);
      const xForDepartTime =
        departDayFloorX + minutesFromFloorToDepartureTime * minuteWidth;
      selectionDrawable.width = xForDepartTime - selectionDrawable.startPoint.x;
    }
  }

  private handleBoardMouseMove(e: MouseEvent | TouchEvent) {
    if (this.isDown === false) {
      return;
    }

    const mouseX = this.getMousePos(e).x;
    const mouseY = this.getMousePos(e).y;
    const width = mouseX - this.clickedPoint.x;
    const height = mouseY - this.clickedPoint.y;

    this.newSelectionDrawable.width = width;
    this.snapToDefaultDepartureTime(this.newSelectionDrawable, mouseX);
    const overlapped = this.isOverlapped(this.newSelectionDrawable);
    if (overlapped) {
      //stop
      return;
    } else {
      this.setSelectionText(mouseY, mouseX);
      this.newSelectionDrawable.update();
    }
  }

  isOverlapped(selectionDrawable: DrawableRectangle) {
    //iterate list of drawable rectangles
    //if the selection drawable is in the same row and has an start x plus width that is to the right of the any drawable rects
    //then return true
    let result = false;
    this.drawables.forEach((drawable) => {
      if (drawable instanceof DrawableRectangle) {
        const rect: DrawableRectangle = drawable;
        const unitIndexOfOther = this.getUnitIndex(rect.startPoint.y);
        const unitIndexOfSelection = this.getUnitIndex(
          selectionDrawable.startPoint.y
        );
        if (
          unitIndexOfSelection === unitIndexOfOther &&
          selectionDrawable.startPoint.x + selectionDrawable.width >=
            rect.startPoint.x &&
          selectionDrawable.startPoint.x <= rect.startPoint.x
          && selectionDrawable.startPoint.y == rect.startPoint.y
        ) {
          result = true;
        }
      }
    });

    //check that it's not overlapping any new drawables either
    if (result == false) {
      this.newSelectionDrawables.forEach((drawable) => {
        if (drawable.id !== selectionDrawable.id) {
          const unitIndexOfOther = this.getUnitIndex(drawable.startPoint.y);
          const unitIndexOfSelection = this.getUnitIndex(
            selectionDrawable.startPoint.y
          );
          if (
            unitIndexOfSelection === unitIndexOfOther &&
            selectionDrawable.startPoint.x + selectionDrawable.width >=
              drawable.startPoint.x &&
            selectionDrawable.startPoint.x <= drawable.startPoint.x
            && selectionDrawable.startPoint.y == drawable.startPoint.y
          ) {
            result = true;
          }
        }
      });
    }

    return result;
  }

  private setSelectionText(mouseY: number, mouseX: number) {
    const start = this.getDate(this.getDateIndex(this.clickedPoint.x));
    const end = this.getDate(this.getDateIndex(mouseX));
    const nights = start.daysDiff(end);
    const startSimplified = start.formatMonthDay();
    const endSimplified = end.formatMonthDay();
    this.newSelectionDrawable.text = `${this.newSelectionDrawable.unit.unitName} ${nights} nights ${startSimplified} to ${endSimplified}`;
  }

  getDateIndex(mouseX: number): number {
    return Math.floor(mouseX / this.dayWidth);
  }

  getDate(dateIndex: number): Date {
    return this.gridModel.startDate.addDays(dateIndex);
  }

  getUnitIndex(mouseY: number): number {
    let index = 0;
    let i = 0;
    for (const unit of this.unitsDisplayed) {
      index += unit.quantity;
      let remainder = mouseY - (index - 1) * this.rowHeight;
      if (remainder < this.rowHeight) {
        return i;
      }
      i++;
    }
    return this.unitsDisplayed.length;
  }

  getUnit(unitIndex: number): Unit {
    // return the correct unit based on the index.
    return this.unitsDisplayed[unitIndex];
  }

  getMousePos(evt: MouseEvent | TouchEvent) {
    const rect = this.board.getBoundingClientRect();

    const eventObj = (evt as TouchEvent).changedTouches
      ? (evt as TouchEvent).changedTouches[0]
      : (evt as MouseEvent);

    return {
      x:
        ((eventObj.clientX - rect.left) / (rect.right - rect.left)) *
        this.getBoardWidth(),
      y:
        ((eventObj.clientY - rect.top) / (rect.bottom - rect.top)) *
        this.getBoardHeight(),
    };
  }

  private handleClear() {
    this.clearBoard();
  }

  private handleBoardMouseUp() {
    this.isDown = false;
  }

  private handleBoardMouseOut() {
    //  this.isDown = false;
  }
}
