
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
export default class ToggleNavigation extends Vue {
  @Prop({ default: "grid" }) public selectedToggle!: string; //can be grid, types, or dates
  private selected = "grid";

  @PropertyStore.State
  public property!: Property;

  show() {
    if (
      this.$route.name?.toLowerCase() == "cart" ||
      this.$route.name?.toLowerCase() == "confirmation"
    ) {
      return false;
    } else {
      return true;
    }
  }

  getToggle() {
    return this.selected;
  }

  mounted() {
    this.selected = this.selectedToggle;
  }
  navigateToUnitGroups(param: string) {
    this.selected = "types";
    this.$router.push({
      name: "UnitGroups",
    });
  }

  navigateToGrid() {
    this.selected = "grid";
    this.$router.push({
      name: "Grid",
      params: {},
    });
  }

  navigateToDateSearch() {
    this.selected = "dates";
    this.$router.push({
      name: "DateSearch",
      params: {},
    });
  }
}
