import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { Account } from './Account';


export async function deleteAccounts(accountsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/account/delete`,
    accountsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the account from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteAccount(accountGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/account/` + accountGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the account from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function saveAccount(account: Account): Promise<boolean> {
  const response = await axiosRetry(
    `/api/account`,
    account,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the account from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getAccounts(propertyGuid: string): Promise<Array<Account>> {
  let url = `/api/account/getaccounts/${propertyGuid}`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Account>>;
  } else {
    const err =
      "An error occurred getting the account from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getAccountsByPerson(personGuid: string): Promise<Array<Account>> {
  const response = await axiosRetry(
    `/api/account/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Account>>;
  } else {
    const err =
      "An error occurred getting the account from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getAccount(accountGuid: string): Promise<Account> {
  const response = await axiosRetry(
    `/api/account/getaccount/${accountGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Account>;
  } else {
    const err =
      "An error occurred getting the account from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

