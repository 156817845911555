
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";
import AmenityEdit from "./AmenityEdit.vue";
import { Field } from "@/models/interfaces/Field";
import { namespace } from "vuex-class";
import { Guid } from "@/utils/Guid";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { StringUtil } from "@/utils/StringUtil";
import { formatNumber } from "@/utils/currencyUtils";
import AmenityTable from "./AmenityTable.vue";
import { deleteAmenities, getAmenities } from "./AmenityService";
import { Amenity } from "./Amenity";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    AmenityTable,
    AmenityEdit,
    ConfirmDeleteModal,
    ConfigurationNavMenu,
  },
})
export default class Amenities extends Vue {
  @PropertyStore.State
  public property!: Property;

  private loading = false;
  private showEditModal = false;
  private fields: Array<Field> = [];
  private infoModal!: {
    id: "info-modal";
    title: string;
    content: string;
  };
  private selected!: Array<any>;
  private clickedAmenity!: Amenity | null;
  private clickedIndex = 0;
  private showConfirmModal = false;
  private amenitiesTable: any;
  private amenities!: Array<Amenity>;

  formatNumber(num: number) {
    return formatNumber(num);
  }

  phoneFormatter(value: string) {
    const result = StringUtil.formatPhoneNumber(value);
    return result;
  }

  getReadableDate(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateLocalized();
  }

  confirmDeleteAmenities() {
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async deleteAmenities() {
    const arrayofAmenitiesGuids = new Array<Guid>();
    this.selected.forEach((p) => {
      arrayofAmenitiesGuids.push(p.amenityGuid);
    });
    await deleteAmenities(arrayofAmenitiesGuids);
    await this.retrieveAmenities();
    this.showConfirmModal = false;
  }

  selectAllRows() {
    this.amenitiesTable.selectAllRows();
  }
  clearSelected() {
    this.amenitiesTable.clearSelected();
  }
  selectThirdRow() {
    this.amenitiesTable.selectRow(2);
  }
  unselectThirdRow() {
    this.amenitiesTable.unselectRow(2);
  }

  selectMyRow(uniqueID) {
    const row = this.amenities.findIndex((x) => x.amenityGuid === uniqueID);
    this.amenitiesTable.selectRow(row);
  }

  selectedItemsCount() {
    return this.selected && this.selected.length > 0;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getActiveAmenity() {
    if (this.clickedAmenity) {
      return this.clickedAmenity;
    } else {
      return null;
    }
  }
  getActiveAmenityGuid() {
    if (this.clickedAmenity) {
      return this.clickedAmenity.amenityGuid;
    } else {
      return null;
    }
  }

  onRowDoubleClicked(amenity: Amenity, index: any, event: any) {
    this.clickedAmenity = amenity;
    this.showEditModal = true;
  }

  async saved(amenity: Amenity) {
    let index = this.amenities.findIndex(
      (p) => p.amenityGuid == amenity.amenityGuid
    );
    this.amenities[index] = amenity;
    await this.amenitiesTable.refresh();
    this.amenitiesTable.selectRow(index);
    this.showEditModal = false;
  }

  cancel() {
    this.showEditModal = false;
  }

  addAmenity() {
    this.clickedAmenity = null;
    this.showEditModal = true;
  }

  async created() {
    await this.retrieveAmenities();
  }

  mounted() {
    this.amenitiesTable = this.$refs.amenitiesTableRef;
    this.getFields();
  }

  private getFields() {
    let field = new Field();
    field.key = "description";
    field.label = "Description";
    this.fields.push(field);
  }

  async retrieveAmenities() {
    this.loading = true;
    this.amenities = await getAmenities(this.property.propertyGuid);
    this.loading = false;
  }
}
