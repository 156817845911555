import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import UnitGroups from '../components/SiteBook/UnitGroups.vue';
import DateSearch from '../components/SiteBook/DateSearch.vue';
import Book from '../components/Marketplace/Book.vue';
import Company from '../components/Marketplace/Company.vue';
import SiteBook from '../components/SiteBook/SiteBook.vue';
import Units from '../components/SiteBook/Units.vue';
import Grid from '../components/SiteBook/Grid.vue';
import MobileGrid from '../components/SiteBook/MobileGrid.vue';
import Confirmation from '../components/SiteBook/Confirmation.vue';
import Cart from '../components/Cart/Cart.vue';
import LayoutBookNow from '../layouts/LayoutBookNow.vue';
import InvoicePay from '../components/Cloud/Invoices/InvoicePay.vue';
import { loadStripeScript } from '@/stripe-import';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'UnitGroups',
    component: UnitGroups,
    meta: { layout: LayoutBookNow }
  },
  /* other routes */
  {
    path: '/healthz', // Ignore or pass on to server 
    meta: { layout: null }
  },
  {
    path: '/groups',
    name: 'UnitGroups',
    component: UnitGroups,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/company/:propertyGuid',
    name: 'Company',
    component: Company,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/datesearch',
    name: 'DateSearch',
    component: DateSearch,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/unitgroups',
    name: 'UnitGroups',
    component: UnitGroups,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/units',
    name: 'Units',
    props: true,
    component: Units,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/grid',
    name: 'Grid',
    props: true,
    component: Grid,
    meta: { layout: LayoutBookNow }
  },
  // {
  //   path: '/book/:prop/:unit/:date',
  //   name: 'Book',
  //   props: true,
  //   component: Book,
  //   meta: { layout: LayoutBook }
  // },
  {
    path: '/book/:unit',
    name: 'Book',
    props: true,
    component: Book,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/unitbook',
    name: 'Book',
    props: true,
    component: Book,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/sitebook',
    name: 'SiteBook',
    props: true,
    component: SiteBook
  },
  {
    path: '/mobilegrid',
    name: 'MobileGrid',
    props: true,
    component: MobileGrid,
    meta: { layout: LayoutBookNow }
  },
  {
    path: '/invoicepay/:id',
    name: 'InvoicePay',
    component: InvoicePay,
    beforeEnter: (to, from, next) => {
      loadStripeScript().then(() => next());
    },
    meta: { requiresAuth: false }
  },

  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    beforeEnter: (to, from, next) => {
      loadStripeScript().then(() => next());
    },
    meta: { layout: LayoutBookNow, hideCart: true }
  },
]

const bookRouter = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

bookRouter.onReady(() => {
  var meta = document.createElement('meta');
  meta.content = "width=device-width,initial-scale=1.0";
  meta.name = "viewport";
  document.getElementsByTagName('head')[0].appendChild(meta);
});


bookRouter.beforeEach((to, from, next) => {
  let tempPropGuid = "";
  if (to.query.property || to.query.prop) {
    if (to.query.prop) {
      tempPropGuid = to.query.prop.toString();
    } else if (to.query.property) {
      tempPropGuid = to.query.property.toString();
    }
    localStorage.setItem("propertyGuid", tempPropGuid);
  }

  const propGuid = localStorage.getItem("propertyGuid");
  if (!propGuid) {
    const defaultPropertyGuid = '1C77120C-F4EB-4BD2-88EB-171112E7D5FD';
    localStorage.setItem("propertyGuid", defaultPropertyGuid);
  }

  next();
});

export default bookRouter

