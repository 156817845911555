import Vue from 'vue';
import App from './App.vue';
import store from './store/VuexStore';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import LoadScript from 'vue-plugin-load-script';

import bookRouter from './router/bookRouter';
import cloudRouter from './router/cloudRouter';
import gwsRouter from './router/gwsRouter';
import marketplaceRouter from './router/marketplaceRouter';
import '././assets/styles/lv.css';
import '././assets/styles/cloud.scss';
Vue.use(LoadScript);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;
const host = window.location.host.toLowerCase();
const href = window.location.href.toLowerCase();
const parts = host.split('.');
const domainLength = 3; // route1.example.com => domain length = 3

const router = () => {

  const isCloud = (host.indexOf('account.lodgevault.com') > -1)
    || (host.indexOf('admin.lodgevault.com') > -1)
    || (host.indexOf('cloud.lodgevault.com') > -1)
    || (host.indexOf('business.lodgevault.com') > -1);

  const isBook = (host.indexOf('book.lodgevault.com') > -1);

  const isGws = ((host.indexOf('gws.lodgevault.com') > -1));

  const isMarketplace = (host.indexOf('treqit.com') > -1
    || (host.indexOf('www.lodgevault.com') > -1)
    || (host.indexOf('marketplace.lodgevault.com') > -1)
    || (host.indexOf('lodgevault.com') == 0));

  console.log('host loc = ' + host.indexOf('localhost'))
  const isLocalhost = host.indexOf('localhost') > -1;

  let routerInstance;
  if (isLocalhost === true) {
    // return the current one you are working on here.
    // routerInstance = gwsRouter;
   routerInstance = bookRouter;
  // routerInstance = cloudRouter;
   // routerInstance = marketplaceRouter;
  } else {
    if (isMarketplace === true) {
      routerInstance = marketplaceRouter;
    } else if (isCloud === true) {
      routerInstance = cloudRouter;
    } else if (isBook === true) {
      routerInstance = bookRouter;
    } else if (isGws == true) {
      routerInstance = gwsRouter;
    } else {
      routerInstance = marketplaceRouter;
    }
  }

  return routerInstance;
};

new Vue({
  store,
  router: router(),
  render: h => h(App),
}).$mount('#app')