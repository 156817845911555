
import { Charge } from "./Charge";
import { IInvoiceItem } from "./IInvoiceItem";
import { Payment } from "./Payment";

export class Invoice {

    constructor() {
        this.payments = [];
        this.charges = [];
        this.items = [];
        this.balance = 0;
    }
    

    public tax1Total!: number;
    public tax2Total!: number;
    public tax3Total!: number;
    public tax4Total!: number;
    public tax5Total!: number;
    public tax6Total!: number;
    public tax7Total!: number;
    public tax8Total!: number;
    public tax9Total!: number;
    public tax10Total!: number;
    public totalDeposits!: number;
    public totalCertificates!: number;
    public totalPayments!: number;
    public totalRefunds!: number;
    public totalDiscounts!: number;
    public preTaxBaseRate!: number;
    public reservationName!: string;
    public collapsed: boolean = false;
    public summarize: boolean = false;
    public unPaidOnly: boolean = false;
    public invoiceGuid!: string;
    public personGuid!: string;
    public personName!: string;
    public reservationGuid!: string;
    public totalCharges!: number;
    public totalCredits!: number;
    public totalChargesPretax!: number;
    public totalTax!: number;
    public balance!: number;
    public createdDate!: Date;
    public propertyGuid!: string;
    public modifiedDate!: Date;
    public stripeId!: string;
    public currencyCode: string = CurrencyCodes.USD;
    public sentDate!: Date;
    public balanceDueDate!: Date;
    public depositDueDate!: Date;
    public depositPaidDate!: Date;
    public balancePaidDate!: Date;
    public depositAmount!: number;
    public due!: number;
    public readableInvoiceNumber!: string;
    public paymentLink!: string;

    //collections
    public items!: Array<IInvoiceItem>;
    public charges!: Array<Charge>;
    public payments!: Array<Payment>;

    get balanceComputed(): number {
        let balance = 0;
        for (const charge of this.charges) {
            balance += charge.totalPrice;
        }
        for (const payment of this.payments) {
            balance += payment.totalPrice;
        }
        return parseFloat(balance.toString());
    }
}

export class CurrencyCodes {
    public static USD: string = "USD";
    public static CAD: string = "CAD";
}

export enum InvoiceItemKind {
    Deposit, //credit
    Charge, //charge
    Refund, //negative credit
    Certificate, //credit
    Payment, //credit
    UnitRate, //charge
    Component, //charge
    Service
}

