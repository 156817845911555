import { fetchRetry } from "@/utils/fetchUtil";

export class Activity {
    constructor() {
        this.description = "";
    }
    public activityGuid!: string;
    public kind!: ActivityKind;
    public description!: string;
    public notes!: string;
    public date!: Date;
    public fileName!: string;
    public personName!: string;
    public reservationName!: string;
    public fileContent!: string;
    public personGuid!: string | undefined;
    public takenBy!: string;
    public propertyGuid!: string;
    public reservationGuid!: string;
    public modifiedDate!: Date;
    public createdDate!: Date;
}

export enum ActivityKind
{
    None,
    Phone,
    Letter,
    Fax,
    Email,
    Other
}