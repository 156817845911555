import { Unit } from "../components/Cloud/Units/Unit";
import { UnitGroup } from "./UnitGroup";

export class AvailableUnitsViewModel {

    public AvailableUnitsViewModel() {
        //default values
        this.page = 0;
        this.billingCurrency = "USD";
        this.itemStart = 1;
        this.itemEnd = 1;
    }

    public units!: Array<Unit>;
    public unitGroups!: Array<UnitGroup>;
    public page!: number;
    public itemStart!: number;
    public itemEnd!: number;
    public totalUnitsCount!: number;
    public billingCurrency!: string;
}