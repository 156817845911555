import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { axiosRetry } from "@/utils/fetchUtil";
import { UnitGroup } from '@/models/UnitGroup';

@Module({ namespaced: true })
class UnitGroupStore extends VuexModule {
  public unitGroupList: Array<UnitGroup> = [];
  public fetchComplete = false;
  public unitGroup: UnitGroup = new UnitGroup();

  @Action
  public async setUnitGroup(unitGroupGuid: string): Promise<boolean> {
    const group = this.unitGroupList.find((group) => group.unitGroupGuid.toLowerCase() === unitGroupGuid.toLowerCase());
    if (group) {
      this.context.commit('updateUnitGroup', group);
      return true;
    }
    return false;
  }

  @Mutation
  public updateUnitGroup(group: UnitGroup): void {
    this.unitGroup = group;
  }

  @Mutation
  public saveList(data: Array<UnitGroup>): void {
    this.fetchComplete = true;
    this.unitGroupList = data;
  }
  @Action
  public async getAllUnitGroups(propertyGuid: string): Promise<boolean> {
    if (this.fetchComplete) {
      return Promise.resolve(true);
    } else {

      return await axiosRetry(`/api/unitgroup/getunitgroups/${propertyGuid}`, null, 'GET')
        .then(r => {
          this.context.commit('saveList', r.data)
          return Promise.resolve(true);
        })
        .catch((ex) => {
          console.log(ex);
          return Promise.resolve(false);
        })
    }
  }

  @Mutation
  public create(newUnitGroup: UnitGroup): void {
    this.unitGroupList.unshift(newUnitGroup)
  }

  @Action
  public async fetchUnitGroup(unitGroupGuid: string): Promise<boolean> {
    return axiosRetry(`/api/unitgroup/getunitgroup/${unitGroupGuid}`, null, 'GET')
      .then(r => {
        this.context.commit('updateUnitGroup', r.data);
        return Promise.resolve(true);
      })
      .catch(() => {
        console.log({
          title: 'Error',
          type: 'error',
          message: 'Could not fetch unit group'
        })
        return false
      })
  }
}
export default UnitGroupStore
