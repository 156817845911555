
import TemplateContextMenu from "@/components/ContextMenu/TemplateContextMenu.vue";
import { Field } from "@/models/interfaces/Field";
import { MouseClick } from "@/models/MouseClick";
import {
  Template,
  getTemplates,
  getSampleTemplates,
  getSiteAdminTemplates,
  saveTemplate,
  deleteTemplate,
} from "@/models/Template";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "../Auth/User";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import TemplateEdit from "./TemplateEdit.vue";

const Auth = namespace("AuthStore");
@Component({
  components: { TemplateContextMenu, TemplateEdit, ConfirmDeleteModal },
})
export default class TemplateTable extends Vue {
  @Prop({ default: null }) public propertyGuid!: string;

  @Auth.State("user")
  private currentUser!: User;

  private showConfirmModal = false;
  private showTemplateModal = false;
  private mode = "";
  private loading = true;
  private templates: Array<Template> = [];
  private templateFields: Array<Field> = [];
  private selected: Array<Template> = [];
  private clickedItem!: Template | null;
  private isContextMenuVisible = false;
  private templatesTable: any;
  private popoverOptions = {
    popoverReference: null as any,
    placement: "bottom",
    offset: "0,0",
  };

  getCount() {
    if (this.selected) {
      return this.selected.length;
    }
    return 0;
  }

  async saved(template: Template) {
    this.loading = true;
    let found = this.templates.find(
      (a) => a.templateGuid === template.templateGuid
    );
    if (!found) {
      await this.templates.unshift(template);
    }
    let index = await this.templates.findIndex(
      (a) => a.templateGuid === template.templateGuid
    );
    this.templates[index] = template;
    await this.templatesTable.refresh();
    this.templatesTable.selectRow(index);
    this.showTemplateModal = false;
    this.loading = false;
  }

  cancel() {
    this.showTemplateModal = false;
  }

  getSelectedTemplateGuid() {
    if (this.selected && this.selected.length > 0) {
      const selectedTemplate = this.selected[0];
      return selectedTemplate.templateGuid;
    }
    return null;
  }

  async editTemplate() {
    const selectedTemplate = this.selected[0];
    this.openTemplate(selectedTemplate, null, null);
  }

  async duplicateTemplates() {
    if (this.selected && this.selected.length > 0) {
      this.loading = true;

      for (const d of this.selected) {
        let duped = await this.templates.find(
          (t) => t.templateGuid == d.templateGuid
        );

        if (duped) {
          duped.templateGuid = Guid.newGuid().toString();
          await saveTemplate(duped);
          const indexToChange = await this.templates.findIndex(
            (t) => t.templateGuid === duped?.templateGuid
          );
          if (indexToChange > -1) {
            await this.templates.splice(indexToChange, 1, duped);
          }
        }
      }
    }

    await this.templatesTable.refresh();
    this.loading = false;
  }

  getTemplateKindColor(kind: string) {
    if (kind == "Site Admin") {
      return "success";
    } else if (kind == "Sample") {
      return "warning";
    } else {
      return "primary";
    }
  }
  getTemplateKind(kind: string) {
    if (kind == "") {
      return "Business";
    } else {
      return kind;
    }
  }

  referenceObject(evt) {
    const left = evt.clientX;
    const top = evt.clientY;
    const right = left + 1;
    const bottom = top + 1;
    const clientWidth = 1;
    const clientHeight = 1;
    console.log(
      " left = " +
        left +
        "  top =" +
        top +
        "  right= " +
        right +
        " bottom= " +
        bottom
    );
    function getBoundingClientRect() {
      return {
        left,
        top,
        right,
        bottom,
      };
    }

    const obj = {
      getBoundingClientRect,
      clientWidth,
      clientHeight,
    };
    return obj;
  }

  async showContextMenu(template: Template, index: any, event: any) {
    await this.closeContextMenu;
    await this.openPopover(event);
    this.isContextMenuVisible = true;
  }

  async openPopover(event: any) {
    const element = event.srcElement
      ? event.srcElement
      : this.referenceObject(event); //event.srcElement
    if (element) {
      this.popoverOptions.popoverReference = element;
      //  this.popoverOptions.offset = event.srcElement.offset;
    }
  }

  async closeContextMenu() {
    this.isContextMenuVisible = false;
    this.popoverOptions.popoverReference = null;
  }

  tableMouseDown(e: MouseEvent | TouchEvent) {
    this.closeContextMenu();
    e.preventDefault();
    e.stopPropagation();
    if (e && e.target) {
      const evt = e as MouseEvent;
      if (evt.buttons === MouseClick.Right) {
        // this.showContextMenu();
      }
    }
  }

  selectedItemsCount() {
    return this.selected && this.selected.length > 0;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getActiveTemplate() {
    if (this.clickedItem) {
      return this.clickedItem;
    } else {
      return null;
    }
  }

  confirmDeleteItem() {
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async deleteTemplates() {
    if (this.selected && this.selected.length > 0) {
      await this.selected.forEach(async (d) => {
        let deleted = d;
        let result = await deleteTemplate(deleted.templateGuid.toString());
        if (result) {
          if (this.templates) {
            let index = this.templates.findIndex(
              (a) => a.templateGuid === deleted.templateGuid
            );
            this.templates.splice(index, 1);
          }
        }
      });
      this.showConfirmModal = false;
    }
  }

  async newitem() {
    await this.selectnone();
    this.clickedItem = null;
    this.showTemplateModal = true;
  }

  async selectall() {
    await this.templatesTable.selectAllRows();
  }

  async selectnone() {
    await this.templatesTable.clearSelected();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  private getTemplateFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    this.templateFields.push(field);

    field = new Field();
    field.key = "description";
    field.label = "Description";
    this.templateFields.push(field);

    if (this.currentUser.roles.includes("SITEADMINISTRATOR")) {
      field = new Field();
      field.key = "kind";
      field.label = "Kind";
      this.templateFields.push(field);
    }

    field = new Field();
    field.key = "active";
    field.label = "Active";
    this.templateFields.push(field);
  }

  getTitle() {
    switch (this.mode) {
      case "samples":
        return "Sample Templates";
        break;
      case "siteadmin":
        return "Site Admin Templates";
        break;
      case "property":
        return "Templates";
        break;
      default:
        return "Templates";
        break;
    }
  }

  async mounted() {
    this.loading = true;
    this.templatesTable = this.$refs.templateTableRef;
    this.getTemplateFields();
    await this.loadTemplates();
    this.loading = false;
  }

  private async loadTemplates() {
    this.loading = true;
    if (this.propertyGuid) {
      this.templates = await getTemplates(this.propertyGuid, true);
    }
    if (this.currentUser.roles.includes("SITEADMINISTRATOR")) {
      const samples = await getSampleTemplates();
      samples.forEach((s) => {
        this.templates.push(s);
      });

      const siteAdmins = await getSiteAdminTemplates();
      siteAdmins.forEach((s) => {
        this.templates.push(s);
      });
    }
    this.loading = false;
  }

  openTemplate(template: Template, index: any, event: any) {
    this.showTemplateModal = true;
  }
}
