import { axiosRetry } from "@/utils/fetchUtil";
import { TimeSlot } from "./TimeSlot";

export class TimeSlotCollection {
    constructor() {
    }
    public name!: string;
    public timeSlots!: Array<TimeSlot>;
    public propertyGuid!: string;
    public timeSlotCollectionGuid!: string;
}

export async function deleteTimeSlotCollection(timeSlotCollectionGuid: string): Promise<boolean> {
    const response = await axiosRetry(
        `/api/timeslot/deletecollection/` + timeSlotCollectionGuid,
        null,
        "DELETE"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the timeSlot from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function saveTimeSlotCollection(collection: TimeSlotCollection): Promise<boolean> {
    const response = await axiosRetry(
        `/api/timeslot/savecollection`,
        collection,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the timeSlot from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getTimeSlotCollections(propertyGuid: string): Promise<Array<TimeSlotCollection>> {
    const response = await axiosRetry(
        `/api/timeslot/getcollections/${propertyGuid}`,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<TimeSlotCollection>>;
    } else {
        const err =
            "An error occurred getting the timeSlotcollection from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getTimeSlotCollection(timeSlotCollectionGuid: string): Promise<TimeSlotCollection> {
    const response = await axiosRetry(
        `/api/timeslot/getcollection/${timeSlotCollectionGuid}`,
        null,
        "GET"
    );
    if (response && response.status == 200) {
        return response.data as Promise<TimeSlotCollection>;
    } else {
        const err =
            "An error occurred getting the timeSlot from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}