
import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Login from "./Login.vue";
import { User } from "./User";

const Auth = namespace("AuthStore");
@Component({
  components: { Login },
})
export default class LoginModal extends Vue {
  @Auth.State("user")
  private currentUser!: User;
  name = "";
  nameState: boolean | null | undefined;
  submittedNames = [];
  checkFormValidity() {
    const valid = true; //this.$refs?.form?.checkValidity();
    this.nameState = valid;
    return valid;
  }
  flipToSignUp() {
    this.$emit("flip-to-signup");
  }
  resetModal() {
    this.name = "";
    this.nameState = null;
  }
  handleOk(bvModalEvent) {
    // Prevent modal from closing
    bvModalEvent.preventDefault();
    // Trigger submit handler
    this.handleSubmit();
  }
  handleSubmit() {
    // Exit when the form isn't valid
    if (!this.checkFormValidity()) {
      return;
    }
    // Push the name to submitted names
    // Hide the modal manually
    this.$nextTick(() => {
      this.$bvModal.hide("modal-login");
    });
  }

  loginSuccess() {
    this.$nextTick(() => {
      this.$bvModal.hide("modal-login");
    });

    if (this.$route.query.redirect && this.$route.query.redirect !== "/") {
      let redirect = this.$route.query.redirect.toString();
      this.$router.push(redirect);
    } else if (this.currentUser && this.currentUser.roles) {
      if (
        this.currentUser.roles.includes("ADMINISTRATOR") ||
        this.currentUser.roles.includes("EMPLOYEE") ||
        this.currentUser.roles.includes("SITEADMINISTRATOR")
      ) {
        this.$router.push("/");
      }
    }
  }
}
