
import { formatter } from "@/utils/currencyUtils";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { ConfirmationViewModel } from "@/models/ConfirmationViewModel";
import { getConfirmation } from "@/components/Cloud/Reservations/ReservationService";
import { StringUtil } from "@/utils/StringUtil";
import { ReqCharge } from "@/models/ReqCharge";
import { getProperty } from "../Cloud/Settings/PropertyService";

@Component({
  components: {},
})
class Confirmation extends Vue {
  private showProgressOverlay = false;
  private reqItems = [];
  private confirmationViewModelGuid = "";
  private model = new ConfirmationViewModel();
  private showThankYou = false;
  public property = new Property();

  getFeeTotal(model: ConfirmationViewModel) {
    let fees = model.stripeFee + model.appFee;
    return fees;
  }

  sumTotal(charges: Array<ReqCharge>) {
    if (charges) {
      return charges.reduce((sum, current) => sum + current.totalPrice, 0);
    } else {
      return 0;
    }
  }
  formatCurrency(num: number) {
    console.log('format = ' + num)
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  async created() {
    if (this.$route.query.req) {
      this.confirmationViewModelGuid = this.$route.query.req.toString();
    }
    if (this.$route.query.new) {
      this.showThankYou =
        this.$route.query.new.toString().toLowerCase() === "true";
    }
    this.model = await getConfirmation(this.confirmationViewModelGuid);
    this.property = await getProperty(this.model.propertyGuid);
    this.scrollToTop();
  }

  scrollToTop() {
    const myDiv = document.getElementById("book-wrap");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  phoneFormatter(value: string) {
    const result = StringUtil.formatPhoneNumber(value);
    return result;
  }
}
export default Confirmation;
