
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "../Auth/User";
import { getVerificationStatus } from "../Templates/EmailService";
import { getBookableUnits } from "../Units/UnitService";
const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({})
export default class Dashboard extends Vue {
  private isTest = location.href.includes("localhost");
  private loading = true;
  private checks = Array<string>();
  private verificationStatus = "Success";
  private timer = 100;

  @PropertyStore.State
  public property!: Property;

  @Auth.State("user")
  private currentUser!: User;

  async getBaseUrl() {
    "https://book.lodgevault.com?property=" + this.property.propertyGuid;
  }

  async onlineBookingsEnabled() {
    let bookableUnits = await getBookableUnits(this.property.propertyGuid);
    return bookableUnits && bookableUnits.length > 0;
  }

  logoIsValid() {
    if (
      this.property.logo &&
      this.property.logo.indexOf(Guid.emptyGuid().toString()) == -1
    ) {
      return true;
    }
    return false;
  }

  // poller for verifivation status
  async getVerificationStatus() {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      this.verificationStatus = await getVerificationStatus(
        this.property.email
      );
      if (this.verificationStatus == "Success") {
        this.abortTimer();
      }
    }, this.timer);
  }

  abortTimer() {
    clearTimeout(this.timer);
  }

  navigate(page: string) {
    this.$router.push({ path: page });
  }

  async created() {
    this.loading = true;
  }
  async mounted() {
    this.loading = true;
    await this.getVerificationStatus();
    this.loading = false;
  }

  checkIsUnhealthy(check: string) {
    return check.toLowerCase().startsWith("unhealthy");
  }
  checkIsHealthy(check: string) {
    return check.toLowerCase().startsWith("success");
  }
}
