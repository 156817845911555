
import { Component, Vue, Prop } from "vue-property-decorator";
import NodeTree from "./NodeTree.vue";

@Component({ components: { NodeTree } })
export default class Tree extends Vue {
  @Prop({ default: null }) public treeData!: any;

  async mounted() {
    let tree = document.getElementById("tree") as HTMLElement;
    if (tree) {
      tree.addEventListener("mousedown", this.handleBoardMouseDown);
    }
  }

  private async handleBoardMouseDown(e: MouseEvent | TouchEvent) {
    const evt = e as MouseEvent;
  }
}
