import CartItem from "../components/Cart/CartItem";

export default class Billing {

    constructor() {
        this.firstName = '';
        this.lastName = '';
    }
    public propertyGuid!: string;
    public firstName!: string;
    public lastName!: string;
    public billingAddress!: string;
    public billingAddressStreetLine2!: string;
    public billingCity!: string;
    public billingState!: string;
    public billingPostalCode!: string;
    public billingCountry!: string;
    public phone!: string;
    public email!: string;
    public nameOnCard!: string;
    public comments!: string;
    public cancellationPolicy!: string;
    public acceptTerms!: boolean;
    public depositAmount!: number;
    public total!: number;
    public leadSource!: string;
    public source: string = "Marketplace"; // or "BookNow"
    public isTestMode!: boolean;
    public acceptsAmex!: boolean;
    public billingCurrency!: string;
    public fee!: number;
    public token!: string;
    public cartItems!: Array<CartItem>;
    get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }

}