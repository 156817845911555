
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatPercentage, formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Account } from "./Account";
import AccountEdit from "./AccountEdit.vue";
import AccountModal from "./AccountModal.vue";
import { deleteAccount, getAccounts, saveAccount } from "./AccountService";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { AccountEdit } })
export default class AccountTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  private mode = "reservation";
  private loading = true;
  private showAccountModal = false;
  private accounts: Array<Account> = [];
  private accountFields: Array<Field> = [];
  private clickedAccount!: Account | null;
  private accountTable: any;
  private selected!: Array<any>;

  async add() {
    this.clickedAccount = null;
    this.showAccountModal = true;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getTaxRateFormat(amount: number) {
    return formatPercentage(amount, false);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.accountTable.selectAllRows();
  }
  clearSelected() {
    this.accountTable.clearSelected();
  }

  async saved(account: Account) {
    this.loading = true;
    // if the list doesn't contain the account, add it
    let found = this.accounts.find(
      (a) => a.accountGuid === account.accountGuid
    );
    if (!found) {
      await this.accounts.push(account);
    }
    let index = await this.accounts.findIndex(
      (a) => a.accountGuid === account.accountGuid
    );
    this.accounts[index] = account;
    await this.accountTable.refresh();
    this.accountTable.selectRow(index);
    this.showAccountModal = false;
    this.loading = false;
  }

  private getAccountFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Account Name";
    this.accountFields.push(field);

    field = new Field();
    field.key = "number";
    field.label = "Account Number";
    this.accountFields.push(field);

    for (let i = 0; i <= 10; i++) {
      if (
        this.property.preference &&
        this.property.preference["tax" + i.toString() + "Label"] &&
        this.property.preference["tax" + i.toString() + "Label"] != ""
      ) {
        field = {
          key: "tax" + i.toString() + "Rate",
          label: this.property.preference[
            "tax" + i.toString() + "Label"
          ] as string,
          sortable: true,
          tdClass: "",
        };
        this.accountFields.push(field);
      }
    }
  }

  async mounted() {
    this.loading = true;
    this.accountTable = this.$refs.accountTableRef;
    this.getAccountFields();
    if (this.property && this.property.propertyGuid) {
      this.accounts = await getAccounts(this.property.propertyGuid);
    } else {
      this.accounts = new Array<Account>();
    }
    this.loading = false;
  }

  getActiveAccount() {
    if (this.clickedAccount) {
      return this.clickedAccount.accountGuid;
    } else {
      return "";
    }
  }

  async deleteAccount() {
    // put up warning confirmation first?
    if (this.selected && this.selected.length > 0) {
      let deleted = this.selected[0];
      let result = await deleteAccount(deleted.accountGuid.toString());
      if (result) {
        if (this.accounts) {
          let index = this.accounts.findIndex(
            (a) => a.accountGuid === deleted.accountGuid
          );
          this.accounts.splice(index, 1);
        }
      }
    }

    await this.accountTable.refresh();
  }

  edit(account: any, index: any, event: any) {
    if (account) {
      this.clickedAccount = account;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedAccount = this.selected[0];
    }
    this.showAccountModal = true;
  }

  closeAccountModal() {
    this.showAccountModal = false;
  }
}
