import { axiosRetry } from "@/utils/fetchUtil";


export default class CheckoutSettings {

    constructor() {
        this.isTestMode = true;
        this.hasFuturePackageTrips = false;
        this.showAvailabilityCalendar = true;
        this.showPricesOnGrid = false;
    }
    public propertyGuid!: string | undefined;
    public footerHtml!: string | undefined;
    public headerHtml!: string | undefined;
    public cancellationPolicy!: string | undefined;
    public backgroundColor!: string | undefined;
    public backgroundImage!: string | undefined;
    public theme!: string | undefined;
    public isTestMode!: boolean;
    public marketplaceEnabled!: boolean;
    public usingDesktop!: boolean;
    public showAvailabilityCalendar!: boolean;
    public showArrivalDepartureTimes!: boolean;
    public showPricesOnGrid!: boolean;
    public stripePublishableKey!: string | undefined;
    public stripeConnectAccountId!: string;
    public hasFuturePackageTrips!: boolean;
    public feeType!: FeeType;
    public feeBillingPlan!: FeeBillingPlan;
    public paymentMethod!: PaymentMethod;
    get multipleRates() {
        return this.propertyGuid == "73F2928A-DE2A-460C-AF2F-36C8E5CBC933";
    }
}

export enum FeeType {
    None,
    TwoPercentToTwentyDollars,
    OwnGateway,
    ThreePercentFlat,
    FourAndHalfPercentFlat,
    FourPercentFifteenMax
}

export enum FeeBillingPlan {
    None,
    Withholding,
    Monthly,
    CustomerAddOn, // 4% this is the way it should be for everyone (except maybe Canada where it's illegal)...make the customer pay the fees
    Inclusive // this is the default which means the total payment has the card fees withheld from it by stripe
}

export enum PaymentMethod {
    RequestOnly,
    PayPal,
    AuthorizeNet,
    CyberSource,
    Stripe,
    StripeConnect //only one still active
}

export async function saveCheckoutSettings(checkoutSettings: CheckoutSettings): Promise<boolean> {
    const response = await axiosRetry(
        `/api/property/savecheckoutsettings`,
        checkoutSettings,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the checkout settings from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function getCheckoutSettings(propertyGuid: string): Promise<CheckoutSettings> {
    let url = `/api/property/getcheckoutsettings/${propertyGuid}`;
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<CheckoutSettings>;
    } else {
        const err =
            "An error occurred getting the account from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}