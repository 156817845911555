
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { Guid } from "@/utils/Guid";
import {
  getChargeItem,
  saveChargeItem,
} from "@/components/Cloud/ChargeItems/ChargeItemService";
import { ChargeItem } from "./ChargeItem";
import { Account } from "../Accounts/Account";
import { getAccounts } from "../Accounts/AccountService";
import AccountEdit from "../Accounts/AccountEdit.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { AccountEdit },
})
export default class ChargeItemEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Prop({ default: null }) public chargeItemGuid!: string;
  private showEditModal = false;
  private loading = false;
  public chargeItem = new ChargeItem();
  private busy = false;
  private expanded = false;
  private accounts: Array<Account> = [];
  private showAccountModal = false;

  changedAccount(arg: any) {
    let account = this.accounts.find((a) => a.accountGuid == arg);
    if (account) {
      for (let i = 1; i < 10; i++) {
        this.chargeItem["tax" + i + "Rate"] = account["tax" + i + "Rate"];
      }
    }
  }

  calculateForward() {
    let totalTax = 0;
    for (let i = 1; i < 10; i++) {
      totalTax += this.getTaxTotal(i);
    }
    this.chargeItem.postTaxPrice =
      Number(this.chargeItem.preTaxPrice) + Number(totalTax);
  }
  calculateReverse() {
    let totalTaxPercent = 0;
    for (let i = 1; i < 10; i++) {
      totalTaxPercent += this.chargeItem["tax" + i + "Rate"];
    }
    totalTaxPercent = totalTaxPercent * 0.01;
    this.chargeItem.preTaxPrice = this.round(
      Number(this.chargeItem.postTaxPrice) / (Number(totalTaxPercent) + 1)
    );
  }

  round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
  getTaxTotal(i: number) {
    return (
      this.chargeItem.preTaxPrice * this.chargeItem["tax" + i + "Rate"] * 0.01
    );
  }
  editAccount(accountGuid: string) {
    // edit or add an account
    this.showAccountModal = true;
  }

  validateName() {
    return true;
  }

  public cancel() {
    this.$emit("close");
  }

  getChargeItem() {
    return this.chargeItem;
  }

  async mounted() {
    this.loading = true;
    if (this.chargeItemGuid) {
      this.chargeItemGuid = this.chargeItemGuid;
    } else if (this.$route.params.chargeItemGuid) {
      this.chargeItemGuid = this.$route.params.id.toString();
    } else if (this.$route.query.chargeItemGuid) {
      this.chargeItemGuid = this.$route.query.chargeItemGuid.toString();
    }
    if (this.chargeItemGuid) {
      this.chargeItem = await getChargeItem(this.chargeItemGuid.toString());
    }
    if (
      !this.chargeItem ||
      this.chargeItem.chargeItemGuid == Guid.emptyGuid() ||
      this.chargeItem.chargeItemGuid == undefined
    ) {
      this.chargeItem = new ChargeItem();
      this.chargeItem.chargeItemGuid = Guid.newGuid();
      this.chargeItem.propertyGuid = this.property.propertyGuid;
      this.chargeItem.createdDate = new Date();
    }
    this.accounts = await getAccounts(this.property.propertyGuid);
    this.loading = false;
  }

  async saveAndClose() {
    this.busy = true;
    //const nameTextBox = this.$refs["name-textbox"];
    // this.chargeItem.description = (nameTextBox as any).localValue;
    await saveChargeItem(this.chargeItem).then(() =>
      this.$emit("success", this.chargeItem)
    );
    this.busy = false;
  }
}
