
import { Component, Vue, Prop } from "vue-property-decorator";
import RegisterGuest from "./RegisterGuest.vue";

@Component({
  components: { RegisterGuest },
})
export default class RegisterGuestModal extends Vue {
  name = "";
  nameState: boolean | null | undefined;
  submittedNames = [];

  flipToLogIn() {
    this.$emit("flip-to-login");
  }
  
  checkFormValidity() {
    const valid = true; //this.$refs?.form?.checkValidity();
    this.nameState = valid;
    return valid;
  }
  resetModal() {
    this.name = "";
    this.nameState = null;
  }
  handleOk(bvModalEvent) {
    // Prevent modal from closing
    bvModalEvent.preventDefault();
    // Trigger submit handler
    this.handleSubmit();
  }
  handleSubmit() {
    // Exit when the form isn't valid
    if (!this.checkFormValidity()) {
      return;
    }
    // Push the name to submitted names
    // Hide the modal manually
    this.$nextTick(() => {
      this.$bvModal.hide("modal-guest-register");
    });
  }
}
