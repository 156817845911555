
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { getRatePlanLabel, Rate } from "./Rate";
import RateEdit from "./RateEdit.vue";
import RateModal from "./RateModal.vue";
import { deleteRate, getRates, getRatesByUnit, saveRate } from "./RateService";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { RateEdit } })
export default class RateTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: null }) public unitGuid!: string;
  @Prop({ default: false }) public abbreviated!: boolean;
  private mode = "reservation";
  private loading = true;
  private showRateModal = false;
  private rates: Array<Rate> = [];
  private rateFields: Array<Field> = [];
  private clickedRate!: Rate | null;
  private rateTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;

  getReadablePlan(rate: Rate) {
    return getRatePlanLabel(rate);
  }

  async add() {
    this.clickedRate = null;
    this.showRateModal = true;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.rateTable.selectAllRows();
  }
  clearSelected() {
    this.rateTable.clearSelected();
  }

  async saved(rate: Rate) {
    this.loading = true;
    // if the list doesn't contain the rate, add it
    let found = this.rates.find((a) => a.rateGuid === rate.rateGuid);
    if (!found) {
      await this.rates.unshift(rate);
    }
    let index = await this.rates.findIndex((a) => a.rateGuid === rate.rateGuid);
    this.rates[index] = rate;
    await this.rateTable.refresh();
    this.rateTable.selectRow(index);
    if (this.unitGuid) {
      this.rates = await getRatesByUnit(this.unitGuid);
    }

    this.showRateModal = false;
    this.loading = false;
    this.$emit("rateschanged");
  }

  private getRateFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    this.rateFields.push(field);

    if (this.abbreviated == true) {
      field = new Field();
      field.key = "plan";
      field.label = "Plan";
      this.rateFields.push(field);

      field = new Field();
      field.key = "baseRate";
      field.label = "Base Rate";
      this.rateFields.push(field);
    } else {
      field = new Field();
      field.key = "accountName";
      field.label = "Account Name";
      this.rateFields.push(field);

      field = new Field();
      field.key = "description";
      field.label = "Description";
      this.rateFields.push(field);

      field = new Field();
      field.key = "enabledOnline";
      field.label = "Enabled Online";
      this.rateFields.push(field);

      field = new Field();
      field.key = "plan";
      field.label = "Plan";
      this.rateFields.push(field);

      field = new Field();
      field.key = "baseRate";
      field.label = "Base Rate";
      this.rateFields.push(field);
    }
  }

  async mounted() {
    this.loading = true;
    this.rateTable = this.$refs.rateTableRef;
    this.getRateFields();

    if (this.unitGuid) {
      this.rates = await getRatesByUnit(this.unitGuid);
    } else if (this.property && this.property.propertyGuid) {
      this.rates = await getRates(this.property.propertyGuid);
    } else {
      this.rates = new Array<Rate>();
    }
    this.loading = false;
  }

  getActiveRate() {
    if (this.clickedRate) {
      return this.clickedRate.rateGuid;
    } else {
      return "";
    }
  }

  async deleteRate() {
    // put up warning confirmation first?
    if (this.selected && this.selected.length > 0) {
      let deleted = this.selected[0];
      let result = await deleteRate(deleted.rateGuid.toString());
      if (result) {
        if (this.rates) {
          let index = this.rates.findIndex(
            (a) => a.rateGuid === deleted.rateGuid
          );
          this.rates.splice(index, 1);
        }
      }
    }

    await this.rateTable.refresh();
  }

  edit(rate: any, index: any, event: any) {
    if (rate) {
      this.clickedRate = rate;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedRate = this.selected[0];
    }
    this.showRateModal = true;
  }

  closeRateModal() {
    this.showRateModal = false;
  }
}
