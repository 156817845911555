import { IDrawable } from "./IDrawable";
import { Point } from "./Point";

export class DrawableLine implements IDrawable {
    public upperLeftCorner = new Point(0, 0);
    public width = 0;
    public height = 0;
    public zIndex = "1";
    public updateWidth(currentWidth: number) {
        throw new Error("Method not implemented.");
    }
    public updatePos(newUpperLeftCornerX: number, y: number) {
        throw new Error("Method not implemented.");
    }
    public updateHeight(currentHeight: number) {
        throw new Error("Method not implemented.");
    }
    public id!: string;
    public isDirty!: boolean;
    public startPoint!: Point;
    public endPoint!: Point;
    public fillColor!: string;
    public thickness!: number;
    public cssId!: string;

    public loadPoints(): string {
        return `${this.startPoint.x},${this.startPoint.y} ${this.endPoint.x},${this.endPoint.y}`;
    }
}