import { render, staticRenderFns } from "./UnitGroupEdit.vue?vue&type=template&id=2fa74899&scoped=true&"
import script from "./UnitGroupEdit.vue?vue&type=script&lang=ts&"
export * from "./UnitGroupEdit.vue?vue&type=script&lang=ts&"
import style0 from "./UnitGroupEdit.vue?vue&type=style&index=0&id=2fa74899&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa74899",
  null
  
)

export default component.exports