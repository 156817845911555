
import ComboBox from "@/components/Controls/ComboBox.vue";
import Property from "@/models/Property";
import {
  getTemplate,
  Template,
  saveTemplate,
  getMergedTemplate,
  TemplateKinds,
  replacePropertyFields,
} from "@/models/Template";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Activity, ActivityKind } from "../Activity/Activity";
import { getActivity, saveActivity } from "../Activity/ActivityService";
import { User } from "../Auth/User";
import Editor from "../Editor/Editor.vue";
import { Invoice } from "../Invoices/Invoice";
import {
  sendEmail,
  SendEmailParams,
  updateVerificationEmailTemplate,
} from "../Templates/EmailService";
import { Person } from "../People/Person";
import { Reservation } from "../Reservations/Reservation";
import { Document } from "../Documents/Document";
import { saveDocument, uploadDocument } from "../Documents/DocumentService";
import { Charge } from "../Invoices/Charge";
import { getInvoice } from "../Invoices/InvoiceService";
import { formatter } from "@/utils/currencyUtils";
import { getReservation } from "../Reservations/ReservationService";
import { PersonInReservation } from "../Reservations/PersonInReservation";
import { GroupMemberStatus } from "../Reservations/ResStatus";
const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({
  components: { Editor, ComboBox },
})
export default class TemplateEdit extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Auth.State("user")
  private currentUser!: User;
  @Prop({ default: "edit" }) public mode!: string; //edit, print,  or send
  @Prop({ default: null }) public templateGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public invoiceGuid!: string;
  @Prop({ default: null }) public sendToEmailAddress!: string;
  @Prop({ default: null }) public activityGuid!: string;
  private personMergeFields: Array<string> = [];
  private invoiceItemMergeFields: Array<string> = [];
  private groupMergeFields: Array<string> = [];
  private reservationMergeFields: Array<string> = [];
  private invoiceMergeFields: Array<string> = [];
  private propertyMergeFields: Array<string> = [];
  private selectedMergeField: string = "";
  private template = new Template();
  private activity!: Activity;
  private saved = false;
  private showOverlay = false;
  private testEmailAddress = "support@lodgevault.com";
  private headerTag = "";
  private headerTags = ["p", "h1", "h2", "h3", "h4", "h5", "h6"];
  private sendTo = "";
  private ccTo = "";
  private bccTo = "";
  private subject = "";
  private takenBy = "";
  private focused_font = "Arial";
  private focused_font_size = "15px";
  private sendErrors = new Array<string>();

  private available_fonts = [
    "Arial",
    "Brush Script MT",
    "Courier New",
    "Garamond",
    "Georgia",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
  ];
  private available_font_sizes = [
    "10px",
    "15px",
    "20px",
    "25px",
    "30px",
    "40px",
  ];

  private iframeWin!: any;
  iframeDoc!: any;
  iframeBody!: any;
  timer = 1000;
  inited = false;
  cache = "";
  view = "design";
  fileContent = "";
  fullscreen = false;
  private showAddTableParams = false;
  private rows: number = 3;
  private columns: number = 4;
  private loading = false;
  private showImageUploadControl = false;
  private invoice!: Invoice;
  private reservation!: Reservation;

  @Watch("fileContent", { immediate: true })
  async onContentChanged(newFileContent: string, oldFileContent: string) {
    if (this.inited) {
      if (this.iframeDoc.firstChild.outerHTML !== newFileContent) {
        if (
          this.template.kind == TemplateKinds.Sample ||
          this.template.kind == TemplateKinds.Business
        ) {
          this.clearIframe();
          let html = await replacePropertyFields(newFileContent, this.property);
          this.iframeDoc.write(html);
          this.addEventHandlers();
        } else {
          this.clearIframe();
          this.iframeDoc.write(newFileContent);
          this.addEventHandlers();
        }
        if (this.mode == "send" || this.mode == "print") {
          this.mergeInvoiceTable();
          this.mergeGroupTable();
        }
      }
      this.view === "design";
    } else {
      this.cache = newFileContent;
    }
  }
  @Watch("selectedMergeField", { immediate: true })
  onMergeFieldSelected(val: string, oldValue: string) {
    if (this.inited) {
      //insert a field into the body of the template
      this.insertHTML("{{" + val + "}}");
    }
  }

  clearIframe(){
          this.iframeDoc.open();
          this.iframeDoc.close();
  }

  setFocusOnIframe() {
    this.iframeWin.focus();
  }

  // poller for printing
  async print() {
    this.timer = setTimeout(async () => {
      if (this.inited) {
        window.document.title = this.template.name;
        this.iframeWin.print();
        if (this.mode == "print") {
          this.close();
        }
        // this.abortTimer();
      }
    }, this.timer);
  }
  abortTimer() {
    clearTimeout(this.timer);
  }

  showFileUpload() {
    this.showImageUploadControl = true;
  }

  async imageUploaded(file: File) {
    this.loading = true;
    var formData = new FormData();
    let doc = new Document();
    doc.documentGuid = Guid.newGuid();
    doc.propertyGuid = this.property.propertyGuid;
    doc.createdDate = new Date();
    doc.modifiedDate = new Date();
    doc.path = file.name;
    await saveDocument(doc);
    formData.append("files", file, doc.documentGuid + ".jpg");
    await uploadDocument(formData);
    let src =
      "https://s3.us-east-2.amazonaws.com/service.lodgevault/" +
      doc.documentGuid +
      ".jpg"; // what about gifs and pngs??
    this.insertHTML("<img src='" + src + "'></img>");
    this.showImageUploadControl = false;
    this.loading = false;
  }

  async showTablePopover() {
    this.showAddTableParams = true;
  }

  async addTable() {
    this.showAddTableParams = false;
    let colHtml = "<td style='border: 1px solid;padding:10px;'></td>";
    let headerColHtml = "<th style='border: 1px solid;padding:10px;'></th>";
    let colsHtml = "";
    let headerColsHtml = "";
    let rowsHmtl = "";
    for (let i = 0, len = this.columns; i < len; i++) {
      colsHtml = colsHtml + colHtml;
      headerColsHtml = headerColsHtml + headerColHtml;
    }
    for (let i = 0, len = this.rows; i < len; i++) {
      if (i == 0) {
        rowsHmtl = rowsHmtl + "<tr>" + headerColsHtml + "</tr>";
      } else {
        rowsHmtl = rowsHmtl + "<tr>" + colsHtml + "</tr>";
      }
    }
    this.insertHTML(
      "<table style='border-collapse: collapse;'>" + rowsHmtl + "</<table>"
    );
  }

  fontSelected(arg: any) {
    this.addTag(
      "p",
      "font-family: " + arg + "; font-size: " + this.focused_font_size + ";"
    );
  }
  fontSizeSelected(arg: any) {
    this.addTag(
      "p",
      "font-family: " + this.focused_font + "; font-size: " + arg + ";"
    );
  }

  selectHeading(arg: any) {
    this.addTag(arg, "");
  }

  hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  rgbToHex(r: any, g: any, b: any) {
    return (
      "#" +
      this.componentToHex(r) +
      this.componentToHex(g) +
      this.componentToHex(b)
    );
  }

  foreColorSelected(arg: any) {
    let rgbColor = this.hexToRgb(arg);
    if (rgbColor) {
      this.addTag(
        "span",
        "color: rgb(" +
          rgbColor.r +
          ", " +
          rgbColor.g +
          ", " +
          rgbColor.b +
          ");"
      );
    }
  }

  backgroundColorSelected(arg: any) {
    let rgbColor = this.hexToRgb(arg);
    if (rgbColor) {
      this.addTag(
        "span",
        "background-color: rgb(" +
          rgbColor.r +
          ", " +
          rgbColor.g +
          ", " +
          rgbColor.b +
          ");"
      );
    }
  }

  align(direction: string) {
    let selection = this.iframeWin.getSelection();
    let range = this.getRange();
    this.addTag("p", "text-align: " + direction + ";");
  }

  getHTMLOfSelection() {
    var range;
    if (this.iframeWin.getSelection()) {
      var selection = this.iframeWin.getSelection();
      if (selection.rangeCount > 0) {
        range = selection.getRangeAt(0);
        var clonedSelection = range.cloneContents();
        var div = document.createElement("div");
        div.appendChild(clonedSelection);
        return div.innerHTML;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getSelection() {
    if (this.iframeWin.getSelection()) {
      return this.iframeWin.getSelection();
    }
  }

  getRange() {
    let sel = this.getSelection();
    let range;
    if (sel && sel.rangeCount !== 0) {
      range = sel.getRangeAt(0);
    }
    return range;
  }

  isExpandedMode() {
    return this.$route.name?.toLowerCase() == "templateedit";
  }

  expand() {
    // navigate to invoice edit page with invoice guid as param
    this.navigate("TemplateEdit", this.template.templateGuid.toString());
  }

  navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }

  removeFormat(name, value) {
    // this.iframeDoc.execCommand(name, false, value);
    let range = this.getRange();
    if (!range) return;
    let container = range.commonAncestorContainer;
    container.nodeType === 3 && (container = container.parentNode);
    container.tagName.toLowerCase() === "span" &&
      (container = container.parentNode);
    this.formatContent(container, "span", "verticalAlign");
    container.normalize();
  }

  formatContent(obj: any, tagName: string, cssName: string) {
    let temp: any = [];
    let pattern = {
      fontSize: /font-size:\s?\d+px;/g,
      verticalAlign: /vertical-align:\s?(sub|super);/g,
    };
    let nodeList = obj.getElementsByTagName(tagName);
    for (let i = 0; i < nodeList.length; i++) {
      let node: any = nodeList[i];
      if (
        node.attributes.length === 1 &&
        node.style.length !== 0 &&
        node.getAttribute("style").match(pattern[cssName]) != null
      ) {
        if (node.children.length === 0) {
          if (node.style.length === 1) {
            let parent = node.parentNode;
            parent.replaceChild(document.createTextNode(node.innerHTML), node);
            parent.normalize();
            i--;
          } else {
            node.style[cssName] = "";
          }
        } else {
          temp.push(node);
        }
      }
    }
    if (temp.length !== 0) {
      this.formatContent(obj, tagName, cssName);
    }
  }

  formatBlock(name, value) {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.match(/rv:([\d.]+)\) like gecko/) || ua.match(/msie ([\d.]+)/)) {
      let range = this.getRange();
      if (!range || range.collapsed) {
      } else {
        this.insertHTML("<" + value.toUpperCase() + ">");
      }
    } else {
      // this.iframeDoc.execCommand('formatblock', false, value)
    }
  }

  addTag(val: string, css: string) {
    let range = this.getRange();
    let html = "";
    if (!range || range.collapsed) {
      html = "<" + val.toUpperCase() + ">";
    } else {
      let style = css ? " style='" + css + "'" : "";
      html =
        "<" +
        val.toUpperCase() +
        style +
        ">" +
        this.getSelection() +
        "</" +
        val.toUpperCase() +
        ">";
    }
    this.insertHTML(html);
  }

  async addEventHandlers() {
    this.iframeDoc.addEventListener(
      "click",
      () => {
        this.iframeDoc.dispatchEvent(new window.Event("selectionchange"));
      },
      false
    );
    this.iframeBody.addEventListener("keydown", this.keydownHandler, false);
    this.iframeDoc.addEventListener("keyup", this.keyupHandler, false);
  }

  keydownHandler(event) {
    if (event.ctrlKey && (event.keyCode === 89 || event.keyCode === 90)) {
      event.preventDefault();
    }
  }

  keyupHandler(event) {
    this.updateFileContentAfterCommand();
  }

  updateFileContentAfterCommand() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.fileContent = this.iframeDoc.firstChild.outerHTML;
      console.log(this.fileContent.length);
    }, 50);
  }

  async init(event: any) {
    this.iframeWin = event.target.contentWindow;
    if (!this.iframeWin || !this.iframeWin.document) {
      return;
    }
    this.iframeDoc = this.iframeWin.document;
    this.iframeBody = this.iframeWin.document.body;
    this.inited = true;
    this.iframeDoc.designMode = "on";
    this.inited = true;
    await this.addEventHandlers();
  }

  insertHTML(value) {
    let sel = this.getSelection();
    let range = this.getRange();
    if (!sel || !range) return;
    range.deleteContents();
    let node!: any;
    let frag = this.iframeDoc.createDocumentFragment();
    let obj = this.iframeDoc.createElement("div");
    obj.innerHTML = value;
    while (obj.firstChild) {
      node = obj.firstChild;
      frag.appendChild(node);
    }
    if (!node) {
      return;
    }
    range.insertNode(frag);
    this.updateFileContentAfterCommand();
  }

  async mounted() {
    //get the template
    this.showOverlay = true;
    if (this.$route.query.templateGuid) {
      this.template = await getTemplate(
        this.$route.query.templateGuid.toString()
      );
    } else if (this.$route.params.templateguid) {
      this.template = await getTemplate(this.$route.params.id.toString());
    } else if (this.templateGuid) {
      if (this.invoiceGuid) {
        this.invoice = await getInvoice(this.invoiceGuid);
      }

      if (this.personGuid) {
        this.template = await getMergedTemplate(
          this.property.propertyGuid,
          this.templateGuid,
          this.reservationGuid,
          this.personGuid,
          this.invoiceGuid
        );
      } else {
        this.template = await getTemplate(this.templateGuid);
      }
    } else if (this.activityGuid) {
      this.activity = await getActivity(this.activityGuid);
    } else {
      this.template = new Template();
      this.template.templateGuid = Guid.newGuid();
      this.template.name = "";
      this.template.kind = TemplateKinds.Business;
      this.template.description = "";
      this.template.propertyGuid = this.property.propertyGuid;
    }
    if (this.mode == "send" || this.mode == "print") {
      this.sendTo = this.sendToEmailAddress;
      this.ccTo = "";
      this.bccTo = "";
      if (this.activity) {
        this.takenBy = this.activity.takenBy;
        this.subject = this.activity.notes;
      } else {
        this.takenBy = this.currentUser.normalizedUserName;
        this.subject = this.template.emailSubject;
      }
    } else {
      await this.addMergeFields();
    }
    if (this.activity && this.activity.fileContent) {
      this.fileContent = await this.activity.fileContent;
    } else {
      this.fileContent = await this.template.fileContent;
      if (this.mode == "send" || this.mode == "print") {
        this.fileContent = await replacePropertyFields(
          this.fileContent,
          this.property
        );
      }
    }

    this.showOverlay = false;

    if (this.mode == "print") {
      this.print();
    }
  }

  async mergeInvoiceTable() {
    let table = this.iframeDoc.getElementById(
      "invoice-table"
    ) as HTMLTableElement;
    if (table && this.invoice) {
      let headerRow = table.rows[1];
      if (headerRow) {
        let headerCells = headerRow.cells;
        table.rows[1].remove();
        this.invoice.items = [];
        for (const charge of this.invoice.charges) {
          this.invoice.items.push(charge);
        }
        for (const payment of this.invoice.payments) {
          this.invoice.items.push(payment);
        }
        for (const item of this.invoice.items) {
          var row = table.insertRow();
          for (const cell of headerCells) {
            let cell1 = row.insertCell();
            let field = cell.innerText
              .replace("{{", "")
              .replace("charge.", "")
              .replace("payment.", "")
              .replace("}}", "");
            let isMoney = typeof item[field] === "number";
            let isDate = new Date(item[field]).isDate();
            if (isMoney && field !== "quantity") {
              cell1.innerText = this.formatCurrency(item[field]);
              cell1.style.textAlign = "right";
            } else if (isDate) {
              cell1.innerHTML = this.formatDate(item[field]);
            } else {
              if (item[field]) {
                cell1.innerHTML = item[field];
                cell1.style.textAlign = cell.style.textAlign;
              }
            }
            cell1.style.border = cell.style.border;
            cell1.style.padding = cell.style.padding;
            cell1.style.backgroundColor = cell.style.backgroundColor;
            cell1.style.height = cell.style.height;
          }
        }
      }
    }
    await this.updateFileContentAfterCommand();
  }

  async mergeGroupTable() {
    let table = this.iframeDoc.getElementById(
      "group-table"
    ) as HTMLTableElement;
    if (table) {
      if (this.reservationGuid && !this.reservation) {
        this.reservation = await getReservation(this.reservationGuid);
      }

      let headerRow = table.rows[1];
      if (headerRow && this.reservation) {
        let headerCells = headerRow.cells;
        table.rows[1].remove();
        for (const groupMember of this.reservation.personInReservations) {
          var row = table.insertRow();
          for (const cell of headerCells) {
            let cell1 = row.insertCell();
            let field = cell.innerText
              .replace("{{", "")
              .replace("groupMember.", "")
              .replace("}}", "");
            let isMoney = typeof groupMember[field] === "number";
            let isDate = new Date(groupMember[field]).isDate();
            if (field == "status") {
              let statusStr: string = GroupMemberStatus[groupMember[field]];
              if (statusStr.toLowerCase() != "none") {
                cell1.innerHTML = statusStr.toString();
              }
            } else if (isMoney) {
              cell1.innerHTML = this.formatCurrency(groupMember[field]);
              cell1.style.textAlign = "right";
            } else if (isDate) {
              cell1.innerHTML = this.formatDate(groupMember[field]);
            } else {
              cell1.innerHTML = groupMember[field];
              cell1.style.textAlign = cell.style.textAlign;
            }
            cell1.style.border = cell.style.border;
            cell1.style.padding = cell.style.padding;
            cell1.style.backgroundColor = cell.style.backgroundColor;
            cell1.style.height = cell.style.height;
          }
        }
      }
    }
    await this.updateFileContentAfterCommand();
  }

  formatDate(dateTo: Date) {
    if (new Date(dateTo).isMinDate()) {
      return "";
    }
    return new Date(dateTo).readableDateLocalized();
  }

  formatCurrency(num: number) {
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    if (result == "undefined") {
      result = "";
    }
    return result;
  }

  async addMergeFields() {
    let charge = new Charge();
    await Object.entries(charge).forEach(([key, value]) => {
      this.invoiceItemMergeFields.push("charge." + key);
    });
    let groupMember = new PersonInReservation();
    await Object.entries(groupMember).forEach(([key, value]) => {
      this.groupMergeFields.push("groupMember." + key);
    });
    let person = new Person();
    await Object.entries(person).forEach(([key, value]) => {
      this.personMergeFields.push("person." + key);
    });
    let res = new Reservation();
    await Object.entries(res).forEach(([key, value]) => {
      this.reservationMergeFields.push("reservation." + key);
    });
    let invoice = new Invoice();
    await Object.entries(invoice).forEach(([key, value]) => {
      this.invoiceMergeFields.push("invoice." + key);
    });
    await Object.entries(this.property).forEach(([key, value]) => {
      this.propertyMergeFields.push("property." + key);
    });
  }

  async save() {
    this.saved = false;
    this.showOverlay = true;
    if (this.mode == "edit") {
      this.template.fileContent = this.fileContent;
      await saveTemplate(this.template);
    }
    if (
      this.template.name == "Email Verification" &&
      this.template.kind == TemplateKinds.SiteAdmin
    ) {
      await updateVerificationEmailTemplate();
    }
    await this.$emit("success", this.template);
    this.saved = true;
    if (this.isExpandedMode()) {
      this.navigate("Templates", "");
    }
    this.showOverlay = false;
  }

  async cancel() {
    this.saved = false;
    this.showOverlay = true;
    await this.$emit("closetemplate");
    this.saved = true;
    this.showOverlay = false;
  }
  async close() {
    this.saved = false;
    this.showOverlay = true;
    await this.$emit("closetemplate");
    this.saved = true;
    this.showOverlay = false;
  }

  async sendEmailTemplate() {
    this.showOverlay = true;
    this.sendErrors = [];
    let params = new SendEmailParams();
    params.subject = this.subject;
    params.recipientEmail = this.sendTo;
    params.recipientFirstName = "";
    params.fromEmail = this.property.email;
    params.fromName = this.property.name;
    params.body = this.fileContent;
    params.CC = this.ccTo;
    params.BCC = this.bccTo;
    let response = await sendEmail(params);
    if (response && response == "Email Sent Successfully") {
      let activity = new Activity();
      activity.activityGuid = Guid.newGuid();
      activity.personGuid = this.personGuid;
      activity.reservationGuid = this.reservationGuid;
      activity.propertyGuid = this.property.propertyGuid;
      activity.createdDate = new Date();
      activity.fileContent = this.fileContent;
      activity.description = this.template.name + " emailed to guest";
      activity.takenBy = this.currentUser.fullname;
      activity.notes =
        "Email sent - " +
        this.template.name +
        " - " +
        this.template.description;
      activity.kind = ActivityKind.Email;
      await saveActivity(activity);
    } else if (response) {
      this.sendErrors.push("send failed");
    }

    await this.close();
    this.showOverlay = false;
  }
}
