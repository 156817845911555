
import ContextMenu from "@/components/ContextMenu/ContextMenu.vue";
import { IPopoverOptions } from "@/components/Popover/IPopoverOptions";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: { ContextMenu },
})
export default class AddResContextMenu extends Vue {
  @Prop() public popoverOptions!: IPopoverOptions;
  private assignGuid!: string;

  async mounted() {
    this.assignGuid =
      this.popoverOptions?.popoverReference?.attributes?.ref?.nodeValue;
  }
  checkIn() {
    this.$emit("checkin", this.assignGuid);
  }

  addRes(){
    this.$emit("showrates");
  }
}
