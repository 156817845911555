
import { Assign } from '@/components/Cloud/Assign/Assign';
import { GlobalSearchParameters } from '@/models/GlobalSearchParameters';
import { ConfirmationViewModel } from '@/models/ConfirmationViewModel';
import { GridAssign } from '@/models/GridAssign';
import { PagingParameters } from '@/models/PagingParameters';
import { PersonInReservation } from '@/components/Cloud/Reservations/PersonInReservation';
import { SearchParams } from '@/models/SearchParams';
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { Reservation } from '@/components/Cloud/Reservations/Reservation';
import { ReservationsPagedModel } from '@/components/Cloud/Reservations/ReservationsPagedModel';
import { ResStatusBackgroundColor } from './ResStatus';

export async function getGlobalSearchResults(propertyGuid: string, searchParams: GlobalSearchParameters): Promise<Array<any>> {
  const response = await axiosRetry(
    `/api/reservation/globalsearch/${propertyGuid}`,
    searchParams,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data;
  } else {
    const err =
      "An error occurred getting the search results from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getReservationsByPerson(personGuid: string): Promise<Array<Reservation>> {
  const response = await axiosRetry(
    `/api/reservation/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Reservation>>;
  } else {
    const err =
      "An error occurred getting the reservations from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getReservationsPaged(propertyGuid: string, pagingParams: PagingParameters): Promise<ReservationsPagedModel> {

  const response = await axiosRetry(
    `/api/reservation/allpaged/${propertyGuid}`,
    pagingParams,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data;
  } else {
    const err =
      "An error occurred getting the reservations from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deleteReservations(reservationsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/reservation/delete`,
    reservationsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the reservations from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveReservation(res: Reservation, updateStatus?: boolean): Promise<Reservation> {

  let str = 'true';
  if (typeof updateStatus !== 'undefined') {
    str = (updateStatus && updateStatus == true) ? 'true' : 'false';
  }
  const response = await axiosRetry(
    `/api/reservation?recalcstatus=${str}`,
    res,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Reservation>;
  }
  else if (response && response.data.error) {
    throw Error(response.data.error);
  }
  else {
    throw Error("save failed");
  }
}

export async function saveNewReservation(res: Reservation): Promise<Reservation> {
  const response = await axiosRetry(
    `/api/reservation/savenewreservation`,
    res,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Reservation>;
  }
  else if (response && response.data.error) {
    throw Error(response.data.error);
  }
  else {
    throw Error("save failed");
  }
}

export async function deleteReservation(reservationGuid: Guid): Promise<any> {
  const response = await axiosRetry(
    `/api/reservation/` + reservationGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<any>;
  }
  else if (response && response.data.error) {
    throw Error(response.data.error);
  }
  else {
    throw Error("Delete failed");
  }
}

export async function getConfirmation(reqReservationGuid: string): Promise<ConfirmationViewModel> {
  const response = await axiosRetry(
    `/api/cart/confirmation?req=${reqReservationGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<ConfirmationViewModel>;
  } else {
    const err =
      "An error occurred saving the group member." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getReservations(propertyGuid: string): Promise<Array<Reservation>> {
  const response = await axiosRetry(
    `/api/reservation/all/${propertyGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Reservation>>;
  } else {
    const err =
      "An error occurred saving the group member." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getReservation(reservationGuid: string): Promise<Reservation> {
  const response = await axiosRetry(
    `/api/reservation/${reservationGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Reservation>;
  } else {
    const err =
      "An error occurred saving the group member." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getGridAssigns(searchParams: SearchParams): Promise<Array<GridAssign>> {
  const response = await axiosRetry(
    `/api/reservation/getgridassigns`,
    searchParams,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<GridAssign>>;
  } else {
    const err =
      "An error occurred saving the group member." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deleteGroupMember(pir: PersonInReservation): Promise<boolean> {
  const response = await axiosRetry(
    `/api/reservation/deletegroupmember`,
    pir,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred saving the group member." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}


export async function getGroup(reservationGuid: string): Promise<Array<PersonInReservation>> {
  const response = await axiosRetry(
    `/api/reservation/group/${reservationGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<PersonInReservation>>;
  } else {
    const err =
      "An error occurred getting the group members." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveGroupMember(pir: PersonInReservation): Promise<boolean> {
  const response = await axiosRetry(
    `/api/reservation/savegroupmember`,
    pir,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred saving the group member." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deleteAssigns(assignGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/reservation/deleteassigns`,
    assignGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the people from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteAssign(assign: Assign): Promise<boolean> {
  let assignGuids = new Array<string>();
  assignGuids.push(assign.assignGuid);
 return deleteAssigns(assignGuids);
}

export async function getAvailableAssigns(propertyGuid: string, inDate: Date, outDate: Date): Promise<Array<Assign>> {
  let searchParams = new SearchParams();
  searchParams.arrivalDate = inDate;
  searchParams.departureDate = outDate;
  searchParams.propertyGuid = propertyGuid;
  const response = await axiosRetry(
    `/api/reservation/getavailableassigns`,
    searchParams,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Assign>>;
  } else {
    const err =
      "An error occurred getting the assign from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getAssign(assignGuid: string): Promise<Assign> {
  const response = await axiosRetry(
    `/api/reservation/getassign/` + assignGuid,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Assign>;
  } else {
    const err =
      "An error occurred getting the assign from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveAssign(assign: Assign): Promise<boolean> {
  const response = await axiosRetry(
    `/api/reservation/saveassign`,
    assign,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the people from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getAssignsByReservation(reservationGuid: string): Promise<Array<Assign>> {
  const response = await axiosRetry(
    `/api/reservation/getassignsbyreservation/${reservationGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Assign>>;
  } else {
    const err =
      "An error occurred getting the assigns from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getAssignsByOutOfService(oosGuid: string): Promise<Array<Assign>> {
  const response = await axiosRetry(
    `/api/reservation/getassignsbyoutofservice/${oosGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Assign>>;
  } else {
    const err =
      "An error occurred getting the assigns from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}




