import { Reservation } from "@/components/Cloud/Reservations/Reservation";

export enum GroupMemberStatus {
    NONE = 0,
    CheckedIn = 4,
    CheckedOut = 6
}

export enum ResStatus {
    Cancelled = 0,
    Pending = 1,
    Confirmed = 2,
    CheckedIn = 4,
    CheckedOut = 6,
    Arriving = 7,
    Departing = 8,
    NoShow = 9
}


export enum ResStatusFontColor {
    Cancelled = "gray",
    Pending = "pink",
    Confirmed = "green",
    CheckedIn = "gray",
    OutOfService = "gray",
    CheckedOut = "gray",
    Arriving = "white",
    Departing = "white",
    NoShow = "red"
}
export enum ResStatusBackgroundColor {
    Cancelled = "gray",
    Pending = "pink",
    Confirmed = "#3deb34",
    CheckedIn = "gray",
    OutOfService = "gray",
    CheckedOut = "gray",
    Arriving = "blue",
    Departing = "green",
    NoShow = "pink"
}

export function getReservationStatusString(reservation: Reservation): string {
    if (!reservation) {
        return '';
    }
    let statusString = "";
    const today = new Date(Date.now()).removeTime();
    switch (reservation.status) {
        case ResStatus.Cancelled:
            statusString = "Cancelled";
            break;
        case ResStatus.CheckedIn:
            if (new Date(reservation.departureDate).areSameDay(today) && reservation.status == ResStatus.CheckedIn) {
                statusString = "Departing";
            } else {
                statusString = "Checked In";
            }
            break;
        case ResStatus.CheckedOut:
            statusString = "Checked Out";
            break;
        case ResStatus.Confirmed:
        case ResStatus.Pending:
            if (new Date(reservation.arrivalDate).areSameDay(today)) {
                statusString = "Arriving";
            } else if (new Date(reservation.arrivalDate).removeTime().valueOf() < today.valueOf() && !new Date(reservation.arrivalDate).isMinDate()) {
                statusString = "No Show";
            } else {
                if (reservation.status == ResStatus.Confirmed) {
                    statusString = "Confirmed";
                } else {
                    statusString = "Pending";
                }
            }
            break;
    }
    return statusString;
}

export function getReservationStatusColorVariant(reservation: Reservation): string {
    return getReservationStatusString(reservation).replace(" ", "").toLowerCase();
}
export function getResStatusColorVariant(status: string): string {
    return status.replace(" ", "").toLowerCase();
}


