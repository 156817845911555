
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({
  components: {},
})
export default class MaskedInput extends Vue {
  @Prop({ default: null }) public initialValue!: string;
  @Prop({ default: "'(___) ___-____'" }) public mask!: string;

  private clean = "";
  private inputBox!: HTMLInputElement;
  private indexes = new Array<number>();
  private value = "";

  @Watch("initialValue", { immediate: true })
  onValueChanged(newVal: string, oldVal: string) {
    if (newVal) {
      this.value = newVal;
      if (this.mask) {
        if (this.value) {
          this.clean = this.value.replace(/[^0-9]/gm, "");
          this.value = this.maskIt(this.clean);
        } else {
          this.value = this.mask;
        }
      } else {
        this.clean = this.value;
      }
      this.$emit("maskedInputChanged", this.clean);
    }
  }
  @Watch("mask", { immediate: true })
  onMaskChanged(newMask: string, oldMask: string) {
    // if the new mask is blank then clean the value and then
    this.clean = this.getOnlyDigits(this.value.toString());
    this.value = this.maskIt(this.clean);
  }

  getOnlyDigits(maskAndDigits: string): string {
    if (maskAndDigits) {
      return maskAndDigits.replace(/[^0-9]/gm, "");
    }
    return "";
  }
  getDigitsAndUnderscores(maskAndDigits: string): string {
    if (maskAndDigits) {
      return maskAndDigits.replace(/[^0-9_]/gm, "");
    }
    return "";
  }

  maskIt(changedVal: string): string {
    let combined = "";
    if (!this.mask) {
      combined = changedVal;
      this.clean = changedVal;
      return combined;
    }
    this.indexes = [];

    for (let i = 0; i < changedVal.length; i++) {
      const digit = parseInt(changedVal[i]);
      if (!isNaN(digit)) {
        this.indexes.push(i);
      }
    }
    let result = "";

    for (let i = 0; i < changedVal.length; i++) {
      if (this.indexes.indexOf(i) == -1) {
        result += changedVal[i];
      }
    }
    if (result == "") {
      result = changedVal;
    }

    for (let i = 0; i < this.mask.length; i++) {
      if (this.mask[i] == "_" && result) {
        combined += result[0];
        result = result.slice(1);
      } else {
        combined += this.mask[i];
      }
    }
    this.clean = combined.replace(/[^0-9]/gm, "");
    return combined;
  }

  async mounted() {
    this.value = this.initialValue;
    if (this.mask) {
      if (this.value) {
        this.clean = this.value.replace(/[^0-9]/gm, "");
        this.value = this.maskIt(this.clean);
      } else {
        this.value = this.mask;
      }
    } else {
      this.clean = this.value;
    }
    this.inputBox = document.getElementById(this.$el.id) as HTMLInputElement;
  }

  putCursorInNextPosition() {
    if (this.mask && this.value) {
      let start = this.value.indexOf("_");
      const segment1 = this.value.indexOf("-_");
      const segment2 = this.value.indexOf(") _");
      if (start == -1) {
        start = this.value.length; //the end
      } else if (segment1 > -1 && segment1 < start) {
        start = segment1;
      } else if (segment2 > -1 && segment2 < start) {
        start = segment2;
      }
      this.inputBox.focus();
      window.setTimeout(() => {
        this.inputBox.setSelectionRange(start, start);
      }, 0);
    }
  }

  handleMousedown(event) {
    if (!this.clean) {
      return;
    }
    if (
      this.clean.length == 0 ||
      event.target.value.replace("_", "").length < this.mask.length
    ) {
      event.preventDefault();
      this.putCursorInNextPosition();
    }
  }

  updateValue(event) {
    let changedValue = event.target.value;
    if (this.mask) {
      changedValue = changedValue.replace(/[^0-9]/gm, "");
      if (event.inputType == "deleteContentBackward" && changedValue) {
        this.clean = event.target.value;
        return;
      }
      if (String(changedValue).length <= 10) {
        this.value = this.maskIt(changedValue);
      }

      this.putCursorInNextPosition();
      this.$forceUpdate();
    } else {
      this.clean = event.target.value;
    }
    this.$emit("maskedInputChanged", this.clean);
  }

  handlePaste() {
    console.log(this.value);
  }
}
