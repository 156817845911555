
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PasswordInput extends Vue {
  @Prop({ default: "" }) public value!: string;
  @Prop({ default: "no" }) public validate!: string; //"enforce" or "no"
  private inputBox!: HTMLInputElement;
  private val = "";
  private passwordFieldType = "password";

  mounted() {
    this.val = this.value;
    this.inputBox = document.getElementById(this.$el.id) as HTMLInputElement;
  }

  validation(): boolean | null {
    if (!this.val) {
      return null;
    }

    let result: boolean | null = null;
    if (this.validate == "enforce") {
      //       RegEx	Description
      // ^	The password string will start this way
      // (?=.*[a-z])	The string must contain at least 1 lowercase alphabetical character
      // (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
      // (?=.*[0-9])	The string must contain at least 1 numeric character
      // (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict
      // (?=.{8,})	The string must be eight characters or longer

      const strongRegex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      result = strongRegex.test(this.val);
    } else {
      // just make sure it's six characters
      result = this.val.trim().length >= 6;
    }
    this.$emit("validate-password", result);
    return result;
  }

  switchVisibility() {
    this.passwordFieldType =
      this.passwordFieldType === "password" ? "text" : "password";
  }

  updateValue(event) {
    this.val = event;
    this.validation();
    this.$emit("input", event);
  }
}
