
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FinanceNavMenu from "../partials/FinanceNavMenu.vue";

const PropertyStore = namespace("PropertyStore");

@Component({
  components: { FinanceNavMenu },
})
export default class Finance extends Vue {
  @PropertyStore.State
  public property!: Property;
  mounted() {
    this.$router.push({ name: "Revenue" });
  }
}
