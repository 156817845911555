
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";

const PropertyStore = namespace("PropertyStore");

@Component({
  components: { ConfigurationNavMenu },
})
export default class Configuration extends Vue {
  @PropertyStore.State
  public property!: Property;
  mounted() {
    this.$router.push({ name: "Units" });
  }
}
