
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { AvailableUnitsViewModel } from "@/models/AvailableUnitsViewModel";
import AvailModelDataService from "@/services/AvailModelDataService";
import DatePicker from "@/components/SiteBook/DatePicker.vue";
import Property from "@/models/Property";
import Lingallery from "./Lingallery.vue";
import { Unit } from "@/components/Cloud/Units/Unit";
import { formatter } from "@/utils/currencyUtils";
import { AvailabilityParameters } from "@/models/AvailabilityParameters";
import { PagingParameters } from "@/models/PagingParameters";
import CartItem, { recalcCartItem } from "@/components/Cart/CartItem";
import { PlanType } from "@/components/Cloud/Rates/PlanType";
import { Rate } from "@/components/Cloud/Rates/Rate";
import { Guid } from "@/utils/Guid";
import { UnitGroup } from "@/models/UnitGroup";
import { getUnitGroups } from "../Cloud/Units/UnitGroupService";
import { getBookableUnits } from "../Cloud/Units/UnitService";

const PropertyStore = namespace("PropertyStore");
const CartStore = namespace("CartStore");
const AvailabilityParametersStore = namespace("AvailabilityParametersStore");
@Component({
  components: { DatePicker, Lingallery },
})
export default class DateSearch extends Vue {
  private pageSize = 6;
  private page = 1;
  private searchTitle = "";
  private availModel = new AvailableUnitsViewModel();
  private gridDays = new Array<Date>();
  private showOverlay = false;
  private indexClicked = -1;
  private showWarning = false;
  private firstPickedDate = new Date();
  private secondPickedDate = new Date();
  public unitGroupList!: Array<UnitGroup>;

  get adultCount() {
    return this.availParams.peopleCount1;
  }
  set adultCount(value: number) {
    this.availParams.peopleCount1 = Number(value);
    this.set(this.availParams).then(() =>
      this.searchParamsChanged(this.firstPickedDate, this.secondPickedDate)
    );
  }
  get childCount() {
    return this.availParams.peopleCount2;
  }
  set childCount(value: number) {
    this.availParams.peopleCount2 = Number(value);
    this.set(this.availParams).then(() =>
      this.searchParamsChanged(this.firstPickedDate, this.secondPickedDate)
    );
  }

  unitDetails(unit: Unit): string {
    if (unit.minimumPeople == 0) {
      unit.minimumPeople = 1;
    }
    if (unit.capacity == 0) {
      unit.capacity = 1;
    }
    let result = "people";
    if (unit.capacity == 1) {
      result = "person";
    }
    if (unit.capacity === unit.minimumPeople) {
      result = unit.capacity + " " + result;
    } else {
      result = unit.minimumPeople + " to " + unit.capacity + " " + result;
    }
    result += ", ";
    if (unit.minimumNights == 0) {
      unit.minimumNights = 1;
    }
    if (unit.maximumNights === unit.minimumNights) {
      result += unit.maximumNights;
    } else {
      result += unit.minimumNights + " to " + unit.maximumNights + " ";
    }

    result += "night";
    if (unit.maximumNights != 1) {
      result += "s";
    }
    return result;
  }

  async created() {
    this.showOverlay = true;
    this.getAvailParams();

    let availParamsChanged = false;
    if (this.$route.query.ArrivalDate) {
      availParamsChanged = true;
      this.availParams.arrivalDate = new Date(
        this.$route.query.ArrivalDate.toString()
      );
    }
      if (this.availParams.arrivalDate >= this.availParams.departureDate ){
        this.availParams.departureDate = this.availParams.arrivalDate.addDays(3);
           availParamsChanged = true;
      }
    if (this.$route.query.NumberOfNights) {
      availParamsChanged = true;
      this.availParams.departureDate = new Date(
        this.availParams.arrivalDate
      ).addDays(Number(this.$route.query.NumberOfNights.toString()));
    }
    if (this.$route.query.PeopleCount1) {
      availParamsChanged = true;
      this.availParams.peopleCount1 = Number(
        this.$route.query.PeopleCount1.toString()
      );
    }
    if (this.$route.query.PeopleCount2) {
      availParamsChanged = true;
      if (
        !this.$route.query.PeopleCount2 ||
        this.$route.query.PeopleCount2.toString() === "" ||
        this.$route.query.PeopleCount2.toString() === "Number of Children" //hack for timber bay
      ) {
        this.availParams.peopleCount2 = 0;
      } else {
        this.availParams.peopleCount2 = Number(
          this.$route.query.PeopleCount2.toString()
        );
      }
    }
    if (availParamsChanged) {
      this.firstPickedDate = this.availParams.arrivalDate;
      this.secondPickedDate = this.availParams.departureDate;
      this.set(this.availParams);
    }

    this.unitGroupList = await getUnitGroups(this.property.propertyGuid);
    this.availModel.units = await getBookableUnits(this.property.propertyGuid);
    this.deselectUnits();
    this.retrieveAvailModel().then(() => {
      this.highlightActiveGroup();
    });
    this.showOverlay = false;
  }

  hasResults() {
    return (
      this.availModel &&
      this.availModel.units &&
      this.availModel.units.length > 0
    );
  }

  handleUnitGroupChange(event: Event, value: string) {
    this.page = 1;
    this.availParams.unitClassGuid = value;
    if (!value) {
      this.availParams.unitClassGuid = Guid.emptyGuid();
    }

    this.set(this.availParams);
    this.retrieveAvailModel();
    this.highlightActiveGroup();
  }

  highlightActiveGroup() {
    const element = document.getElementById(
      "unit-group-div"
    ) as HTMLTextAreaElement;
    if (element) {
      const elements = element.children;
      if (elements) {
        for (const element of elements) {
          element.classList.add("navigation");
          element.classList.remove("current");
          if (element.id === this.availParams.unitClassGuid) {
            element.classList.add("current");
            element.classList.remove("navigation");
          }
        }
      }
      if (this.availParams.unitClassGuid === Guid.emptyGuid()) {
        const allGroups = document.getElementById(
          "all-groups"
        ) as HTMLTextAreaElement;
        allGroups.classList.add("current");
        allGroups.classList.remove("navigation");
      }
    }
  }

  getRateLabel(unitObj: Unit) {
    if (unitObj && unitObj.rates && unitObj.rates.length > 0) {
      const rate = unitObj.rates[0];
      let result = "";
      if (rate) {
        if (rate.seasonObjects && rate.seasonObjects.length > 0) {
          result = this.formatCurrency(rate.seasonObjects[0].baseRate);
        } else {
          result = this.formatCurrency(rate.baseRate);
        }

        result += " " + this.getRatePlanLabel(rate);

        result =
          result +
          " (" +
          this.formatCurrency(rate.priceForSelections) +
          " total)";
        if (rate.seasonObjects && rate.seasonObjects.length > 0) {
          const seasonNames = rate.seasonObjects.map(({ name }) => name);
          result += " " + seasonNames.join("/");
        } else {
          result += " " + rate.name;
        }

        return result;
      }
    }
    return "";
  }

  getRatePlanLabel(rate: Rate) {
    let result = "";
    switch (rate.planType) {
      case PlanType.Monthly:
        result = "per month";
        break;
      case PlanType.Nightly:
        if (rate.rateOption2) {
          result = "(weekend)";
        }
        result = "per night";
        break;
      case PlanType.Weekly:
        result = "per week";
        break;
      case PlanType.PerPerson:
        result = "per person";
        break;
      case PlanType.Special:
        result = "special";
        break;
      case PlanType.PerAdultPerChild:
        result = "per person";
        break;
      case PlanType.OtherTimePeriod:
        result = "special";
        break;
      case PlanType.Hourly:
        result = "special";
        break;
      default:
        result = "special";
        break;
    }
    return result;
  }

  formatCurrency(num: number) {
    return formatter(num, this.availModel.billingCurrency);
  }

  deselectUnits() {
    this.availParams.unitClassGuid = Guid.emptyGuid();
    this.availParams.unitGuid = Guid.emptyGuid();
    this.set(this.availParams);
  }

  handlePageChange(value) {
    this.page = value;
    this.retrieveAvailModel();
  }

  refreshList() {
    this.retrieveAvailModel();
  }

  getAvailParams() {
    if (!this.availParams) {
      const paramsAvail = new AvailabilityParameters();
      this.set(paramsAvail);
    }
    this.firstPickedDate = this.availParams.arrivalDate;
    this.secondPickedDate = this.availParams.departureDate;
  }

  getPagingParams(searchTitle, page, pageSize, propertyGuid) {
    const params = new PagingParameters();

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["CurrentPage"] = page;
    }

    if (pageSize) {
      params["PageSize"] = pageSize;
    }

    if (propertyGuid) {
      params["PropertyGuid"] = propertyGuid;
    }
    return params;
  }

  async retrieveAvailModel() {
    this.showOverlay = true;
    const pagingParams = this.getPagingParams(
      this.searchTitle,
      this.page,
      this.pageSize,
      this.property.propertyGuid
    );
    if (this.availParams && pagingParams) {
      await AvailModelDataService.getAll(this.availParams, pagingParams)
        .then((response) => {
          if (response != null) {
            this.availModel = response;
            this.showWarning =
              !this.availModel.units ||
              (this.availModel.units && this.availModel.units.length === 0);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    this.showOverlay = false;
  }

  searchParamsChanged(start: Date, end: Date) {
    this.firstPickedDate = new Date(start);
    this.secondPickedDate = new Date(end);
    this.availParams.arrivalDate = this.firstPickedDate;
    this.availParams.departureDate = this.secondPickedDate;
    this.availParams.defaultMode = false;
    this.set(this.availParams);

    this.deselectUnits();
    this.retrieveAvailModel();
  }

  rows() {
    return this.availModel?.totalUnitsCount;
  }


  public async onClickButton(unit: Unit, index: number) {
    this.indexClicked = index;

    if (!this.availParams) {
      this.availParams = new AvailabilityParameters();
    }
    this.availParams.unitGuid = unit.unitGuid;
    this.set(this.availParams);

    this.$router.push({
      name: "Book",
      params: {
        unit: unit.unitGuid,
      },
      query: {
        length: this.availParams.nights.toString(),
        date: new Date(this.availParams.arrivalDate).dashedDate(),
      },
    });
  }

  navigate(page: string) {
    this.$router.push({
      name: page,
    });
  }

  @PropertyStore.State
  public property!: Property;

  @AvailabilityParametersStore.State
  private availParams!: AvailabilityParameters;

  @CartStore.Action
  public addCartItem!: (cartItem: CartItem) => void;

  @AvailabilityParametersStore.Action
  public set!: (params: AvailabilityParameters) => Promise<boolean>;
}
