
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({ components: {} })
class ProgressModal extends Vue {
  @Prop({ default: 300 }) public max!: number;
  @Prop({ default: 0 }) public currentvalue!: number;
  private timer = 0;
  private localvalue = 0;

  @Watch("currentvalue", { immediate: true })
  async onChanged(newVal: any, old: any) {
    this.localvalue = newVal;
  }

  async mounted() {
    this.localvalue = this.currentvalue;
  }
}
export default ProgressModal;
