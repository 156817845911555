
import CartItem from '@/components/Cart/CartItem';
import { Charge } from '@/components/Cloud/Invoices/Charge';
import PriceQuote from '@/models/PriceQuote';
import { Unit } from '@/components/Cloud/Units/Unit';
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { Rate } from '../Rates/Rate';
import Property from '@/models/Property';

export async function deleteUnits(unitsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/unit/delete`,
    unitsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the unit from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteUnit(unitGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/unit/` + unitGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the unit from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function saveUnit(unit: Unit): Promise<boolean> {
  const response = await axiosRetry(
    `/api/unit`,
    unit,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the unit from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getBookableUnits(propertyGuid: string): Promise<Array<Unit>> {
  let url = `/api/book/units/${propertyGuid}/true`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );

  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Unit>>;
  } else {
    const err =
      "An error occurred getting the units from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }

}

export async function filterProperties(): Promise<Array<Property>> {
  const response = await axiosRetry("/api/book/filterproperties", null, "GET");
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Property>>;
  } else {
    const err =
      "An error occurred getting the units from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function filterUnits(propertyGuid: string): Promise<Array<Unit>> {
  const response = await axiosRetry(`/api/book/filterunits/${propertyGuid}/`, null, "GET");
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Unit>>;
  } else {
    const err =
      "An error occurred getting the units from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getUnits(propertyGuid: string): Promise<Array<Unit>> {
  let url = `/api/unit/getunits/${propertyGuid}`;
  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Unit>>;
  } else {
    const err =
      "An error occurred getting the unit from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getUnitsByPerson(personGuid: string): Promise<Array<Unit>> {
  const response = await axiosRetry(
    `/api/unit/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Unit>>;
  } else {
    const err =
      "An error occurred getting the unit from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getUnit(unitGuid: string): Promise<Unit> {
  const response = await axiosRetry(
    `/api/unit/getunit/${unitGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Unit>;
  } else {
    const err =
      "An error occurred getting the unit from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function availableDates(unitGuid: string): Promise<Array<Date>> {
  const response = await axiosRetry(
    `/api/book/availabledates/${unitGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Date>>;
  } else {
    const err =
      "An error occurred getting the unit from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getUnitAvailabilityDates(unitGuid: string) {
  const response = await axiosRetry(`/api/book/availability/${unitGuid}`, null, "GET");
  return await response.data;
}

export async function getAvailableRates(cartItem: CartItem): Promise<Array<Rate>> {
  const response = await axiosRetry("/api/book/availablerates", cartItem, "POST");
  return await response.data;
}
export async function getChargesForPriceQuote(priceQuote: PriceQuote): Promise<Array<Charge>> {
  const response = await axiosRetry("/api/book/chargesforpricequote", priceQuote, "POST");
  return await response.data;
}
export async function getAllRatesForUnit(priceQuote: PriceQuote): Promise<Array<Rate>> {
  const response = await axiosRetry("/api/book/ratesforpricequote", priceQuote, "POST");
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Rate>>;
  } else {
    const err =
      "An error occurred getting the unit from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
