
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Amenity } from "./Amenity";
import AmenityEdit from "./AmenityEdit.vue";

@Component({ components: { AmenityEdit } })
class AmenityModal extends Vue {
  @Prop({ default: null }) public amenityGuid!: Guid;
  @Prop({ default: null }) public personGuid!: Guid;
  @Prop({ default: null }) public reservationGuid!: Guid;
  private loading = false;

  async success(amenity: Amenity) {
    this.$emit("success", amenity);
  }
  async close() {
    this.$emit("close");
  }
}
export default AmenityModal;
