import { render, staticRenderFns } from "./CartPopup.vue?vue&type=template&id=029b58b0&scoped=true&"
import script from "./CartPopup.vue?vue&type=script&lang=ts&"
export * from "./CartPopup.vue?vue&type=script&lang=ts&"
import style0 from "./CartPopup.vue?vue&type=style&index=0&id=029b58b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "029b58b0",
  null
  
)

export default component.exports