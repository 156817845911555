
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';

export class TimeSlot {
  constructor() {
  }
  public timeSlotGuid!: string;

  // start and end are timespans in c# so this is a solution where we get a time object
  public startTimeOfDayString: string = "00:00:00";
  public endTimeOfDayString: string = "00:00:00";
  public durationDays: number = 0;
  public propertyGuid!: string;
  public rateGuid!: string;
  public timeSlotCollectionGuid!: string;
  public startDate: Date | undefined;
  public endDate: Date | undefined;
  public disabled = false;
  // need to be populated with number available on chosen date and time
  public available = 1;
}


export async function deleteTimeSlots(timeSlotsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/timeSlot/delete`,
    timeSlotsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the timeSlot from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteTimeSlot(timeSlotGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/timeSlot/` + timeSlotGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the timeSlot from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveTimeSlot(timeSlot: TimeSlot): Promise<boolean> {
  const response = await axiosRetry(
    `/api/timeslot`,
    timeSlot,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the timeSlot from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getTimeSlotsByRate(rateGuid: string): Promise<Array<TimeSlot>> {
  const response = await axiosRetry(
    `/api/timeSlot/getbyrate/${rateGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<TimeSlot>>;
  } else {
    const err =
      "An error occurred getting the timeSlot from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getTimeSlots(rateGuid: string, unitGuid: string, dateFor: Date): Promise<Array<TimeSlot>> {
  let strDate = new Date(dateFor).dashedDate();
  const response = await axiosRetry(
    `/api/timeslot/gettimeslots/${rateGuid}/${unitGuid}/${strDate}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<TimeSlot>>;
  } else {
    const err =
      "An error occurred getting the timeSlot from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getTimeSlotsByCollection(collectionGuid: string): Promise<Array<TimeSlot>> {
  const response = await axiosRetry(
    `/api/timeSlot/getbycollection/${collectionGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<TimeSlot>>;
  } else {
    const err =
      "An error occurred getting the timeSlot from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getTimeSlot(timeSlotGuid: string): Promise<TimeSlot> {
  const response = await axiosRetry(
    `/api/timeSlot/gettimeSlot/${timeSlotGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<TimeSlot>;
  } else {
    const err =
      "An error occurred getting the timeSlot from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}



