
import { Field } from "@/models/interfaces/Field";
import {
  getAssignsByReservation,
  getAvailableAssigns,
  getReservation,
  saveAssign,
} from "@/components/Cloud/Reservations/ReservationService";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Reservation } from "../Reservations/Reservation";
import Property from "@/models/Property";
import AvailableRatesModal from "../Rates/AvailableRatesModal.vue";
import { Invoice } from "../Invoices/Invoice";
import { Assign } from "./Assign";
import PriceQuote from "@/models/PriceQuote";

const AddReservationStore = namespace("AddReservationStore");
const PropertyStore = namespace("PropertyStore");
@Component({ components: { AvailableRatesModal } })
export default class AddAssign extends Vue {
  @PropertyStore.State
  public property!: Property;
  @AddReservationStore.State
  public reservation!: Reservation;
  @AddReservationStore.Action
  public saveNewReservationToStore!: (res: Reservation) => void;
  @Prop({ default: null }) public reservationGuid!: string;
  private loading = true;
  private startDate: Date = new Date();
  private endDate: Date = new Date();
  private assignedAssigns: Array<Assign> = [];
  private availableAssigns: Array<Assign> = [];
  private assignFields: Array<Field> = [];
  private res!: Reservation;
  private assignsCurrentTable: any;
  private assignsAvailTableRef: any;
  private selectedCurrent!: Array<any>;
  private selectedAvail!: Array<Assign>;
  private availableRatesVisible = false;

  async save() {
    // get hte selected avail units and then load them into the Available Rates Modal window
    this.loading = true;
    this.res.assigns = new Array<Assign>();
    for (var i = 0, len = this.selectedAvail.length; i < len; i++) {
      let selectedAssign = this.selectedAvail[i];
      selectedAssign.reservationGuid = this.res.reservationGuid;
      selectedAssign.propertyGuid = this.property.propertyGuid;
      selectedAssign.inDateTime = new Date(selectedAssign.inDateTime);
      selectedAssign.outDateTime = new Date(selectedAssign.outDateTime);
      this.res.assigns.push(selectedAssign);
      await saveAssign(selectedAssign);
    }
    this.res.invoices = new Array<Invoice>();
    this.res.invoices.push(new Invoice());
    //stuff the res in the store
    this.saveNewReservationToStore(this.res);

    this.availableRatesVisible = true;
    this.loading = false;
    // when that is clicked, then save them to this reservation and reload the list in the edit screen
  }

  async saveAvailableRates(arg: any) {
    this.loading = true;
    let priceQuotes: Array<PriceQuote> = arg;
    this.$emit("success", priceQuotes);
    this.availableRatesVisible = false;
    this.loading = false;
  }

  closeAvailableRatesModal() {
    this.availableRatesVisible = false;
  }

  getNewResModalTitle() {
    if (this.res && this.res.arrivalDate && this.res.departureDate) {
      return (
        "Available Rates for " +
        new Date(this.res.arrivalDate).formatDayOfWeekMMDDYYYY() +
        " to " +
        new Date(this.res.departureDate).formatDayOfWeekMMDDYYYY()
      );
    }
    return "";
  }

  onRowSelected(items: any) {
    this.selectedCurrent = items;
    this.$forceUpdate();
  }
  onAvailRowSelected(items: any) {
    this.selectedAvail = items;
    this.$forceUpdate();
  }

  selectAllCurrentRows() {
    this.assignsCurrentTable.selectAllRows();
  }
  clearCurrentSelected() {
    this.assignsCurrentTable.clearSelected();
  }
  selectAllAvailRows() {
    this.assignsAvailTableRef.selectAllRows();
  }
  clearAvailSelected() {
    this.assignsAvailTableRef.clearSelected();
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  async mounted() {
    this.loading = true;
    this.assignsCurrentTable = this.$refs.assignsCurrentTableRef;
    this.assignsAvailTableRef = this.$refs.assignsAvailTableRef;
    this.getAssignFields();
    if (this.reservationGuid) {
      this.res = await getReservation(this.reservationGuid);
      if (
        !new Date(this.res.arrivalDate).isMinDate() &&
        !new Date(this.res.departureDate).isMinDate()
      ) {
        this.availableAssigns = await getAvailableAssigns(
          this.property.propertyGuid,
          this.res.arrivalDate,
          this.res.departureDate
        );
      }

      this.assignedAssigns = await getAssignsByReservation(
        this.reservationGuid
      );
      this.startDate = this.res.arrivalDate;
      this.endDate = this.res.departureDate;
    } else {
      this.assignedAssigns = new Array<Assign>();
    }

    this.loading = false;
  }

  private getAssignFields() {
    let field = new Field();
    field.tdClass = "assign-table-td";
    field.key = "unitName";
    field.label = "Unit";
    this.assignFields.push(field);

    field = new Field();
    field.key = "inDateTime";
    field.label = "In";
    this.assignFields.push(field);

    field = new Field();
    field.key = "outDateTime";
    field.label = "Out";
    this.assignFields.push(field);

    field = new Field();
    field.key = "nights";
    field.label = "Nights";
    this.assignFields.push(field);
  }
}
