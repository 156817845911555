
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Reservation } from "../Reservations/Reservation";
import { getReservation } from "../Reservations/ReservationService";
import { export_table_to_csv } from "@/utils/exportToCsv";
import { Charge } from "../Invoices/Charge";
import InvoiceEdit from "../Invoices/InvoiceEdit.vue";
import { getCharges } from "../Invoices/ChargeService";
import { Invoice, InvoiceItemKind } from "../Invoices/Invoice";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { InvoiceEdit } })
export default class ChargeTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  @Prop({ default: null }) public personGuid!: string;
  @Prop({ default: null }) public reservationGuid!: string;
  @Prop({ default: "daterange" }) public mode!: string; // 'property', 'daterange' ??
  private loading = true;
  private showInvoiceModal = false;
  private charges: Array<Charge> = [];
  private fields: Array<Field> = [];
  private activeCharge = new Charge();
  private chargeTable: any;
  private selected!: Array<any>;
  private showConfirmModal = false;
  private reservation!: Reservation;
  private startDate = new Date().addDays(-7);
  private endDate = new Date();

  getChargeDiscount(num: number) {
    num = num * 100;
    return this.getPercentage(num);
  }

  getPercentage(num: number) {
    let isNum = typeof num === "number" && num === num;
    if (!isNum) {
      return "";
    }

    var s = Number(num / 100).toLocaleString(undefined, {
      style: "percent",
      minimumFractionDigits: 2,
    });
    return s;
  }

  getInvoiceItemKindReadableString(kind: InvoiceItemKind): string {
    return InvoiceItemKind[kind];
  }
  getInvoiceItemKindVariant(kind: InvoiceItemKind): string {
    return new String(InvoiceItemKind[kind]).toLowerCase();
  }

  getClassFromKind(kind: InvoiceItemKind) {
    return InvoiceItemKind[kind].toLowerCase();
  }

  async saved(invoice: Invoice) {
    this.loading = true;
    let index = 0;
    for (const charge of invoice.charges) {
      let found = this.charges.find((a) => a.chargeGuid === charge.chargeGuid);
      if (!found) {
        await this.charges.push(charge);
      }
      index = await this.charges.findIndex(
        (a) => a.chargeGuid === charge.chargeGuid
      );
      this.charges[index] = charge;
    }

    await this.chargeTable.refresh();
    this.chargeTable.selectRow(index);
    this.$emit("invoices-changed");
    this.showInvoiceModal = false;
    this.loading = false;
  }

  formatCurrency(num: number) {
    let isNum = typeof num === "number" && num === num;
    if (!isNum) {
      return "";
    }

    // to do: do we need the charge currency code here?
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  sum(fieldName: string) {
    let sum = 0;
    for (const charge of this.charges) {
      sum = sum + charge[fieldName];
    }
    return sum;
  }
  getTotalCharges() {
    let sum = 0;
    for (const charge of this.charges) {
      sum = sum + charge.totalPrice;
    }
    return sum;
  }

  async exportTableToExcel() {
    this.loading = true;
    var csvTable = this.$el.querySelector("#chargeTableRef"); // this is the ID of the table to export
    if (csvTable) {
      await export_table_to_csv(csvTable, "Charges.csv");
    }
    this.loading = false;
  }

  async startDateChanged(arg: Date) {
    this.startDate = new Date(arg.toString() + " 00:00:00");
    await this.loadCharges();
  }
  async endDateChanged(arg: Date) {
    this.endDate = new Date(arg.toString() + " 00:00:00");
    await this.loadCharges();
  }

  async loadCharges() {
    this.loading = true;
    this.charges = await getCharges(
      "",
      "",
      this.property.propertyGuid,
      this.startDate,
      this.endDate
    );
    this.loading = false;
  }
  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDate(dateToFormat: Date) {
    if (new Date(dateToFormat).isDate() == false) {
      return "";
    }
    return new Date(dateToFormat).readableDateLocalized();
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.chargeTable.selectAllRows();
  }
  clearSelected() {
    this.chargeTable.clearSelected();
  }

  private getFields() {
    let field = {
      key: "chargeDate",
      label: "Date",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };
    this.fields.push(field);

    field = {
      key: "itemName",
      label: "Item",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };
    this.fields.push(field);
    field = {
      key: "preTaxPrice",
      label: "Pre Tax",
      sortable: true,
      tdClass: "",
      summable: true,
      isCurrency: true,
    };
    this.fields.push(field);

    field = {
      key: "quantity",
      label: "Qty",
      sortable: true,
      tdClass: "",
      summable: true,
      isCurrency: false,
    };
    this.fields.push(field);
    field = {
      key: "discount",
      label: "Discount",
      sortable: true,
      summable: false,
      tdClass: "",
      isCurrency: false,
    };
    this.fields.push(field);

    field = {
      key: "preTaxSubTotal",
      label: "Sub Total",
      sortable: true,
      tdClass: "",
      summable: true,
      isCurrency: true,
    };

    this.fields.push(field);

    for (let i = 0; i <= 10; i++) {
      if (
        this.property.preference &&
        this.property.preference["tax" + i.toString() + "Label"] &&
        this.property.preference["tax" + i.toString() + "Label"] != ""
      ) {
        field = {
          key: "tax" + i.toString() + "Total",
          label: this.property.preference[
            "tax" + i.toString() + "Label"
          ] as string,
          sortable: true,
          tdClass: "",
          summable: true,
          isCurrency: true,
        };
        this.fields.push(field);
      }
    }

    field = {
      key: "totalPrice",
      label: "Total",
      sortable: true,
      tdClass: "",
      summable: true,
      isCurrency: true,
    };
    this.fields.push(field);
    field = {
      key: "unitName",
      label: "Unit",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };
    this.fields.push(field);
    field = {
      key: "kind",
      label: "Kind",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };
    this.fields.push(field);
    field = {
      key: "number",
      label: "Number",
      sortable: true,
      tdClass: "",
      summable: false,
      isCurrency: false,
    };

    this.fields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.chargeTable = this.$refs.chargeTableRef;
    this.getFields();
    if (this.personGuid) {
      this.charges = await getCharges(this.personGuid);
    } else if (this.reservationGuid) {
      this.reservation = await getReservation(this.reservationGuid);
      this.charges = await getCharges(undefined, this.reservationGuid);
    } else if (this.mode == "property") {
      await this.loadCharges();
    } else {
      this.charges = new Array<Charge>();
    }

    this.loading = false;
  }

  getActiveCharge() {
    if (this.activeCharge) {
      return this.activeCharge;
    } else {
      return "";
    }
  }

  edit(charge: any) {
    if (charge) {
      this.activeCharge = charge;
    } else if (this.selected && this.selected.length > 0) {
      this.activeCharge = this.selected[0];
    }
    this.showInvoiceModal = true;
  }

  async closeInvoiceModal() {
    await this.chargeTable.refresh();
    this.showInvoiceModal = false;
    this.$emit("charges-changed");
  }
}
