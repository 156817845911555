
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { Unit } from "@/components/Cloud/Units/Unit";
import { UnitGroup } from "@/models/UnitGroup";
import { formatter } from "@/utils/currencyUtils";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import UnitEdit from "./UnitEdit.vue";
import { getUnitGroups } from "./UnitGroupService";
import { deleteUnit, getUnits, saveUnit } from "./UnitService";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { UnitEdit, ConfirmDeleteModal } })
export default class UnitTable extends Vue {
  @PropertyStore.State
  public property!: Property;
  private mode = "reservation";
  private loading = true;
  private showUnitModal = false;
  private units: Array<Unit> = [];
  private unitFields: Array<Field> = [];
  private clickedUnit!: Unit | null;
  private unitTable: any;
  private selected!: Array<any>;
  private groups!: Array<UnitGroup>;
  private showConfirmModal = false;

  async reSelectSelectedRows() {
    let index = 0;
    for (const item of this.unitTable.items) {
      if (item.selected) {
        this.unitTable.selectRow(index);
      }
      index++;
    }
  }

  selectAllColumnHeaderClicked(arg: any) {
    if (arg) {
      this.selectAll();
    } else {
      this.selectNone();
    }
  }

  allSelected() {
    let result = this.units.every((a) => a.selected);
    return result;
  }

  selectChanged(arg: any) {
    if (arg.item.selected) {
      this.unitTable.selectRow(arg.index);
    } else {
      this.unitTable.unselectRow(arg.index);
    }
  }

  confirmDelete() {
    this.showConfirmModal = true;
  }

  cancelDeletion() {
    this.showConfirmModal = false;
  }

  getGroupName(groupGuid: string) {
    if (!this.groups) {
      return "";
    }

    let index = this.groups.findIndex((a) => a.unitGroupGuid === groupGuid);
    let group = this.groups[index];

    if (!group) {
      return "";
    }
    return group?.name;
  }

  async add() {
    this.clickedUnit = null;
    this.showUnitModal = true;
  }

  onRowSelected(items: any) {
    for (const res of this.units) {
      res.selected = false;
    }
    for (const i of items) {
      i.selected = true;
    }
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAll() {
    if (this.allSelected()) {
      this.selectNone();
      return;
    }
    for (const unit of this.units) {
      unit.selected = true;
    }
    this.unitTable.selectAllRows();
  }
  selectNone() {
    for (const unit of this.units) {
      unit.selected = false;
    }
    this.unitTable.clearSelected();
  }

  async saved(unit: Unit) {
    this.loading = true;
    // if the list doesn't contain the unit, add it
    let found = this.units.find((a) => a.unitGuid === unit.unitGuid);
    if (!found) {
      await this.units.unshift(unit);
    }
    let index = await this.units.findIndex((a) => a.unitGuid === unit.unitGuid);
    this.units[index] = unit;
    await this.unitTable.refresh();
    this.unitTable.selectRow(index);
    await this.reSelectSelectedRows();
    this.loading = false;
  }

  private getUnitFields() {
    let field = new Field();
    field.sortable = true;
    field.key = "selected";
    field.label = "Select";
    this.unitFields.push(field);

    field = new Field();
    field.key = "unitName";
    field.label = "Name";
    this.unitFields.push(field);

    field = new Field();
    field.key = "unitType";
    field.label = "Unit Type";
    this.unitFields.push(field);

    field = new Field();
    field.key = "sortPosition";
    field.label = "Position";
    this.unitFields.push(field);

    field = new Field();
    field.key = "description";
    field.label = "Description";
    this.unitFields.push(field);

    field = new Field();
    field.key = "notes";
    field.label = "Notes";
    this.unitFields.push(field);

    field = new Field();
    field.key = "capacity";
    field.label = "Capacity";
    this.unitFields.push(field);

    field = new Field();
    field.key = "unitGroupGuid";
    field.label = "Group";
    this.unitFields.push(field);

    field = new Field();
    field.key = "category";
    field.label = "Category";
    this.unitFields.push(field);

    field = new Field();
    field.key = "active";
    field.label = "Active";
    this.unitFields.push(field);

    field = new Field();
    field.key = "bookOnline";
    field.label = "Book Online";
    this.unitFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.unitTable = this.$refs.unitTableRef;
    this.getUnitFields();
    if (this.property && this.property.propertyGuid) {
      this.units = await getUnits(this.property.propertyGuid);
      this.groups = await getUnitGroups(this.property.propertyGuid);
    } else {
      this.units = new Array<Unit>();
    }
    this.loading = false;
  }

  getActiveUnit() {
    if (this.clickedUnit) {
      return this.clickedUnit.unitGuid;
    } else {
      return "";
    }
  }

  async deleteUnits() {
    if (this.selected && this.selected.length > 0) {
      this.selected.forEach(async (s) => {
        let deleted = s;
        let result = await deleteUnit(deleted.unitGuid.toString());
        if (result) {
          if (this.units) {
            let index = this.units.findIndex(
              (a) => a.unitGuid === deleted.unitGuid
            );
            this.units.splice(index, 1);
          }
        }
      });
    }
    this.showConfirmModal = false;
    await this.unitTable.refresh();
    await this.reSelectSelectedRows();
  }

  edit(unit: any, index: any, event: any) {
    if (unit) {
      this.clickedUnit = unit;
    } else if (this.selected && this.selected.length > 0) {
      this.clickedUnit = this.selected[0];
    }
    this.showUnitModal = true;
  }

  closeUnitModal() {
    this.showUnitModal = false;
  }
}
