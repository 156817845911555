
import { PagingParameters } from '@/models/PagingParameters';
import { PeoplePagedModel } from '@/models/PeoplePagedModel';
import { Person } from '@/components/Cloud/People/Person';
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';


export async function getPeopleForReservation(reservationGuid: string): Promise<Array<Person>> {
  const response = await axiosRetry(
    `/api/person/byres/${reservationGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Person>>;
  } else {
    const err =
      "An error occurred getting the people from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getAllPeopleForProperty(propertyGuid: string): Promise<Array<Person>> {
  const response = await axiosRetry(
    `/api/person/all/${propertyGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Person>>;
  } else {
    const err =
      "An error occurred getting the people from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getAllPeoplePaged(propertyGuid: string, pagingParams: PagingParameters): Promise<PeoplePagedModel> {
  const response = await axiosRetry(
    `/api/person/allpaged/${propertyGuid}`,
    pagingParams,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<PeoplePagedModel>;
  } else {
    const err =
      "An error occurred getting the people from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deletePeople(peopleGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/person/delete`,
    peopleGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the people from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getPerson(personGuid: string): Promise<Person> {
  const response = await axiosRetry(
    `/api/person/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Person>;
  } else {
    const err =
      "An error occurred getting the people from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function savePerson(person: Person): Promise<boolean> {
  const response = await axiosRetry(
    `/api/person`,
    person,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the people from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}