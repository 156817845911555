import Property from '@/models/Property';
import { axiosRetry } from '@/utils/fetchUtil';

export async function deleteProperty(propertyGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/property/` + propertyGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the property from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function saveProperty(property: Property): Promise<Property> {
  const response = await axiosRetry(
    `/api/property/saveproperty`,
    property,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Property>;
  } else {
    const err =
      "An error occurred getting the property from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getProperty(propertyGuid: string): Promise<Property> {
  const response = await axiosRetry(
    `/api/property/propertyasync/${propertyGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Property>;
  } else {
    const err =
      "An error occurred getting the property from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

