
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TemplateTable from "./TemplateTable.vue";
import { Template, getTemplates, addSamples } from "@/models/Template";
import { User } from "../Auth/User";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");

@Component({ components: { TemplateTable } })
export default class Templates extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Auth.State("user")
  private currentUser!: User;

  async addSamplesForProperty() {
    await addSamples(this.property.propertyGuid);
    window.location.reload();
  }
}
