
import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { readonly } from 'vue';

export class DeliveryOptionType {
  static Delivery = "Delivery";
  static Pickup = "Pick-up";
  public static DeliveryPickup = "Delivery/Pick-up";
}

export class DeliveryOption {
  constructor() {
  }
  public deliveryOptionGuid!: string;
  public propertyGuid!: string;
  public accountGuid!: string | undefined;
  public description!: string;
  public location!: string;
  public type!: string;
  public coordinates!: string;
  public price!: number;
  public checked!: boolean;
}


export class DeliveryOptionInUnit {
  constructor() {
  }
  public deliveryOptionGuid!: string;
  public unitGuid!: string;
}

export async function saveDeliveryOptionInUnits(deliveryOptionInUnits: Array<DeliveryOptionInUnit>): Promise<boolean> {
  let url = '';
  url = `/api/deliveryoption/savedeliveryoptionsinunit`;
  const response = await axiosRetry(
    url,
    deliveryOptionInUnits,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the deliveryOption from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deleteDeliveryOptionInUnitsByUnit(unitGuid: string): Promise<boolean> {
  let url = '';
  url = `/api/deliveryoption/deletedeliveryoptioninunitsbyunit/${unitGuid}`;
  const response = await axiosRetry(
    url,
    unitGuid,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the deliveryOption from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function deleteDeliveryOptions(deliveryOptionsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/deliveryOption/delete`,
    deliveryOptionsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the deliveryOption from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deleteDeliveryOption(deliveryOptionGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/deliveryOption/` + deliveryOptionGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the deliveryOption from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function saveDeliveryOption(deliveryOption: DeliveryOption): Promise<boolean> {
  const response = await axiosRetry(
    `/api/deliveryoption`,
    deliveryOption,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the deliveryOption from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getDeliveryOptions(unitGuid: string): Promise<Array<DeliveryOption>> {
  const response = await axiosRetry(
    `/api/deliveryOption/getbyunit/${unitGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<DeliveryOption>>;
  } else {
    const err =
      "An error occurred getting the deliveryOption from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function getDeliveryOptionsByProperty(propertyGuid: string): Promise<Array<DeliveryOption>> {
  const response = await axiosRetry(
    `/api/deliveryOption/getbyproperty/${propertyGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<DeliveryOption>>;
  } else {
    const err =
      "An error occurred getting the deliveryOption from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getDeliveryOption(deliveryOptionGuid: string): Promise<DeliveryOption> {
  const response = await axiosRetry(
    `/api/deliveryOption/getdeliveryOption/${deliveryOptionGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<DeliveryOption>;
  } else {
    const err =
      "An error occurred getting the deliveryOption from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}



