
export class Season {
    constructor() {
       this.baseRate = 0;
    }
    public seasonGuid: string | undefined;
    public rateGuid: string | undefined;
    public propertyGuid: string | undefined;
    public name: string | undefined;
    public startDate: Date | undefined;
    public endDate: Date | undefined;
    public baseRate: number;
    public weekendRate: number | undefined;
    public maxPeople: number | undefined;
    public extraPersonCharge: number | undefined;
    public nights: number | undefined;
    public notes: string | undefined;
    public createdDate: Date | undefined;
    public modifiedDate: Date | undefined;
}





