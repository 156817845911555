
import Property from "@/models/Property";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import MaskedInput from "@/components/Controls/MaskedInput.vue";
import { savePreference } from "@/models/Preference";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { MaskedInput },
})
export default class Taxes extends Vue {
  @PropertyStore.State
  public property!: Property;
  private loading = true;

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  async save() {
    //save the property info
    await savePreference(this.property.preference);
    this.navigate("Settings");
  }
}
