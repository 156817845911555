
import { Vue, Component } from "vue-property-decorator";
import {
  SendEmailParams,
  sendPurchaseRequestEmail,
} from "../Cloud/Templates/EmailService";

@Component({ components: {} })
export default class PurchaseDesktop extends Vue {
  private errors = new Array<string>();
  private message = "";
  private model = { name: "", email: "", company: "" };
  private showOverlay = false;

  async sendPurchaseRequest() {
    this.showOverlay = true;
    if (await this.validate()) {
      // send en email to this person
      let params = new SendEmailParams();
      params.subject = "Purchase Request";
      params.recipientEmail = this.model.email;
      params.recipientFirstName = this.model.name + " - " + this.model.company;
      params.fromEmail = "support@lodgevault.com";
      params.fromName = "Lodge Vault";
      let response = await sendPurchaseRequestEmail(params);

      this.message =
        "Thank you. An email message with further instructions has been sent to the address you specified. " +
        "We'll be in contact with you with a subscription link, and an activation code shortly.";
    }
    this.showOverlay = false;
  }

  async validate() {
    this.errors = [];
    if (this.model.name == "") {
      this.errors.push("Your full name is required");
    }
    if (
      this.model.email == "" ||
      !(this.model.email.includes("@") && this.model.email.includes("."))
    ) {
      this.errors.push("A valid email address is required");
    }
    if (this.model.company == "") {
      this.errors.push("Your company name is required");
    }
    return this.errors.length == 0;
  }
}
