interface Date {
    dayOfWeek: () => number;
    time: (readable: boolean) => string;
    timestring: () => string;
    fullDate: () => string;
    removeTime: () => Date;
    readableDate: (abbreviation: boolean) => string;
    readableDateLocalized: () => string;
    readableDateTimeLocalized: () => string;
    formatMonthDay: () => string;
    formatMMDDYYYY: () => string;
    formatDayOfWeekMMDDYYYY: () => string;
    formatDayOfWeekMMDDYY: () => string;
    formatDayOfWeekAndDate: (abbreviation: boolean) => string;
    getDayOfMonth: () => string;
    getDayOfWeekName: (abbreviation: boolean) => string;
    isWeekend: (fullName: boolean) => boolean;
    isMinDate: () => boolean;
    isDate: () => boolean;
    getMonthName: (fullName: boolean) => string;
    formatDayOfWeekMonthAndDate: () => string;
    getDateString: (short: boolean) => string;
    addDays: (days: any) => Date;
    addMinutes: (minutes: number) => Date;
    dateAdd: (interval: string, units: number) => Date;
    addMonths: (days: number) => Date;
    daysInMonth: () => number;
    daysInMonthAndYear: (month: number, year: number) => number;
    minutesDiff: (secondDate: Date) => number;
    daysDiff: (secondDate: Date) => number;
    datesInArray: (dateArray: Array<Date>, dateToFind: Date) => boolean;
    getDaysOfWeek: (fullName: boolean) => Array<string>;
    getMonthNames: (fullName: boolean) => Array<string>;
    areSameDay: (secondDate: Date) => boolean;
    removeOffset: () => Date;
    convertTimeZone: (timezone: string) => Date;
    minDate: () => Date;
    dashedDate: () => string;
    monthDay: () => string;
    readableDateTime: () => string;
    addTime: (time: string) => Date;
}

// adds tiem in string fomrat to a date + ' ' + '09:00:00'
Date.prototype.addTime = function (time: string) {
    if (time.length > 8) {
        time = time.substring(0, 8);
    }
    return new Date(this.dashedDate() + " " + time);
};

///2023-09-03
Date.prototype.dashedDate = function () {
    return new Date(this).toISOString().replace(/T.*/, '');
};

Date.prototype.minDate = function () {
    return new Date('0001-01-01T00:00:00Z');
};

//removes the hours component of the date so it's just 12:00:00 on the date
Date.prototype.convertTimeZone = function (timezone: string) {
    return new Date(this.toLocaleString("en-US", { timeZone: timezone }));
};

//hasn't been confirmed this works
Date.prototype.removeOffset = function () {
    let hoursDiff = this.getHours() - (this.getTimezoneOffset() / 60);
    let minutesDiff = (this.getMinutes() - this.getTimezoneOffset());
    this.setHours(hoursDiff);
    this.setMinutes(minutesDiff);
    return this;
};

//removes the hours component of the date so it's just 12:00:00 on the date
Date.prototype.removeTime = function () {
    return new Date(this.setHours(0, 0, 0, 0));
};
//23/04/1976
Date.prototype.formatMMDDYYYY = function () { //23/04/1976
    let day = this.getDate().toString();        // yields day
    let month = (this.getMonth() + 1).toString();    // yields month
    const year = this.getFullYear().toString();  // yields year
    if (day.toString().length === 1) {
        day = '0' + day;
    }
    if (month.toString().length === 1) {
        month = '0' + month;
    }
    // After this construct a string with the above results as below
    const time = day + "/" + month + "/" + year;
    return time;
};

//Two Nights
//make a function that returns this

//Wednesday, July 21st 2021 @ 3pm
//make a function that returns something like this Wednesday, July 21st 2021 @ 3pm

// Wed Jul 13, 2021, 2:30 PM
Date.prototype.readableDateTime = function () {
    return this.formatDayOfWeekMMDDYYYY() + " " + this.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

};

//Wed Jul 13, 2021
Date.prototype.formatDayOfWeekMMDDYYYY = function () {

    const dayOfWeek = this.getDayOfWeekName(true);        // yields day
    const month = this.getMonthName(false);    // yields month
    const year = this.getFullYear().toString();  // yields year
    const time = dayOfWeek + " " + month + " " + this.getDayOfMonth() + ", " + year;
    return time;
};
//Wed Jul 13, 21
Date.prototype.formatDayOfWeekMMDDYY = function () {

    const dayOfWeek = this.getDayOfWeekName(true);        // yields day
    const month = this.getMonthName(false);    // yields month
    const year = this.getFullYear().toString().substr(2); // yields year
    const time = dayOfWeek + " " + month + " " + this.getDayOfMonth() + ", " + year;
    return time;
};
//Saturday 24
Date.prototype.formatDayOfWeekAndDate = function () {
    return (this.getDayOfWeekName(false)) + " " + this.getDate()
};

// 7/12
Date.prototype.formatMonthDay = function () {
    const month = this.getMonth() + 1;    // yields month index so need to add one
    return month + "/" + this.getDayOfMonth();
};
// 1/23/2022, 2:30 PM
Date.prototype.readableDateTimeLocalized = function () {
    return this.toLocaleDateString() + " " + this.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

};

// 1/23/2022
Date.prototype.readableDateLocalized = function () {
    if (!this.isMinDate()) {
        return this.toLocaleDateString();
    } else {
        return "";
    }
};

//Monday January 1
Date.prototype.formatDayOfWeekMonthAndDate = function (abbreviation = false) {
    return (this.getDayOfWeekName(abbreviation)) + " " + this.getMonthName(true) + " " + this.getDate()
};
//Monday January 1, 2021
Date.prototype.fullDate = function () {
    return (this.getDayOfWeekName(false)) + " " + this.getMonthName(true) + " " + this.getDate() + ", " + this.getFullYear();
};

//Oct 11, 2021
Date.prototype.readableDate = function (abbreviation = true) {
    return (this.getMonthName(abbreviation)) + " " + this.getDayOfMonth() + ", " + this.getFullYear();
};
//Oct 11
Date.prototype.monthDay = function () {
    return (this.getMonthName(true)) + " " + this.getDayOfMonth();
};

//Monday January 1
Date.prototype.formatDayOfWeekMonthAndDate = function (abbreviation = false) {
    return (this.getDayOfWeekName(abbreviation)) + " " + this.getMonthName(true) + " " + this.getDate()
};

Date.prototype.getDayOfMonth = function () {
    return this.getDate().toString();
};

Date.prototype.isDate = function () {
    const date = new Date(this.valueOf());
    const result = !this.isMinDate() && (date instanceof Date && !isNaN(date.valueOf()));
    return result;
};

Date.prototype.getDayOfWeekName = function (abbreviation = false) {
    return this.getDaysOfWeek(!abbreviation)[this.getDay()];
};

Date.prototype.isWeekend = function (satSun = false) {
    if (satSun == true) {
        return this.getDayOfWeekName(false)?.toLowerCase() === "saturday" || this.getDayOfWeekName(false)?.toLowerCase() === "sunday";
    }
    return this.getDayOfWeekName(false)?.toLowerCase() === "saturday" || this.getDayOfWeekName(false)?.toLowerCase() === "friday";
};
Date.prototype.isMinDate = function () {
    //Wed Dec 31, 1969
    return this.getFullYear() === 1 || this.getFullYear() == 1901 || this.getFullYear() == 2001 || this.getFullYear() == 1969;
};

Date.prototype.getDaysOfWeek = function (fullName = false) {
    if (fullName) {
        const weekdays = new Array(7);
        weekdays[0] = "Sunday";
        weekdays[1] = "Monday";
        weekdays[2] = "Tuesday";
        weekdays[3] = "Wednesday";
        weekdays[4] = "Thursday";
        weekdays[5] = "Friday";
        weekdays[6] = "Saturday";
        return weekdays;
    } else {
        const daysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return daysShort;
    }
};

Date.prototype.getMonthName = function (fullName = false) {
    return this.getMonthNames(fullName)[this.getMonth()];
};

Date.prototype.getMonthNames = function (fullName = false) {
    if (fullName) {
        return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    } else {
        return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    }
};

Date.prototype.addDays = function (days: any) {
    days = parseInt(days);
    const dateInst = new Date(this.valueOf());
    const nDays = new Date(new Date(dateInst).setDate(dateInst.getDate() + days))
    return nDays;
};

/**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
Date.prototype.dateAdd = function (interval: string, units: number) {
    const date = new Date(this.valueOf());
    var ret = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };
    switch (String(interval).toLowerCase()) {
        case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
        case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
        case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
        case 'week': ret.setDate(ret.getDate() + 7 * units); break;
        case 'day': ret.setDate(ret.getDate() + units); break;
        case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
        case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
        case 'second': ret.setTime(ret.getTime() + units * 1000); break;
        default: ret = date; break;
    }
    return ret;
}


Date.prototype.addMinutes = function (minutes) {
    const date = new Date(this.valueOf());
    date.setTime(date.getTime() + minutes * 60000);
    return date;
};

Date.prototype.addMonths = function (n) {
    const date = new Date(this.valueOf());
    date.setDate(1);
    date.setMonth(date.getMonth() + n);
    return date;
};

// Month in JavaScript is 0-indexed (January is 0, February is 1, etc), 
// but by using 0 as the day it will give us the last day of the prior
// month. So passing in 1 as the month number will return the last dayKs
// of January, not February
Date.prototype.daysInMonthAndYear = function (month: number, year: number) {
    const daysInMonth = new Date(year, month, 0).getDate();
    return daysInMonth;
};
Date.prototype.daysInMonth = function () {
    const date = new Date(this.valueOf());
    const daysInMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
    return daysInMonth;
};

// doesn't work use date.toLocaleTimeString()
// 00:00:00
Date.prototype.timestring = function () {
    let d = this;
    let h = d.getHours();
    var hStr, mStr, sStr;
    hStr = h < 10 ? "0" + h : h;
    let m = d.getMinutes();
    mStr = m < 10 ? "0" + m : m;
    let s = d.getSeconds();
    sStr = s < 10 ? "0" + s : s;

    let time = hStr + ":" + mStr + ":" + sStr;
    return time;
}

// 2:38 PM
Date.prototype.time = function (readable: boolean = true) {
    const date = new Date(this.valueOf());
    const hour = date.getHours();     // yields hours in military time
    const minute = date.getMinutes(); // yields minutes
    const seconds = date.getSeconds(); // yields minutes
    let formatted = '';
    if (readable && readable == true) {
        let hourFormatted = ((hour > 12) ? (hour - 12) : hour);
        if (hour < 1) {
            hourFormatted = 12;
        }
        formatted = hourFormatted
            + ':' + (minute < 10 ? "0" + minute : minute) + " " + ((hour < 12) ? "AM" : "PM");
    } else {
        formatted = (hour < 10 ? "0" + hour : hour)
            + ':' + (minute < 10 ? "0" + minute : minute) + ":" + (seconds < 10 ? "0" + seconds : seconds);
    }

    return formatted;

};


Date.prototype.getDateString = function (short: boolean) {
    const date = new Date(this.valueOf());
    const day = date.getDate();       // yields date
    const month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
    const year = date.getFullYear();  // yields year
    const hour = date.getHours();     // yields hours 
    const minute = date.getMinutes(); // yields minutes
    const second = date.getSeconds(); // yields seconds

    // After this construct a string with the above results as below
    if (short) {
        const formatted = (day < 10 ? "0" + day : day) + "/"
            + (month < 10 ? "0" + month : month)
            + "/" + year + " ";
        return formatted;
    } else {
        const formatted = (day < 10 ? "0" + day : day) + "/"
            + (month < 10 ? "0" + month : month)
            + "/" + year + " "
            + (hour < 10 ? "0" + hour : hour)
            + ':' + (minute < 10 ? "0" + minute : minute)
            + ':' + (second < 10 ? "0" + second : second);
        return formatted;
    }
};


//second date is further out or larger or departure
Date.prototype.minutesDiff = function (secondDate) {
    const firstDate = new Date(this.valueOf());
    secondDate = new Date(secondDate);

    const diffMs = (secondDate.getTime() - firstDate.getTime()); // milliseconds
    // const diffMs = diffSeconds * 1000; // milliseconds 
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(diffMs / 60000); // minutes
    return diffMins;
};

//second date is further out or larger or departure
Date.prototype.daysDiff = function (secondDate) {
    const date = new Date(this.valueOf()).removeTime();
    secondDate = new Date(secondDate).removeTime();
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((date.getTime() - secondDate.getTime()) / (oneDay)));
    return diffDays;
}

//second date is further out or larger or departure
Date.prototype.areSameDay = function (secondDate) {
    return (this.daysDiff(secondDate) === 0);
}

Date.prototype.datesInArray = function (dateArray: Array<Date>, dateToFind: Date): boolean {
    return dateArray.some(
        (d) =>
            new Date(d).getDateString(true) ===
            new Date(dateToFind).getDateString(true)
    );
}

/* let isChrome = navigator.userAgent.indexOf('Chrome') > -1;
const isExplorer = navigator.userAgent.indexOf('MSIE') > -1;
const isFirefox = navigator.userAgent.indexOf('Firefox') > -1;
let isSafari = navigator.userAgent.indexOf("Safari") > -1;
const isOpera = navigator.userAgent.toLowerCase().indexOf("op") > -1;
if ((isChrome) && (isSafari)) { isSafari = false; }
if ((isChrome) && (isOpera)) { isChrome = false; }


const weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

function parseDate(str, delim) {
    const mdy = str.split(delim);
    return new Date(mdy[2], mdy[0] - 1, mdy[1]);
}

function parseDateByFormat(input, format) {
    format = format || 'yyyy-mm-dd'; // default format
    const parts = input.match(/(\d+)/g);
        let i = 0;
        const fmt = [];
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
}


Date.prototype.getDayOfWeekName = function (fullName) {

    const daysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return daysShort[this.getDay()];
};


Date.prototype.addYears = function (n) {
    if (this.tempDate === null) {
        this.tempDate = this.getDate();
    }
    this.setDate(1);
    this.setFullYear(this.getFullYear() + n);
    this.setDate(Math.min(this.tempDate, this.getMaxDays()));
};
Date.prototype.getMaxDays = function () {
    const tmpDate = new Date(Date.parse(this));
        let d = 28;
        let m = 0;
    m = tmpDate.getMonth();
    d = 28;
    while (tmpDate.getMonth() === m) {
        d++;
        tmpDate.setDate(d);
    }
    return d - 1;
};
Date.prototype.formatMMDD = function () {
    return (this.getMonth() + 1) + "/" + this.getDate();
};
Date.prototype.formatMMYYYY = function () {
    return (this.getMonthName(false)) +
        " " + this.getFullYear()
};





function DayDiffNights(firstDate, secondDate) {
    secondDate = new Date(secondDate.setHours(0));
    firstDate = new Date(firstDate.setHours(0));
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
    return diffDays;
}

function getAllDays(fromDate, toDate) {
    let s = new Date(fromDate.setHours(0, 0, 0, 0).valueOf() - 0);
    const e = new Date(toDate - 0);
    const a = [];

    while (s < e) {
        a.push(s.getTime());
        s = new Date(s.setDate(
            s.getDate() + 1
        ));
    }

    return a;
} */