
import { Vue, Component } from "vue-property-decorator";
import {
  sendDownloadRequestEmail,
  SendEmailParams,
} from "../Cloud/Templates/EmailService";

@Component({ components: {} })
export default class Demo extends Vue {
  private errors = new Array<string>();
  private message = "";
  private model = { name: "", email: "", company: "" };
  private showOverlay = false;

  async sendDownloadRequest() {
    this.showOverlay = true;
    if (await this.validate()) {
      // send en email to this person
      let params = new SendEmailParams();
      params.subject = "Download Request";
      params.recipientEmail = this.model.email;
      params.recipientFirstName = this.model.name + " - " + this.model.company;
      params.fromEmail = "support@lodgevault.com";
      params.fromName = "Lodge Vault";
      let response = await sendDownloadRequestEmail(params);

      this.message =
        "Thank you. An email message with download instructions has been sent to the address you specified.";
    }
    this.showOverlay = false;
  }

  async validate() {
    this.errors = [];
    if (this.model.name == "") {
      this.errors.push("Name is required");
    }
    if (
      this.model.email == "" ||
      !(this.model.email.includes("@") && this.model.email.includes("."))
    ) {
      this.errors.push("A valid email address is required");
    }
    if (this.model.company == "") {
      this.errors.push("Company is required");
    }
    return this.errors.length == 0;
  }
}
