export class ChargeItem {
    constructor() {
    }
    public chargeItemGuid!: string;
    public propertyGuid!: string;
    public accountGuid!: string;
    public accountName!: string;
    public accountNumber!: string;
    public modifiedDate!: Date;
    public createdDate!: Date;
    public name!: string;
    public tax1Rate!: number;
    public tax2Rate!: number;
    public tax3Rate!: number;
    public tax4Rate!: number;
    public tax5Rate!: number;
    public tax6Rate!: number;
    public tax7Rate!: number;
    public tax8Rate!: number;
    public tax9Rate!: number;
    public tax10Rate!: number;
    public totalTax!: number;
    public preTaxPrice!: number;
    public postTaxPrice!: number;
    public totalQuantity!: number;
    public inventory!: string;
    public inventoryUpdated!: Date;
    public activeOnline!: boolean;
    public checked = false;
}
