
import { Vue, Component, Prop } from "vue-property-decorator";
import { Reservation } from "@/components/Cloud/Reservations/Reservation";
import {
  deleteAssign,
  deleteAssigns,
  deleteReservation,
  getGroup,
  getReservation,
  saveGroupMember,
  saveReservation,
} from "@/components/Cloud/Reservations/ReservationService";
import { Guid } from "@/utils/Guid";
import ActivityTable from "../Activity/ActivityTable.vue";
import InvoiceTable from "../Invoices/InvoiceTable.vue";
import GroupTable from "../People/GroupTable.vue";
import { namespace } from "vuex-class";
import Property from "@/models/Property";
import { getTemplates, Template } from "@/models/Template";
import TemplateEdit from "../Templates/TemplateEdit.vue";
import { Person } from "@/components/Cloud/People/Person";
import { getPeopleForReservation, getPerson } from "../People/PersonService";
import { getActivities, saveActivity } from "../Activity/ActivityService";
import DatePicker from "@/components/SiteBook/DatePicker.vue";
import MaskedInput from "@/components/Controls/MaskedInput.vue";
import SearchBox from "@/components/Controls/SearchBox.vue";
import { PersonInReservation } from "./PersonInReservation";
import AuthService from "../Auth/AuthService";
import { User } from "../Auth/User";
import {
  getReservationStatusColorVariant,
  getReservationStatusString,
  getResStatusColorVariant,
  GroupMemberStatus,
  ResStatus,
} from "./ResStatus";
import { formatter } from "@/utils/currencyUtils";
import AssignTable from "../Assign/AssignTable.vue";
import { Activity, ActivityKind } from "../Activity/Activity";
import {
  getInvoicesByReservation,
  getPrimaryInvoice,
  saveInvoice,
} from "../Invoices/InvoiceService";
import PriceQuote from "@/models/PriceQuote";
import { Invoice } from "../Invoices/Invoice";
import { saveCharges } from "../Invoices/ChargeService";
import { ConfirmationMethod } from "./ConfirmationMethod";
import AddPayment from "../Invoices/AddPayment.vue";
import { UnitTypes } from "../Units/Unit";
import RentalDetails from "./RentalDetails.vue";

const PropertyStore = namespace("PropertyStore");
const Auth = namespace("AuthStore");
@Component({
  components: {
    GroupTable,
    InvoiceTable,
    AssignTable,
    ActivityTable,
    TemplateEdit,
    DatePicker,
    MaskedInput,
    SearchBox,
    AddPayment,
    RentalDetails,
    PersonEdit: () => import("../People/PersonEdit.vue"),
  },
})
export default class ReservationEdit extends Vue {
  @PropertyStore.State
  public property!: Property;

  @Auth.State("user")
  private currentUser!: User;

  @Prop({ default: null }) public paramReservationGuid!: string;
  @Prop({ default: null }) public paramPersonGuid!: string;
  @Prop({ default: false }) public isNew!: boolean;
  public reservationGuid!: string;
  public templateSelected!: Template | null;
  private templateMode = "send";
  private showEditModal = false;
  private showPersonEditModal = false;
  private loading = true;
  private reservationIndex = 0;
  public res!: Reservation;
  private busy = false;
  private templates!: Array<Template>;
  private group!: Array<Person>;
  private invoices = new Array<Invoice>();
  private modal: any;
  private showTemplateSendModal = false;
  private activitiesKey = Guid.newGuid();
  private invoicesKey = Guid.newGuid();
  private statusKey = Guid.newGuid();
  private groupKey = Guid.newGuid();
  private assignsKey = Guid.newGuid();
  private activePersonGuid: string = "";
  private arrivalTime: string = "00:00:00";
  private departureTime: string = "00:00:00";
  private members = new Array<User>();
  private confirmationMethods: Array<string> = [];
  private confMethod = 2;
  private showAddPaymentModal = false;
  private invoice = new Invoice();
  private forcePending = false;

  hasRental() {
    let hasRental = false;
    let index = this.res.assigns.findIndex(
      (a) => a.unitType == UnitTypes.Rental || a.unitType == UnitTypes.Event
    );
    if (index > -1) {
      hasRental = true;
    }
    return hasRental;
  }

  showDepositPanel() {
      return true;
  }

  isConfirmed() {
    return this.res.status == ResStatus.Confirmed;
  }

  async changeToPending(arg: any) {
    this.busy = true;
    this.res.confirmationNumber = "";
    this.res.status = ResStatus.Pending;
    this.setDatesAndTimes();
    this.res = await saveReservation(this.res, false);
    // this.statusKey = Guid.newGuid();
    this.forcePending = true;
    this.busy = false;
  }

  getTemplateMode() {
    return this.templateMode;
  }

  getInvoiceGuid() {
    if (this.invoice && this.invoice.invoiceGuid != Guid.emptyGuid()) {
      return this.invoice.invoiceGuid;
    }
  }
  getPersonGuid() {
    if (this.invoice && this.invoice.invoiceGuid != Guid.emptyGuid()) {
      return this.invoice.personGuid;
    }
  }

  async customEmail() {
    this.templateMode = "send";
    this.templateSelected = null;
    this.showTemplateSendModal = true;
  }

  async closeAddPaymentModal() {
     this.invoicesKey = await Guid.newGuid();
    await this.setDatesAndTimes();
    this.res = await saveReservation(this.res, true);
    this.invoicesKey = Guid.newGuid();
    this.showAddPaymentModal = false;
  }
  openAddPaymentModal() {
    this.showAddPaymentModal = true;
  }

  getDepositStatus() {
    // return 'not sent' or 'sent'
    if (this.primaryInvoice()) {
      let inv = this.primaryInvoice();
      if (inv) {
        if (new Date(inv.depositPaidDate).isMinDate() == false) {
          return "Paid on " + new Date(inv.depositPaidDate).readableDate(true);
        } else if (new Date(inv.sentDate).isMinDate() == false) {
          return "Sent " + new Date(inv.sentDate).readableDate(true);
        } else if (new Date(inv.sentDate).isMinDate() == true) {
          return "Not Sent";
        }
      }
    }
  }

  async addNewPrimaryContact() {
    if (this.isNew) {
      this.setDatesAndTimes();
      this.res = await saveReservation(this.res);
    }
    this.activePersonGuid = "";
    this.showPersonEditModal = true;
  }

  hasPrimaryContact() {
    return this.primaryContact();
  }

  expand() {
    // navigate to invoice edit page with invoice guid as param
    this.navigate("ReservationEdit", this.res.reservationGuid.toString());
  }

  async assignsChanged(arg: any) {
    this.busy = true;
    let priceQuotes: Array<PriceQuote> = arg;
    if (priceQuotes) {
      let primaryInv = this.primaryInvoice();
      if (primaryInv) {
        //save the price quote charges array
        for (const pq of priceQuotes) {
          for (const charge of pq.charges) {
            charge.invoiceGuid = primaryInv.invoiceGuid;
          }
          await saveCharges(pq.charges);
        }
      }
    }

    this.assignsKey = Guid.newGuid();
    this.invoicesKey = Guid.newGuid();
    this.groupKey = Guid.newGuid();
    this.busy = false;
  }

  async invoicesChanged() {
    this.setDatesAndTimes();
    this.res = await saveReservation(this.res);
    this.invoicesKey = Guid.newGuid();
  }
  async invoicesLoaded(arg: any) {
    if (arg) {
      this.invoices = arg;
    }
  }

  getActivePersonGuid() {
    if (this.activePersonGuid) {
      return this.activePersonGuid;
    }
  }

  openGroupMember(arg: any) {
    this.activePersonGuid = arg;
    this.showPersonEditModal = true;
  }

  async makePrimary(arg: any) {
    this.res.personInReservations = await getGroup(this.res.reservationGuid);
    if (this.res && this.res.personInReservations && this.group) {
      let pc = this.primaryContact();
      if (pc) {
        pc.isPrimaryContact = false;
        await saveGroupMember(pc);
      }
      const groupMember = this.res.personInReservations.find(
        (g) => g.personGuid == arg
      );
      if (groupMember) {
        groupMember.isPrimaryContact = true;
        await saveGroupMember(groupMember);

        let act = new Activity();
        act.kind = ActivityKind.None;
        act.personGuid = groupMember.personGuid;
        act.reservationGuid = groupMember.reservationGuid;
        act.description = "Primary contact set to " + groupMember.name;
        act.notes = act.description;
        act.propertyGuid = this.property.propertyGuid;
        act.takenBy = this.currentUser.userName;
        await saveActivity(act);
      }
    }

    this.groupKey = Guid.newGuid();
    this.activitiesKey = Guid.newGuid();
  }
  async checkIn(arg: any) {
    if (this.res && this.res.personInReservations && this.group) {
      const groupMember = this.res.personInReservations.find(
        (g) => g.personGuid == arg
      );
      if (groupMember) {
        groupMember.status = GroupMemberStatus.CheckedIn;
        await saveGroupMember(groupMember);
      }
    }

    this.groupKey = Guid.newGuid();
  }

  async checkInRes() {
    if (this.res && this.res.personInReservations && this.group) {
      this.res.status = ResStatus.CheckedIn;
      this.setDatesAndTimes();
      await saveReservation(this.res);
      for (const groupMember of this.res.personInReservations) {
        if (groupMember) {
          groupMember.status = GroupMemberStatus.CheckedIn;
          await saveGroupMember(groupMember);
        }
      }
      let act = new Activity();
      act.propertyGuid = this.property.propertyGuid;
      act.kind = ActivityKind.Other;
      act.reservationGuid = this.res.reservationGuid;
      act.description = "Checked in";
      act.notes = "Checked in";
      act.takenBy = this.currentUser.userName;
      await saveActivity(act);
    }

    this.groupKey = Guid.newGuid();
    this.activitiesKey = Guid.newGuid();
  }

  async checkOutRes() {
    if (this.res && this.res.personInReservations && this.group) {
      this.res.status = ResStatus.CheckedOut;
      this.setDatesAndTimes();
      await saveReservation(this.res);
      for (const groupMember of this.res.personInReservations) {
        if (groupMember) {
          groupMember.status = GroupMemberStatus.CheckedOut;
          await saveGroupMember(groupMember);
        }
      }
      let act = new Activity();
      act.propertyGuid = this.property.propertyGuid;
      act.kind = ActivityKind.Other;
      act.reservationGuid = this.res.reservationGuid;
      act.description = "Checked out";
      act.notes = "Checked out";
      act.takenBy = this.currentUser.userName;
      await saveActivity(act);
    }

    this.groupKey = Guid.newGuid();
    this.activitiesKey = Guid.newGuid();
  }

  async cancelRes() {
    this.busy = true;
    if (this.res && this.res.personInReservations && this.group) {
      this.res.status = ResStatus.Cancelled;
      this.setDatesAndTimes();
      await saveReservation(this.res);
      for (const groupMember of this.res.personInReservations) {
        if (groupMember) {
          groupMember.status = GroupMemberStatus.NONE;
          await saveGroupMember(groupMember);
        }
      }
      for (const ass of this.res.assigns) {
        if (ass) {
          await deleteAssign(ass);
        }
        this.res.assigns = [];
      }
      let act = new Activity();
      act.propertyGuid = this.property.propertyGuid;
      act.kind = ActivityKind.Other;
      act.reservationGuid = this.res.reservationGuid;
      act.description = "Cancelled";
      act.notes = "Cancelled";
      act.takenBy = this.currentUser.userName;
      await saveActivity(act);
    }

    this.groupKey = Guid.newGuid();
    this.activitiesKey = Guid.newGuid();
    this.assignsKey = Guid.newGuid();
    this.busy = false;
  }

  cardChanged(arg: any) {
    this.res.creditCardNumber = arg;
  }

  async confirmationMethodChanged(arg: any) {
    this.res.confirmationMethod = Number(arg);
    this.confMethod = Number(arg);
    if (this.confMethod == ConfirmationMethod.NoneRequired) {
      //make confirmed
      this.res.status = ResStatus.Confirmed;
      this.setDatesAndTimes();
      this.res = await saveReservation(this.res);
    }
  }
  confNumChanged(arg: any) {
    // does this need to be changeable??
    this.res.confirmationNumber = arg;
  }

  formatCurrency(num: number) {
    // to do: do we need the invoice currency code here?
    let result = formatter(num, this.property.currencyCode);
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  depositAmountChanged(arg: any) {
    this.res.depositAmount = arg;
  }

  depositDateChanged(arg: any) {
    this.res.depositDate = arg;
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  getReadableResStatus() {
    let resStatus = getReservationStatusString(this.res);
    return resStatus;
  }
  getResStatusVariant() {
    let resStatusVariant = getReservationStatusColorVariant(this.res);
    return resStatusVariant;
  }

  notesChanged(arg: any) {
    this.res.notes = arg;
  }
  requestsChanged(arg: any) {
    this.res.requests = arg;
  }

  async refreshGroup() {
    this.groupKey = Guid.newGuid();
    this.invoicesKey = Guid.newGuid();
    this.activitiesKey = Guid.newGuid();
  }

  async editGroupMember(arg: any) {
    let person: Person = arg;
    // add this person as a group member. If none, add as primary contact
    if (this.isNew) {
      this.setDatesAndTimes();
      await saveReservation(this.res);
    }
    let groupMember = this.res.personInReservations.find(
      (g) => g.personGuid == person.personGuid
    );
    if (groupMember) {
      await saveGroupMember(groupMember);
    } else {
      await this.addNewGroupMember(person);
    }

    this.groupKey = Guid.newGuid();
    this.invoicesKey = Guid.newGuid();
    this.activitiesKey = Guid.newGuid();
    this.showPersonEditModal = false;
  }

  async addNewGroupMember(arg: any) {
    let person: Person = arg;
    // add this person as a group member. If none, add as primary contact
    if (this.isNew) {
      this.setDatesAndTimes();
      await saveReservation(this.res);
    }
    let groupMember = new PersonInReservation();
    groupMember.personGuid = person.personGuid;
    groupMember.reservationGuid = this.res.reservationGuid;
    let inv = new Invoice();
    inv.reservationGuid = this.res.reservationGuid;
    inv.propertyGuid = this.property.propertyGuid;
    inv.personGuid = groupMember.personGuid;
    inv.invoiceGuid = Guid.newGuid();
    inv.modifiedDate = new Date();
    inv.createdDate = new Date();

    if (!this.primaryContact()) {
      // set the invoice to this new primary contact
      if (this.res.invoices && this.res.invoices.length == 1) {
        inv = this.res.invoices[0];
        inv.personGuid = groupMember.personGuid;
      }
      this.res.name = person.lastCommaFirst;
      groupMember.isPrimaryContact = true;
    }
    inv = await saveInvoice(inv);
    this.res.invoices.push(inv);

    await saveGroupMember(groupMember);
    this.res.personInReservations.push(groupMember);
    // reload the group
    if (groupMember.isPrimaryContact) {
      this.res.name = person.lastCommaFirst;

      let act = new Activity();
      act.kind = ActivityKind.None;
      act.personGuid = person.personGuid;
      act.reservationGuid = this.res.reservationGuid;
      act.description = "New Primary Contact added - " + person.fullName;
      act.notes = act.description;
      act.propertyGuid = this.property.propertyGuid;
      act.takenBy = this.currentUser.userName;
      await saveActivity(act);
    }

    this.groupKey = Guid.newGuid();
    this.invoicesKey = Guid.newGuid();
    this.activitiesKey = Guid.newGuid();
    this.showPersonEditModal = false;
  }

  lockUnitsChanged(arg: any) {
    this.res.lockUnits = arg;
  }
  numPeople1Changed(arg: any) {
    this.res.numberOfPeople1 = arg;
  }
  numPeople3Changed(arg: any) {
    this.res.numberOfPeople3 = arg;
  }
  numPeople4Changed(arg: any) {
    this.res.numberOfPeople4 = arg;
  }
  numPeople2Changed(arg: any) {
    this.res.numberOfPeople2 = arg;
  }
  arrTimeChanged(arg: any) {
    this.arrivalTime = arg.toString();
     this.setDatesAndTimes();
    saveReservation(this.res);
  }
  depTimeChanged(arg: any) {
    this.departureTime = arg.toString();
     this.setDatesAndTimes();
    saveReservation(this.res);
  }
  arrivalDateChanged(arrDate: Date) {
    // set dates and times and save
    this.res.arrivalDate = new Date(arrDate + " " + this.arrivalTime);
    this.setDatesAndTimes();
    saveReservation(this.res);
  }
  departureDateChanged(depDate: Date) {
    this.res.departureDate = new Date(depDate + " " + this.departureTime);
     this.setDatesAndTimes();
    saveReservation(this.res);
  }

  editPrimaryContact() {
    //open a person edit window with the primary contact as the personGuid
    let pc = this.primaryContact();
    if (pc) {
      this.activePersonGuid = pc.personGuid;
      if (this.isExpandedMode() == true) {
        this.navigate("PersonEdit", this.activePersonGuid);
      }
      this.showPersonEditModal = true;
    }
  }

  navigate(page: string, id: string) {
    this.$router.push({
      name: page,
      params: {
        id: id,
      },
    });
  }

  primaryContact(): PersonInReservation | undefined {
    if (this.res && this.res.personInReservations) {
      const personInRes = this.res.personInReservations?.find(
        (pir) => pir.isPrimaryContact
      );
      if (personInRes) {
        return personInRes;
      }
    }
  }
  primaryInvoice(): Invoice | undefined {
    if (this.res && this.res.invoices) {
      const primaryInv = this.res.invoices?.find(
        (inv) => inv.personGuid == this.primaryContact()?.personGuid
      );
      if (primaryInv) {
        return primaryInv;
      } else {
        return this.res.invoices[0];
      }
    }
  }

  newPrimaryContact() {
    this.showPersonEditModal = true;
  }

  closePerson() {
    this.showPersonEditModal = false;
  }

  getActivitiesKey() {
    return this.activitiesKey;
  }
  getInvoicesKey() {
    return this.invoicesKey;
  }
  getStatusKey() {
    return this.statusKey;
  }

  getGroupKey() {
    return this.groupKey;
  }
  getAssignsKey() {
    return this.assignsKey;
  }

  async templatesent() {
    this.activitiesKey = Guid.newGuid();
    this.showTemplateSendModal = false;
  }

  async showModal() {
    await this.modal.show();
  }
  hideModal() {
    this.showTemplateSendModal = false;
    // this.modal.hide();
  }
  toggleModal() {
    // We pass the ID of the button that we want to return focus to
    // when the modal has hidden
    if (this.modal) {
      this.modal.toggle("#toggle-btn");
    }
  }

  getSendToEmailAddress() {
    if (this.res && this.res.personInReservations && this.group) {
      const personInRes = this.res.personInReservations?.find(
        (pir) => pir.isPrimaryContact
      );
      const primaryContactPersonGuid = personInRes?.personGuid;
      const groupMember = this.group.find(
        (g) => g.personGuid == primaryContactPersonGuid
      );
      if (groupMember) {
        return groupMember.email;
      }
    }
    return null;
  }

  getSelectedTemplateGuid() {
    if (this.templateSelected) {
      return this.templateSelected.templateGuid;
    } else {
      return null;
    }
  }

  getReservationGuid() {
    if (this.reservationGuid) {
      return this.reservationGuid;
    } else {
      return null;
    }
  }
  getPrimaryContactPersonGuid() {
    if (this.res && this.res.personInReservations && this.group) {
      const personInRes = this.res.personInReservations?.find(
        (pir) => pir.isPrimaryContact
      );
      const primaryContactPersonGuid = personInRes?.personGuid;

      if (primaryContactPersonGuid) {
        return primaryContactPersonGuid;
      }
    }
    return null;
  }

  async mergeAndDisplayTemplate(template: Template, inv: Invoice) {
    this.templateMode = "send";
    await (this.invoice = inv);
    await (this.templateSelected = template);
    await (this.showTemplateSendModal = true);
  }
  async printTemplate(template: Template, inv: Invoice) {
    this.templateMode = "print";
    await (this.invoice = inv);
    await (this.templateSelected = template);
    await (this.showTemplateSendModal = true);
  }

  validateName() {
    return true;
  }

  async cancel() {
     console.log('isNew = ' + this.isNew)
    // if (this.isNew) {
    //   await deleteReservation(this.res.reservationGuid);
    // }
    this.$emit("cancel");
  }

  getRes() {
    return this.res;
  }

  async mounted() {
    this.loading = true;
    if (this.paramReservationGuid) {
      this.reservationGuid = this.paramReservationGuid;
    } else if (this.$route.params.id) {
      this.reservationGuid = this.$route.params.id.toString();
    } else if (this.$route.query.reservationGuid) {
      this.reservationGuid = this.$route.query.reservationGuid.toString();
    }
    if (this.reservationGuid) {
      try {
        this.res = await getReservation(this.reservationGuid.toString());
      } catch {
        console.log("not found res");
      }
    }
    if (!this.res) {
      this.res = new Reservation();
      this.res.reservationGuid = Guid.newGuid();
      this.res.propertyGuid = this.property.propertyGuid;
      this.res.arrivalDate = new Date().addDays(14).addTime(
        new Date(this.property.defaultArrivalTime).toTimeString()
      );
      this.res.departureDate = new Date().addDays(21).addTime(
        new Date(this.property.defaultDepartureTime).toTimeString()
      );
      this.res.createdDate = new Date();
      this.res.numberOfPeople1 = this.property.defaultPeopleCount;
      this.res.status = ResStatus.Pending;
      this.res.confirmationMethod = this.property.confirmationMethod;

      if (this.isNew == true && this.paramPersonGuid) {
        // this is a new booking for a person. add a pir for the person
        await saveReservation(this.res);
        let pir = new PersonInReservation();
        pir.personGuid = this.paramPersonGuid;
        pir.isPrimaryContact = true;
        pir.reservationGuid = this.res.reservationGuid;
        this.res.personInReservations.push(pir);
        await saveGroupMember(pir);

        let person = await getPerson(this.paramPersonGuid);
        this.res.name = person.lastCommaFirst;
        let invoice = new Invoice();
        invoice.reservationGuid = this.res.reservationGuid;
        invoice.invoiceGuid = Guid.newGuid();
        invoice.propertyGuid = this.property.propertyGuid;
        invoice.personGuid = pir.personGuid;
        this.res.invoices.push(invoice);
        await saveInvoice(invoice);
      }
    } else {
      this.res.invoices = await getInvoicesByReservation(
        this.res.reservationGuid
      );
      this.group = await getPeopleForReservation(this.res.reservationGuid);
      this.members = await AuthService.getUsersForProperty(
        this.property.propertyGuid
      );
    }

    this.modal = this.$refs["my-modal"] as any;
    this.arrivalTime = new Date(this.res.arrivalDate).time(false);
    this.departureTime = new Date(this.res.departureDate).time(false);
    this.templates = await getTemplates(this.property.propertyGuid);
    this.confMethod = this.res.confirmationMethod;
    this.loading = false;
    console.log('isNew = ' + this.isNew);
  }

  setDatesAndTimes() {
    let arrdateString = new Date(this.res.arrivalDate).toDateString();
    this.res.arrivalDate = new Date(arrdateString + " " + this.arrivalTime);
    let depdateString = new Date(this.res.departureDate).toDateString();
    this.res.departureDate = new Date(depdateString + " " + this.departureTime);
  }

  async saveAndClose() {
     console.log('isNew = ' + this.isNew)
    this.busy = true;
    this.setDatesAndTimes();

    if (this.isNew && this.isNew == true) {
      let numNights = new Date(this.res.arrivalDate).daysDiff(
        new Date(this.res.departureDate)
      );
      let act = new Activity();
      act.kind = ActivityKind.Other;
      act.personGuid = this.primaryContact()?.personGuid;
      act.reservationGuid = this.res.reservationGuid;
      act.notes =
        "Added a new reservation " +
        this.res.name +
        " arriving on " +
        new Date(this.res.arrivalDate).toDateString() +
        " for " +
        numNights +
        " nights";
      act.description = "New Res created - " + this.res.name;
      act.propertyGuid = this.property.propertyGuid;
      act.takenBy = this.currentUser.userName;
      await saveActivity(act);
    }
    this.res = await saveReservation(
      this.res,
      this.forcePending == true ? false : true
    );
    if (this.isExpandedMode()) {
      this.navigate("Reservations", "");
    }
    this.$emit("success", this.res);
  }

  isExpandedMode() {
    return this.$route.name?.toLowerCase() == "reservationedit";
  }

  formatDate(dateTo: Date) {
    if (new Date(dateTo).isMinDate()) {
      return "";
    }
    return new Date(dateTo).readableDate(false);
  }
}
