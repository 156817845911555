
import { namespace } from "vuex-class";
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {},
})
class StripeConnect extends Vue {
  private isTest = location.href.includes("localhost");
  private loading = true;

  private LIVE_CLIENT_ID = "ca_By8DNqQKKZWt7AcEg5uNsymIm89GxZ9w";
  private TEST_CLIENT_ID = "ca_By8D7wUp4xOY6oyzGZWPqd8O44NnI1Ex";

  private LIVE_REDIRECT_URI = "https://cloud.lodgevault.com/stripecomplete";
  private TEST_REDIRECT_URI = "http://localhost:8080/stripecomplete";

  private clientId = this.isTest ? this.TEST_CLIENT_ID : this.LIVE_CLIENT_ID;
  private redirectUri = this.isTest
    ? this.TEST_REDIRECT_URI
    : this.LIVE_REDIRECT_URI;

  private buttonHref = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${this.clientId}&scope=read_write&redirect_uri=${this.redirectUri}`;

  async created() {
    if (this.$route.query.propertyGuid) {
      //this is not needed because we require login to do this now
    }
    this.loading = false;
  }

  @PropertyStore.State
  public property!: Property;
}
export default StripeConnect;
