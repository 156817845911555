
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";
import AccountEdit from "./AccountEdit.vue";
import { Field } from "@/models/interfaces/Field";
import { namespace } from "vuex-class";
import { Guid } from "@/utils/Guid";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { StringUtil } from "@/utils/StringUtil";
import { formatNumber } from "@/utils/currencyUtils";
import AccountTable from "./AccountTable.vue";
import { deleteAccounts, getAccounts } from "./AccountService";
import { Account } from "./Account";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    ConfigurationNavMenu,
    AccountTable,
    AccountEdit,
    ConfirmDeleteModal,
  },
})
export default class Accounts extends Vue {
  @PropertyStore.State
  public property!: Property;

  private loading = false;
  private showEditModal = false;
  private fields: Array<Field> = [];
  private infoModal!: {
    id: "info-modal";
    title: string;
    content: string;
  };
  private selected!: Array<any>;
  private clickedAccount!: Account | null;
  private clickedIndex = 0;
  private showConfirmModal = false;
  private accountsTable: any;
  private accounts!: Array<Account>;

  formatNumber(num: number) {
    return formatNumber(num);
  }

  phoneFormatter(value: string) {
    const result = StringUtil.formatPhoneNumber(value);
    return result;
  }

  getReadableDate(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateLocalized();
  }

  confirmDeleteAccounts() {
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async deleteAccounts() {
    const arrayofAccountsGuids = new Array<Guid>();
    this.selected.forEach((p) => {
      arrayofAccountsGuids.push(p.accountGuid);
    });
    await deleteAccounts(arrayofAccountsGuids);
    await this.retrieveAccounts();
    this.showConfirmModal = false;
  }

  selectAllRows() {
    this.accountsTable.selectAllRows();
  }
  clearSelected() {
    this.accountsTable.clearSelected();
  }
  selectThirdRow() {
    this.accountsTable.selectRow(2);
  }
  unselectThirdRow() {
    this.accountsTable.unselectRow(2);
  }

  selectMyRow(uniqueID) {
    const row = this.accounts.findIndex((x) => x.accountGuid === uniqueID);
    this.accountsTable.selectRow(row);
  }

  selectedItemsCount() {
    return this.selected && this.selected.length > 0;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getActiveAccount() {
    if (this.clickedAccount) {
      return this.clickedAccount;
    } else {
      return null;
    }
  }
  getActiveAccountGuid() {
    if (this.clickedAccount) {
      return this.clickedAccount.accountGuid;
    } else {
      return null;
    }
  }

  onRowDoubleClicked(account: Account, index: any, event: any) {
    this.clickedAccount = account;
    this.showEditModal = true;
  }

  async saved(account: Account) {
    let index = this.accounts.findIndex(
      (p) => p.accountGuid == account.accountGuid
    );
    this.accounts[index] = account;
    await this.accountsTable.refresh();
    this.accountsTable.selectRow(index);
    this.showEditModal = false;
  }

  cancel() {
    this.showEditModal = false;
  }

  addAccount() {
    this.clickedAccount = null;
    this.showEditModal = true;
  }

  async created() {
    await this.retrieveAccounts();
  }

  mounted() {
    this.accountsTable = this.$refs.accountsTableRef;
    this.getFields();
  }

  private getFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    this.fields.push(field);

    field = new Field();
    field.key = "description";
    field.label = "Description";
    this.fields.push(field);
  }

  async retrieveAccounts() {
    this.loading = true;
    this.accounts = await getAccounts(this.property.propertyGuid);
    this.loading = false;
  }
}
