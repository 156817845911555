
import Property from "@/models/Property";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FinanceNavMenu from "../partials/FinanceNavMenu.vue";
import InvoiceModal from "./InvoiceModal.vue";
import InvoiceTable from "./InvoiceTable.vue";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { InvoiceModal, FinanceNavMenu, InvoiceTable } })
export default class Invoices extends Vue {
  @PropertyStore.State
  public property!: Property;
}
