import { axiosRetry } from '@/utils/fetchUtil';
import { Guid } from '@/utils/Guid';
import { Payment } from './Payment';
import { CardTransactionResult } from '@/models/CardTransactionResult';


export async function deletePayments(paymentsGuids: Array<Guid>): Promise<boolean> {
  const response = await axiosRetry(
    `/api/payment/delete`,
    paymentsGuids,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the payment from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}
export async function deletePayment(paymentGuid: string): Promise<boolean> {
  const response = await axiosRetry(
    `/api/invoice/deletepayment/` + paymentGuid,
    null,
    "DELETE"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<boolean>;
  } else {
    const err =
      "An error occurred getting the payment from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function savePayment(payment: Payment): Promise<Payment> {
  const response = await axiosRetry(
    `/api/invoice/savepayment`,
    payment,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Payment>;
  } else {
    const err =
      "An error occurred getting the payment from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function processPayment(payment: Payment): Promise<CardTransactionResult> {
  const response = await axiosRetry(
    `/api/cart/processpayment`,
    payment,
    "POST"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<CardTransactionResult>;
  } else {
    const err =
      "An error occurred getting the payment from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}


export async function getPayments(personGuid?: string, reservationGuid?: string,
  propertyGuid?: string, startDate?: Date,
  endDate?: Date): Promise<Array<Payment>> {
  let url = '';
  if (personGuid) {
    url = `/api/payment/getbyperson/${personGuid}`;
  } else if (reservationGuid) {

    url = `/api/payment/getbyreservation/${reservationGuid}`;
  } else if (startDate && endDate) {
    let strStart = new Date(startDate).toDateString();
    let strEnd = new Date(endDate).toDateString();
    url = `/api/payment/getbyproperty/${propertyGuid}/${strStart}/${strEnd}`;
  }
  else {

    url = `/api/payment/getbyperson/${personGuid}`;
  }

  const response = await axiosRetry(
    url,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Payment>>;
  } else {
    const err =
      "An error occurred getting the payment from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getPaymentsByPerson(personGuid: string): Promise<Array<Payment>> {
  const response = await axiosRetry(
    `/api/payment/getbyperson/${personGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Array<Payment>>;
  } else {
    const err =
      "An error occurred getting the payment from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

export async function getPayment(paymentGuid: string): Promise<Payment> {
  const response = await axiosRetry(
    `/api/invoice/getpayment/${paymentGuid}`,
    null,
    "GET"
  );
  if (response && response.data && !response.data.error && response.status == 200) {
    return response.data as Promise<Payment>;
  } else {
    const err =
      "An error occurred getting the payment from the server." +
      `The error code was ${response.status} (${response.statusText}).`;
    throw Error(err);
  }
}

