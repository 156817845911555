import { ConfirmationMethod } from "@/components/Cloud/Reservations/ConfirmationMethod";
import GridSettings from "@/components/Cloud/Settings/GridSettings";
import { axiosRetry } from "@/utils/fetchUtil";
import CheckoutSettings from "./CheckoutSettings";
import { DayOfWeek } from "./DayOfWeek";
import { Preference } from "./Preference";
import { DepositMethod } from "@/components/Cloud/Reservations/DepositMethod";

export default class Property {

    constructor() {
    }

    public propertyGuid!: string;
    public name!: string;
    public address!: string;
    public city!: string;
    public state!: string;
    public zip!: string;
    public country!: string;
    public phone!: string;
    public email!: string;
    public website!: string;
    public active!: boolean;
    public defaultPeopleCount!: number;
    public cancelDays!: number;
    public thursdayIsWeekend!: boolean;
    public fridayIsWeekend!: boolean;
    public saturdayIsWeekend!: boolean;
    public sundayIsWeekend!: boolean;
    public mondayIsWeekend!: boolean;
    public tuesdayIsWeekend!: boolean;
    public wednesdayIsWeekend!: boolean;
    public defaultArrivalTime!: Date;
    public defaultDepartureTime!: Date;
    public defaultDepositAmount!: number;
    public defaultDepositMethod!: DepositMethod;
    public cancelFeeAmount!: number;
    public currencyCode!: string;
    public readableAddress!: string;
    public seasonOpenDate: Date | undefined;
    public seasonCloseDate: Date | undefined;
    public checkoutSettings!: CheckoutSettings;
    public gridSettings!: GridSettings;
    public analyticsScript!: string;
    public analyticsAccountNumber!: string;
    public description!: string;
    public preference!: Preference;
    public lastSyncEndDate!: Date;
    public logo!: string;
    public imageGuid!: string;
    public primaryColor!: string;
    public secondaryColor!: string;
    public timeZone!: string;
    public confirmationMethod!: ConfirmationMethod;
    public distance!: string;
    public categories!: Array<string>;
    public cityStateZip!: string;

    /// If this date is considered a weekend at this property
    public IsWeekendAtProperty(dateToCheck: DayOfWeek): boolean {
        let result = false;
        switch (dateToCheck) {
            case DayOfWeek.Monday:
                result = this.mondayIsWeekend;
                break;
            case DayOfWeek.Tuesday:
                result = this.tuesdayIsWeekend;
                break;
            case DayOfWeek.Wednesday:
                result = this.wednesdayIsWeekend;
                break;
            case DayOfWeek.Thursday:
                result = this.thursdayIsWeekend;
                break;
            case DayOfWeek.Friday:
                result = this.fridayIsWeekend;
                break;
            case DayOfWeek.Saturday:
                result = this.saturdayIsWeekend;
                break;
            case DayOfWeek.Sunday:
                result = this.sundayIsWeekend;
                break;
        }
        return result;
    }
}


export async function getProperties(): Promise<Array<Property>> {
    let url = `/api/property/getproperties`;
    const response = await axiosRetry(
        url,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<Array<Property>>;
    } else {
        const err =
            "An error occurred getting the account from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}