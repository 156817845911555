
import { Field } from "@/models/interfaces/Field";
import Property from "@/models/Property";
import { formatter } from "@/utils/currencyUtils";
import { Guid } from "@/utils/Guid";
import { Vue, Component, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import {
  deleteCustomQuestion,
  CustomQuestion,
  getCustomQuestions,
  getCustomQuestionsByProperty,
  saveCustomQuestion,
  QuestionType,
} from "./CustomQuestion";

const PropertyStore = namespace("PropertyStore");
@Component({ components: { ConfirmDeleteModal } })
export default class CustomQuestionTable extends Vue {
  @Prop({ default: null }) public unitGuid: string | undefined;
  @Prop({ default: null }) public customQuestionGuid: string | undefined;
  @Prop({ default: null }) public propertyGuid!: string;

  @PropertyStore.State
  public property!: Property;

  private showConfirmModal = false;
  private loading = true;
  private customQuestions: Array<CustomQuestion> = [];
  private customQuestionFields: Array<Field> = [];
  private activeOpt!: CustomQuestion | null;
  private customQuestionTable: any;
  private selected!: Array<any>;
  private timer!: number | undefined;

  confirmDelete(opt: CustomQuestion) {
    this.activeOpt = opt;
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  getTypeStr(opt: CustomQuestion) {
    return opt.type;
  }

  changeType(opt: CustomQuestion, val: string) {
    this.loading = true;
    opt.type = val;
    this.save(opt);
    this.loading = false;
  }

  async optChanged(opt: CustomQuestion) {
    clearTimeout(this.timer);
    this.timer = window.setTimeout(() => this.save(opt), 1000);
  }

  async add() {
    this.activeOpt = null;
    let question = new CustomQuestion();
    question.customQuestionGuid = Guid.newGuid();
    question.propertyGuid = this.property.propertyGuid;
    question.questionText = "";
    this.customQuestions.push(question);
    await saveCustomQuestion(question);
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getCurrency(amount: number) {
    return formatter(amount);
  }

  getReadableDateTime(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateTimeLocalized();
  }

  selectAllRows() {
    this.customQuestionTable.selectAllRows();
  }
  clearSelected() {
    this.customQuestionTable.clearSelected();
  }

  private getCustomQuestionFields() {
    let field = new Field();
    field.key = "questionText";
    field.label = "Question";
    this.customQuestionFields.push(field);

    field = new Field();
    field.key = "type";
    field.label = "Type";
    this.customQuestionFields.push(field);

    field = new Field();
    field.key = "required";
    field.label = "Required";
    this.customQuestionFields.push(field);

    field = new Field();
    field.key = "actions";
    field.label = "Actions";
    this.customQuestionFields.push(field);
  }

  async mounted() {
    this.loading = true;
    this.customQuestionTable = this.$refs.customQuestionTableRef;
    this.getCustomQuestionFields();
    if (this.unitGuid) {
      this.customQuestions = await getCustomQuestions(this.unitGuid);
    } else {
      this.customQuestions = await getCustomQuestionsByProperty(
        this.property.propertyGuid
      );
    }
    this.loading = false;
  }

  getActiveCustomQuestion() {
    if (this.activeOpt) {
      return this.activeOpt.customQuestionGuid;
    } else {
      return "";
    }
  }

  async save(opt: CustomQuestion) {
    await saveCustomQuestion(opt);
    await this.customQuestionTable.refresh();
  }

  async deleteOption() {
    let opt = this.activeOpt as CustomQuestion;
    if (opt) {
      let result = await deleteCustomQuestion(opt.customQuestionGuid);
      if (result) {
        if (this.customQuestions) {
          let index = this.customQuestions.findIndex(
            (a) => a.customQuestionGuid === opt.customQuestionGuid
          );
          this.customQuestions.splice(index, 1);
        }
      }
      await this.customQuestionTable.refresh();
    }
    this.showConfirmModal = false;
  }

  edit(customQuestion: any, index: any, event: any) {
    if (customQuestion) {
      this.activeOpt = customQuestion;
    } else if (this.selected && this.selected.length > 0) {
      this.activeOpt = this.selected[0];
    }
  }
}
