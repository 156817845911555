import { render, staticRenderFns } from "./SiteBook.vue?vue&type=template&id=6bc8367e&scoped=true&"
import script from "./SiteBook.vue?vue&type=script&lang=ts&"
export * from "./SiteBook.vue?vue&type=script&lang=ts&"
import style0 from "./SiteBook.vue?vue&type=style&index=0&id=6bc8367e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc8367e",
  null
  
)

export default component.exports