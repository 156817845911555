
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "../Cloud/Auth/User";
const Auth = namespace("AuthStore");

@Component
export default class GuestBookings extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  formatDate(dateFor: Date) {
    return new Date(dateFor).formatDayOfWeekMMDDYYYY();
  }

  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
  }
}
