
import { Vue, Component } from "vue-property-decorator";
import {
  getAllPeoplePaged,
  deletePeople,
} from "@/components/Cloud/People/PersonService";
import { PeoplePagedModel } from "@/models/PeoplePagedModel";
import { PagingParameters } from "@/models/PagingParameters";
import Property from "@/models/Property";
import PersonEdit from "../People/PersonEdit.vue";
import { Field } from "@/models/interfaces/Field";
import { namespace } from "vuex-class";
import {
  PersonRelationship,
  getPersonRelationshipColorVariant,
  getRelationshipFromPreference,
} from "@/components/Cloud/People/PersonRelationship";
import { Preference } from "@/models/Preference";
import { Person } from "@/components/Cloud/People/Person";
import { Guid } from "@/utils/Guid";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { StringUtil } from "@/utils/StringUtil";
import { Filter } from "@/models/Filter";
import { formatNumber } from "@/utils/currencyUtils";
import SearchBox from "@/components/Controls/SearchBox.vue";
import { export_table_to_csv } from "@/utils/exportToCsv";
import PeopleContextMenu from "./PeopleContextMenu.vue";
import ProgressModal from "../Dialogs/ProgressModal.vue";
import {
  mergeAndSend,
  sendEmail,
  SendEmailParams,
} from "../Templates/EmailService";
import { Template } from "@/models/Template";
import ConfirmSendModal from "../Dialogs/ConfirmSendModal.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    PersonEdit,
    ConfirmDeleteModal,
    SearchBox,
    PeopleContextMenu,
    ProgressModal,
    ConfirmSendModal,
  },
})
export default class People extends Vue {
  @PropertyStore.State
  public property!: Property;

  private pageSize = 50;
  private page = 1;
  private loading = false;
  private personIndex = 0;
  private searchTitle = "";
  private peoplePagedModel = new PeoplePagedModel();
  private showEditModal = false;
  private fields: Array<Field> = [];
  private infoModal!: {
    id: "info-modal";
    title: string;
    content: string;
  };
  private selected!: Array<any>;
  private clickedPerson!: Person | null;
  private clickedIndex = 0;
  private showConfirmModal = false;
  private peopleTable: any;
  private filters!: Array<Filter>;
  private relationship!: PersonRelationship | null;
  private pageOptions = [
    5,
    10,
    20,
    50,
    100,
    500,
    1000,
    {
      value: 0,
      text: "show all",
    },
  ];
  private popoverOptions = {
    popoverReference: null as any,
    placement: "auto",
    offset: "0,0",
  };
  private isContextMenuVisible = false;
  private progressValue = 0;
  private progressMax = 5;
  private progressMilliseconds = 50;
  private showProgressModal = false;
  private showConfirmSendModal = false;
  private template!: Template;

  confirmSend(template: Template) {
    this.isContextMenuVisible = false;
    this.template = template;
    this.showConfirmSendModal = true;
  }
  confirmSendNo() {
    this.showConfirmSendModal = false;
  }

  async confirmSendYes() {
    this.showConfirmSendModal = false;
    this.showProgressModal = true;
    await this.sendEmailsOnTimer(this.template);
  }

  async sendEmail(person: Person, template: Template) {
    let param = new SendEmailParams();
    param.subject = template.emailSubject;
    param.recipientEmail = person.email ?? person.email2;
    param.recipientFirstName = person.firstName;
    param.fromEmail = this.property.email;
    param.fromName = this.property.name;
    await mergeAndSend(template, param, this.property, person);
  }

  async sendEmailsOnTimer(template: Template) {
    this.progressValue = 0;
    this.progressMax = this.selected.length;
    let vm = this;
    let timer = await setInterval(() => {
      vm.progressValue += 1;
      vm.sendEmail(vm.selected[vm.progressValue - 1], template);
      if (vm.progressValue >= vm.progressMax) {
        clearInterval(timer);
        this.closeProgressModal();
      }
    }, this.progressMilliseconds);
  }

  async closeProgressModal() {
    this.showProgressModal = false;
  }

  async showContextMenu(people: People, index: any, event: any) {
    await this.closeContextMenu;
    await this.openPopover(event);
    this.isContextMenuVisible = true;
  }

  async openPopover(event: any) {
    let element = event.srcElement ?? event.srcElement;
    if (element) {
      if (element.parentElement) {
        element = element.parentElement;
      }
      this.popoverOptions.popoverReference = element;
      this.popoverOptions.placement = "top-start";
    }
  }

  async closeContextMenu() {
    this.isContextMenuVisible = false;
    this.popoverOptions.popoverReference = null;
  }

  async reSelectSelectedRows() {
    let index = 0;
    for (const item of this.peopleTable.items) {
      if (item.selected) {
        this.peopleTable.selectRow(index);
      }
      index++;
    }
  }

  selectAllColumnHeaderClicked(arg: any) {
    if (arg) {
      this.selectAll();
    } else {
      this.selectNone();
    }
  }

  allSelected() {
    if (this.peoplePagedModel.people) {
      let result = this.peoplePagedModel.people.every((a) => a.selected);
      return result;
    }
    return false;
  }

  selectChanged(arg: any) {
    if (arg.item.selected) {
      this.peopleTable.selectRow(arg.index);
    } else {
      this.peopleTable.unselectRow(arg.index);
    }
  }

  selectAll() {
    if (this.allSelected()) {
      this.selectNone();
      return;
    }
    for (const res of this.peoplePagedModel.people) {
      res.selected = true;
    }
    this.peopleTable.selectAllRows();
  }
  selectNone() {
    for (const res of this.peoplePagedModel.people) {
      res.selected = false;
    }
    this.peopleTable.clearSelected();
  }

  selectMyRow(uniqueID) {
    const row = this.peoplePagedModel.people.findIndex(
      (x) => x.personGuid === uniqueID
    );
    this.peopleTable.selectRow(row);
  }

  selectedItemsCount() {
    return this.selected && this.selected.length > 0;
  }

  onRowSelected(items: any) {
    for (const res of this.peoplePagedModel.people) {
      res.selected = false;
    }
    for (const p of items) {
      p.selected = true;
    }
    this.selected = items;
    this.$forceUpdate();
  }

  onTableSorted(arg: any) {
    // get the search params and set the page to one, set the sort ASC/DESC to the opposite
    // and set the params sort property
    // go back to the server and reload the list
    // still need a way to select all and send email to all people...or do a search and send to a large number
    //console.log("sorted " + arg);
  }

  pageSizeChanged(arg: any) {
    this.page = 1;
    this.retrievePeople();
  }

  async exportTableToExcel() {
    this.loading = true;
    var csvTable = this.$el.querySelector("#peopleTableRef"); // this is ID
    if (csvTable) {
      await export_table_to_csv(csvTable, "People.csv");
    }
    this.loading = false;
  }

  editPerson() {
    if (this.selected && this.selected.length > 0) {
      let person = this.selected[0];
      this.navigateToSearchResult(person);
    }
  }

  navigateToSearchResult(arg: any) {
    this.clickedPerson = arg;
    this.showEditModal = true;
  }

  formatNumber(num: number) {
    return formatNumber(num);
  }

  getPersonRelationshipCount(relationship: PersonRelationship) {
    if (!this.peoplePagedModel || !this.peoplePagedModel.relationshipCounts) {
      return 0;
    }
    if (!relationship) {
      //this is the total people so just add them up
      const result = this.peoplePagedModel.relationshipCounts.reduce(
        (accumulator, current) => {
          return accumulator + current;
        },
        0
      );
      return this.formatNumber(result);
    }
    return this.formatNumber(
      this.peoplePagedModel.relationshipCounts[relationship]
    );
  }

  activeRelationship() {
    return this.relationship;
  }

  async filterPeople(relationship: PersonRelationship) {
    this.filters = [];
    this.page = 1;
    if (relationship) {
      this.relationship = relationship;
      const filter = new Filter();
      filter.field = "Relationship";
      filter.value = relationship.toString();
      this.filters.push(filter);
    } else {
      this.relationship = null;
    }

    await this.retrievePeople();
  }

  phoneFormatter(value: string) {
    const result = StringUtil.formatPhoneNumber(value);
    return result;
  }

  getReadableDate(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateLocalized();
  }

  confirmDeletePeople() {
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async deletePeople() {
    if (this.selected && this.selected.length > 0) {
      const arrayofPeopleGuids = new Array<Guid>();
      for (const deletedPerson of this.selected) {
        arrayofPeopleGuids.push(deletedPerson.personGuid);
      }
      await deletePeople(arrayofPeopleGuids);

      this.selected.forEach(async (s) => {
        let deleted = s;
        if (this.peoplePagedModel.people) {
          let index = this.peoplePagedModel.people.findIndex(
            (a) => a.personGuid === deleted.personGuid
          );
          this.peoplePagedModel.people.splice(index, 1);
        }
      });
    }
    this.showConfirmModal = false;
    await this.peopleTable.refresh();
    this.reSelectSelectedRows();
  }
  getActivePerson() {
    if (this.clickedPerson) {
      return this.clickedPerson;
    } else {
      return null;
    }
  }
  getActivePersonGuid() {
    if (this.clickedPerson) {
      return this.clickedPerson.personGuid;
    } else {
      return null;
    }
  }

  rowMouseDown(person: Person, index: any, event: any) {
    this.clickedPerson = person;
    this.showEditModal = true;
  }

  async saved(person: Person) {
    let found = this.peoplePagedModel.people.find(
      (r) => r.personGuid === person.personGuid
    );
    if (!found) {
      await this.peoplePagedModel.people.splice(0, 0, person);
      this.peopleTable.selectRow(0);
    } else {
      let index = await this.peoplePagedModel.people.findIndex(
        (r) => r.personGuid === person.personGuid
      );

      this.peoplePagedModel.people[index] = person;
      await this.peopleTable.refresh();
      this.peopleTable.selectRow(index);
    }
    await this.reSelectSelectedRows();
    this.showEditModal = false;
  }

  getRelationshipColor(val: PersonRelationship) {
    if (!val) {
      return "";
    }
    return getPersonRelationshipColorVariant(val);
  }

  getPersonRelationshipName(val: PersonRelationship) {
    return getRelationshipFromPreference(
      this.property.preference as Preference,
      val
    );
  }

  cancel() {
    this.showEditModal = false;
  }

  addPerson() {
    this.clickedPerson = null;
    this.showEditModal = true;
  }

  getPagingParams(searchTitle, page, pageSize, propertyGuid) {
    const params = new PagingParameters();

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["CurrentPage"] = page;
    }

    if (pageSize) {
      params["PageSize"] = pageSize;
    }

    if (propertyGuid) {
      params["PropertyGuid"] = propertyGuid;
    }
    params["SortBy"] = "LastName";
    params.filters = this.filters;
    return params;
  }

  handlePageChange(value) {
    this.page = value;
    this.retrievePeople();
  }

  async created() {
    await this.retrievePeople();
  }

  mounted() {
    this.peopleTable = this.$refs.peopleTableRef;
    this.getFields();
  }

  private getFields() {
    let field = new Field();
    field.sortable = true;
    field.key = "selected";
    field.label = "Select";
    this.fields.push(field);

    field = new Field();
    field.key = "lastCommaFirst";
    field.label = "Name";
    field.sortable = true;
    this.fields.push(field);

    field = new Field();
    field.key = "company";
    field.label = "Company";
    field.sortable = true;
    this.fields.push(field);

    field = new Field();
    field.key = "cityStateZip";
    field.label = "City State Zip";
    field.sortable = true;
    this.fields.push(field);

    field = new Field();
    field.key = "relationship";
    field.label = "Relationship";
    field.sortable = true;
    this.fields.push(field);

    field = new Field();
    field.key = "callBack";
    field.label = "Call Back";
    field.sortable = true;
    this.fields.push(field);

    // field = new Field();
    // field.key = "nextStay";
    // field.label = "Next";
    // this.fields.push(field);

    // field = new Field();
    // field.key = "lastStay";
    // field.label = "Last";
    // this.fields.push(field);

    // field = new Field();
    // field.key = "addr1Line1";
    // field.label = "Address";
    // this.fields.push(field);

    // field = new Field();
    // field.key = "addr1Line2";
    // field.label = "Address 2";
    // this.fields.push(field);

    field = new Field();
    field.key = "email";
    field.label = "Email";
    this.fields.push(field);

    field = new Field();
    field.key = "homePhone";
    field.label = "Home";
    this.fields.push(field);

    // field = new Field();
    // field.key = "cellPhone";
    // field.label = "Cell";
    // this.fields.push(field);

    // field = new Field();
    // field.key = "notes";
    // field.label = "Notes";
    // this.fields.push(field);
  }

  async retrievePeople() {
    this.loading = true;
    const pagingParams = this.getPagingParams(
      this.searchTitle,
      this.page,
      this.pageSize,
      this.property.propertyGuid
    );
    await getAllPeoplePaged(this.property.propertyGuid, pagingParams)
      .then((data) => {
        this.peoplePagedModel = data;
      })
      .catch((ex) => {
        console.log(ex);
      });
    this.loading = false;
  }
}
