
import MpFooter from "@/components/Cloud/partials/MpFooter.vue";
import MpHeader from "@/components/Cloud/partials/MpHeader.vue";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: { MpHeader, MpFooter },
})
class LayoutMp extends Vue {
  private loading = true;
  private windowWidth = window.innerWidth;
  private windowHeight = window.innerHeight;

  currentRouteName() {
    return this.$route.name;
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  created() {
    this.loading = true;
    this.loading = false;
  }
  navigate(page: string) {
    this.$router.push(page);
  }
}
export default LayoutMp;
