import { axiosRetry } from "@/utils/fetchUtil";

export class OutOfService {
    constructor() {
    }
    public outOfServiceGuid!: string;
    public propertyGuid!: string;
    public modifiedDate!: Date;
    public createdDate!: Date;
    public notes!: string;
}


export async function getOutOfService(outOfServiceGuid: string): Promise<OutOfService> {
    const response = await axiosRetry(
        `/api/reservation/getoutofservice/` + outOfServiceGuid,
        null,
        "GET"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<OutOfService>;
    } else {
        const err =
            "An error occurred getting the assign from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}

export async function saveOutOfService(outOfService: OutOfService): Promise<boolean> {
    const response = await axiosRetry(
        `/api/reservation/saveoutofservice`,
        outOfService,
        "POST"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred getting the people from the server." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}


export async function deleteOutOfService(oos: OutOfService): Promise<boolean> {
    const response = await axiosRetry(
        `/api/reservation/deleteoutofservice`,
        oos,
        "DELETE"
    );
    if (response && response.data && !response.data.error && response.status == 200) {
        return response.data as Promise<boolean>;
    } else {
        const err =
            "An error occurred saving the group member." +
            `The error code was ${response.status} (${response.statusText}).`;
        throw Error(err);
    }
}
