import CartItem from '@/components/Cart/CartItem';
import { fetchRetry } from '@/utils/fetchUtil';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
@Module({ namespaced: true })
class CartStore extends VuexModule {
    private cart = window.localStorage.getItem('cart');
    public cartCount = window.localStorage.getItem('cartCount');
    public cartItems: Array<CartItem> = this.cart ? JSON.parse(this.cart) : [];
    public showPopupCart = false;
    public showPopupCloseModal = false;


    @Action
    public async setCartItem(cartItem: CartItem): Promise<boolean> {
        this.context.commit('UPDATE_CARTITEM', cartItem);
        this.context.commit('SAVE_CART');
        return true;
    }

    @Action
    public getCartItem(cartItemGuid: string): CartItem | undefined {
        return this.cartItems.find((c) => c.cartItemGuid === cartItemGuid);
    }

    @Action
    public showOrHidePopupCart(): void {
        this.context.commit('SHOW_POPUP_CART');
    }
    @Action
    public showOrHideCloseModal(): void {
        this.context.commit('SHOW_POPUP_CLOSE_MODAL');
    }
    @Action
    public getItemsInCart(): Array<CartItem> {
        return this.cartItems;
    }
    @Action
    public getPopupCart(): boolean | undefined {
        return this.showPopupCart;
    }

    @Action
    public async addCartItem(cartItem: CartItem): Promise<boolean> {
        this.context.commit('ADD_CARTITEM', cartItem);
        this.context.commit('SAVE_CART');
        return true;
    }
    @Action
    public removeCartItem(id: string): void {
        this.context.commit('REMOVE_CARTITEM', id);
        this.context.commit('SAVE_CART');
    }
    @Action
    public clearCart(): void {
        this.context.commit('CLEAR_CART');
        this.context.commit('SAVE_CART');
    }

    @Mutation
    public SAVE_CART() {
        window.localStorage.setItem('cart', JSON.stringify(this.cartItems));
        window.localStorage.setItem('cartCount', this.cartItems.length.toString());
    }

    @Mutation
    public UPDATE_CARTITEM(cartItemToUpdate: CartItem): void {
        const indexToChange = this.cartItems.findIndex((c) => c.cartItemGuid === cartItemToUpdate.cartItemGuid);
        if (indexToChange > -1) {
            this.cartItems.splice(indexToChange, 1, cartItemToUpdate);
        }
    }

    @Mutation
    public ADD_CARTITEM(cartItem: CartItem): void {
        this.cartItems.push(cartItem);
    }

    @Mutation
    public REMOVE_CARTITEM(id: string): void {
        if (this.cartItems) {
            let index = this.cartItems.findIndex(c => c.cartItemGuid == id);
            if (index > -1) {
                this.cartItems.splice(index, 1);
            }
        }
    }
    @Mutation
    public CLEAR_CART(): void {
        if (this.cartItems) {
            this.cartItems = [];
        }
    }
    @Mutation
    public SHOW_POPUP_CART(): void {
        this.showPopupCart = !this.showPopupCart;
    }
    @Mutation
    public SHOW_POPUP_CLOSE_MODAL(): void {
        this.showPopupCloseModal = !this.showPopupCloseModal;
    }

}
export default CartStore
