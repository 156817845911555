
import { Vue, Component } from "vue-property-decorator";
import Property from "@/models/Property";
import ChargeItemEdit from "./ChargeItemEdit.vue";
import { Field } from "@/models/interfaces/Field";
import { namespace } from "vuex-class";
import { Guid } from "@/utils/Guid";
import ConfirmDeleteModal from "../Dialogs/ConfirmDeleteModal.vue";
import { StringUtil } from "@/utils/StringUtil";
import { formatNumber } from "@/utils/currencyUtils";
import ChargeItemTable from "./ChargeItemTable.vue";
import { deleteChargeItems, getChargeItems } from "./ChargeItemService";
import { ChargeItem } from "./ChargeItem";
import ConfigurationNavMenu from "../partials/ConfigurationNavMenu.vue";

const PropertyStore = namespace("PropertyStore");
@Component({
  components: {
    ChargeItemTable,
    ChargeItemEdit,
    ConfirmDeleteModal,
    ConfigurationNavMenu,
  },
})
export default class ChargeItems extends Vue {
  @PropertyStore.State
  public property!: Property;

  private loading = false;
  private showEditModal = false;
  private fields: Array<Field> = [];
  private infoModal!: {
    id: "info-modal";
    title: string;
    content: string;
  };
  private selected!: Array<any>;
  private clickedChargeItem!: ChargeItem | null;
  private clickedIndex = 0;
  private showConfirmModal = false;
  private chargeItemsTable: any;
  private chargeItems!: Array<ChargeItem>;

  formatNumber(num: number) {
    return formatNumber(num);
  }

  phoneFormatter(value: string) {
    const result = StringUtil.formatPhoneNumber(value);
    return result;
  }

  getReadableDate(dateToFormat: Date) {
    return new Date(dateToFormat).readableDateLocalized();
  }

  confirmDeleteChargeItems() {
    this.showConfirmModal = true;
  }
  cancelDeletion() {
    this.showConfirmModal = false;
  }

  async deleteChargeItems() {
    const arrayofChargeItemsGuids = new Array<Guid>();
    this.selected.forEach((p) => {
      arrayofChargeItemsGuids.push(p.chargeItemGuid);
    });
    await deleteChargeItems(arrayofChargeItemsGuids);
    await this.retrieveChargeItems();
    this.showConfirmModal = false;
  }

  selectAllRows() {
    this.chargeItemsTable.selectAllRows();
  }
  clearSelected() {
    this.chargeItemsTable.clearSelected();
  }
  selectThirdRow() {
    this.chargeItemsTable.selectRow(2);
  }
  unselectThirdRow() {
    this.chargeItemsTable.unselectRow(2);
  }

  selectMyRow(uniqueID) {
    const row = this.chargeItems.findIndex(
      (x) => x.chargeItemGuid === uniqueID
    );
    this.chargeItemsTable.selectRow(row);
  }

  selectedItemsCount() {
    return this.selected && this.selected.length > 0;
  }

  onRowSelected(items: any) {
    this.selected = items;
    this.$forceUpdate();
  }

  getActiveChargeItem() {
    if (this.clickedChargeItem) {
      return this.clickedChargeItem;
    } else {
      return null;
    }
  }
  getActiveChargeItemGuid() {
    if (this.clickedChargeItem) {
      return this.clickedChargeItem.chargeItemGuid;
    } else {
      return null;
    }
  }

  onRowDoubleClicked(chargeItem: ChargeItem, index: any, event: any) {
    this.clickedChargeItem = chargeItem;
    this.showEditModal = true;
  }

  async saved(chargeItem: ChargeItem) {
    let index = this.chargeItems.findIndex(
      (p) => p.chargeItemGuid == chargeItem.chargeItemGuid
    );
    this.chargeItems[index] = chargeItem;
    await this.chargeItemsTable.refresh();
    this.chargeItemsTable.selectRow(index);
    this.showEditModal = false;
  }

  cancel() {
    this.showEditModal = false;
  }

  addChargeItem() {
    this.clickedChargeItem = null;
    this.showEditModal = true;
  }

  async created() {
    await this.retrieveChargeItems();
  }

  mounted() {
    this.chargeItemsTable = this.$refs.chargeItemsTableRef;
    this.getFields();
  }

  private getFields() {
    let field = new Field();
    field.key = "name";
    field.label = "Name";
    this.fields.push(field);

    field = new Field();
    field.key = "description";
    field.label = "Description";
    this.fields.push(field);
  }

  async retrieveChargeItems() {
    this.loading = true;
    this.chargeItems = await getChargeItems(this.property.propertyGuid);
    this.loading = false;
  }
}
