
import MultiSelect from "vue-multiselect";
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
  components: { MultiSelect },
})
export default class MultiSelectTester extends Vue {
  private value = [{ name: "Javascript", code: "js" }];
  options = [
    { name: "Vue.js", code: "vu" },
    { name: "Javascript", code: "js" },
    { name: "Open Source", code: "os" },
  ];

  addTag(newTag) {
    const tag = {
      name: newTag,
      code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    };
    this.options.push(tag);
    this.value.push(tag);
  }

  private selectedCountries = [
    {
      name: "United Arab Emirates",
      code: "AE",
    },
    {
      name: "Antarctica",
      code: "AQ",
    },
  ];
  private countries = [
    {
      name: "United Arab Emirates",
      code: "AE",
    },
    {
      name: "Antarctica",
      code: "AQ",
    },
  ];
  private isLoading = false;

  limitText(count) {
    return `and ${count} other countries`;
  }
  async asyncFind(query) {
    this.isLoading = true;
    let response = await this.ajaxFindCountry(query);
    this.countries = response;
    this.isLoading = false;
  }
  clearAll() {
    this.selectedCountries = [];
  }

  async ajaxFindCountry(query: string) {
    return [
      {
        name: "United Arab Emirates",
        code: "AE",
      },
      {
        name: "Antarctica",
        code: "AQ",
      },
    ];
  }

  async mounted() {}
}
