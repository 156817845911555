export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    static isValidGuid() {
        const UUID_REGEX = /^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/i;
        return function (uuid) {
            return uuid && UUID_REGEX.test(uuid);
        };
    }

    static isEmptyGuid(data: string) {
        return data === '00000000-0000-0000-0000-000000000000';
    }
    static emptyGuid() {
        return '00000000-0000-0000-0000-000000000000';
    }
}