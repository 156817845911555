
import PasswordInput from "@/components/Controls/PasswordInput.vue";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AuthService from "./AuthService";
const Auth = namespace("AuthStore");
@Component({
  components: { PasswordInput },
})
export default class ConfirmEmail extends Vue {
  private errors: Array<string> = [];
  private loading = false;
  private showProgressOverlay = false;
  private emailAddress = "";
  private token = "";
  private userId = "";
  private verified = false;
  private hasBeenResent = false;
  private resentFailed = false;

  @Auth.Getter
  private isLoggedIn!: boolean;

  navigate(page: string) {
    this.$router.push({ name: page });
  }

  async mounted() {
    this.showProgressOverlay = true;
    this.loading = true;
    this.errors = [];
    this.token = this.$route.query.code.toString();
    this.userId = this.$route.query.userid.toString();
    // get the user ??
    // or just skip and go right to confirm?
    await this.confirm();
  }

  private async resend() {
    this.errors = [];
    return await AuthService.resendConfirmEmail(this.userId).then(
      (response) => {
        if (response.data.error) {
          this.loading = false;
          this.showProgressOverlay = false;
          this.hasBeenResent = false;
          this.errors.push(response.data.error);
        } else {
          // show success
          this.loading = false;
          this.showProgressOverlay = false;
          this.hasBeenResent = true;
        }
      },
      (error) => {
        this.loading = false;
        this.showProgressOverlay = false;
        this.hasBeenResent = false;
        this.errors.push(error);
      }
    );
  }

  private async confirm() {
    this.errors = [];
    return await AuthService.confirmUser(this.userId, this.token).then(
      (response) => {
        if (response.data.error) {
          this.loading = false;
          this.showProgressOverlay = false;
          this.verified = false;
        } else {
          // show success
          this.loading = false;
          this.showProgressOverlay = false;
          this.verified = true;
        }
      },
      (error) => {
        this.loading = false;
        this.showProgressOverlay = false;
        this.verified = false;
      }
    );
  }
}
