
import { axiosRetry, fetchRetry } from "@/utils/fetchUtil";
import {
  getAllAvailability,
  getAvailability,
  GridModel,
  GridParameters,
} from "@/models/GridModel";
import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { GridUnit } from "@/models/GridUnit";
import GridDatePopup from "@/components/SiteBook/GridDatePopup.vue";
import { Unit, UnitTypes } from "@/components/Cloud/Units/Unit";
import BasePopover from "@/components/Popover/BasePopover.vue";
import BasePopoverContent from "@/components/Popover/BasePopoverContent.vue";
import VueTourPopoverContent from "@/components/Popover/VueTourPopoverContent.vue";
import RatePopup from "@/components/Popover/RatePopup.vue";
import { GridDay } from "@/models/GridDay";
import DatePicker from "@/components/SiteBook/DatePicker.vue";
import Property from "@/models/Property";
import { GridMonth } from "@/models/GridMonth";
import { AvailabilityParameters } from "@/models/AvailabilityParameters";
import { formatter, roundToDollar } from "@/utils/currencyUtils";
import { UnitGroup } from "@/models/UnitGroup";
import { Guid } from "@/utils/Guid";
import { getUnitGroups } from "../Cloud/Units/UnitGroupService";
import MobileGrid from "./MobileGrid.vue";

const PropertyStore = namespace("PropertyStore");
const AvailabilityParametersStore = namespace("AvailabilityParametersStore");
@Component({
  components: {
    GridDatePopup,
    BasePopover,
    BasePopoverContent,
    VueTourPopoverContent,
    RatePopup,
    MobileGrid,
    DatePicker,
  },
})
export default class Grid extends Vue {
  @PropertyStore.State
  public property!: Property;

  @AvailabilityParametersStore.State
  private availParams!: AvailabilityParameters;

  private showOverlay = false;
  private gridModel = new GridModel();
  private length = 14;
  private firstHoveredDate!: Date | null;
  private secondHoveredDate!: Date | null;
  private firstClickedDate!: Date | null;
  private secondClickedDate!: Date | null;
  public isPopoverVisible = false;
  private windowWidth = window.innerWidth;
  private popoverOptions = {
    popoverReference: null as any,
    placement: "top",
    offset: "0,0",
  };
  private activeDayIndex = 0;
  private activeWeekIndex = 0;
  private activeUnitIndex = 0;
  private showMobilePopup = false;
  private selectedUnit?: Unit = new Unit();
  private showDatePicker = false;
  private hoveredUnitBackgroundImage = "";
  private frozen = false;
  private clickedGridUnit: GridUnit | undefined;
  private skipUnavailable = false;
  private unitGroups = new Array<UnitGroup>();

  activeGridUnit() {
    return this.clickedGridUnit ? this.clickedGridUnit : null;
  }

  navigateToUnit(unitObj: Unit) {
    this.$router.push({
      name: "Book",
      params: { unit: unitObj.unitGuid },
      query: {
        date: this.firstClickedDate
          ? new Date(this.firstClickedDate).dashedDate()
          : new Date().dashedDate(),
      },
    });
  }

  showAsAvailableDay(gridDay: GridDay, gridUnit: GridUnit) {
    return (!gridDay.booked && !gridDay.isInPast) || gridDay.arriving;
  }

  showAsBooked(gridDay: GridDay, unit: Unit) {
    return (
      (gridDay.booked || gridDay.isInPast || this.isToday(gridDay)) &&
      !gridDay.arriving
    );
  }

  isToday(gridDay: GridDay) {
    return new Date(gridDay.dateFor).areSameDay(new Date());
  }

  displayPrice(gridDay: GridDay) {
    if (gridDay.nightlyPrice) {
      return gridDay.nightlyPrice;
    }
  }

  shouldShowPrice(gridDay: GridDay): boolean {
    return (
      this.property.checkoutSettings.showPricesOnGrid &&
      (gridDay.nightlyPrice > 0 || gridDay.weeklyPrice > 0) &&
      !gridDay.arriving &&
      !gridDay.booked &&
      !gridDay.isSecondDate
    );
  }

  clickedUnitGuid() {
    return this.clickedGridUnit ? this.clickedGridUnit.unit.unitGuid : null;
  }
  clickedUnit() {
    return this.clickedGridUnit ? this.clickedGridUnit.unit : null;
  }

  clicked() {
    return this.firstClickedDate || this.secondClickedDate;
  }

  firstDate() {
    return this.firstClickedDate
      ? this.firstClickedDate
      : this.firstHoveredDate
      ? this.firstHoveredDate
      : "";
  }

  secondDate() {
    return this.secondClickedDate
      ? new Date(this.secondClickedDate)
      : this.secondHoveredDate
      ? new Date(this.secondHoveredDate)
      : "";
  }

  isWeekend(dateObj: Date) {
    return new Date(dateObj).isWeekend(true);
  }

  formatCurrency(num: number) {
    let result = roundToDollar(num);
    if (this.property.currencyCode === "CAD") {
      result = "CA" + result;
    }
    if (num < 0) {
      //remove the negative sign
      while (result.charAt(0) === "-") {
        result = result.substring(1);
      }

      result = "(" + result + ")";
    }
    return result;
  }

  gridUnitsInGroup(unitGroupName: string) {
    if (this.unitGroups.length <= 1) {
      return this.gridModel.gridUnits;
    }
    return this.gridModel.gridUnits.filter((gu) => {
      return gu.unit.unitGroupName == unitGroupName;
    });
  }

  dateClick(gridDay: GridDay, gridUnit: GridUnit) {
    const dateClicked = gridDay.dateFor;
    // make sure action happens on same unit otherwise clear existing selection
    if (this.clickedGridUnit != gridUnit) {
      this.clear();
      this.clickedGridUnit = gridUnit;
    }

    if (this.firstClickedDate != null && this.secondClickedDate != null) {
      this.firstClickedDate = null;
      this.secondClickedDate = null;
      this.clear();
    }

    if (
      gridUnit.unit.unitType == UnitTypes.Event ||
      gridUnit.unit.unitType == UnitTypes.Rental
    ) {
      this.firstClickedDate = dateClicked;
      this.secondClickedDate = dateClicked;
      gridDay.selected = true;
      gridDay.isFirstDate = true;
      gridDay.isSecondDate = true;
      this.isPopoverVisible = true;
      this.navigateToUnit(gridUnit.unit);
    }

    if (this.firstClickedDate === null) {
      if (!gridDay.arriving) {
        this.firstClickedDate = new Date(dateClicked);
        this.secondClickedDate = null;
        gridDay.selected = true;
        gridDay.isFirstDate = true;
        this.isPopoverVisible = true;
      }
    } else if (this.secondClickedDate === null) {
      this.secondClickedDate = new Date(dateClicked);
      //swap
      if (
        new Date(this.firstClickedDate).valueOf() >
        this.secondClickedDate.valueOf()
      ) {
        const firstDateTemp = this.firstClickedDate;
        this.firstClickedDate = this.secondClickedDate;
        this.secondClickedDate = firstDateTemp;
      }
    }

    if (this.firstClickedDate && this.secondClickedDate) {
      // do some validation
      if (
        !this.validate(this.firstClickedDate, this.secondClickedDate, gridUnit)
      ) {
        this.clear();
      } else {
        this.frozen = true;
        this.highlightDateRange(
          this.firstClickedDate,
          this.secondClickedDate,
          gridUnit
        );
      }
    }
  }

  validate(firstDate: Date, secondDate: Date, grdUnit: GridUnit): boolean {
    if (!this.$el) {
      return false;
    }

    const container = document.querySelector(
      "#unit-" + grdUnit.unit.unitGuid.toString()
    );
    if (container) {
      const children = container.querySelectorAll(".grid-day");
      for (const cell of children) {
        const cellElement = cell as HTMLTextAreaElement;
        const thisCellData = cellElement?.getAttribute("data-date")?.toString();
        if (thisCellData) {
          const thisCellsDate = new Date(thisCellData).removeTime();
          firstDate = new Date(firstDate).removeTime();
          secondDate = new Date(secondDate).removeTime();
          const gridDay = this.getGridDay(thisCellsDate, grdUnit);

          if (!gridDay) {
            //disregard; it's nothing
            return false;
          } else {
            if (thisCellsDate.valueOf() === secondDate.valueOf()) {
              // departure
              if (gridDay.booked && !gridDay.arriving) {
                return false;
              }
            } else if (thisCellsDate.valueOf() === firstDate.valueOf()) {
              //arrival
              if (gridDay.booked || gridDay.arriving) {
                return false;
              }
            } else if (
              (thisCellsDate.valueOf() < secondDate.valueOf() &&
                thisCellsDate.valueOf() > firstDate.valueOf()) ||
              (thisCellsDate.valueOf() > secondDate.valueOf() &&
                thisCellsDate.valueOf() < firstDate.valueOf())
            ) {
              // in range
              if (gridDay.booked || gridDay.arriving) {
                return false;
              }
            } else {
              //out of range
            }
          }
        }
      }
    }
    return true;
    //check for packages with strict day of week
    // check for zero or absent price and tell to call or contact on the popup??
  }

  private clear() {
    for (const gridUnit of this.gridModel.gridUnits) {
      for (const gridDay of gridUnit.gridDays) {
        gridDay.selected = false;
        gridDay.inRange = false;
        gridDay.isFirstDate = false;
        gridDay.isSecondDate = false;
      }
    }
    this.firstClickedDate = null;
    this.secondClickedDate = null;
    this.frozen = false;
    this.closePopover();
  }

  mouseoverDay(el: string, dateHovered: any, gridUnitHovered: GridUnit) {
    if (!this.frozen) {
      if (
        gridUnitHovered.unit.unitGuid != this.clickedGridUnit?.unit.unitGuid
      ) {
        this.clear();
      }

      if (gridUnitHovered) {
        this.hoveredUnitBackgroundImage = gridUnitHovered.unit.thumb;
      }
      if (dateHovered) {
        if (!this.firstClickedDate) {
          this.firstHoveredDate = new Date(dateHovered);
        } else {
          this.secondHoveredDate = new Date(dateHovered);
        }
      }

      this.openPopover(el);
      if (this.firstClickedDate && this.secondHoveredDate) {
        this.highlightDateRange(
          this.firstClickedDate,
          this.secondHoveredDate,
          gridUnitHovered
        );
      }
    }
  }

  private highlightDateRange(
    firstDate: Date,
    secondDate: Date,
    grdUnit: GridUnit
  ) {
    if (!this.$el) {
      return;
    }

    const container = document.querySelector(
      "#unit-" + grdUnit.unit.unitGuid.toString()
    );
    if (container) {
      const children = container.querySelectorAll(".grid-day");
      for (const cell of children) {
        const cellElement = cell as HTMLTextAreaElement;
        const thisCellData = cellElement?.getAttribute("data-date")?.toString();
        if (thisCellData) {
          const thisCellsDate = new Date(thisCellData);
          const gridDay = this.getGridDay(thisCellsDate, grdUnit);
          if (!gridDay) {
            //disregard; it's nothing
          } else {
            if (thisCellsDate.valueOf() === secondDate.valueOf()) {
              gridDay.selected = true;
              gridDay.isSecondDate = true;
              gridDay.isFirstDate = false;
              gridDay.inRange = false;
            } else if (thisCellsDate.valueOf() === firstDate.valueOf()) {
              gridDay.selected = true;
              gridDay.isFirstDate = true;
              gridDay.isSecondDate = false;
              gridDay.inRange = false;
            } else if (
              (thisCellsDate.valueOf() < secondDate.valueOf() &&
                thisCellsDate.valueOf() > firstDate.valueOf()) ||
              (thisCellsDate.valueOf() > secondDate.valueOf() &&
                thisCellsDate.valueOf() < firstDate.valueOf())
            ) {
              gridDay.inRange = true;
              gridDay.selected = false;
              gridDay.isFirstDate = false;
              gridDay.isSecondDate = false;
            } else {
              gridDay.inRange = false;
              gridDay.selected = false;
              gridDay.isFirstDate = false;
              gridDay.isSecondDate = false;
            }
          }
        }
      }
    }
  }

  private getGridDay(
    forDate: Date,
    forGridUnit: GridUnit
  ): GridDay | undefined {
    if (!forGridUnit) {
      return;
    }
    if (!forDate) {
      return;
    }
    for (const gridUnit of this.gridModel.gridUnits) {
      if (gridUnit.unit.unitGuid === forGridUnit.unit.unitGuid) {
        for (const gridDay of forGridUnit.gridDays)
          if (new Date(gridDay.dateFor).areSameDay(forDate)) {
            return gridDay;
          }
      }
    }
  }

  minMaxNightsDisplay(gridUnit: GridUnit) {
    if (
      gridUnit.unit.unitType == UnitTypes.Event ||
      gridUnit.unit.unitType == UnitTypes.Rental
    ) {
      return "";
    }
    if (gridUnit.unit.minimumNights == 0) {
      gridUnit.unit.minimumNights = 1;
    }
    if (gridUnit.unit.maximumNights == 0) {
      gridUnit.unit.maximumNights = gridUnit.unit.minimumNights;
    }
    //can be guests or nights
    let result = "night";
    if (gridUnit.unit.maximumNights === gridUnit.unit.minimumNights) {
      result = gridUnit.unit.maximumNights + " " + result;
    } else {
      result =
        gridUnit.unit.minimumNights +
        " to " +
        gridUnit.unit.maximumNights +
        " " +
        result;
    }
    if (gridUnit.unit.maximumNights != 1) {
      result += "s";
    }
    return result;
  }

  minMaxPeopleDisplay(gridUnit: GridUnit) {
    if (
      gridUnit.unit.unitType == UnitTypes.Event ||
      gridUnit.unit.unitType == UnitTypes.Rental
    ) {
      // it's a rental. what to display??
      return gridUnit.unit.quantity + " total spots available";
    }

    if (gridUnit.unit.minimumPeople == 0) {
      gridUnit.unit.minimumPeople = 1;
    }
    if (gridUnit.unit.capacity == 0) {
      gridUnit.unit.capacity = gridUnit.unit.minimumPeople;
    }
    //can be guests or nights
    let result = "people";
    if (gridUnit.unit.capacity == 1) {
      result = "person";
    }
    if (gridUnit.unit.capacity === gridUnit.unit.minimumPeople) {
      result = gridUnit.unit.capacity + " " + result;
    } else {
      result =
        gridUnit.unit.minimumPeople +
        " to " +
        gridUnit.unit.capacity +
        " " +
        result;
    }
    return result;
  }

  async datePickedHandler(datePicked: Date) {
    this.showOverlay = true;
    this.gridModel.startDate = datePicked;
    await this.populateGrid();
    this.showOverlay = false;
  }

  toggleDatePicker() {
    this.showDatePicker = !this.showDatePicker;
  }

  getUnitGridDay(gridDay: GridDay, gridUnit: GridUnit) {
    return gridUnit.gridDays.find((d) => d.dateFor === gridDay.dateFor);
  }

  onWindowResized() {
    this.windowWidth = window.innerWidth;
  }

  isMobile(): boolean {
    return this.windowWidth < 700;
  }

  closePopover() {
    if (!this.frozen) {
      if (this.isPopoverVisible) {
        this.isPopoverVisible = false;
      }
    }
  }
  openPopover(elementName: string) {
    if (!this.frozen) {
      const element = this.$refs[elementName];
      if (element) {
        this.popoverOptions.popoverReference = element[0];
      }
      this.isPopoverVisible = true;
    }
  }

  async scroll(days: number) {
    this.showOverlay = true;
    this.skipUnavailable = false;
    this.gridModel.startDate = new Date(this.gridModel.startDate).addDays(days);
    this.isPopoverVisible = false;
    await this.populateGrid();
    this.showOverlay = false;
  }

  scrollToTop() {
    const myDiv = document.getElementById("book-wrap");
    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }

  async getMonths() {
    const month = new GridMonth();
    const endMonth = new GridMonth();
    const months = new Array<GridMonth>();
    month.name = new Date(this.gridModel.startDate).getMonthName(false);
    month.year = new Date(this.gridModel.startDate).getFullYear().toString();
    month.daysDisplayed = this.gridModel.length;
    months.push(month);
    const endMonthName = new Date(this.gridModel.startDate)
      .addDays(this.gridModel.length)
      .getMonthName(false);
    if (month.name !== endMonthName) {
      const daysInFirstMonth = new Date(
        this.gridModel.startDate
      ).daysInMonthAndYear(
        new Date(this.gridModel.startDate).getMonth() + 1,
        new Date(this.gridModel.startDate).getFullYear()
      );
      const lastDayInMonth = new Date(
        new Date(this.gridModel.startDate).getFullYear(),
        new Date(this.gridModel.startDate).getMonth(),
        daysInFirstMonth
      );
      const daysDisplayedInFirstMonth = new Date(
        this.gridModel.startDate
      ).daysDiff(lastDayInMonth);

      if (daysDisplayedInFirstMonth < this.gridModel.length) {
        month.daysDisplayed = daysDisplayedInFirstMonth;
        endMonth.name = endMonthName;
        endMonth.year = new Date(this.gridModel.startDate)
          .addDays(this.gridModel.length)
          .getFullYear()
          .toString();
        endMonth.daysDisplayed = this.gridModel.length - month.daysDisplayed;
        months.push(endMonth);

        if (month.daysDisplayed <= 0) {
          month.daysDisplayed = 1;
          endMonth.daysDisplayed = 13;
        }
        if (month.daysDisplayed == 1) {
          month.daysDisplayed = 0;
          endMonth.daysDisplayed = 12;
        }

        if (month.daysDisplayed < 3) {
          month.name = "";
          month.year = "";
        }
        if (endMonth.daysDisplayed <= 3) {
          endMonth.name = "";
          endMonth.year = "";
        }
      }
    }
    if (months.length > 1) {
      endMonth.daysDisplayed = 12 - month.daysDisplayed;
    }
    return months;
  }

  async populateGrid() {
    this.firstClickedDate = null;
    this.secondClickedDate = null;
    const param: GridParameters = {
      propertyGuid: this.property.propertyGuid,
      unitGuid: null,
      startDate: this.gridModel.startDate,
      length: this.length,
      isMobile: false,
      skipUnavailable: this.skipUnavailable,
    };
    this.gridModel = await getAvailability(param);
    this.gridModel.gridMonths = await this.getMonths();
  }

  created() {
    window.addEventListener("resize", this.onWindowResized);
  }

  async mounted() {
    this.showOverlay = true;
    this.skipUnavailable = false;

    this.gridModel.startDate = this.availParams
      ? this.availParams.arrivalDate
      : new Date();

   await this.populateGrid();
    this.unitGroups = await getUnitGroups(this.property.propertyGuid);
    if (this.unitGroups.length == 0) {
      let group = new UnitGroup();
      group.name = "";
      this.unitGroups.push(group);
    }
    this.scrollToTop();
    this.showOverlay = false;
  }

  destroyed() {
    window.removeEventListener("resize", this.onWindowResized);
  }
}
